import React from 'react';
import Table from 'react-table-lite';
import { Edit, Eye } from '../svgs';

export default function TableLite(props) {
  const CUSTOM_ACTION_BUTTONS = {
    view: (row) => (
       <Eye className="h-5 w-5 cursor-pointer" onClick={e => props?.onRowView(e, row)} />
    ),
    edit: (row) => (
       <Edit className="small-left-margin h-5 w-5 cursor-pointer" onClick={e => props?.onRowEdit(e, row)} />
    ),
  }; 
  return (
    <Table
      {...props}
      showNumberofPages={5}
      showPagination={Boolean(props?.showPagination)}
      currentPage={props?.currentPage || 1}
      onPaginate={(e, page) => props?.onPaginate(page)}
      customRenderActions={CUSTOM_ACTION_BUTTONS}
      headerStyle={{ textTransform: 'capitalize' }}
    />
  )
}