// Packages
import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { Store } from 'react-notifications-component';
import dayjs from "dayjs";
import jsPDF from 'jspdf';

// Apis
import { ApprovedFsrDetailsRequest, ApprovedFsrListRequest } from "../../../../requests";

// Utils
import { authenticationErrorHandle, findObjectDifferences, constants, decodeString, ignoreTimeZone } from "../../../../utils";
import { auth } from "../../../../atoms";
import { Clock, Loader, Modal, X } from "../../../../components";
import RequestComparisonChangesSection from "./requestComparisonChangesSection";

function RequestHistoryModal({
    requestId,
    approvedHistoryId,
    showHistoryModal,
    closeHistoryModal,
    crewMembers,
    services

}) {

    const [isLoading, _isLoading] = useState(false);
    const [authState, _authState] = useAtom(auth);
    const [approvedHistoryList, _approvedHistoryList] = useState([]);
    const [historyObjectToCompare, _historyObjectToCompare] = useState({});
    const [historyObjectToBeCompared, _historyObjectToBeCompared] = useState({});
    const [changesFound, _changesFound] = useState(null);

    useEffect(() => {
        if (requestId && approvedHistoryId) {
            getToBeComparedApprovedFsrDetails(approvedHistoryId);
            getApprovedFsrList(requestId);
        }
    }, [requestId, approvedHistoryId]);

    useEffect(() => {
        if (historyObjectToCompare && historyObjectToCompare?.id) {
            calculateDifference();
        }
    }, [historyObjectToCompare]);

    const calculateDifference = () => {
        const requestDetailKeys = [
            "comments",
            "aircraft",
            "contact_person_details",
            "purpose_of_flight",
            "urgent_request"
        ];
        const requestLegKeys = [
            "leg_type",
            "departure_time",
            "arrival_time",
            "destination_airport_iata",
            "departure_airport_iata",
            "destination_airport_icao",
            "departure_airport_icao"
        ];

        const requestDetailsOne = {};
        const requestDetailsTwo = {};
        let crewMembersOne = [];
        let crewMembersTwo = [];
        let servicesToOne = [];
        let servicesToTwo = [];
        let servicesFromOne = [];
        let servicesFromTwo = [];
        let hotelsOne = [];
        let hotelsTwo = [];
        let transportationOne = [];
        let transportationTwo = [];
        let cateringOne = [];
        let cateringTwo = [];

        requestDetailKeys.forEach(key => {
            requestDetailsOne[key] = historyObjectToCompare[key];
            requestDetailsTwo[key] = historyObjectToBeCompared[key];
        });

        let requestLegsOne = [...historyObjectToCompare?.approve_request_leg];
        let requestLegsTwo = [...historyObjectToBeCompared?.approve_request_leg];

        /********* Comparing Legs  *********/
        const filteredRequestLegsOne = requestLegsOne.map(L => {
            let filteredLegObj = {};
            requestLegKeys.forEach(key => {
                filteredLegObj[key] = L[key];
            });
            return filteredLegObj;
        });

        const filteredRequestLegsTwo = requestLegsTwo.map(L => {
            let filteredLegObj = {};
            requestLegKeys.forEach(key => {
                filteredLegObj[key] = L[key];
            });
            return filteredLegObj;
        });

        const haveLegsChanged = filteredRequestLegsTwo
            ?.map(L => `${L?.departure_airport_icao}/${L?.departure_airport_iata} → ${L?.destination_airport_icao}/${L?.destination_airport_iata}`)?.toLocaleString() !==
            filteredRequestLegsOne
                ?.map(L => `${L?.departure_airport_icao}/${L?.departure_airport_iata} → ${L?.destination_airport_icao}/${L?.destination_airport_iata}`)?.toLocaleString();

        const changesInLegs = findObjectDifferences(filteredRequestLegsOne, filteredRequestLegsTwo)?.filter(L => L?.lhs && L?.rhs);

        /********* Setting Crew Members for comparison  *********/
        crewMembersOne = requestLegsOne?.map(L => {
            const crewmembers = L?.approve_request_leg_crew_member
                ?.map(c => c.crewmember)
                ?.sort((a, b) => a > b);
            return ({
                id: `${L?.departure_airport_icao}/${L?.departure_airport_iata} → ${L?.destination_airport_icao}/${L?.destination_airport_iata}`,
                crewmembers
            })
        });

        crewMembersTwo = requestLegsTwo?.map(L => {
            const crewmembers = L?.approve_request_leg_crew_member
                ?.map(c => c.crewmember)
                ?.sort((a, b) => a > b);
            return ({
                id: `${L?.departure_airport_icao}/${L?.departure_airport_iata} → ${L?.destination_airport_icao}/${L?.destination_airport_iata}`,
                crewmembers
            })
        });

        /********* Setting Services for comparison  *********/
        servicesToOne = requestLegsOne?.map(L => {
            const servicesTo = L?.approve_request_leg_service_to
                ?.map(s => s.service)
                ?.sort((a, b) => a > b);
            return ({
                id: `${L?.departure_airport_icao}/${L?.departure_airport_iata} → ${L?.destination_airport_icao}/${L?.destination_airport_iata}`,
                servicesTo
            })
        });

        servicesToTwo = requestLegsTwo?.map(L => {
            const servicesTo = L?.approve_request_leg_service_to
                ?.map(s => s.service)
                ?.sort((a, b) => a > b);
            return ({
                id: `${L?.departure_airport_icao}/${L?.departure_airport_iata} → ${L?.destination_airport_icao}/${L?.destination_airport_iata}`,
                servicesTo
            })
        });

        servicesFromOne = requestLegsOne?.map(L => {
            const servicesFrom = L?.approve_request_leg_service_from
                ?.map(s => s.service)
                ?.sort((a, b) => a > b);
            return ({
                id: `${L?.departure_airport_icao}/${L?.departure_airport_iata} → ${L?.destination_airport_icao}/${L?.destination_airport_iata}`,
                servicesFrom
            })
        });

        servicesFromTwo = requestLegsTwo?.map(L => {
            const servicesFrom = L?.approve_request_leg_service_from
                ?.map(s => s.service)
                ?.sort((a, b) => a > b);
            return ({
                id: `${L?.departure_airport_icao}/${L?.departure_airport_iata} → ${L?.destination_airport_icao}/${L?.destination_airport_iata}`,
                servicesFrom
            })
        });

        /********* Setting Hotels for comparison  *********/
        hotelsOne = requestLegsOne?.map(L => {
            const isHotelAvailable = L?.approve_request_leg_hotel?.length;
            const hotelMembers = L?.approve_request_leg_hotel?.[0]
                ?.approve_request_leg_hotel_pax?.map(pax => pax.crewMembers)
                ?.sort((a, b) => a > b) || [];
            return ({
                id: `${L?.departure_airport_icao}/${L?.departure_airport_iata} → ${L?.destination_airport_icao}/${L?.destination_airport_iata}`,
                hotelMembers,
                isHotelAvailable
            })
        });

        hotelsTwo = requestLegsTwo?.map(L => {
            const isHotelAvailable = L?.approve_request_leg_hotel?.length;
            const hotelMembers = L?.approve_request_leg_hotel?.[0]
                ?.approve_request_leg_hotel_pax?.map(pax => pax.crewMembers)
                ?.sort((a, b) => a > b) || [];
            return ({
                id: `${L?.departure_airport_icao}/${L?.departure_airport_iata} → ${L?.destination_airport_icao}/${L?.destination_airport_iata}`,
                hotelMembers,
                isHotelAvailable
            })
        });

        /********* Setting Transportation for comparison  *********/
        transportationOne = requestLegsOne?.map(L => {
            const isTransportationAvailable = L?.approve_request_leg_transportation?.length;
            const transportations = L?.approve_request_leg_transportation
                ?.map(T => `${T.transport_type} (${T.transport_time})`)
                ?.sort((a, b) => a > b);
            return ({
                id: `${L?.departure_airport_icao}/${L?.departure_airport_iata} → ${L?.destination_airport_icao}/${L?.destination_airport_iata}`,
                transportations,
                isTransportationAvailable
            })
        });

        transportationTwo = requestLegsTwo?.map(L => {
            const isTransportationAvailable = L?.approve_request_leg_transportation?.length;
            const transportations = L?.approve_request_leg_transportation
                ?.map(T => `${T.transport_type} (${T.transport_time})`)
                ?.sort((a, b) => a > b);
            return ({
                id: `${L?.departure_airport_icao}/${L?.departure_airport_iata} → ${L?.destination_airport_icao}/${L?.destination_airport_iata}`,
                transportations,
                isTransportationAvailable
            })
        });

        /********* Setting Catering for comparison  *********/
        cateringOne = requestLegsOne?.map(L => {
            const isCateringAvailable = L?.approve_request_leg_catering_from?.length;
            const catering = L?.approve_request_leg_catering_from
                ?.map(c => `${c?.type_of_catering} x ${c?.crewcat}`)
                ?.sort((a, b) => a > b);
            return ({
                id: `${L?.departure_airport_icao}/${L?.departure_airport_iata} → ${L?.destination_airport_icao}/${L?.destination_airport_iata}`,
                catering,
                isCateringAvailable
            })
        });

        cateringTwo = requestLegsTwo?.map(L => {
            const isCateringAvailable = L?.approve_request_leg_catering_from?.length;
            const catering = L?.approve_request_leg_catering_from
                ?.map(c => `${c?.type_of_catering} x ${c?.crewcat}`)
                ?.sort((a, b) => a > b);
            return ({
                id: `${L?.departure_airport_icao}/${L?.departure_airport_iata} → ${L?.destination_airport_icao}/${L?.destination_airport_iata}`,
                catering,
                isCateringAvailable
            })
        });

        /********* Comparing Crew Members  *********/
        const changedCrewMembers = crewMembersTwo?.map(parent => {
            const matchedLeg = crewMembersOne?.find(matched => matched?.id === parent.id)
            if (matchedLeg &&
                matchedLeg?.crewmembers?.sort((a, b) => a > b).toLocaleString() !== parent?.crewmembers?.sort((a, b) => a > b).toLocaleString()
            ) {
                return ({ id: parent?.id, lhs: matchedLeg?.crewmembers, rhs: parent?.crewmembers });
            }
            else return {};
        });

        const addedCrewMembers = crewMembersTwo?.map(parent => {
            const matchedLeg = crewMembersOne?.every(matched => matched?.id !== parent.id)
            if (matchedLeg && parent?.crewmembers?.length) {
                return ({ id: parent?.id, rhs: parent?.crewmembers });
            }
            else return {};
        });

        /********* Comparing Services  *********/
        const changedServicesFrom = servicesFromTwo?.map(parent => {
            const matchedLeg = servicesFromOne?.find(matched => matched?.id === parent.id)
            if (matchedLeg &&
                matchedLeg?.servicesFrom?.sort((a, b) => a > b).toLocaleString() !== parent?.servicesFrom?.sort((a, b) => a > b).toLocaleString()
            ) {
                return ({ id: parent?.id, lhs: matchedLeg?.servicesFrom, rhs: parent?.servicesFrom });
            }
            else return {};
        });

        const addedServicesFrom = servicesFromTwo?.map(parent => {
            const matchedLeg = servicesFromOne?.every(matched => matched?.id !== parent.id)
            if (matchedLeg && parent?.servicesFrom?.length) {
                return ({ id: parent?.id, rhs: parent?.servicesFrom });
            }
            else return {};
        });

        const changedServicesTo = servicesToTwo?.map(parent => {
            const matchedLeg = servicesToOne?.find(matched => matched?.id === parent.id)
            if (matchedLeg &&
                matchedLeg?.servicesTo?.sort((a, b) => a > b).toLocaleString() !== parent?.servicesTo?.sort((a, b) => a > b).toLocaleString()
            ) {
                return ({ id: parent?.id, lhs: matchedLeg?.servicesTo, rhs: parent?.servicesTo });
            }
            else return {};
        });

        const addedServicesTo = servicesToTwo?.map(parent => {
            const matchedLeg = servicesToOne?.every(matched => matched?.id !== parent.id)
            if (matchedLeg && parent?.servicesTo?.length) {
                return ({ id: parent?.id, rhs: parent?.servicesTo });
            }
            else return {};
        });

        /********* Comparing Hotels *********/
        const changedHotels = hotelsTwo?.map(parent => {
            const matchedLeg = hotelsOne?.find(matched => matched?.id === parent.id)
            if (matchedLeg &&
                (matchedLeg.isHotelAvailable > parent?.isHotelAvailable ||
                    matchedLeg?.hotelMembers?.sort((a, b) => a > b).toLocaleString() !== parent?.hotelMembers?.sort((a, b) => a > b).toLocaleString()
                )
            ) {
                return ({ id: parent?.id, lhs: matchedLeg?.hotelMembers, rhs: parent?.hotelMembers, hasHotelRemoved: matchedLeg?.isHotelAvailable > parent?.isHotelAvailable });
            }
            else return {};
        });

        /********* Comparing Transportation  *********/
        const changedTransportations = transportationTwo?.map(parent => {
            const matchedLeg = transportationOne?.find(matched => matched?.id === parent.id)
            if (matchedLeg &&
                matchedLeg?.transportations?.sort((a, b) => a > b).toLocaleString() !== parent?.transportations?.sort((a, b) => a > b).toLocaleString()
            ) {
                return ({ id: parent?.id, lhs: matchedLeg?.transportations, rhs: parent?.transportations, hasTransportationRemoved: matchedLeg?.isTransportationAvailable > parent?.isTransportationAvailable });
            }
            else return {};
        });

        /********* Comparing Catering  *********/
        const changedCatering = cateringTwo?.map(parent => {
            const matchedLeg = cateringOne?.every(matched => matched?.id !== parent.id)
            if (matchedLeg && parent?.catering?.length) {
                return ({ id: parent?.id, rhs: parent?.catering, hasCateringRemoved: matchedLeg?.isCateringAvailable > parent?.matchedLeg });
            }
            else return {};
        });

        _changesFound({
            fsrDetails: findObjectDifferences(requestDetailsOne, requestDetailsTwo),
            oldLegs: filteredRequestLegsOne,
            newLegs: filteredRequestLegsTwo,
            changesInLegs,
            haveLegsChanged,
            addedCrewMembers,
            changedCrewMembers,
            changedServicesFrom,
            addedServicesFrom,
            changedServicesTo,
            addedServicesTo,
            changedHotels,
            changedTransportations,
            changedCatering
        });
    };

    const getApprovedFsrList = (requestId) => {
        const token = decodeString(authState);
        if (isLoading) return;
        _isLoading(true);
        ApprovedFsrListRequest(token, requestId).then(res => {
            if (res && res?.status === 401) {
                authenticationErrorHandle(() => _authState('0'));
                return (
                    { errorCodes: constants.SESSIONTIMEOUT }
                );
            } else return (res.json())
        }).then(data => {
            if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
            if (data) {
                _approvedHistoryList([...data?.filter(history => history?.id != approvedHistoryId)]);
            } else {
                throw 'Request Failed';
            }
        }
        ).catch(
            err => {
                console.error(err);
                // _loadingApprovedFsrList(old => [...old?.filter(id => id !== requestId)]);
                Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch approved request history' });
            }
        );
    };

    const getToComapareWithApprovedFsrDetails = (approvedRequestId) => {
        const token = decodeString(authState);
        _isLoading(true);
        ApprovedFsrDetailsRequest(token, approvedRequestId).then(res => {
            if (res && res?.status === 401) {
                authenticationErrorHandle(() => _authState('0'));
                return (
                    { errorCodes: constants.SESSIONTIMEOUT }
                );
            } else return (res.json())
        }).then(data => {
            if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
            if (data) {
                _isLoading(false);
                _historyObjectToCompare(data);
            } else {
                throw 'Request Failed';
            }
        }
        )
            .catch(
                err => {
                    _isLoading(false);
                    console.error(err);
                    Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch request details' });
                }
            )
    };

    const getToBeComparedApprovedFsrDetails = (approvedRequestId) => {
        const token = decodeString(authState);
        // _isLoading(true);
        ApprovedFsrDetailsRequest(token, approvedRequestId).then(res => {
            if (res && res?.status === 401) {
                authenticationErrorHandle(() => _authState('0'));
                return (
                    { errorCodes: constants.SESSIONTIMEOUT }
                );
            } else return (res.json())
        }).then(data => {
            if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
            if (data) {
                _isLoading(false);
                _historyObjectToBeCompared(data);
            } else {
                throw 'Request Failed';
            }
        }
        )
            .catch(
                err => {
                    // _isLoading(false);
                    console.error(err);
                    Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch request details' });
                }
            )
    };

    // *********** Handlers ***********

    const handleSelectHistoryObject = (history) => {
        getToComapareWithApprovedFsrDetails(history?.id);
    };

    const handleGoBackToHistoryList = () => {
        _changesFound(null);
    };

    const handleDownloadPdf = () => {
        const fileName = `Changes_Log.pdf`;
        // create a wrapper div for content and set it's width
        const wrapper = document.createElement('div');
        wrapper.style.width = '1020px';
        wrapper.style.margin = '0px';
        // create a duplicate of the content div which is to be printed
        let sourceDiv = document.querySelector('.changes-block');
        sourceDiv = sourceDiv.cloneNode(true);
        sourceDiv.style.padding = '0px';
        sourceDiv.innerHTML = sourceDiv?.innerHTML?.replaceAll('→', '>>>');
        wrapper.appendChild(sourceDiv);
        let doc = new jsPDF('p', 'px', [650, 1500]);
        // create water mark        
        doc.html(wrapper, {
            callback: function (doc) {
                doc.save(fileName);
            },
            autoPaging: 'text',
            html2canvas: { scale: 0.6 },
            margin: [25, 0, 25, 0],
            x: 18,
            y: 0
        });
    };

    // *********** Render Functions ***********

    const MODAL_HEADER = () => (
        <div className="flex wrap justify-between log-modal-header">
            <h4 className="history-heading"> {changesFound ? "Changes" : "Select request history to compare"} </h4>
            <X onClick={closeHistoryModal} className='w-6 h-6' />
        </div>
    );

    const HISTORY_LIST = () => (
        <div>
            <ul className="no-style-list text-primary-blue log-history-list">
                {!isLoading &&
                approvedHistoryList &&
                approvedHistoryList?.length
                ? approvedHistoryList?.map((history, index) => (
                    <li key={index} onClick={e => handleSelectHistoryObject(history)}>
                        <div className="history-icon-holder">
                            <Clock />
                        </div>
                        <div className="w3-medium cursor-pointer">
                            <div className="heading">{`ID: ${history?.request}-${history?.version}`}</div>
                            <div className="description">{`Updated on: ${dayjs(ignoreTimeZone(history?.updatedat))?.format('YYYY-MM-DD HH:mm')}`}</div>
                        </div>
                    </li>
                ))
                : <> No changes history available </>
                }
            </ul>
        </div>
    );

    const LOADER = () => (
        <div className="request-form-container">
            <div className="h-30 flex justify-center items-center">
                <div><Loader spinnerClassName='w-10 h-10 text-primary-blue' />
                    <p className='text-primary-blue'> Loading data... </p>
                </div>
            </div>
        </div>
    );

    const FOOTER = () => (
        <div className='flex wrap justify-end w-full text-right small-top-margin'>
            <button onClick={handleGoBackToHistoryList} className='w3-btn bg-primary-blue w3-text-white'> Back </button>
            <button className='w3-btn bg-primary-blue w3-text-white small-left-margin' onClick={handleDownloadPdf}> Print </button>
        </div>
    );

    const CHANGES = () => (
        <div>
            <div className="changes-block">
                <RequestComparisonChangesSection
                    changesFound={changesFound}
                    historyObjectToCompare={historyObjectToCompare}
                    historyObjectToBeCompared={historyObjectToBeCompared}
                    crewMembers={crewMembers}
                    services={services}
                />
            </div>
            {FOOTER()}
        </div>
    );

    return (
        <Modal isOpen={showHistoryModal} modalClass="w-half w3-round-medium">
            <div className="w-full p-2">
                {MODAL_HEADER()}
                {changesFound && !isLoading
                    ? CHANGES()
                    : HISTORY_LIST()
                }
                {
                    isLoading && LOADER()
                }
            </div>
        </Modal>
    );
}

export default RequestHistoryModal;
