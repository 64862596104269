import { constants } from "../utils";

const HotelBillingListRequest = (token, data) => {
  var requestHeaders = new Headers();
  requestHeaders.append("Authorization", `Bearer ${token}`);
  requestHeaders.append("Content-Type", "application/json");  
  return fetch(`${constants.BASEURL}/api/hotelrequest/get_hotel_with_filters/`, {
    method: "POST",
    body: data,
    headers: requestHeaders,
    redirect: "follow",
  });
};

export default HotelBillingListRequest;
