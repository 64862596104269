// Packages
import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import dayjs from "dayjs";
import jsPDF from 'jspdf';
import { Store } from "react-notifications-component";
import { useParams } from "react-router-dom";

// APIs 
import { ApprovedInsuranceRequestDetailsRequest, CrewMemberByFleetListRequest, FleetByAirbaseListRequest, AirbaseListRequest, GetApprovedInsurancePdfRequest } from '../../../requests';

// Utils
import { auth, airbasesCache, loggedUserInfoCache } from '../../../atoms';
import { constants, decodeString, authenticationErrorHandle } from '../../../utils';
import { Save } from "../../../components";


function ApprovedInsuranceRequestView() {

  const INITIAL_FORM = {
    data: {
      fleet: '',
      aircraft: '',
      location: '',
      airbase: '',
      contract: '',
      tailnumber: '',
      fleet_name: '',
      date_duration: dayjs()?.format('YYYY-MM-DD'),
      remarks: '',
      crew_members: [],
      status: 'draft'
    },
    errors: {}
  };
  const { insuranceRequestId } = useParams();

  const [form, _form] = useState({ ...INITIAL_FORM });
  const [isLoading, _isLoading] = useState(false);
  const [isLoadingCrew, _isLoadingCrew] = useState(false);
  const [authState, _authState] = useAtom(auth);
  const [airbases, _airbases] = useAtom(airbasesCache);
  const [loggedUserInfo, _loggedUserInfo] = useAtom(loggedUserInfoCache);
  const [fleet, _fleet] = useState([]);
  const [crewMembers, _crewMembers] = useState([]);

  // Get airbases and menu items either from cache or from server
  useEffect(() => {
    if (authState) {
      if (!airbases || !airbases.created || Date.now() - airbases.created >= 1200000) {
        getAirbases();
      }
      if (insuranceRequestId) {
        getInsuranceRequestDetails();
      }
    }
  }, [authState, insuranceRequestId]);

  // Auto set fleet and airbase in edit case
  useEffect(() => {
    if (authState && loggedUserInfo && loggedUserInfo?.data) {
      setFleetAndAirbase();
    }
  }, [authState, airbases, loggedUserInfo?.data])

  // Get fleet list On change of airbase 
  useEffect(() => {
    if (authState && form?.data?.airbase) {
      getFleetByAirbase();
    }
  }, [authState, form?.data?.airbase]);

  // Get crewmembers, and aircrafts list On change of fleet 
  useEffect(() => {
    if (authState && form?.data?.fleet) {
      getCrewMembersByFleet();
    }
  }, [authState, form?.data?.fleet]);

  const setFleetAndAirbase = () => {
    // Set pre-selected fleet and airbase according to loggedIn user's info
    if (loggedUserInfo?.data?.fleet && loggedUserInfo?.data?.fleet?.id) {
      _form(old => ({
        ...old,
        data: {
          ...old?.data,
          fleet: loggedUserInfo?.data?.fleet?.id || '',
          airbase: loggedUserInfo?.data?.fleet?.airbase || '',
          sendby: loggedUserInfo?.data?.username || '',
        }
      }));
    }
  };

  const getInsuranceRequestDetails = () => {
    _isLoading(true);
    const token = decodeString(authState);
    ApprovedInsuranceRequestDetailsRequest(token, insuranceRequestId).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        _isLoading(false);
        // console.log(data);
        _form(old => (
          {
            ...old,
            data
          }
        ));
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch insurance request details' });
        }
      )
  };

  const getAirbases = () => {
    const token = decodeString(authState);
    AirbaseListRequest(token).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((nonPaginatedData) => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in cache with current time
        _airbases({
          data: [...nonPaginatedData],
          created: Date.now(),
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch airbases" });
    });
  };

  const getFleetByAirbase = () => {
    const token = decodeString(authState);
    FleetByAirbaseListRequest(token, form?.data?.airbase).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((nonPaginatedData) => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in local state
        _fleet({
          data: [...nonPaginatedData],
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch fleet" });
    });
  };

  const getCrewMembersByFleet = () => {
    const token = decodeString(authState);
    _isLoadingCrew(true);
    CrewMemberByFleetListRequest(token, form?.data?.fleet).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((nonPaginatedData) => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in local state
        _crewMembers({
          data: [...nonPaginatedData]
        });
        _isLoadingCrew(false);
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      _isLoadingCrew(false);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch crew members" });
    });
  };

  // *********** Handlers ***********

  const handleDownloadPdf = () => {
    const fileName = `hotel_request_${insuranceRequestId}.pdf`;
    // create a wrapper div for content and set it's width
    const wrapper = document.createElement('div');
    wrapper.style.width = '1020px';
    wrapper.style.margin = '0px';
    // create a duplicate of the content div which is to be printed
    let sourceDiv = document.querySelector('.request-block');
    sourceDiv = sourceDiv.cloneNode(true);
    sourceDiv.style.padding = '0px';
    wrapper.appendChild(sourceDiv);
    let doc = new jsPDF('p', 'px', [650, 1500]);
    // create water mark
    for (let i = 1; i <= 2; i++) {
      doc.setPage(i)
      doc.saveGraphicsState();
      doc.setGState(new doc.GState({ opacity: 0.2, strokeOpacity: 1 }));
      doc.setTextColor('#1D1D1D40');
      // doc.text('DRAFT', 38, 15, { align: 'center', baseline: 'middle' })
      doc.restoreGraphicsState();
    }
    doc.html(wrapper, {
      callback: function (doc) {
        doc.save(fileName);
      },
      autoPaging: 'text',
      html2canvas: { scale: 0.6 },
      margin: [25, 0, 25, 0],
      x: 18,
      y: 0
    });
  };

  const handleGetPdfFromServer = () => {
    const token = decodeString(authState);
    GetApprovedInsurancePdfRequest(token, insuranceRequestId)
      .then(res => {
        if (!res.ok) {
          throw "Request Failed";
          // throw new Error('Network response was not ok');
        }
        return res.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `Insurance-${insuranceRequestId}.pdf`;
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch(err => {
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch pdf file" });
      })
  };

  // *********** Render Functions ***********

  const INSURANCE_REQUEST_DETAILS_SECTION = () => {
    return (
      <React.Fragment>
        <div className="table-heading w3-margin-top">
          <span className="float-left"> {`Id ${insuranceRequestId}`}</span>
          <span> INSURANCE REQUEST </span>
        </div>
        <table className="w3-table w3-border">
          <tbody>
            <tr>
              <td className='w3-border bold'>Status</td>
              <td className='w3-border'>
                <div>{form?.data?.status}</div>
              </td>
              <td className='w3-border bold'>Airbase</td>
              <td className='w3-border'>
                <div>{airbases?.data && airbases?.data?.find(A => A.id == form?.data?.airbase)?.name}</div>
              </td>
            </tr>
            <tr>
              <td className='w3-border bold'>Fleet</td>
              <td className='w3-border'>
                <div>{fleet?.data && fleet?.data?.find(F => F.id == form?.data?.fleet)?.name}</div>
              </td>
              <td className='w3-border bold'>Aircraft</td>
              <td className='w3-border'>
                <div>{form?.data?.tailnumber}</div>
              </td>
            </tr>
            <tr>
              <td className="w3-border bold"> Location </td>
              <td className='w3-border'>
                <div>{form?.data?.location}</div>
              </td>
              <td className="w3-border bold"> Date Duration </td>
              <td className='w3-border' colSpan={3}>
                <div>
                  {dayjs(form?.data?.date_duration_from)?.format('YYYY-MM-DD')} to {dayjs(form?.data?.date_duration_to)?.format('YYYY-MM-DD')}
                </div>
              </td>
            </tr>
            <tr>
              <td className="w3-border bold" colSpan={1}> Remarks </td>
              <td className='w3-border' colSpan={5}>
                <div>{form?.data?.remarks}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    )
  };

  const INSURANCE_REQUEST_CREW_SECTION = () => {
    const insurance_crew = [...form?.data?.crew_members?.map(c => parseInt(c.crew_member))];
    return (
      <React.Fragment>
        <div className="table-heading w3-margin-top">CREW MEMBERS</div>
        <table className="w3-table">
          <tbody>
            <tr className='bold'>
              <td className="title w3-border">Duty</td>
              <td className="title w3-border">Name</td>
              <td className="title w3-border">Gender</td>
              <td className="title w3-border">Saudia ID</td>
              <td className="title w3-border">Nationality</td>
              <td className="title w3-border">ID/Passport#</td>
              <td className="title w3-border">License#</td>
            </tr>
            {
              !crewMembers?.data?.length && isLoadingCrew
                ? <tr>
                  <td className='w3-border' colSpan={6}> Loading... </td>
                </tr>
                : insurance_crew?.map((crew, index) => (
                  <tr key={index}>
                    <td className='w3-border'>{crewMembers?.data?.find(C => crew == C?.id)?.crewtitle}</td>
                    <td className='w3-border'>{crewMembers?.data?.find(C => crew == C?.id)?.crewname}</td>
                    <td className='w3-border'>{constants.CREW_GENDER[crewMembers?.data?.find(C => crew == C?.id)?.gender]}</td>
                    <td className='w3-border'>{crewMembers?.data?.find(C => crew == C?.id)?.nationality?.toLowerCase()?.indexOf("saudi") > -1 ? "Yes" : "No"}</td>
                    <td className='w3-border'>{crewMembers?.data?.find(C => crew == C?.id)?.nationality}</td>
                    <td className='w3-border'>{crewMembers?.data?.find(C => crew == C?.id)?.passport}</td>
                    <td className='w3-border'>{crewMembers?.data?.find(C => crew == C?.id)?.license}</td>
                  </tr>
                ))
            }
          </tbody>
        </table>
      </React.Fragment>
    );
  };

  const DOWNLOAD_PDF_BUTTON = () => (
    <button
      // onClick={handleDownloadPdf}
      onClick={handleGetPdfFromServer}
      className={`pdf-download-button fixed bottom-0 right-0 w3-btn bg-primary-blue w3-text-white shadow-lg w3-circle`}
    >
      <Save className='h-10 w-10' />
    </button>
  );

  const INSURANCE_REQUEST_VIEW = () => (
    <div className='request-block'>
      {INSURANCE_REQUEST_DETAILS_SECTION()}
      {INSURANCE_REQUEST_CREW_SECTION()}
      {DOWNLOAD_PDF_BUTTON()}
    </div>
  );

  return (
    <div id='InsuranceRequest-View'>
      {INSURANCE_REQUEST_VIEW()}
    </div>
  )
}

export default ApprovedInsuranceRequestView;