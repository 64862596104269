// Packages
import React from 'react';

// Utils
import { Modal, Warn } from '../../../../components';

function StepbackConfirmationModal({
  isSubmitting,
  showConfirmStepBackModal,
  handleCloseConfirmStepBackModal,
  handleConfirmDiscardDataStepBack
}) {
  return (
    <Modal isOpen={showConfirmStepBackModal} modalClass="w-96 w3-round-medium">
      <div className="w-full">
        <div className='w3-pale-red w3-text-red py-1 px-2 flex flex-wrap items-center'>
          <Warn className='w-6 h-6 small-right-margin' />
          <h3>
            Discard Leg Setup
          </h3>
        </div>
        <div className='py-1 px-2'>
          Are you sure you want to go back?
          <p className='w3-text-red'> If you go back, all the changes made in leg setup will be discarded </p>
          <div className="h-3"></div>
          <div className="flex justify-end">
            <button onClick={handleCloseConfirmStepBackModal} className='w3-btn w3-grey w3-text-white'> Cancel </button>
            <button
              onClick={handleConfirmDiscardDataStepBack}
              disabled={false}
              className='w3-btn w3-red small-left-margin'
            >
              {isSubmitting ? 'Processing' : 'Confirm'}
            </button>
          </div>
          <div className="h-3"></div>
        </div>
      </div>
    </Modal>
  )
};

export default StepbackConfirmationModal;