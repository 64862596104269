// Packages
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { Spinner } from '../';
export default function Loader(props) {
  return (
    <div className={`${props.className} flex w-full justify-center items-center`}>
        <Spinner className={`w3-spin ${props.spinnerClassName}`} />
    </div>
  )
}

Loader.propTypes = {
  className: PropTypes.string,
  spinnerClassName: PropTypes.string
};

Loader.defaultProps = {
  className: '',
  spinnerClassName: ''
}
