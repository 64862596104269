// Packages
import React from "react";
import dayjs from "dayjs";

// Utils
import { Cancel, Edit, Eye, ChevronRight, Complete } from "../../../../components";
import { ignoreTimeZone } from "../../../../utils";

function CustomTicketRequestTable(props) {
    const CUSTOM_VIEW_BTN = {
        "render":
            <button
                style={{ "color": "black" }}
                className={"custom-view-fsr-btn"}
            >
                <span className="w3-tooltip">
                    <span className='tooltip-text w3-text w3-tag w-12'>
                        View
                    </span>
                    <Eye className='h-5 w-5' />
                </span>
            </button>
        ,
        "className": "custom-view-fsr-btn"
    };

    const CUSTOM_DELETE_BTN = {
        "render":
            <button
                style={{ "color": "black" }}
                className={"custom-edit-fsr-btn small-left-margin"}
            >
                <span className="w3-tooltip">
                    <span className='tooltip-text w3-text w3-tag w-12'>
                        Edit
                    </span>
                    <Cancel className='h-5 w-5' />
                </span>
            </button>
        ,
        "className": "custom-edit-fsr-btn"
    };

    const CUSTOM_EDIT_BTN = {
        "render":
            <button
                style={{ "color": "black" }}
                className={"custom-edit-fsr-btn small-left-margin"}
            >
                <span className="w3-tooltip">
                    <span className='tooltip-text w3-text w3-tag w-12'>
                        Edit
                    </span>
                    <Edit className='h-5 w-5' />
                </span>
            </button>
        ,
        "className": "custom-edit-fsr-btn"
    };

    const CUSTOM_COMPLETE_BTN = {
        "render":
            <button
                style={{ "color": "black" }}
                className={"custom-delete-fsr-btn small-left-margin"}
            >
                <span className="w3-tooltip">
                    <span className='tooltip-text w3-text w3-tag w-12'>
                        Cancel
                    </span>
                    <Complete className='h-5 w-5' />
                </span>
            </button>
        ,
        "className": "custom-delete-fsr-btn"
    };

    return (
        <div className='custom-data-table react-table-lite-container'>
            <table className='react-table-lite-table' cellSpacing={0}>
                <thead className='react-table-lite-header'>
                    <tr>
                        <th></th>
                        {props?.headers?.map((h, index) => (
                            <th key={index}>
                                <div>{h?.replaceAll('_', ' ')}</div>
                            </th>
                        ))}
                        <th> <div> Actions </div></th>
                    </tr>
                </thead>
                <tbody>
                    {props?.ticketRequestList?.length < 1 && props?.isLoading
                        ? <tr>
                            <td colSpan={props?.headers?.length + 2}> <div>Loading data...</div> </td>
                        </tr>
                        : props?.ticketRequestList?.length < 1 && !props?.isLoading
                            ? <tr><td colSpan={props?.headers?.length + 2}> <div>No data found</div></td></tr>
                            : null
                    }
                    {props?.ticketRequestList && props?.ticketRequestList?.map((TicketReq, index) => {
                        const row = <React.Fragment key={index}>
                            <tr className='react-table-lite-row'>
                                <td>
                                    <div className='cursor-pointer' onClick={e => props?.toggleExpandTicketRequest(TicketReq)}>
                                        <ChevronRight
                                            className={`w-4 h-4 ${props?.expandedTicketRequestListIds?.find(id => id == TicketReq?.id) ? 'rotate-clock-wise-90' : ''}`}
                                        />
                                    </div>
                                </td>

                                <td><div>{TicketReq?.id}</div></td>
                                <td><div>{TicketReq?.departure_city}</div></td>
                                <td><div>{TicketReq?.arrival_city}</div></td>
                                <td><div>{TicketReq?.no_of_ticket}</div></td>
                                <td><div>{dayjs(ignoreTimeZone(TicketReq?.departure_datetime))?.format('YYYY-MM-DD')}</div></td>
                                {/* <td><div>{TicketReq?.arrival_datetime}</div></td> */}
                                <td>
                                    <div
                                        className={{
                                            'draft': 'w3-text-grey',
                                            'Approved': 'w3-text-blue',
                                            'Completed': 'text-success',
                                            'Cancelled': 'w3-text-red',
                                        }[TicketReq?.status]}
                                    >
                                        {TicketReq?.status}
                                    </div>
                                </td>
                                <td className='rtl-table-actions'>
                                    <div className='rtl-action-btn-container flex'>
                                        {
                                            <span onClick={e => props?.routeToViewTicketRequest(TicketReq?.id)}>
                                                {CUSTOM_VIEW_BTN.render}
                                            </span>
                                        }
                                        {
                                            !TicketReq?.status?.toLowerCase()?.includes('completed') &&
                                                !TicketReq?.status?.toLowerCase()?.includes('cancel')
                                                ? <span onClick={e => props?.routeToEditTicketRequest(TicketReq?.id)}>
                                                    {CUSTOM_EDIT_BTN.render}
                                                </span>
                                                : null
                                        }
                                        {
                                            TicketReq?.status?.toLowerCase()?.includes('draft')
                                                ? <span onClick={e => props?.handleApproveRequest(TicketReq)}>
                                                    {CUSTOM_COMPLETE_BTN.render}
                                                </span>
                                                : null
                                        }
                                        {
                                            TicketReq?.status?.toLowerCase()?.includes('draft') ||
                                                TicketReq?.status?.toLowerCase()?.includes('approved')
                                                ? <span onClick={e => props?.handleCancelRequest(TicketReq)}>
                                                    {CUSTOM_DELETE_BTN.render}
                                                </span>
                                                : null
                                        }
                                    </div>
                                </td>
                            </tr>
                            {
                                props?.expandedTicketRequestListIds?.find(id => id == TicketReq?.id) &&
                                props?.approvedTicketRequestList
                                    ?.filter(item => item?.ticket_request == TicketReq?.id)
                                    ?.map((approvedTicketRequest, index) => (
                                        <tr className={`w3-pale-indigo react-table-lite-row ${index === 0 ? 'approved-row' : ''} `} key={index}>
                                            <td></td>
                                            <td><div>{`${TicketReq?.id}-${approvedTicketRequest?.version}`}</div></td>
                                            <td><div>{approvedTicketRequest?.departure_city}</div></td>
                                            <td><div>{approvedTicketRequest?.arrival_city}</div></td>
                                            <td><div>{approvedTicketRequest?.no_of_ticket}</div></td>
                                            <td><div>{dayjs(approvedTicketRequest?.departure_datetime)?.format('YYYY-MM-DD')}</div></td>
                                            <td><div>{approvedTicketRequest?.status}</div></td>
                                            <td className="rtl-table-actions">
                                                {
                                                    // index == 0
                                                    true
                                                        ? <div className='rtl-action-btn-container flex items-center'>
                                                            <span onClick={e => props?.routeToViewApprovedTicketRequest(approvedTicketRequest?.id)} className='cursor-pointer'>
                                                                {CUSTOM_VIEW_BTN.render}
                                                            </span>
                                                            {
                                                                approvedTicketRequest?.status?.toLowerCase()?.includes('approved') &&
                                                                    props?.isStaff
                                                                    ? <span onClick={e => props?.handleCompleteRequest(approvedTicketRequest)}>
                                                                        {CUSTOM_COMPLETE_BTN.render}
                                                                    </span>
                                                                    : null
                                                            }
                                                        </div>
                                                        : null
                                                }
                                            </td>
                                        </tr>
                                    ))
                            }
                            {
                                props?.expandedTicketRequestListIds?.find(id => id == TicketReq?.id) &&
                                    props?.loadingapprovedTicketRequestList?.find(id => id == TicketReq?.id)
                                    ? <tr>
                                        <td className='w3-pale-indigo'></td>
                                        <td className='w3-pale-indigo' colSpan={props?.headers?.length + 2}>
                                            <div> Loading history... </div>
                                        </td>
                                    </tr>
                                    : null
                            }
                            {
                                props?.expandedTicketRequestListIds?.find(id => id == TicketReq?.id) &&
                                    !props?.loadingapprovedTicketRequestList?.find(id => id == TicketReq?.id) &&
                                    !props?.approvedTicketRequestList?.find(item => item?.ticket_request == TicketReq?.id)
                                    ? <tr>
                                        <td className='w3-pale-indigo'></td>
                                        <td className='w3-pale-indigo' colSpan={props?.headers?.length + 2}>
                                            <div> No approved reservations history </div>
                                        </td>
                                    </tr>
                                    : null
                            }
                        </React.Fragment>;
                        return row;
                    })}
                </tbody>
            </table>
        </div>
    )
};

export default CustomTicketRequestTable;