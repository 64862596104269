import { constants } from "../utils";

const InsuranceRequestDetailsRequest = (token, insuranceRequestId) => {
    var requestHeaders = new Headers();
    requestHeaders.append("Authorization", `Bearer ${token}`);
    requestHeaders.append("Content-Type", "application/json");
    return (fetch(`${constants.BASEURL}/api/medicalinsurance/${insuranceRequestId}`, {
        method: 'GET',
        headers: requestHeaders,
        redirect: 'follow'
    }));
};

export default InsuranceRequestDetailsRequest;
