// Packages
import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Store } from 'react-notifications-component';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';

// APIs
import {
  FleetListRequest, 
  AirbaseListRequest,
  AircraftCreateRequest,
  AircraftDetailsRequest,
  AircraftEditRequest,
  AircraftListRequest
} from '../../../requests';

// Utils
import { auth, aircraftsCache, airbasesCache, fleetCache, loggedUserInfoCache } from '../../../atoms';
import { constants, decodeString, authenticationErrorHandle, validateSubmissionData } from '../../../utils';
import { Loader, Refresh } from '../../../components';

function AircraftForm() {
  const { aircraftId } = useParams();
  const Navigate = useNavigate();

  const INITIAL_FORM = {
    tailnumber: '',
    fleet: '',
    aircraft_type : '',
    contract: '',
    company: ''
  };

  const VALIDATIONS = {
    tailnumber: { isRequired: true },
    fleet: { isRequired: true, isNumber: true }    
  };

  const [isSubmitting, _isSubmitting] = useState(false);
  const [isLoading, _isLoading] = useState(aircraftId ? true : false);
  const [formErrors, _formErrors] =  useState({});
  const [form, _form] = useState({ ...INITIAL_FORM });
  const [authState, _authState] = useAtom(auth);
  const [loggedUserInfo] = useAtom(loggedUserInfoCache);
  const [aircrafts, _aircrafts] = useAtom(aircraftsCache);
  const [fleet, _fleet] = useAtom(fleetCache);
  const [airbases, _airbases] = useAtom(airbasesCache);

  useEffect(() => {
    if (aircraftId && authState) {
      getAircraftDetails();
    }
  }, [authState, aircraftId]);

  // Get fleet & airbases either from cache or from server
  useEffect(() => {
    if (authState) {
      if (!fleet || !fleet.created || Date.now() - fleet.created >= 1200000) {
        getFleet();
      }
      if (!airbases || !airbases.created || Date.now() - airbases.created >= 1200000) {
        getAirbases();
      }
    }
  }, [authState, form?.id]);

  // Set company from logged user Info for create case
  useEffect(() => {
    if (!aircraftId && loggedUserInfo?.data) {
      setCompany();
    }
  }, [loggedUserInfo?.data, form?.id]);

  const getFleet = () => {
    const token = decodeString(authState);
    FleetListRequest(token).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data && data.results) {
        // Keep server data in cache with current time
        _fleet({
          data: [...data.results],
          created: Date.now()
        });
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch fleet' });
        }
      )
  };

  const getAirbases = () => {
    const token = decodeString(authState);
    AirbaseListRequest(token).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(nonPaginatedData => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in cache with current time
        _airbases({
          data: [...nonPaginatedData],
          created: Date.now()
        });
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch airbases' });
        }
      )
  };

  const getAircraftDetails = () => {
    const token = decodeString(authState);
    _isLoading(true);
    AircraftDetailsRequest(token, aircraftId).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        _isLoading(false);
        _form(data);
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch aircraft details' });
        }
      )
  };

  const getAircrafts = () => {
    const token = decodeString(authState);
    AircraftListRequest(token).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data && data.results) {
        // Keep server data in cache with current time
        _aircrafts({
          data: [...data.results],
          created: Date.now()
        });
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch aircrafts' });
        }
      )
  };

  const setCompany = () => {
    _form(old => ({
      ...old,
      company: loggedUserInfo?.data?.company
    }))
  };

  // *********** Handlers ***********

  const handleChangeFleet = (name, value) => {
    // If fleet is changed set contract value according to airbase contract in feet
    let contract = '';
    if (fleet && fleet.data) {
      const selectedFleet = fleet?.data.find(F => F.id == value);
      const selectedAirbase = airbases?.data.find(A => A.id == selectedFleet?.airbase);
      contract = selectedAirbase ? selectedAirbase.contract : null;
    }
    _form(old => ({
      ...old,
      [name]: value,
      contract,
    }));
  };

  const handleAircraftFieldChange = (name, value) => {
    _form(old => ({
      ...old,
      [name]: value
    }));
  };

  const handleCheckValidation = () => {
    const { allValid, errors } = validateSubmissionData(form, VALIDATIONS);
    if (!allValid) {
      _formErrors(errors);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Required fields have errors" });
    } else {
      _formErrors({});
    }
    return allValid;
  };

  const handleSubmit = () => {
    const token = decodeString(authState);
    const data = JSON.parse(JSON.stringify(form));
    const allValid = handleCheckValidation();
    if (!allValid) return;
    _isSubmitting(true);
    if (aircraftId) {
      AircraftEditRequest(token, JSON.stringify(data), aircraftId)
        .then(res => {
          if (res && res?.status === 401) {
            authenticationErrorHandle(() => _authState('0'));
            return (
              { errorCodes: constants.SESSIONTIMEOUT }
            );
          } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
            throw 'Request Failed';
          } else return (res.json())
        })
        .then(data => {
          if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
          if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
            throw 'Request Failed';
          } else {
            getAircrafts();
            _isSubmitting(false);
            Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Aircraft updated' });
            Navigate("/aircrafts");
          }
        })
        .catch(err => {
          _isSubmitting(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
        });
    } else {
      AircraftCreateRequest(token, JSON.stringify(data))
        .then(res => {
          if (res && res?.status === 401) {
            authenticationErrorHandle(() => _authState('0'));
            return (
              { errorCodes: constants.SESSIONTIMEOUT }
            );
          } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
            throw 'Request Failed';
          } else return (res.json())
        })
        .then(data => {
          if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
          if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
            throw 'Request Failed';
          } else {
            getAircrafts();
            _isSubmitting(false);
            Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Aircraft Registered' });
            Navigate("/aircrafts");
          }
        })
        .catch(err => {
          _isSubmitting(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
        });
    }
  };

  // *********** Render Functions ***********

  const AIRCRAFT_DETAILS = () => (
    <React.Fragment>
      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <label> Tail Number </label>
          <input
            type='text'
            value={form?.tailnumber}
            onChange={e => handleAircraftFieldChange('tailnumber', e.target.value)}
            className={`w3-input w3-border w3-round small-top-margin small-bottom-margin ${formErrors?.tailnumber ? 'error-field' : ''}`}
          />
        </div>
        <div className='w3-col l6 m6 s12'>
          <label> Aircraft Type </label>
          <input
            type='text'
            placeholder='Enter Aircraft Type...'
            value={form?.aircraft_type}
            onChange={e => handleAircraftFieldChange('aircraft_type', e.target.value)}
            className={`w3-input w3-border w3-round small-top-margin small-bottom-margin ${formErrors?.aircraft_type ? 'error-field' : ''}`}
          />
        </div>
      </div>

      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <label> Fleet </label>
          <div className='flex items-center'>
            <Select
              placeholder='Search Fleet'
              options={fleet && fleet?.data?.map(F => (
                { value: F.id, label: F.name })
              )}
              value={form?.fleet ? {
                value: form?.fleet,
                label: fleet && fleet?.data?.find(F => F.id == form?.fleet)?.name
              } : null}
              onChange={F => handleChangeFleet('fleet', F.value)}
              className={`w-full small-top-margin small-bottom-margin ${formErrors?.fleet ? 'error-field' : ''}`}
            />
            <span onClick={getFleet} className='px-2 cursor-pointer'> <Refresh className='h-5 w-5 w3-text-grey' /> </span>
          </div>
        </div>
      </div>

    </React.Fragment>
  );

  const LOADER = () => (
    <div className="request-form-container">
      <div className="h-30 flex justify-center items-center">
        <div><Loader spinnerClassName='w-10 h-10 text-primary-blue' />
          <p className='text-primary-blue'> Loading data... </p>
        </div>
      </div>
    </div>
  );

  const FORM_FOOTER = () => (
    <React.Fragment>
      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12 text-right'>
          <button
            disabled={isSubmitting}
            onClick={handleSubmit}
            className='w3-btn bg-primary-blue w3-text-white small-top-margin'
          >
            {isSubmitting ? 'Submitting' : 'Submit'}
          </button>
        </div>
      </div>
      <div className='h-2'></div>
    </React.Fragment>
  );

  const AIRCRAFT_FORM = () => (
    <div className='request-form-container w-full relative'>
      <span className='heading'> Aircraft </span>
      <div className='border-primary-blue w3-round p-2'>
        {AIRCRAFT_DETAILS()}
        {FORM_FOOTER()}
      </div>
    </div>
  );
  return (
    <div id='Aircraft-Form'>
      {isLoading
        ? LOADER()
        : AIRCRAFT_FORM()
      }
    </div>
  )
}

export default AircraftForm;