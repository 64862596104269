// Packages
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { Store } from 'react-notifications-component';

// APIs
import { UserListRequest } from '../../../requests';

// Utils
import { constants, decodeString, authenticationErrorHandle } from '../../../utils';
import { auth, usersCache } from '../../../atoms';
import { TableLite, Plus, Refresh } from '../../../components';

function UserList() {

  const [isLoading, _isLoading] = useState(false);
  const [authState, _authState] = useAtom(auth);
  const [users, _users] = useAtom(usersCache);
  const Navigate = useNavigate();

  // Get users either from cache or from server
  useEffect(() => {
    if (authState) {
      if (!users || !users.created || Date.now() - users.created >= 1200000) {
        getUsers();
      }
    }
  }, [authState]);

  const getUsers = () => {
    const token = decodeString(authState);
    _isLoading(true);
    UserListRequest(token).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(
      data => {
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if (data && data.results) {
          // Keep server data in cache with current time
          _users({
            data: [...data.results],
            created: Date.now()
          });
          _isLoading(false);
        } else {
          throw 'Request Failed';
        }
      }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch users' });
        }
      )
  };

  const Userheaders = ["id", "firstname", "lastname", "email"];

  const CustomHeaders = {
    "id": "User Id",
    "firstname": "First Name",
    "lastname": "Last Name",
  };

  // *********** Handlers ***********

  const routeToEditUser = (id) => {
    Navigate(`/users/edit/${id}`);
  };

  const routeToNewUser = (id) => {
    Navigate(`/users/new`);
  };

  // *********** Render Functions ***********

  const CUSTOM_EDIT_BTN = {
    "render":
      <button
        style={{ "background": "green", "color": "white" }}
        className={"custom-edit-fsr-btn small-left-margin"}
      >
        <span className='w3-small'>Edit User</span>
      </button>
    ,
    "className": "custom-edit-fsr-btn"
  };

  const NEW_USER_BUTTON = () => (
    <div className='flex w3-margin-bottom'>
      <div className=' ml-auto'>
        <div className='flex'>
          <button className='flex items-center w3-btn bg-primary-blue w3-text-white small-right-margin' onClick={getUsers}>
            <Refresh className='w-4 h-4' /> <span className='px-1'> Refresh </span>
          </button>
          <button className='flex items-center w3-btn bg-primary-blue w3-text-white' onClick={routeToNewUser}>
            <Plus className='w-5 h-5' /> <span className='px-1'> New User </span>
          </button>
        </div>
      </div>
    </div>
  );

  const CONTENT = () => (
    <div className='page-content w3-white h-full relative overflow-hidden'>
      <div className='py-2'>
        {NEW_USER_BUTTON()}
        <div className='list-view-container overflow-auto'>
          <TableLite
            showActions={true}
            data={users && users?.data || []}
            headers={Userheaders}
            customHeaders={CustomHeaders}
            actionTypes={['edit']}
            renderEdit={CUSTOM_EDIT_BTN}
            onRowEdit={(event, row) => routeToEditUser(row.id)}
            cellStyle={{ fontSize: '0.8em' }}
          />
        </div>
      </div>
    </div>
  )
  return (
    <div>
      {CONTENT()}
    </div>
  )
}

export default UserList;