// Packages
import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Store } from 'react-notifications-component';
import { useParams } from 'react-router-dom';
import Select from 'react-select';

// APIs
import { FleetListRequest, CrewMemberCreateRequest, CrewMemberDetailsRequest, CrewMemberEditRequest, CrewMemberListRequest } from '../../../requests';

// Utils
import { auth, crewMembersCache, fleetCache, } from '../../../atoms';
import { constants, decodeString, authenticationErrorHandle } from '../../../utils';
import { Refresh } from '../../../components';

function UserForm() {
  const { userId } = useParams();

  const INITIAL_FORM = {
    crewname: '',
    crewtitle: '',
    license: '',
    passport: '',
    nationality: '',
    fleet: ''
  };

  const [isSubmitting, _isSubmitting] = useState(false);
  const [isLoading, _isLoading] = useState(false);
  const [form, _form] = useState({ ...INITIAL_FORM });
  const [authState, _authState] = useAtom(auth);
  const [, _crewMembers] = useAtom(crewMembersCache);
  const [fleet, _fleet] = useAtom(fleetCache);


  useEffect(() => {
    if (userId && authState) {
      getCrewMemberDetails();
    }
  }, [userId, authState]);

  // Get fleet either from cache or from server
  useEffect(() => {
    if (authState) {
      if (!fleet || !fleet.created || Date.now() - fleet.created >= 1200000) {
        getFleet();
      }
    }
  }, [authState, form?.id]);

  const getCrewMemberDetails = () => {
    const token = decodeString(authState);
    _isLoading(true);
    CrewMemberDetailsRequest(token, userId).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        _isLoading(false);
        _form(data);
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch crew member details' });
        }
      )
  };

  const getCrewMembers = () => {
    const token = decodeString(authState);
    CrewMemberListRequest(token).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data && data.results) {
        // Keep server data in cache with current time
        _crewMembers({
          data: [...data.results],
          created: Date.now()
        });
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch crew members' });
        }
      )
  };

  const getFleet = () => {
    const token = decodeString(authState);
    FleetListRequest(token).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data && data.results) {
        // Keep server data in cache with current time
        _fleet({
          data: [...data.results],
          created: Date.now()
        });
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch fleet' });
        }
      )
  };

  // *********** Handlers ***********

  const handleUserFieldChange = (name, value) => {
    _form(old => ({
      ...old,
      [name]: value
    }));
  };

  const handleSubmit = () => {
    const token = decodeString(authState);
    const data = JSON.parse(JSON.stringify(form));
    const form_data = new FormData();
    const JSONStringData = JSON.stringify(data);
    form_data.append('data', JSON.stringify(data));
    _isSubmitting(true);
    if (userId) {
      CrewMemberEditRequest(token, form_data, userId)
        .then(res => {
          if (res && res?.status === 401) {
            authenticationErrorHandle(() => _authState('0'));
            return (
              { errorCodes: constants.SESSIONTIMEOUT }
            );
          } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
            throw 'Request Failed';
          } else return (res.json())
        })
        .then(data => {
          if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
          if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
            throw 'Request Failed';
          } else {
            getCrewMembers();
            _isSubmitting(false);
            Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Crew member updated' });
          }
        })
        .catch(err => {
          _isSubmitting(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
        });
    } else {
      CrewMemberCreateRequest(token, form_data)
        .then(res => {
          if (res && res?.status === 401) {
            authenticationErrorHandle(() => _authState('0'));
            return (
              { errorCodes: constants.SESSIONTIMEOUT }
            );
          } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
            throw 'Request Failed';
          } else return (res.json())
        })
        .then(data => {
          if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
          if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
            throw 'Request Failed';
          } else {
            getCrewMembers();
            _isSubmitting(false);
            Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Crew member created' });
          }
        })
        .catch(err => {
          _isSubmitting(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
        });
    }
  };


  // *********** Render Functions ***********

  const USER_DETAILS = () => (
    <React.Fragment>
      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <label> Name </label>
          <input
            type='text'
            value={form?.crewname}
            onChange={e => handleUserFieldChange('crewname', e.target.value)}
            className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
          />
        </div>
        <div className='w3-col l6 m6 s12'>
          <label> Title </label>
          <input
            type='text'
            value={form?.crewtitle}
            onChange={e => handleUserFieldChange('crewtitle', e.target.value)}
            className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
          />
        </div>
      </div>

      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <label> License </label>
          <input
            type='text'
            value={form?.license}
            onChange={e => handleUserFieldChange('license', e.target.value)}
            className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
          />
        </div>
        <div className='w3-col l6 m6 s12'>
          <label> Passport </label>
          <input
            type='text'
            value={form?.passport}
            onChange={e => handleUserFieldChange('passport', e.target.value)}
            className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
          />
        </div>
      </div>

      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <label> Fleet </label>
          <div className='flex items-center'>
            <Select
              placeholder='Search Fleet'
              options={fleet && fleet?.data?.map(F => (
                { value: F.id, label: F.name })
              )}
              value={form?.fleet ? {
                value: form?.fleet,
                label: fleet && fleet?.data?.find(F => F.id == form?.fleet)?.name
              } : null}
              onChange={F => handleUserFieldChange('fleet', F.value)}
              className='w-full small-top-margin small-bottom-margin'
            />
            <span onClick={getFleet} className='px-2 cursor-pointer'> <Refresh className='h-5 w-5 w3-text-grey' /> </span>
          </div>
        </div>
        <div className='w3-col l6 m6 s12'>
          <label> Nationality </label>
          <Select
            placeholder='Select Country'
            options={constants.COUNTRYARRAY?.map(C => (
              { value: C.country, label: C.country })
            )}
            value={form?.nationality ? {
              value: form?.nationality,
              label: form?.nationality
            } : null}
            onChange={A => handleUserFieldChange('nationality', A.value)}
            className='w-full small-top-margin small-bottom-margin'
          />
        </div>
      </div>

    </React.Fragment>
  );

  const FORM_FOOTER = () => (
    <React.Fragment>
      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <button
            disabled={isSubmitting}
            onClick={handleSubmit}
            className='w3-btn bg-primary-blue w3-text-white small-top-margin'
          >
            {isSubmitting ? 'Submitting' : 'Submit'}
          </button>
        </div>
      </div>
      <div className='h-2'></div>
    </React.Fragment>
  );

  const USER_FORM = () => (
    <div className='request-form-container w-full relative'>
      <span className='heading'> User </span>
      <div className='border-primary-blue w3-round p-2'>
        {USER_DETAILS()}
        {FORM_FOOTER()}
      </div>
    </div>
  )
  return (
    <div id='User-Form'>
      {USER_FORM()}
    </div>
  )
}

export default UserForm;