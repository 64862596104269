// Packages
import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import dayjs from "dayjs";
import { Store } from "react-notifications-component";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';

// APIs 
import { CrewMemberByFleetListRequest, InsuranceRequestEditRequest, FleetByAirbaseListRequest, AircraftByFleetListRequest, AirbaseListRequest, InsuranceRequestDetailsRequest, InsuranceRequestCreateRequest } from '../../../requests';

// Utils
import { auth, airbasesCache, loggedUserInfoCache } from '../../../atoms';
import { constants, decodeString, authenticationErrorHandle, validateSubmissionData } from '../../../utils';
import { AirportSelect, Loader } from "../../../components";
import { CreateCrewMemberModal } from './components';


function InsuranceRequestForm() {

  const INPUT_FIELD_CLASS = `w3-input w3-white w3-border w3-round small-top-margin small-bottom-margin`;
  const INITIAL_FORM = {
    data: {
      fleet: '',
      aircraft: '',
      location: '',
      airbase: '',
      contract: '',
      tailnumber: '',
      fleet_name: '',
      date_duration_from: dayjs()?.format('YYYY-MM-DD'),
      date_duration_to: dayjs()?.format('YYYY-MM-DD'),
      remarks: '',
      crew_members: [],
      status: 'draft'
    },
    validations: {
      fleet: { isRequired: true, isNumber: true },
      airbase: { isRequired: true, isNumber: true },
      aircraft: { isRequired: true, isNumber: true },
      location: { isRequired: true },
      remarks: { isRequired: true },
      crew_members: { isRequired: true, isArray: true, min: 1 },
    },
    errors: {}
  };
  const { insuranceRequestId } = useParams();
  const Navigate = useNavigate();

  const [form, _form] = useState({ ...INITIAL_FORM });
  const [isLoading, _isLoading] = useState(false);
  const [isSubmitting, _isSubmitting] = useState(false);
  const [showAddCrewMemberModal, _showAddCrewMemberModal] = useState(false);
  const [searchFSRString, _searchFSRString] = useState('');
  const [authState, _authState] = useAtom(auth);
  const [airbases, _airbases] = useAtom(airbasesCache);
  const [loggedUserInfo, _loggedUserInfo] = useAtom(loggedUserInfoCache);
  const [fleet, _fleet] = useState([]);
  const [aircrafts, _aircrafts] = useState([]);
  const [crewMembers, _crewMembers] = useState([]);
  const [fsrList, _fsrList] = useState([]);

  // Get airbases and menu items either from cache or from server
  useEffect(() => {
    if (authState) {
      if (!airbases || !airbases.created || Date.now() - airbases.created >= 1200000) {
        getAirbases();
      }
      if (insuranceRequestId) {
        getInsuranceRequestDetails();
      }
    }
  }, [authState, insuranceRequestId]);

  // Auto set fleet and airbase in edit case
  useEffect(() => {
    if (authState && loggedUserInfo && loggedUserInfo?.data) {
      setFleetAndAirbase();
    }
  }, [authState, airbases, loggedUserInfo?.data])

  // Get fleet list On change of airbase 
  useEffect(() => {
    if (authState && form?.data?.airbase) {
      getFleetByAirbase();
    }
  }, [authState, form?.data?.airbase]);

  // Get crewmembers, and aircrafts list On change of fleet 
  useEffect(() => {
    if (authState && form?.data?.fleet) {
      getAircraftsByFleet();
      getCrewMembersByFleet();
    }
  }, [authState, form?.data?.fleet, fleet?.data]);

  const setRequestData = (type) => {
    const data = JSON.parse(JSON.stringify(form?.data));
    if (type === 'edit') {
      data.crew_members = data?.crew_members?.map(crewObj => crewObj?.crew_member);
      data.tailnumber = aircrafts?.data?.find(A => A?.id == form?.data?.aircraft)?.tailnumber;
      data.contract = aircrafts?.data?.find(A => A.id === form?.data?.aircraft)?.contract;
      data.fleet_name = fleet?.data?.find(F => F?.id == form?.data?.fleet)?.name;
      delete data?.approve_medical_insurance_request;
      delete data?.medical_insurance_request_crew_member;
      delete data?.createdat;
      delete data?.updatedat;
      return data;
    }
    else {
      data.crew_members = data?.crew_members?.map(crewObj => crewObj?.crew_member);
      data.tailnumber = aircrafts?.data?.find(A => A?.id == form?.data?.aircraft)?.tailnumber;
      data.contract = aircrafts?.data?.find(A => A.id === form?.data?.aircraft)?.contract;
      data.fleet_name = fleet?.data?.find(F => F?.id == form?.data?.fleet)?.name;
      return data;
    }
  };

  const setFleetAndAirbase = () => {
    // Set pre-selected fleet and airbase according to loggedIn user's info
    if (loggedUserInfo?.data?.fleet && loggedUserInfo?.data?.fleet?.id) {
      _form(old => ({
        ...old,
        data: {
          ...old?.data,
          fleet: loggedUserInfo?.data?.fleet?.id || '',
          airbase: loggedUserInfo?.data?.fleet?.airbase || '',
          sendby: loggedUserInfo?.data?.username || '',
        }
      }));
    }
  };

  const getInsuranceRequestDetails = () => {
    _isLoading(true);
    const token = decodeString(authState);
    InsuranceRequestDetailsRequest(token, insuranceRequestId).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        _isLoading(false);
        _form(old => (
          {
            ...old,
            data:{
              ...data,
              date_duration_from: dayjs(data?.date_duration_from)?.format('YYYY-MM-DD'),
              date_duration_to: dayjs(data?.date_duration_to)?.format('YYYY-MM-DD')
            }
          }
        ));
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch insurance request details' });
        }
      )
  };

  const getAirbases = () => {
    const token = decodeString(authState);
    AirbaseListRequest(token).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((nonPaginatedData) => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in cache with current time
        _airbases({
          data: [...nonPaginatedData],
          created: Date.now(),
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch airbases" });
    });
  };

  const getFleetByAirbase = () => {
    const token = decodeString(authState);
    FleetByAirbaseListRequest(token, form?.data?.airbase).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((nonPaginatedData) => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in local state
        _fleet({
          data: [...nonPaginatedData],
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch fleet" });
    });
  };

  const getAircraftsByFleet = () => {
    const token = decodeString(authState);
    AircraftByFleetListRequest(token, form?.data?.fleet).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((nonPaginatedData) => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in local state
        _aircrafts({
          data: [...nonPaginatedData]
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch aircrafts" });
    });
  };

  const getCrewMembersByFleet = () => {
    const token = decodeString(authState);
    CrewMemberByFleetListRequest(token, form?.data?.fleet).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((nonPaginatedData) => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in local state
        _crewMembers({
          data: [...nonPaginatedData]
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch crew members" });
    });
  };

  // *********** Handlers ***********

  const handleAirbaseChange = (name, value) => {
    _form(old => ({
      ...old,
      data: {
        ...old?.data,
        [name]: value,
        crew_members: [],
        fleet: ''
      }
    }));
  };

  const handleFleetChange = (name, value) => {
    _form(old => ({
      ...old,
      data: {
        ...old?.data,
        [name]: value,
        aircraft: '',
        crew_members: []
      }
    }));
  };

  const handleInsuranceRequestFieldChange = (name, value) => {
    _form(old => ({
      ...old,
      data: {
        ...old?.data,
        [name]: value
      }
    }));
  };

  const handleOpenAddCrewMemberModal = () => {
    _showAddCrewMemberModal(true);
  };

  const handleCloseAddCrewMemberModal = () => {
    _showAddCrewMemberModal(false);
  };

  const handleAddCrewMemberSave = (crewMemberObj) => {
    if (crewMemberObj) {
      _form(old => ({
        ...old,
        data: {
          ...old?.data,
          no_of_rooms: parseInt(old?.data?.no_of_rooms) + 1,
          crew_members: [...old?.data?.crew_members, { crew_member: crewMemberObj?.id }]
        }
      }))
    }
    handleCloseAddCrewMemberModal();
    getCrewMembersByFleet();
  };

  const handleChangeCrewMember = (crew) => {
    let crew_members = [];
    crew_members = crew?.map(C => ({ crew_member: C.value }));
    _form(old => ({
      ...old,
      data: {
        ...old?.data,
        crew_members,
        no_of_rooms: crew_members?.length || 0
      }
    }));
  };

  const handleCheckValidation = () => {
    const { allValid, errors } = validateSubmissionData(form.data, form.validations);
    if (!allValid) {
      _form(old => ({
        ...old,
        errors
      }));
      Store.addNotification({ ...constants.ERRORTOAST, message: "Required fields have errors" });
    } else {
      _form(old => ({
        ...old,
        errors: {}
      }));
    }
    return allValid;
  };

  const handleSubmit = (e) => {
    const token = decodeString(authState);
    const allValid = handleCheckValidation();
    if (!allValid) return;
    _isSubmitting(true);
    if (insuranceRequestId) {
      const data = setRequestData('edit');
      InsuranceRequestEditRequest(token, JSON.stringify(data), insuranceRequestId)
        .then(res => {
          if (res && res?.status === 401) {
            authenticationErrorHandle(() => _authState('0'));
            return (
              { errorCodes: constants.SESSIONTIMEOUT }
            );
          } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
            throw 'Request Failed';
          } else return (res.json())
        })
        .then(data => {
          if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
          if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
            throw 'Request Failed';
          } else {
            _isSubmitting(false);
            Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Insurance request updated' });
            Navigate("/insurance-requests");
          }
        })
        .catch(err => {
          _isSubmitting(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
        });
    }
    else {
      const data = setRequestData('create');
      InsuranceRequestCreateRequest(token, JSON.stringify(data))
        .then(res => {
          if (res && res?.status === 401) {
            authenticationErrorHandle(() => _authState('0'));
            return (
              { errorCodes: constants.SESSIONTIMEOUT }
            );
          } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
            throw 'Request Failed';
          } else return (res.json())
        })
        .then(data => {
          if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
          if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
            throw 'Request Failed';
          } else {
            _isSubmitting(false);
            Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Insurance request created' });
            Navigate("/insurance-requests");
          }
        })
        .catch(err => {
          _isSubmitting(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
        });
    }
  };

  // *********** Render Functions ***********

  const INSURANCE_REQUEST_INFO = () => (
    <React.Fragment>
      <div className='w3-row-padding'>
        <div className="w3-col l6 m6 s12">
          <label> Airbase </label>
          <select
            value={form?.data?.airbase}
            onChange={(e) => handleAirbaseChange("airbase", e.target.value)}
            className={`${INPUT_FIELD_CLASS} ${form?.errors?.airbase ? 'error-field' : ''}`}
          >
            <option value={""} disabled > Select Airbase </option>
            {airbases &&
              airbases?.data &&
              airbases?.data?.map((A, index) => (
                <option key={index} value={A.id}> {A.name} </option>
              ))}
          </select>
        </div>
        <div className="w3-col l6 m6 s12">
          <label> Fleet </label>
          <select
            value={form?.data?.fleet}
            onChange={(e) => handleFleetChange("fleet", e.target.value)}
            className={`${INPUT_FIELD_CLASS} ${form?.errors?.fleet ? 'error-field' : ''}`}
          >
            <option value={""} disabled> Select Fleet </option>
            {fleet &&
              fleet?.data &&
              fleet?.data
                ?.filter((F) => F.airbase == form?.data?.airbase)
                ?.map((F, index) => (
                  <option value={F.id} key={index}> {F.name} </option>
                ))}
          </select>
        </div>
      </div>

      <div className='w3-row-padding'>
        <div className="w3-col l6 m6 s12">
          <label> Aircraft </label>
          <Select
            placeholder="Select Aircraft"
            value={
              aircrafts &&
              aircrafts?.data
                ?.filter((A) => A.fleet == form?.data?.fleet && A.id == form?.data?.aircraft)
                .map((A) => ({ label: A.tailnumber, value: A.id }))
            }
            options={
              fleet &&
              aircrafts &&
              fleet?.data &&
              aircrafts?.data &&
              aircrafts?.data
                ?.filter((A) => A.fleet == form?.data?.fleet)
                .map((A) => ({ label: A.tailnumber, value: A.id }))
            }
            onChange={(option) => {
              handleInsuranceRequestFieldChange("aircraft", option.value)
            }}
            className={`${form?.errors?.aircraft ? 'error-field' : ''} w-full small-top-margin small-bottom-margin`}
          />
        </div>
        <div className='w3-col l6 m6 s12'>
          <label>Location</label>
          <AirportSelect
            placeholder='Select Airport'
            containerClass="block w-full small-top-margin small-bottom-margin"
            inputClass={`${form?.errors?.location ? 'error-field' : ''} no-focus w3-border`}
            defaultValue={form?.data?.location}
            handleOptionSelect={(e, A) => { handleInsuranceRequestFieldChange('location', `${A?.[0]}/${e?.[0]}`) }}
          />

        </div>
      </div>

      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <label> From Date </label>
          <input
            type='date'
            value={form?.data?.date_duration_from}
            min={dayjs().format('YYYY-MM-DD')}
            onChange={e => handleInsuranceRequestFieldChange("date_duration_from", e.target.value)}
            className={`${INPUT_FIELD_CLASS} ${form?.errors?.date_duration_from ? 'error-field' : ''}`}
          />
        </div>

        <div className='w3-col l6 m6 s12'>
          <label> To Date </label>
          <input
            type='date'
            value={form?.data?.date_duration_to}
            min={form?.data?.date_duration_from
              ? dayjs(form?.data?.date_duration_from)?.format('YYYY-MM-DD')
              : dayjs().format('YYYY-MM-DD')
            }
            onChange={e => handleInsuranceRequestFieldChange("date_duration_to", e.target.value)}
            className={`${INPUT_FIELD_CLASS} ${form?.errors?.date_duration_to ? 'error-field' : ''}`}
          />
        </div>

        <div className='w3-col l6 m6 s12'>
          <label> Remarks </label>
          <input
            type='text'
            value={form?.data?.remarks}
            onChange={e => handleInsuranceRequestFieldChange("remarks", e.target.value)}
            className={`${INPUT_FIELD_CLASS} ${form?.errors?.remarks ? 'error-field' : ''}`}
          />
        </div>

        <div className='w3-col l12 m12 s12'>
          <label> Crew Members </label>
          <CreatableSelect
            isMulti={true}
            closeMenuOnSelect={false}
            isDisabled={!form?.data?.fleet}
            placeholder='Select Crew Members'
            onCreateOption={handleOpenAddCrewMemberModal}
            options={
              crewMembers &&
              crewMembers?.data &&
              crewMembers?.data?.map((CM) => ({
                value: CM?.id,
                label: `${CM.crewname} (${CM?.passport || 'N/A'}) (${CM?.saudia_id || 'N/A'})`
              }))
            }
            value={
              crewMembers?.data &&
              crewMembers?.data
                ?.filter((CM) => form?.data?.crew_members
                  ?.map(obj => parseInt(obj?.crew_member))
                  ?.indexOf(CM?.id) > -1)
                ?.map(CM => ({
                  value: CM?.id,
                  label: `${CM.crewname} (${CM?.passport || 'N/A'}) (${CM?.saudia_id || 'N/A'})`
                }))
            }
            onChange={obj => handleChangeCrewMember(obj)}
            className={`${form?.errors?.crew_members ? 'error-field' : ''} w-full small-top-margin small-bottom-margin`}
          />
        </div>
      </div>
    </React.Fragment>
  );

  const INSURANCE_REQUEST_SECTION = () => (
    <div className='request-form-container w-full relative'>
      <span className='heading'> Insurance Request </span>
      <div className='border-primary-blue w3-round p-2'>
        {INSURANCE_REQUEST_INFO()}
        {FORM_FOOTER()}
      </div>
    </div>
  );

  const FORM_FOOTER = () => (
    <React.Fragment>
      {!form?.data?.status?.toLowerCase()?.includes('cancelled')
        // && !form?.data?.status?.toLowerCase()?.includes('approved')
        ? <div className='w3-row-padding'>
          <div className='w3-col l6 m6 s12'>
            <button
              disabled={isSubmitting}
              onClick={handleSubmit}
              className='w3-btn bg-primary-blue w3-text-white small-top-margin ml-auto'
            >
              {isSubmitting ? 'Saving' : 'Save'}
            </button>
          </div>
        </div>
        : null
      }
      <div className='h-2'></div>
    </React.Fragment>
  );

  const ADD_CREWMEMBER_MODAL = () => (
    <>
      {showAddCrewMemberModal &&
        <CreateCrewMemberModal
          fleet={fleet}
          isOpen={showAddCrewMemberModal}
          selectedFleet={form?.data?.fleet}
          onCrewMemberCreate={handleAddCrewMemberSave}
          handleCloseAddCrewMemberModal={handleCloseAddCrewMemberModal}
        />
      }
    </>
  );

  const LOADER = () => (
    <div className="request-form-container">
      <div className="h-30 flex justify-center items-center">
        <div><Loader spinnerClassName='w-10 h-10 text-primary-blue' />
          <p className='text-primary-blue'> Loading data... </p>
        </div>
      </div>
    </div>
  );

  const INSURANCE_REQUEST_FORM = () => (
    <React.Fragment>
      {INSURANCE_REQUEST_SECTION()}
      {ADD_CREWMEMBER_MODAL()}
    </React.Fragment>
  );

  return (
    <div id='InsuranceRequest-Form'>
      {isLoading
        ? LOADER()
        : INSURANCE_REQUEST_FORM()
      }
    </div>
  )
}

export default InsuranceRequestForm;