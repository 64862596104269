import { constants } from '.';
import jwt_decode from "jwt-decode";
import { diff } from "deep-diff";
const CryptoJS = require("crypto-js");

export const encodeString = (val, encoder = null) => {
    const encodedWith = encoder || constants.ENCODER;
    const ciphertext = CryptoJS.AES.encrypt(val, encodedWith).toString();
    return ciphertext;
};

export const decodeString = (val, encoder = null) => {
    const decodedWith = encoder || constants.ENCODER;
    const bytes  = CryptoJS.AES.decrypt(val, decodedWith);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
};

export const decodeToken = (token) => jwt_decode(token);

export const generateUID = (prefix = null) => {
    let x = new Date().getTime();
    if (prefix) {
        x = `${prefix}${x}`;
    }
    return x;
};

export const authenticationErrorHandle = (callback) => {
    if (window.location.hash !== '#Timeout') {
        window.location.hash = 'Timeout';
        callback();
    }
};

export const calculateAirportFlightTime = (flightDetails) => {
    let timeOfFlight = '0';
    if(flightDetails && flightDetails?.steps?.length &&  flightDetails?.steps?.find(s => s?.distance)){
        timeOfFlight = flightDetails?.steps?.find(s => s?.distance)?.distance?.flight?.find(f => f.time)?.time;
    }
    return { hrs: timeOfFlight?.replace(/h[^h]*$/g, ''), mins: timeOfFlight?.replace(/(\dh )*(min)*/g, '') };
};

export const minutesToHours = (n) => {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return ({ hrs: rhours, mins: rminutes });
};

export const ignoreTimeZone = (isodatetime = '1/1/2000') => {    
    const date = new Date(isodatetime);
    const result = new Date(date.toISOString().slice(0, -1));
    return result;
}

export const getCurrentGMTDateTime = () => {
    const now = new Date();
    const gmtDateTime = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    return gmtDateTime.toISOString();
}

export const isLocalFlight = (departureIcao, arrivalIcao) => {
    if(!departureIcao || !arrivalIcao) return false;
    if (departureIcao.indexOf('OE') === 0 && arrivalIcao.indexOf('OE') === 0) {
        return true;
    } else return false;
}

export const isInternationalFlight = (departureIcao, arrivalIcao) => {
    if (departureIcao && arrivalIcao && (departureIcao.indexOf('OE') !== 0 || arrivalIcao.indexOf('OE') !== 0)) {
        return true;
    } else return false;
}

export const verifyFileSizeLessThanOneMb = (size) => {
    if ((size / 1024) < 1024) {
        return true;
    } else {
        return false
    }
};

export const formatPrice = (amount = 0) => {
    const p = new Intl.NumberFormat('en-US').format(amount.toFixed(2));
    if (p && !isNaN(amount)) return p;
    else return 0;
}

export const findObjectDifferences = (obj1, obj2) => {
    return diff(obj1, obj2);
};
