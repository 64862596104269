// Packages
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { Store } from "react-notifications-component";

// APIs
import { AirbaseListRequest } from "../../../requests";

// Utils
import { constants, decodeString, authenticationErrorHandle } from "../../../utils";
import { auth, airbasesCache } from "../../../atoms";
import { TableLite, Edit, Plus, Refresh } from "../../../components";

function AirbasesList() {
  const [isLoading, _isLoading] = useState(false);
  const [authState, _authState] = useAtom(auth);
  const [airbases, _airbases] = useAtom(airbasesCache);
  const Navigate = useNavigate();

  const Airbaseheaders = [
    "id",
    "name",
    "city",
    "phone",
    "iata",
    "oca",
    "address",
    "country",
    "state",
    "zip",
  ];

  const CustomHeaders = { 'id': "Airbase Id" };
  const sortBy = ["id", "name", "country", "city"];

  // Get airbases either from cache or from server
  useEffect(() => {
    if (authState) {
      if (
        !airbases ||
        !airbases.created ||
        Date.now() - airbases.created >= 1200000
      ) {
        getAirbases();
      }
    }
  }, [authState]);

  const getAirbases = () => {
    _isLoading(true);
    const token = decodeString(authState);
    AirbaseListRequest(token)
      .then((res) => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else return res.json();
      })
      .then((nonPaginatedData) => {
        if (constants?.LOGOUTERRORTYPES?.includes(nonPaginatedData?.errorCodes)) return;
        if (nonPaginatedData) {
          // Keep server data in cache with current time
          _airbases({
            data: [...nonPaginatedData],
            created: Date.now(),
          });
          _isLoading(false);
        } else {
          throw "Request Failed";
        }
      })
      .catch((err) => {
        console.error(err);
        _isLoading(false);
        Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch airbases"});
      });
  };

  // *********** Handlers ***********
  const routeToNewAirbase = () => {
    Navigate("/airbases/new");
  };

  const routeToEditAirbase = (id) => {
    Navigate(`/airbases/edit/${id}`);
  };

  // *********** Render Functions ***********

  const CUSTOM_EDIT_BTN = {
    "render":
      <button
        style={{ "color": "black" }}
        className={"custom-edit-fsr-btn small-left-margin"}
      >
        <span className="w3-tooltip">
          <span className='tooltip-text w3-text w3-tag w-12'>
            Edit
          </span>
          <Edit className='h-5 w-5' />
        </span>
      </button>
    ,
    "className": "custom-edit-fsr-btn"
  };

  const NEW_AIRBASE_BUTTON = () => (
    <div className="flex w3-margin-bottom">
      <div className=" ml-auto">
        <div className="flex wrap">
          <button
            className="flex items-center w3-btn bg-primary-blue w3-text-white small-right-margin"
            onClick={getAirbases}
          >
            <Refresh className="w-4 h-4" />{" "}
            <span className="px-1"> Refresh </span>
          </button>
          <button
            className="flex items-center w3-btn bg-primary-blue w3-text-white"
            onClick={routeToNewAirbase}
          >
            <Plus className="w-5 h-5" />{" "}
            <span className="px-1"> New Airbase </span>
          </button>
        </div>
      </div>
    </div>
  );

  const CONTENT = () => (
    <div className="page-content w3-white h-full relative overflow-hidden">
      <div className="py-2">
        {NEW_AIRBASE_BUTTON()}
        <div className="list-view-container overflow-auto">
          <TableLite
            showActions={true}
            data={(airbases && airbases?.data) || []}
            headers={Airbaseheaders}
            customHeaders={CustomHeaders}
            sortBy={sortBy}
            actionTypes={["edit"]}
            renderEdit={CUSTOM_EDIT_BTN}
            onRowEdit={(event, row) => routeToEditAirbase(row.id)}
            cellStyle={{ fontSize: "0.8em" }}
            noDataMessage={isLoading ? 'Loading data...' : 'No data found'}
          />
        </div>
      </div>
    </div>
  );
  return <div>{CONTENT()}</div>;
}

export default AirbasesList;
