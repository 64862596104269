import React, { useEffect, useState } from 'react';
import { DigitalTime } from "react-clock-select";
import { ignoreTimeZone } from '../../utils';
import dayjs from 'dayjs';
import { Refresh } from '../svgs';

export default function ZuluTime(props) {

  const [initTime, _initTime] = useState('00:00:00');

  useEffect(() => {
    calculateInitTime();

    // Set interval to execute every 20 minutes
    const interval = setInterval(() => {
      calculateInitTime();
    }, 20 * 60 * 1000); // 20 minutes in milliseconds

    return () => clearInterval(interval);
  }, [])

  const calculateInitTime = () => {    
    let currentTime = ignoreTimeZone(new Date().toISOString());
    currentTime = dayjs(currentTime)?.format('HH:mm:ss');
    _initTime(currentTime);
  };


  return (
    <div className={`${props.containerClass} flex items-center`}>
      <DigitalTime
        type={"display"}
        value={initTime}
        hoursFormat={24}
        selectorPosition={"top"}
        liveUpdater={true}
        color={"#FFF"}
        size={0.8}
      />
      <Refresh className='w3-text-white h-5 w-5 cursor-pointer' onClick={calculateInitTime} />
    </div>
  )
}

// ZuluTime.propTypes = {
//   image: PropTypes.string,
//   containerClass: PropTypes.string,
//   imageClass: PropTypes.string,
//   onClick: PropTypes.func,
// };

// ZuluTime.defaultProps = {
//   onClick: () => null
// }
