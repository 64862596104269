// Packages
import React from 'react';
import dayjs from 'dayjs';

// Utils
import { Hotel } from '../../../../components'
import { constants, ignoreTimeZone } from '../../../../utils';

const AddedHotels = ({
  leg,
  legs,
  handleSetHotel,
  handleSethotelField,
  handleSethotelPAX,
  crewMembers
}) => (
  <React.Fragment>
    <div className='w-full'>
      <h4 className='request-form-section-heading'> <Hotel className='w-5 h-5 small-right-margin' /> <span>Hotel</span> </h4>
    </div>
    <div>
      <div className='w-full'>
        <input
          type='checkbox'
          id={`hotel_leg_${leg?.id}`}
          value={"hotels"}
          checked={leg?.requestleghotel_requestLegHotel?.length}
          onChange={e => handleSetHotel(leg.id, e)}
        />
        <label htmlFor={`hotel_leg_${leg?.id}`} className='px-1'> Book Hotel </label>
      </div>
    </div>
    <div className='w-full'>
      {leg?.requestleghotel_requestLegHotel?.length
        ? <div className='w-full small-top-margin'>
          <div className='flex flex-wrap'>
            <div>
              <label> Check In </label>
              <input
                className='w3-input w3-white w3-border w3-round'
                value={dayjs(leg?.requestleghotel_requestLegHotel?.find(h => h?.hotelType)?.checkInDateTime)?.format('YYYY-MM-DD')}
                onChange={e => handleSethotelField(leg.id, e, 'date')}
                // min={dayjs(leg?.arrival_date)?.format('YYYY-MM-DD')}
                name='checkInDateTime'
                type='date'
              />
            </div>
            <div className='w-2'> </div>

            <div>
              <label> Check In Time (Zulu) </label>
              <input
                className='w3-input w3-white w3-border w3-round'
                defaultValue={constants.TIME_FORMAT_REGEX.exec(leg?.requestleghotel_requestLegHotel?.find(h => h?.hotelType)?.checkInDateTime)?.[1]}
                onBlur={e => {
                  try {
                    const validDateCheck = dayjs('2001-01-01 ' + e.target.value)?.format('YYYY-MM-DD')
                    e.target.value = dayjs('2001-01-01 ' + e.target.value)?.format('HH:mm');
                    if (!Boolean(Date.parse(validDateCheck))) throw 'invalid date'
                  } catch (err) {
                    e.target.value = "00:00";
                  }
                  handleSethotelField(leg.id, e, 'time');
                }}
                placeholder='00:00'
                maxLength={5}
                name='checkInDateTime'
                type='text'
              />
            </div>


          </div>
          <div className='flex flex-wrap py-2'>
            <div>
              <label> Check Out </label>
              <input
                className='w3-input w3-white w3-border w3-round'
                value={dayjs(leg?.requestleghotel_requestLegHotel?.find(h => h?.hotelType)?.checkOutDateTime)?.format('YYYY-MM-DD')}
                onChange={e => handleSethotelField(leg.id, e, 'date')}
                // min={dayjs(leg?.arrival_date)?.format('YYYY-MM-DD')}
                name='checkOutDateTime'
                type='date'
              />
            </div>
            <div className='w-2'> </div>
            <div>
              <label> Check Out Time (Zulu) </label>
              <input
                className='w3-input w3-white w3-border w3-round'
                defaultValue={constants.TIME_FORMAT_REGEX.exec(leg?.requestleghotel_requestLegHotel?.find(h => h?.hotelType)?.checkOutDateTime)?.[1]}
                onBlur={e => {
                  try {
                    const validDateCheck = dayjs('2001-01-01 ' + e.target.value)?.format('YYYY-MM-DD')
                    e.target.value = dayjs('2001-01-01 ' + e.target.value)?.format('HH:mm');
                    if (!Boolean(Date.parse(validDateCheck))) throw 'invalid date'
                  } catch (err) {
                    e.target.value = "00:00";
                  }
                  handleSethotelField(leg.id, e, 'time');
                }}
                placeholder='00:00'
                maxLength={5}
                name='checkOutDateTime'
                type='text'
              />
            </div>
          </div>
          <div className='small-top-margin'>
            {/* <label> Hotel PAX </label> */}
            <div className='h-2'> </div>
            <table>
              <thead>
                <tr>
                  <th className='text-left'>Crew</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                {crewMembers?.filter(c => (
                  leg?.requestlegcrewmember_crewMembers?.map(legCrew => legCrew.crewmember).indexOf(c.id) > -1
                ))?.map((crew, index) => (
                  <tr key={index}>
                    <td>
                      <div>
                        <input
                          id={`crew-${crew.id}`}
                          type='checkbox'
                          value={crew.id}
                          className='small-right-margin'
                          onChange={handleSethotelPAX}
                          checked={leg
                            ?.requestleghotel_requestLegHotel?.find(h => h?.id)
                            ?.requestleghotelpax_requestLegHotelPAX?.map(p => parseInt(p.crewMembers))
                            ?.includes(crew.id)
                          }
                        />
                        <label htmlFor={`crew-${crew.id}`}>{crew?.crewname}</label>
                      </div>
                    </td>
                    <td>
                      <div className='px-1'>
                        {constants.CAPTAINTITLE.includes(crew?.crewtitle)
                          ? <span className='w-full w3-badge bg-primary-blue w3-small w3-round-xxlarge p-1'> Standard </span>
                          : <span className='w-full w3-badge bg-primary-blue w3-small w3-round-xxlarge p-1'> Standard </span>
                        }
                      </div>
                    </td>
                  </tr>
                )
                )}
              </tbody>
            </table>
          </div>
          <div className='small-top-margin'>
            <label> Comment </label>
            <textarea
              name='description'
              onChange={e => handleSethotelField(leg.id, e)}
              value={leg?.requestleghotel_requestLegHotel?.find(h => h?.hotelType)?.description || ''}
              className='w3-input w3-border w3-round small-top-margin small-bottom-margin resize-vertical-only'
            />
          </div>
        </div>
        : null
      }
    </div>
  </React.Fragment>
);

export default AddedHotels;