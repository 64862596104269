import { constants } from "../utils";

const UserListRequest = (token) => {
    var requestHeaders = new Headers();
    requestHeaders.append("Authorization", `Bearer ${token}`);
    requestHeaders.append("Content-Type", "application/json");
    return (fetch(`${constants.BASEURL}/api/customuser/`, { 
        method: 'GET',
        headers: requestHeaders,
        redirect: 'follow'
    }));
};

export default UserListRequest;