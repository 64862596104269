// Packages
import React from 'react';
import Select from 'react-select';
import dayjs from 'dayjs';

// Utils
import { CarRental } from '../../../../components'

const AddedRentalCars = ({
	leg,
	legs,
	crewMembers,
	handleCarRentalChange,
	handleSetCarRental
}) => (
	<React.Fragment>
		<div className='w-full'>
			<div className='w3-col'>
				<h4 className='request-form-section-heading'> <CarRental className='w-5 h-5 small-right-margin' /> <span>Car Rental</span> </h4>
			</div>
		</div>
		<div className=''>
			<div className='w3-col'>
				<input
					type='checkbox'
					id={`car_rental_leg_${leg?.id}`}
					value={"car-rental"}
					checked={leg?.reqlegcarrental_requestLegCarRental?.length}
					onChange={e => handleSetCarRental(leg.id, e)}
				/>
				<label htmlFor={`car_rental_leg_${leg?.id}`} className='px-1'> Book Car Rental </label>
			</div>
			<div className='h-10'></div>
		</div>
		{
			leg?.reqlegcarrental_requestLegCarRental?.slice(0, 1)?.map((C, index) => (
				<div key={index} className='w-full'>
					<div className='w3-col flex'>
						<div>
							<label> Rental Days </label>
							<input
								type='text'
								onChange={e => e}
								disabled={true}
								// min={1}
								// value={dayjs(leg?.arrival_date).diff(dayjs(leg?.departure_date), 'day') + 1}
								// onChange={e => handleCarRentalChange(leg.id, C.id, 'rental_days', e.target.value)}
								value={leg?.reqlegcarrental_requestLegCarRental?.find(c => c.id === C.id)?.rental_days}
								className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
							/>
						</div>
						{/* <div className='px-1'>
							<label> Pickup Date </label>
							<input
								type='date'
								min={dayjs(leg?.arrival_date)?.format('YYYY-MM-DD')}
								value={dayjs(leg?.reqlegcarrental_requestLegCarRental?.find(c => c.id === C.id)?.pick_up_date).format('YYYY-MM-DD') || ''}
								onChange={e => handleCarRentalChange(leg.id, C.id, 'pick_up_date', e.target.value)}
								className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
								// min={legs?.[legs?.findIndex(L => L.id == leg.id) - 1]
								// 	? dayjs(legs[legs?.findIndex(L => L.id == leg.id) - 1]?.arrival_date)?.format('YYYY-MM-DD')
								// 	: dayjs()?.format('YYYY-MM-DD')
								// }
							/>
						</div>
						<div className='px-1'>
							<label> Dropoff Date </label>
							<input
								type='date'
								value={dayjs(leg?.reqlegcarrental_requestLegCarRental?.find(c => c.id === C.id)?.drop_off_date).format('YYYY-MM-DD') || ''}
								onChange={e => handleCarRentalChange(leg.id, C.id, 'drop_off_date', e.target.value)}
								className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
							/>
						</div> */}
						<div className='px-1'>
							<label> Details </label>
							<input
								type='text'
								placeholder='Enter details...'
								value={leg?.reqlegcarrental_requestLegCarRental?.find(c => c.id === C.id)?.car_rental_type || ''}
								onChange={e => handleCarRentalChange(leg.id, C.id, 'car_rental_type', e.target.value)}
								className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
							/>
						</div>
						{/* <div>
							Crew Members
							<div className='flex items-center'>
								<Select
									placeholder='Select passengers'
									className='w-full small-top-margin small-bottom-margin'
									isMulti={true}
									value={
										C?.crewmembers?.map(p => ({
											id: p || 0,
											label: crewMembers?.data?.find(c => c.id == p)?.crewname,
											value: crewMembers?.data?.find(c => c.id == p)?.id,
											crewMembers: crewMembers?.data?.find(c => c.id == p)?.id,
											company: crewMembers?.data?.find(c => c.id == p)?.company,
											crewname: crewMembers?.data?.find(c => c.id == p)?.crewname,
										}))
									}
									onChange={c => handleCarRentalChange(leg.id, C.id, 'crewmembers', c?.map(crew => crew?.value))}
									options={crewMembers &&
										crewMembers?.data?.map((c) => (
											{
												label: c.crewname,
												value: c.id,
												crewMembers: c.id,
												company: c?.company,
												crewname: c.crewname
											}
										))
									}
								/>
							</div>
						</div> 
					*/}
						{/****** Only two car rental for now ******/}
						{/* <div>
							<label className='no-opacity'> Rental Type </label>
							<div className='flex items-center'>
								{leg?.reqlegcarrental_requestLegCarRental &&
									index === leg?.reqlegcarrental_requestLegCarRental?.length - 1 &&
									<div
										onClick={e => handleAddNewCarRental(leg.id)}
										className='flex w3-circle w3-grey cursor-pointer small-left-margin'
									>
										<Plus className='h-6 w-6 w3-text-white' />
									</div>
								}
								{leg?.reqlegcarrental_requestLegCarRental?.length > 1 &&
									<div
										onClick={e => handleRemoveCarRental(leg.id, C.id)}
										className='flex w3-circle w3-grey cursor-pointer small-left-margin'
									>
										<X className='h-6 w-6 w3-text-white' />
									</div>
								}
							</div>
						</div> */}
					</div>
				</div>
			))
		}
		<div className=''>
			<div className='w3-col'>
				<div className='h-5'></div>
			</div>
		</div>
	</React.Fragment>
);

export default AddedRentalCars;