// Packages
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { Store } from 'react-notifications-component';
import dayjs from 'dayjs';

// APIs
import { ApprovedHotelRequestListRequest, HotelRequestListRequest, HotelRequestStatusChangeRequest, HotelRequestCancelOrCompleteRequest, HotelRequestEditRequest  } from '../../../requests';

// Utils
import { constants, decodeString, authenticationErrorHandle } from '../../../utils';
import { auth, loggedUserInfoCache } from '../../../atoms';
import { TableLite, Plus, Refresh, Modal, Warn } from '../../../components';
import { CustomHotelRequestTable } from './components';

function HotelRequestList() {
  const [isLoading, _isLoading] = useState(false);
  const [isSubmitting, _isSubmitting] = useState(false);
  const [authState, _authState] = useAtom(auth);
  const [selectedHotelRequest, _selectedHotelRequest] = useState(null);
  const [showRequestCancelModal, _showRequestCancelModal] = useState(false);
  const [showRequestApproveModal, _showRequestApproveModal] = useState(false);
  const [showRequestCompleteModal, _showRequestCompleteModal] = useState(false);
  const [hotelRequests, _hotelRequests] = useState([]);
  const [expandedHotelRequestListIds, _expandedHotelRequestListIds] = useState([]);
  const [approvedHotelRequestList, _approvedHotelRequestList] = useState([]);
  const [loadingApprovedHotelRequestList, _loadingApprovedHotelRequestList] = useState([]);
  const [loggedUserInfo, _loggedUserInfo] = useAtom(loggedUserInfoCache);
  const [pagination, _pagination] = useState({
    paginationAvailable: 0,
    currentPage: 1,
    totalPages: 1
  });
  const Navigate = useNavigate();

  const HotelRequestHeaders = ['id', 'ACFT_REG', 'no_of_rooms', 'country', 'city', 'checkin_date', 'checkout_date', 'status'];
  const CustomHeaders = {
    id: 'Reservation Id',
    ACFT_REG: "Aircraft",
    no_of_rooms: 'Rooms',
    country: "Country",
    city: "City",
    checkin_date: "Checkin",
    checkout_date: "Checkout",
    status: "status"
  };
 
  useEffect(() => {
    if (authState) {
      getHotelRequests();
    }
  }, [authState]);

  const getHotelRequests = (page = undefined) => {
    const token = decodeString(authState);
    _isLoading(true);
    HotelRequestListRequest(token, page).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    })
      .then(data => {
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if (data && data.results) {
          _isLoading(false);
          const newPagination = {
            currentPage: page,
            paginationAvailable: data?.count,
            totalPages: Math.ceil(data?.count / constants.PAGINATIONPERPAGE)
          };
          data.results = data.results?.map(D => (
            {
              ...D,
              checkin_date: dayjs(D?.checkin_date)?.format('YYYY-MM-DD') || '',
              checkout_date: dayjs(D?.checkout_date)?.format('YYYY-MM-DD') || ''
            }
          ));
          _hotelRequests([...data.results]);
          if (data?.count) _pagination(newPagination);
        } else {
          throw 'Request Failed';
        }
      }
      )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch hotel requests' });
        }
      )
  };

  const getApprovedHotelRequestList = (hotelRequestId) => {
    const token = decodeString(authState);
    if (loadingApprovedHotelRequestList?.find(id => id === hotelRequestId)) return;
    _loadingApprovedHotelRequestList(old => [...old, hotelRequestId]);
    ApprovedHotelRequestListRequest(token, hotelRequestId).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        _approvedHotelRequestList(old => [...old, ...data]);
        _loadingApprovedHotelRequestList(old => [...old?.filter(id => id !== hotelRequestId)]);
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch approved reservations history' });
        }
      );
  };


  // *********** Handlers ***********

  const handlePaginate = (pageNo) => {
    getHotelRequests(pageNo);
  };

  const routeToNewHotelRequest = () => {
    Navigate('/hotel-reservations/new');
  };

  const routeToEditHotelRequest = (id) => {
    Navigate(`/hotel-reservations/edit/${id}`);
  };

  const routeToViewHotelRequest = (id) => {
    Navigate(`/hotel-reservations/view/${id}`);
  };

  const routeToApprovedViewHotelRequest = (id) => {
    Navigate(`/approved-hotel-reservations/view/${id}`);
  };

  const handleCancelRequest = (hotelRequest) => {
    _selectedHotelRequest(hotelRequest);
    _showRequestCancelModal(true);
  };

  const handleCloseRequestCancelModal = () => {
    _selectedHotelRequest(null);
    _showRequestCancelModal(false);
  };

  const handleApproveRequest = (hotelRequest) => {
    _selectedHotelRequest(hotelRequest);
    _showRequestApproveModal(true);
  };

  const handleCloseRequestApproveModal = () => {
    _selectedHotelRequest(null);
    _showRequestApproveModal(false);
  };

  const handleCompleteRequest = (hotelRequest) => {
    _selectedHotelRequest(hotelRequest);
    _showRequestCompleteModal(true);
  };

  const handleCloseRequestCompleteModal = () => {
    _selectedHotelRequest(null);
    _showRequestCompleteModal(false);
  };

  const handleToggleExpandHotelRequest = (hotelReq) => {
    if (expandedHotelRequestListIds?.find(id => id == hotelReq?.id)) {
      _expandedHotelRequestListIds(old => old?.filter(id => id != hotelReq?.id))
    } else _expandedHotelRequestListIds(old => [...old, hotelReq?.id]);
    if (approvedHotelRequestList?.find(approvedHotelRequest => approvedHotelRequest?.hotel_request == hotelReq?.id)) {
      _approvedHotelRequestList(old => old?.filter(approvedHotelRequest => approvedHotelRequest?.hotel_request != hotelReq?.id));
    } else
      getApprovedHotelRequestList(hotelReq?.id);
  };

  const handleConfirmHotelRequestCancel = () => {
    const token = decodeString(authState);    
    _isSubmitting(true);
    const data = {};
    data.status = "Cancelled";    
    data.hotel_request_id = selectedHotelRequest?.id;
    HotelRequestCancelOrCompleteRequest(token, JSON.stringify(data), selectedHotelRequest?.id)
      .then(res => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
          throw 'Request Failed';
        } else return (res.json())
      })
      .then(data => {
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
          throw 'Request Failed';
        } else {
          _isSubmitting(false);
          _expandedHotelRequestListIds([]);
          _approvedHotelRequestList([]);
          getHotelRequests();
          handleCloseRequestCancelModal();
          Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Hotel reservation cancelled' });
        }
      })
      .catch(err => {
        _isSubmitting(false);
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
      });
  };

  const handleConfirmHotelRequestApprove = () => {
    const token = decodeString(authState);    
    _isSubmitting(true);
    const data = { ...selectedHotelRequest };
    data.status = "Approved";
    data.crew_members = data?.hotel_request_crew_member?.map(HC => HC?.crew_member);
    delete data.hotel_request_crew_member;
    delete data.approve_hotel_request;
    delete data.createdat;
    delete data.updatedat;
    HotelRequestStatusChangeRequest(token, JSON.stringify(data), selectedHotelRequest?.id)
      .then(res => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
          throw 'Request Failed';
        } else return (res.json())
      })
      .then(data => {
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
          throw 'Request Failed';
        } else {
          _isSubmitting(false);
          _expandedHotelRequestListIds([]);
          _approvedHotelRequestList([]);
          getHotelRequests();
          handleCloseRequestApproveModal();
          // getApprovedHotelRequestList(selectedHotelRequest?.id);
          Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Hotel reservation approved' });
        }
      })
      .catch(err => {
        _isSubmitting(false);
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
      });
  };

  const handleConfirmHotelRequestComplete = () => {
    const token = decodeString(authState);    
    _isSubmitting(true);
    const data = {};
    data.status = "Completed";
    data.approve_hotel_request_id = selectedHotelRequest?.id;
    data.hotel_request_id = selectedHotelRequest?.hotel_request;
    HotelRequestCancelOrCompleteRequest(token, JSON.stringify(data))
      .then(res => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
          throw 'Request Failed';
        } else return (res.json())
      })
      .then(data => {
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
          throw 'Request Failed';
        } else {
          _isSubmitting(false);          
          _expandedHotelRequestListIds([]);
          _approvedHotelRequestList([]);
          getHotelRequests();
          // getApprovedHotelRequestList(selectedHotelRequest?.hotel_request);
          handleCloseRequestCompleteModal();
          Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Hotel reservation completed' });
        }
      })
      .catch(err => {
        _isSubmitting(false);
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
      });
  };
  
  // *********** Render Functions ***********

  const NEW_HOTEL_REQUEST_BUTTON = () => (
    <div className="flex w3-margin-bottom">
      <div className="ml-auto">
        <div className="flex wrap">
          <button className="flex items-center w3-btn bg-primary-blue w3-text-white small-right-margin" onClick={() => getHotelRequests()}>
            <Refresh className="w-4 h-4" /> <span className="px-1"> Refresh </span>
          </button>
          <button className="flex items-center w3-btn bg-primary-blue w3-text-white" onClick={routeToNewHotelRequest}>
            <Plus className="w-5 h-5" /> <span className="px-1"> New Hotel Reservation </span>
          </button>
        </div>
      </div>
    </div>
  );

  const REQUEST_CANCEL_MODAL = () => (
    <Modal isOpen={showRequestCancelModal} modalClass="w-fit w3-round-medium">
      <div className="w-full">
        <div className='w3-pale-red w3-text-red py-1 px-2 flex flex-wrap items-center'>
          <Warn className='w-6 h-6 small-right-margin' />
          <h3>
            Cancel Reservation
          </h3>
        </div>
        <div className='py-1 px-2'>
          <React.Fragment>
            Are you sure you want to cancel
            <span className='bold'>{` Hotel Reservation ID ${selectedHotelRequest?.id}`}</span>
            ?
            <p className='w3-text-red'> Cancelled reservations can not be changed again </p>
          </React.Fragment>
          <div className="h-3"></div>
          <div className="flex justify-end">
            <button onClick={handleCloseRequestCancelModal} className='w3-btn w3-grey w3-text-white'> Close </button>
            <button onClick={handleConfirmHotelRequestCancel} disabled={isSubmitting} className='w3-btn w3-red small-left-margin'>
              {isSubmitting ? 'Processing' : 'Confirm'}
            </button>
          </div>
          <div className="h-3"></div>
        </div>
      </div>
    </Modal>
  );

  const REQUEST_APPROVE_MODAL = () => (
    <Modal isOpen={showRequestApproveModal} modalClass="w-fit w3-round-medium">
      <div className="w-full">
        <div className='bg-primary-blue w3-text-white py-1 px-2 flex flex-wrap items-center'>
          <Warn className='w-6 h-6 small-right-margin' />
          <h3>
            Approve Reservation
          </h3>
        </div>
        <div className='py-1 px-2'>
          <React.Fragment>
            Are you sure you want to approve
            <span className='bold'>{` Hotel Reservation ID ${selectedHotelRequest?.id}`}</span>
            ?                        
          </React.Fragment>
          <div className="h-3"></div>
          <div className="flex justify-end">
            <button onClick={handleCloseRequestApproveModal} className='w3-btn w3-grey w3-text-white'> Close </button>
            <button onClick={handleConfirmHotelRequestApprove} disabled={isSubmitting} className='w3-btn w3-text-white bg-primary-blue small-left-margin'>
              {isSubmitting ? 'Processing' : 'Confirm'}
            </button>
          </div>
          <div className="h-3"></div>
        </div>
      </div>
    </Modal>
  );

  const REQUEST_COMPLETE_MODAL = () => (
    <Modal isOpen={showRequestCompleteModal} modalClass="w-fit w3-round-medium">
      <div className="w-full">
        <div className='w3-pale-green w3-text-green py-1 px-2 flex flex-wrap items-center'>
          <Warn className='w-6 h-6 small-right-margin' />
          <h3>
            Complete Reservation
          </h3>
        </div>
        <div className='py-1 px-2'>
          <React.Fragment>
            Are you sure you want to complete
            <span className='bold'>{` Hotel Reservation `}</span>
            ?                        
          </React.Fragment>
          <div className="h-3"></div>
          <div className="flex justify-end">
            <button onClick={handleCloseRequestCompleteModal} className='w3-btn w3-grey w3-text-white'> Close </button>
            <button onClick={handleConfirmHotelRequestComplete} disabled={isSubmitting} className='w3-btn w3-green small-left-margin'>
              {isSubmitting ? 'Processing' : 'Confirm'}
            </button>
          </div>
          <div className="h-3"></div>
        </div>
      </div>
    </Modal>
  );

  const CONTENT = () => (
    <div className='page-content w3-white h-full relative overflow-hidden'>
      <div className='py-2'>
        {NEW_HOTEL_REQUEST_BUTTON()}
        <div className='list-view-container overflow-auto'>
          <CustomHotelRequestTable
            hotelRequestList={hotelRequests}
            headers={Object.values(CustomHeaders)}
            expandedHotelRequestListIds={expandedHotelRequestListIds}
            approvedHotelRequestList={approvedHotelRequestList}
            routeToEditHotelRequest={routeToEditHotelRequest}
            routeToViewHotelRequest={routeToViewHotelRequest}
            routeToViewApprovedHotelRequest={routeToApprovedViewHotelRequest}
            handleCancelRequest={row => handleCancelRequest(row)}
            handleApproveRequest={row => handleApproveRequest(row)}
            handleCompleteRequest={row => handleCompleteRequest(row)}
            toggleExpandHotelRequest={row => handleToggleExpandHotelRequest(row)}
            isStaff={loggedUserInfo && loggedUserInfo?.data?.is_staff}
            loadingApprovedHotelRequestList={loadingApprovedHotelRequestList}
            isLoading={isLoading}
          />
          {/* Table lite code only used for pagination */}
          <TableLite
            data={[]}
            // data={hotelRequests}
            headers={HotelRequestHeaders}
            customHeaders={CustomHeaders}
            showPagination={pagination?.paginationAvailable}
            totalPages={pagination?.totalPages}
            currentPage={pagination?.currentPage}
            onPaginate={(pageNo) => handlePaginate(pageNo)}
            tableClass='hidden'
            rowStyle={{ display: 'hidden' }}
            headerStyle={{ display: 'hidden' }}
            cellStyle={{ fontSize: '0.8em' }}
            noDataMessage={isLoading ? 'Loading data...' : 'No data found'}
          />
        </div>
      </div>
      {REQUEST_CANCEL_MODAL()}
      {REQUEST_APPROVE_MODAL()}
      {REQUEST_COMPLETE_MODAL()}
    </div>
  );

  return (
    <div>
      {CONTENT()}
    </div>
  )
}

export default React.memo(HotelRequestList);