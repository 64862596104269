import { constants } from "../utils";

const AircraftByContractListRequest = (token, contractId) => {
    var requestHeaders = new Headers();
    requestHeaders.append("Authorization", `Bearer ${token}`);
    requestHeaders.append("Content-Type", "application/json");    
    return (fetch(`${constants.BASEURL}/api/aircraft/get_aircraft_by_contract/?contract_id=${contractId}`, { 
        method: 'GET',
        headers: requestHeaders,
        redirect: 'follow'
    }));
};

export default AircraftByContractListRequest;