import React from 'react';
import PropTypes from 'prop-types';

export default function Modal(props) {

  // *********** Render Functions ***********
  const W3_CSS_MODAL = () => (
    <div
      className={`${props.modalContainerClass} w3-modal z-100`}
      style={{ display: props.isOpen ? 'block' : 'none' }}
    >
      <div className={` ${props.modalClass} w3-modal-content w3-animate-bottom w3-card-4`}> {/* Class w3-animate-zoom can also be used */}
        {props.children}
      </div>
    </div>
  );

  return (
    <React.Fragment>
      {W3_CSS_MODAL()}
    </React.Fragment>
  )
}


Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  modalContainerClass: PropTypes.string,
  modalClass: PropTypes.string
};