// Packages
import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Store } from 'react-notifications-component';
import { useParams } from 'react-router-dom';
import Select from 'react-select';

// APIs
import { ContractServiceListRequest, ContractServiceCreateRequest, ContractServiceEditRequest, ServiceListRequest, ContractsListRequest, ContractServiceDetailsRequest, FleetListRequest, AirbaseListRequest } from '../../../requests';

// Utils
import { auth, servicesCache, airbasesCache, loggedUserInfoCache, contractsCache, contractServicesCache, fleetCache } from '../../../atoms';
import { constants, decodeString, authenticationErrorHandle } from '../../../utils';
import { Refresh } from '../../../components';

function ContractServicesForm() {
  const { contractServiceId } = useParams();

  const INITIAL_FORM = {
    price: '',
    service: '',
    contract: '',
    company: '',
    fleet: ''
  };

  const [isSubmitting, _isSubmitting] = useState(false);
  const [isLoading, _isLoading] = useState(false);
  const [searchString, _searchString] = useState("");
  const [form, _form] = useState({ ...INITIAL_FORM });
  const [contractServices, _contractServices] = useState([]);
  const [authState, _authState] = useAtom(auth);
  const [services, _services] = useAtom(servicesCache);
  const [fleet, _fleet] = useAtom(fleetCache);
  const [contracts, _contracts] = useAtom(contractsCache);
  const [airbases, _airbases] = useAtom(airbasesCache);
  const [loggedUserInfo, _loggedUserInfo] = useAtom(loggedUserInfoCache);

  useEffect(() => {
    if (form?.fleet && authState) {
      getContractServiceDetails();
    }
  }, [authState, form?.fleet]);

  // Get services, fleet, contracts, airbases & aircrafts either from cache or from server
  useEffect(() => {
    if (authState) {
      if (!services || !services.created || Date.now() - services.created >= 1200000) {
        getServices();
      }
      if (!contracts || !contracts.created || Date.now() - contracts.created >= 1200000) {
        getContracts();
      }
      if (!airbases || !airbases.created || Date.now() - airbases.created >= 1200000) {
        getAirbases();
      }
      if (!fleet || !fleet.created || Date.now() - fleet.created >= 1200000) {
        getFleet();
      }
    }
  }, [authState, form?.id]);

  // Set company from logged user Info for create case
  useEffect(() => {
    if (!contractServiceId && loggedUserInfo?.data) {
      setCompany();
    }
  }, [loggedUserInfo?.data, form?.id]);

  const getContractServiceDetails = () => {
    const token = decodeString(authState);
    _isLoading(true);
    ContractServiceDetailsRequest(token, form?.fleet).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        _isLoading(false);
        data = data?.map(d => (
          {
            ...d,
            price: Number(d?.price).toFixed(2)
          })
        )
          || [];
        _contractServices(data);
      } else {
        throw 'Request Failed';
      }
    }
    ).catch(err => {
      _isLoading(false);
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch contract service details' });
    }
    )
  };

  const getServices = () => {
    const token = decodeString(authState);
    ServiceListRequest(token).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((data) => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data && data.results) {
        // Keep server data in cache with current time
        _services({
          data: [...data.results],
          created: Date.now(),
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({
        ...constants.ERRORTOAST,
        message: "Failed to fetch services",
      });
    });
  };

  const getContracts = () => {
    const token = decodeString(authState);
    ContractsListRequest(token).then((res) => { 
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((data) => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data && data.results) {
        // Keep server data in cache with current time
        _contracts({
          data: [...data.results],
          created: Date.now(),
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({
        ...constants.ERRORTOAST,
        message: "Failed to fetch contracts",
      });
    });
  };

  const getFleet = () => {
    const token = decodeString(authState);
    FleetListRequest(token)
      .then((res) => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else return res.json();
      })
      .then((data) => {
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if (data && data.results) {
          // Keep server data in cache with current time
          _fleet({
            data: [...data.results],
            created: Date.now(),
          });
        } else {
          throw 'Request Failed';
        }
      })
      .catch((err) => {
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch fleet' });
      });
  };

  const getAirbases = () => {
    const token = decodeString(authState);
    AirbaseListRequest(token).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((nonPaginatedData) => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in cache with current time
        _airbases({
          data: [...nonPaginatedData],
          created: Date.now(),
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({
        ...constants.ERRORTOAST,
        message: "Failed to fetch airbases",
      });
    });
  };

  const setCompany = () => {
    _form(old => ({
      ...old,
      company: loggedUserInfo?.data?.company
    }))
  };

  // *********** Handlers ***********

  const handleFleetChange = (name, value) => {
    // extract contract by matching fleet & airbase and set it in contract field of form
    const selectedContract = airbases &&
      airbases?.data?.find(A => (
        A?.fleet &&
        A?.fleet?.includes(parseInt(value))
      ))
        ?.contract;
    _form(old => ({
      ...old,
      fleet: value,
      contract: selectedContract
    }));
  };

  const handleServicePriceChange = (e, serviceId) => {
    let newContractServices = [...contractServices];
    // check if service is present in contract services
    if (newContractServices?.find(c => c?.service === serviceId)) {
      newContractServices = newContractServices.map(c => {
        if (c?.service === serviceId) return (
          {
            ...c,
            price: e.target.value
          }
        );
        return ({ ...c });
      });
    } else {
      // else enter the service in contract services
      newContractServices.push(
        {
          service: serviceId,
          price: e.target.value,
          fleet: form?.fleet,
          contract: form?.contract,
          company: form?.company
        }
      )
    }
    _contractServices(newContractServices);
  };

  const handleServiceSetDefaultChange = (e, serviceId) => {
    let newContractServices = [...contractServices];
    newContractServices = newContractServices.map(c => {
      if (c?.service === serviceId) return (
        {
          ...c,
          is_default: e.target.checked
        }
      );
      return ({ ...c });
    });
    _contractServices(newContractServices);
  };

  const handleServicePriceBlur = (e, serviceId) => {
    let newContractServices = [...contractServices];
    // check if service is present in contract services
    if (newContractServices?.find(c => c?.service === serviceId)) {
      // check if user has input non-zero price for existing service in contract services
      if (Number(e.target.value)) {
        newContractServices = newContractServices.map(c => {
          if (c?.service === serviceId) return (
            {
              ...c,
              price: e.target.value
            }
          );
          return ({ ...c });
        });
      } else {
        // remove it if the value is NaN or zero
        newContractServices = newContractServices.filter(c => c?.service !== serviceId);
      }
    }
    _contractServices(newContractServices);
  };

  const handleSubmit = () => {
    const token = decodeString(authState);
    const data = JSON.parse(JSON.stringify(contractServices));
    _isSubmitting(true);
    if (form?.fleet) {
      ContractServiceEditRequest(token, JSON.stringify(data), form?.fleet)
        .then(res => {
          if (res && res?.status === 401) {
            authenticationErrorHandle(() => _authState('0'));
            return (
              { errorCodes: constants.SESSIONTIMEOUT }
            );
          } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
            throw 'Request Failed';
          } else return (res.json())
        })
        .then(data => {
          if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
          if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
            throw 'Request Failed';
          } else {
            _isSubmitting(false);
            Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Contract service updated' });
          }
        })
        .catch(err => {
          _isSubmitting(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
        });
    }
  };

  const handleSort = (sortBy, asc_dsc) => {
    let data = services?.data ? [...services?.data] : [];
    if (sortBy === 'price') {
      if (asc_dsc === 'asc')
        data.sort((a, b) => {
          const priceA = (contractServices && contractServices?.find(c => c?.service == a?.id)?.price) || 0;
          const priceB = (contractServices && contractServices?.find(c => c?.service == b?.id)?.price) || 0;
          // const priceA = (contractServices && contractServices?.find(c => c?.service == a?.id)?.price) || a?.price;
          // const priceB = (contractServices && contractServices?.find(c => c?.service == b?.id)?.price) || b?.price;          
          return (Number(priceA) - Number(priceB));
        });
      else
        data.sort((a, b) => {
          const priceA = (contractServices && contractServices?.find(c => c?.service == a?.id)?.price) || 0;
          const priceB = (contractServices && contractServices?.find(c => c?.service == b?.id)?.price) || 0;
          // const priceA = (contractServices && contractServices?.find(c => c?.service == a?.id)?.price) || a?.price;
          // const priceB = (contractServices && contractServices?.find(c => c?.service == b?.id)?.price) || b?.price;          
          return (Number(priceB) - Number(priceA));
        });
    } else {
      if (asc_dsc === 'asc')
        data.sort((a, b) => a[sortBy].localeCompare(b[sortBy]))
      else
        data.sort((a, b) => b[sortBy].localeCompare(a[sortBy]))
    }
    _services({
      data: [...data],
      created: Date.now(),
    });
  };

  const handleSearch = (e) => {
    _searchString(e.target.value.trim());
  };

  // *********** Render Functions ***********

  const CONTRACT_SERVICE_DETAILS = () => (
    <React.Fragment>
      <div className='w3-row-padding'>
        <div className="w3-col l6 m6 s12">
          <label> Fleet </label>
          <div className='flex items-center'>
            <select
              value={form?.fleet}
              disabled={form?.airbase === "" || !loggedUserInfo?.data?.is_staff}
              onChange={(e) => handleFleetChange("fleet", e.target.value)}
              className='w3-input w3-border w3-round small-top-margin small-bottom-margin w3-white'
            >
              <option value={""} disabled> Select Fleet </option>
              {fleet &&
                fleet?.data &&
                fleet?.data
                  ?.map((F, index) => (
                    <option value={F.id} key={index}> {F.name} </option>
                  ))}
            </select>
            <span onClick={getContracts} className='px-2 cursor-pointer'> <Refresh className='h-5 w-5 w3-text-grey' /> </span>
          </div>
        </div>
        <div className="w3-col l6 m6 s12">
          Contract
          <div className='flex items-center'>
            <Select
              onChange={c => 0}
              isDisabled={true}
              placeholder='Contract by fleet'
              options={contracts && contracts?.data?.map(C => (
                { value: C.id, label: C.name })
              )}
              value={form?.contract ? {
                value: form?.contract,
                label: contracts && contracts?.data?.find(C => C.id == form?.contract)?.name
              } : null}
              // onChange={c => handleChangeContractService('contract', c.value)}
              className='w-full small-top-margin small-bottom-margin'
            />
            <span onClick={getContracts} className='px-2 cursor-pointer'> <Refresh className='h-5 w-5 w3-text-grey' /> </span>
          </div>
        </div>
      </div>

      <div className='w3-row-padding'>
        <div className='w3-col l12 m12 s12 small-bottom-margin'>
          <span> Services </span>
        </div>
        <div className='w3-col w-full relative'>
          <div className='overflow-auto'>
            <input
              className='search-input w3-input w3-border w3-round small-top-margin small-bottom-margin'
              placeholder='Search service by name'
              value={searchString}
              onChange={handleSearch}
            />
            <table className='w3-table w3-bordered'>
              <thead>
                <tr className='w3-light-grey'>
                  <th>
                    <span>Name</span>
                    <span onClick={e => handleSort('name', 'asc')} className='w3-small cursor-pointer'> ▲ </span>
                    <span onClick={e => handleSort('name', 'dsc')} className='w3-small cursor-pointer'> ▼ </span>
                  </th>
                  <th>
                    <span>Type</span>
                    <span onClick={e => handleSort('serviceType', 'asc')} className='w3-small cursor-pointer'> ▲ </span>
                    <span onClick={e => handleSort('serviceType', 'dsc')} className='w3-small cursor-pointer'> ▼ </span>
                  </th>
                  <th>
                    <span>Price</span>
                    <span onClick={e => handleSort('price', 'asc')} className='w3-small cursor-pointer'> ▲ </span>
                    <span onClick={e => handleSort('price', 'dsc')} className='w3-small cursor-pointer'> ▼ </span>
                  </th>
                  <th>
                    <span> Set Default </span>
                  </th>
                </tr>
              </thead>
              <tbody className='w3-medium'>
                {form?.fleet &&
                  form?.contract &&
                  services?.data
                  ? services?.data?.map((S, index) => (
                    <tr
                      key={`service_${index}`}
                      className={`
                      react-table-lite-row
                      ${searchString &&
                          !S?.name?.toLowerCase()?.includes(searchString?.toLowerCase())
                          ? 'hidden'
                          : ''
                        }
                      `}
                    >
                      <td> {S.name} </td>
                      <td> {S.serviceType}</td>
                      <td>
                        <input
                          min={0}
                          type='number'
                          step={0.01}
                          placeholder='0.00'
                          id={`service_${S?.id}_price`}
                          onChange={e => handleServicePriceChange(e, S?.id)}
                          onBlur={e => handleServicePriceBlur(e, S?.id)}
                          className='w-fit w3-padding-small w3-border w3-input'
                          value={
                            (contractServices && contractServices?.find(c => c?.service == S?.id)?.price) || ''
                          }
                        />
                      </td>
                      <td className='table-cell-middle'>
                        <div className='flex items-center justify-center'>
                          <label className='site-switcher'>
                            <input
                              onChange={e => handleServiceSetDefaultChange(e, S?.id)}
                              checked={
                                (contractServices && contractServices?.find(c => c?.service == S?.id)?.is_default) || false
                              }
                              className='w3-input w-4'
                              type='checkbox'
                            />
                            <span className='fake-switcher'></span>
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))
                  : <tr>
                    <td colSpan={3}> Select contract and fleet </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </React.Fragment>
  );

  const FORM_FOOTER = () => (
    <React.Fragment>
      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <button
            disabled={isSubmitting}
            onClick={handleSubmit}
            className='w3-btn bg-primary-blue w3-text-white small-top-margin'
          >
            {isSubmitting ? 'Saving' : 'Save'}
          </button>
        </div>
      </div>
      <div className='h-2'></div>
    </React.Fragment>
  );

  const CONTRACT_SERVICE_FORM = () => (
    <div className='request-form-container w-full relative'>
      <span className='heading'> Contract Service </span>
      <div className='border-primary-blue w3-round p-2'>
        {CONTRACT_SERVICE_DETAILS()}
        {form?.fleet && FORM_FOOTER()}
      </div>
    </div>
  );
  return (
    <div id='Contract-Service-Form'>
      {CONTRACT_SERVICE_FORM()}
    </div>
  )
}

export default ContractServicesForm;