import { constants } from "../utils";

const AirportListRequest = (token, searchString) => {
    var requestHeaders = new Headers();
    requestHeaders.append("Content-Type", "application/json");
    requestHeaders.append("Authorization", `Bearer ${token}`);
    return (fetch(`${constants.AIRPORTSEARCHURL}/api/airportsearch/${searchString}`, { 
    // return (fetch(`${constants.AIRPORTSEARCHURL}/api/common/airportsearch/?search=${searchString}`, { 
        method: 'GET',
        headers: requestHeaders,
        redirect: 'follow'
    }));
};

export default AirportListRequest;
