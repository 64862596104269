// Packages
import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { Store } from "react-notifications-component";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';

// APIs 
import { CrewMemberByFleetListRequest, TicketRequestCreateRequest, TicketRequestDetailsRequest, TicketRequestEditRequest, AircraftByFleetListRequest, AirbaseListRequest, FleetByAirbaseListRequest } from '../../../requests';

// Utils
import { airbasesCache, auth, fleetCache, loggedUserInfoCache } from '../../../atoms';
import { constants, decodeString, authenticationErrorHandle, validateSubmissionData, ignoreTimeZone } from '../../../utils';
import { AirportSelect, Loader } from "../../../components";
import { CreateCrewMemberModal } from './components';


function TicketRequestForm() {

  const INPUT_FIELD_CLASS = `w3-input w3-white w3-border w3-round small-top-margin small-bottom-margin`;
  const INITIAL_FORM = {
    data: {
      departure_country: '',
      departure_city: '',
      arrival_country: '',
      arrival_city: '',
      departure_iata: '',
      arrival_iata: '',
      departure_datetime: dayjs()?.format('YYYY-MM-DD HH:mm'),
      arrival_datetime: dayjs()?.format('YYYY-MM-DD HH:mm'),
      departure_date: dayjs()?.format('YYYY-MM-DD'),
      departure_time: dayjs(ignoreTimeZone(dayjs().toISOString()))?.format('HH:mm'),
      arrival_date: '',
      arrival_time: '',
      crew_members: [],
      no_of_ticket: '',
      comments: '',
      airbase: '',
      fleet: '',
      aircraft: '',
    },
    validations: {
      fleet: { isRequired: true, isNumber: true },
      aircraft: { isRequired: true, isNumber: true },
      airbase: { isRequired: true, isNumber: true },
      departure_iata: { isRequired: true },
      arrival_iata: { isRequired: true },
      country: { isRequired: true },
      comments: { isRequired: true },
      crew_members: { isRequired: true, isArray: true, min: 1 },
    },
    errors: {}
  };
  const { ticketRequestId } = useParams();
  const Navigate = useNavigate();

  const [form, _form] = useState({ ...INITIAL_FORM });
  const [isLoading, _isLoading] = useState(false);
  const [isSubmitting, _isSubmitting] = useState(false);
  const [showAddCrewMemberModal, _showAddCrewMemberModal] = useState(false);
  const [authState, _authState] = useAtom(auth);
  const [airbases, _airbases] = useAtom(airbasesCache);
  const [loggedUserInfo, _loggedUserInfo] = useAtom(loggedUserInfoCache);
  const [fleet, _fleet] = useAtom(fleetCache);
  const [crewMembers, _crewMembers] = useState([]);
  const [aircrafts, _aircrafts] = useState([]);

  // Get ticket request details and fleet
  useEffect(() => {
    if (authState) {
      if (ticketRequestId) {
        getTicketRequestDetails();
      }
      if (!airbases || !airbases.created || Date.now() - airbases.created >= 1200000) {
        getAirbases();
      }
    }
  }, [authState, ticketRequestId]);

  // Get aircrafts & crew members on fleet change
  useEffect(() => {
    if (authState && form?.data?.fleet) {
      getCrewMembersByFleet();
      getAircraftsByFleet();
    }
  }, [authState, form?.data?.fleet]);

  // Get fleet list On change of airbase 
  useEffect(() => {
    if (authState && form?.data?.airbase) {
      getFleetByAirbase();
    }
  }, [authState, form?.data?.airbase]);

  // Auto set fleet if non admin user
  useEffect(() => {
    if (loggedUserInfo && !loggedUserInfo?.data?.is_staff && loggedUserInfo?.data?.fleet?.id) {
      setFleet();
    }
  }, [authState, loggedUserInfo?.data])

  const setRequestData = (type) => {
    const data = JSON.parse(JSON.stringify(form?.data));
    delete data.status;
    if (type === 'edit') {
      data.crew_members = data?.crew_members?.map(crewObj => crewObj?.crew_member);
      data.departure_datetime = data?.departure_date + ' ' + data?.departure_time;
      data.arrival_datetime = data?.arrival_date + ' ' + data?.arrival_time;
      delete data?.approve_ticket_request;
      delete data?.approve_hotel_requests;
      delete data?.ticket_request_crew_member;

      // this line added to fix a bug in edit case
      data.arrival_datetime = null;
      return data;
    }
    else {
      data.crew_members = data?.crew_members?.map(crewObj => crewObj?.crew_member);
      data.departure_datetime = data?.departure_date + ' ' + data?.departure_time;
      data.arrival_datetime = data?.arrival_date + ' ' + data?.arrival_time;
      data.arrival_datetime = null;
      delete data?.approve_hotel_request;
      delete data?.approve_hotel_requests;
      delete data?.hotel_request_crew_member;
      return data;
    }
  };

  const setFleet = () => {
    _form(old => ({
      ...old,
      data: {
        ...old?.data,
        fleet: loggedUserInfo?.data?.fleet?.id
      }
    }))
  };

  const getTicketRequestDetails = () => {
    _isLoading(true);
    const token = decodeString(authState);
    TicketRequestDetailsRequest(token, ticketRequestId).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        _isLoading(false);
        _form(old => (
          {
            ...old,
            data: {
              ...data,
              departure_date: dayjs(data?.departure_datetime)?.format('YYYY-MM-DD') || '',
              arrival_date: dayjs(data?.arrival_datetime)?.format('YYYY-MM-DD') || '',
              departure_time: dayjs(ignoreTimeZone(data?.departure_datetime))?.format('HH:mm') || '',
              arrival_time: dayjs(ignoreTimeZone(data?.arrival_datetime))?.format('HH:mm') || ''
            }
          }
        ));
      } else {
        throw 'Request Failed';
      }
    }
    ).catch(
      err => {
        _isLoading(false);
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch ticket request details' });
      }
    )
  };

  const getAirbases = () => {
    const token = decodeString(authState);
    AirbaseListRequest(token).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((nonPaginatedData) => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in cache with current time
        _airbases({
          data: [...nonPaginatedData],
          created: Date.now(),
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch airbases" });
    });
  };

  const getFleetByAirbase = () => {
    const token = decodeString(authState);
    FleetByAirbaseListRequest(token, form?.data?.airbase).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((nonPaginatedData) => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in local state
        _fleet({
          data: [...nonPaginatedData],
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch fleet" });
    });
  };

  const getCrewMembersByFleet = () => {
    const token = decodeString(authState);
    CrewMemberByFleetListRequest(token, form?.data?.fleet).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((nonPaginatedData) => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in local state
        _crewMembers({
          data: [...nonPaginatedData]
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch crew members" });
    });
  };

  const getAircraftsByFleet = () => {
    const token = decodeString(authState);
    AircraftByFleetListRequest(token, form?.data?.fleet).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((nonPaginatedData) => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in local state
        _aircrafts({
          data: [...nonPaginatedData]
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch aircrafts" });
    });
  };

  // *********** Handlers ***********

  const handleTicketRequestFieldChange = (name, value) => {
    _form(old => ({
      ...old,
      data: {
        ...old?.data,
        [name]: value
      }
    }));
  };

  const handleOpenAddCrewMemberModal = () => {
    _showAddCrewMemberModal(true);
  };

  const handleCloseAddCrewMemberModal = () => {
    _showAddCrewMemberModal(false);
  };

  const handleAddCrewMemberSave = (crewMemberObj) => {
    if (crewMemberObj) {
      _form(old => ({
        ...old,
        data: {
          ...old?.data,
          no_of_ticket: parseInt(old?.data?.no_of_ticket) + 1,
          crew_members: [...old?.data?.crew_members, { crew_member: crewMemberObj?.id }]
        }
      }))
    }
    handleCloseAddCrewMemberModal();
    getCrewMembersByFleet();
  };

  const handleChangeCrewMember = (crew) => {
    let crew_members = [];
    crew_members = crew?.map(C => ({ crew_member: C.value }));
    _form(old => ({
      ...old,
      data: {
        ...old?.data,
        crew_members,
        no_of_ticket: crew_members?.length || 0
      }
    }));
  };

  const handleFleetChange = (name, value) => {
    _form(old => ({
      ...old,
      data: {
        ...old?.data,
        [name]: value,
        crew_members: [],
        no_of_ticket: '',
        aircraft: ''
      }
    }));
  };

  const handleAirbaseChange = (name, value) => {
    _form(old => ({
      ...old,
      data: {
        ...old?.data,
        [name]: value,
        fleet: ''
      }
    }));
  };

  const handleAirportChange = (type, iata, icao, airport) => {
    _form(old => ({
      ...old,
      data: {
        ...old?.data,
        [`${type}_iata`]: `${icao?.[0]} / ${iata?.[0]}`,
        [`${type}_country`]: airport?.country?.[0],
        [`${type}_city`]: airport?.city?.[0]
      }
    }))
  };

  const handleCheckValidation = () => {
    const { allValid, errors } = validateSubmissionData(form.data, form.validations);
    if (!allValid) {
      _form(old => ({
        ...old,
        errors
      }));
      Store.addNotification({ ...constants.ERRORTOAST, message: "Required fields have errors" });
    } else {
      _form(old => ({
        ...old,
        errors: {}
      }));
    }
    return allValid;
  };

  const handleSubmit = (e) => {
    const token = decodeString(authState);
    const allValid = handleCheckValidation();
    if (!allValid) return;
    _isSubmitting(true);
    if (ticketRequestId) {
      const data = setRequestData('edit');
      TicketRequestEditRequest(token, JSON.stringify(data), ticketRequestId)
        .then(res => {
          if (res && res?.status === 401) {
            authenticationErrorHandle(() => _authState('0'));
            return (
              { errorCodes: constants.SESSIONTIMEOUT }
            );
          } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
            throw 'Request Failed';
          } else return (res.json())
        })
        .then(data => {
          if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
          if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
            throw 'Request Failed';
          } else {
            _isSubmitting(false);
            Store.addNotification({ ...constants.SUCCESSTOAST, message: 'ticket request updated' });
            Navigate("/ticket-requests");
          }
        })
        .catch(err => {
          _isSubmitting(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
        });
    }
    else {
      const data = setRequestData('create');
      TicketRequestCreateRequest(token, JSON.stringify(data))
        .then(res => {
          if (res && res?.status === 401) {
            authenticationErrorHandle(() => _authState('0'));
            return (
              { errorCodes: constants.SESSIONTIMEOUT }
            );
          } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
            throw 'Request Failed';
          } else return (res.json())
        })
        .then(data => {
          if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
          if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
            throw 'Request Failed';
          } else {
            _isSubmitting(false);
            Store.addNotification({ ...constants.SUCCESSTOAST, message: 'ticket request created' });
            Navigate("/ticket-requests");
          }
        })
        .catch(err => {
          _isSubmitting(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
        });
    }
  };

  const handleFormatTime = (e) => {
    let TimeValue = dayjs(`2001-01-01 ${e.target.value}`);
    if (dayjs(TimeValue).isValid) {
      TimeValue = dayjs(TimeValue).format('HH:mm');
    } else {
      TimeValue = '00:00';
    }
    return TimeValue
  };

  // *********** Render Functions ***********

  const TICKET_REQUEST_DETAILS = () => (
    <React.Fragment>
      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <label> Departure </label>
          <AirportSelect
            defaultValue={form?.data?.departure_iata}
            containerClass="block w-full small-top-margin small-bottom-margin"
            handleOptionSelect={(iata, icao, airport) => handleAirportChange('departure', iata, icao, airport)}
            inputClass={INPUT_FIELD_CLASS + ` w3-border w3-round ${form?.errors?.departure_iata ? 'error-field' : ''}`}
          />
        </div>

        <div className='w3-col l6 m6 s12'>
          <label> Arrival </label>
          <AirportSelect
            defaultValue={form?.data?.arrival_iata}
            handleOptionSelect={(iata, icao, airport) => handleAirportChange('arrival', iata, icao, airport)}
            containerClass="block w-full small-top-margin small-bottom-margin"
            inputClass={INPUT_FIELD_CLASS + ` w3-border w3-round ${form?.errors?.arrival_iata ? 'error-field' : ''}`}
          />
        </div>

        <div className='w3-col l6 m6 s12'>
          <label> Departure Date  </label>
          <input
            type='date'
            value={form?.data?.departure_date}
            onChange={e => handleTicketRequestFieldChange("departure_date", e.target.value)}
            className={`${INPUT_FIELD_CLASS} ${form?.errors?.departure_date ? 'error-field' : ''}`}
          />
        </div>

        <div className='w3-col l6 m6 s12'>
          <label> Departure Time  </label>
          <input
            type='text'
            value={form?.data?.departure_time}
            onChange={e => handleTicketRequestFieldChange("departure_time", e.target.value)}
            onBlur={e => handleTicketRequestFieldChange("departure_time", handleFormatTime(e))}
            className={`${INPUT_FIELD_CLASS} ${form?.errors?.departure_time ? 'error-field' : ''}`}
          />
        </div>

        {/* <div className='w3-col l6 m6 s12'>
          <label> Arrival Date  </label>
          <input
            type='date'
            value={form?.data?.arrival_date}
            onChange={e => handleTicketRequestFieldChange("arrival_date", e.target.value)}
            className={`${INPUT_FIELD_CLASS} ${form?.errors?.arrival_date ? 'error-field' : ''}`}
          />
        </div>

        <div className='w3-col l6 m6 s12'>
          <label> Arrival Time  </label>
          <input
            type='text'
            value={form?.data?.arrival_time}
            onChange={e => handleTicketRequestFieldChange("arrival_time", e.target.value)}
            onBlur={e => handleTicketRequestFieldChange("arrival_time", handleFormatTime(e))}
            className={`${INPUT_FIELD_CLASS} ${form?.errors?.arrival_time ? 'error-field' : ''}`}
          />
        </div> */}
        <div className="w3-col l6 m6 s12">
          <label> Airbase </label>
          <select
            value={form?.data?.airbase}
            onChange={(e) => handleAirbaseChange("airbase", e.target.value)}
            className={`${INPUT_FIELD_CLASS} ${form?.errors?.airbase ? 'error-field' : ''}`}
          >

            <option value={""} disabled > Select Airbase </option>
            {airbases &&
              airbases?.data &&
              airbases?.data?.map((A, index) => (
                <option key={index} value={A.id}> {A.name} </option>
              ))}
          </select>
        </div>
        <div className="w3-col l6 m6 s12">
          <label> Fleet </label>
          <select
            value={form?.data?.fleet}
            onChange={(e) => handleFleetChange("fleet", e.target.value)}
            className={`${INPUT_FIELD_CLASS} ${form?.errors?.fleet ? 'error-field' : ''}`}
          >
            <option value={""} disabled> Select Fleet </option>
            {fleet &&
              fleet?.data &&
              fleet?.data
                ?.filter((F) => F.airbase == form?.data?.airbase)
                ?.map((F, index) => (
                  <option value={F.id} key={index}> {F.name} </option>
                ))}
          </select>
        </div>
        <div className="w3-col l6 m6 s12">
          <label> Aircraft </label>
          <Select
            placeholder="Select aircraft"
            value={
              aircrafts &&
              aircrafts?.data
                ?.filter((A) => A.fleet == form?.data?.fleet && A.id == form?.data?.aircraft)
                .map((A) => ({ label: A.tailnumber, value: A.id }))
            }
            options={
              fleet &&
              aircrafts &&
              fleet?.data &&
              aircrafts?.data &&
              aircrafts?.data
                ?.filter((A) => A.fleet == form?.data?.fleet)
                .map((A) => ({ label: A.tailnumber, value: A.id }))
            }
            onChange={(option) => {
              handleTicketRequestFieldChange("aircraft", option.value)
            }}
            className={`${form?.errors?.aircraft ? 'error-field' : ''} w-full small-top-margin small-bottom-margin`}
          />
        </div>

        <div className='w3-col l6 m6 s12'>
          <label> No. Of Tickets  </label>
          <input
            type='text'
            disabled={true}
            value={form?.data?.no_of_ticket}
            onChange={e => handleTicketRequestFieldChange("no_of_ticket", e.target.value)}
            className={`${INPUT_FIELD_CLASS} ${form?.errors?.no_of_ticket ? 'error-field cursor-disable' : 'cursor-disable'}`}
          />
        </div>

        <div className='w3-col l6 m6 s12'>
          <label> Comments  </label>
          <input
            type='text'
            value={form?.data?.comments}
            onChange={e => handleTicketRequestFieldChange("comments", e.target.value)}
            className={`${INPUT_FIELD_CLASS} ${form?.errors?.comments ? 'error-field' : ''}`}
          />
        </div>

      </div>
    </React.Fragment>
  );

  const FORM_FOOTER = () => (
    <React.Fragment>
      {!form?.data?.status?.toLowerCase()?.includes('cancelled') &&
        !form?.data?.status?.toLowerCase()?.includes('approved')
        ? <div className='w3-row-padding'>
          <div className='w3-col l6 m6 s12'>
            <button
              disabled={isSubmitting}
              onClick={handleSubmit}
              className='w3-btn bg-primary-blue w3-text-white small-top-margin ml-auto'
            >
              {isSubmitting ? 'Saving' : 'Save'}
            </button>            
          </div>
        </div>
        : null
      }
      <div className='h-2'></div>
    </React.Fragment>
  );

  const TICKET_REQUEST_DETAILS_SECTION = () => (
    <div className='request-form-container w-full relative'>
      <span className='heading'> Ticket Request </span>
      <div className='border-primary-blue w3-round p-2'>
        {TICKET_REQUEST_DETAILS()}
        {FORM_FOOTER()}
      </div>
    </div>
  );

  const TICKET_CREW_MEMBERS_SECTION = () => (
    <div className='request-form-container w-full relative'>
      <span className='heading'>Tickets</span>
      <div className='border-primary-blue w3-round p-2'>
        <h3>Please confirm the following</h3>
        <div className="list-view-container w3-margin-bottom">
          <div className="react-table-lite-container">
            <table className="react-table-lite-table align-top">
              <thead className="react-table-lite-header">
                <tr>
                  <th>Crew Member</th>
                </tr>
              </thead>
              <tbody>
                <tr className="react-table-lite-row">
                  <td>
                    <CreatableSelect
                      isMulti={true}
                      isDisabled={!form?.data?.fleet}
                      placeholder='Search Crew Members'
                      onCreateOption={handleOpenAddCrewMemberModal}
                      options={
                        crewMembers &&
                        crewMembers?.data &&
                        crewMembers?.data?.map((CM) => ({
                          value: CM?.id,
                          label: `${CM.crewname} (${CM?.passport || 'N/A'}) (${CM?.saudia_id || 'N/A'})`
                        }))
                      }
                      value={
                        crewMembers?.data &&
                        crewMembers?.data
                          ?.filter((CM) => form?.data?.crew_members
                            ?.map(obj => parseInt(obj?.crew_member))
                            ?.indexOf(CM?.id) > -1)
                          ?.map(CM => ({ label: `${CM.crewname} (${CM?.passport || 'N/A'}) (${CM?.saudia_id || 'N/A'})`, value: CM?.id }))
                      }
                      onChange={obj => handleChangeCrewMember(obj)}
                      className={`${form?.errors?.crew_members ? 'error-field' : ''} w-full small-top-margin small-bottom-margin`}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <button className="w3-btn w3-red block w-full">Note: Cancellation must be made before 24 hours.</button>
      </div>
    </div>
  );

  const TICKET_REQUEST_NOTICE_SECTION = () => (
    <div className='request-form-container w-full relative py-2'>
      <span className='heading'>Services Included</span>
      <div className='border-primary-blue w3-round p-2'>
        <div className="hotel-service-box">
          <ul className="list-services">
            <li>3 Daily meals or one room service meal (maximum) after restaurant working hours</li>
            <li>Laundry for 3 pieces per day (maximum)</li>
            <li>Internet service</li>
            <li>Airport transfer</li>
          </ul>
        </div>
        <div className="hotel-service-box">
          <strong className="hotel-service-title">On Saudia Private Aviation account.</strong>
          <span className="hotel-service-alert">*Please Note:</span>
          <ul className="list-services">
            <li>Saudia Private Aviation is not obligated to pay any other services that not included.</li>
            <li>The guest registration form must be signed by the guest and attached to the invoice in order to process the payment. otherwise, the hotel accommodation request will be void.</li>
            <li>The invoices must be sent within maximum 7 days from the guest checkout date to avoid any delay in the payment process to the email AP@spa.sa and to the below address by postal:</li>
          </ul>
        </div>
        <div className="hotel-service-box">
          <ul className="list-services no-bullets">
            <li>Saudia Private Aviation Co Ltd</li>
            <li>North Terminal</li>
            <li>Kind Abdul Aziz International</li>
            <li>Airport</li>
            <li>Saudi Arabia</li>
          </ul>
        </div>
      </div>
    </div>
  );

  const ADD_CREWMEMBER_MODAL = () => (
    <>
      {showAddCrewMemberModal &&
        <CreateCrewMemberModal
          fleet={fleet}
          isOpen={showAddCrewMemberModal}
          selectedFleet={form?.data?.fleet}
          onCrewMemberCreate={handleAddCrewMemberSave}
          handleCloseAddCrewMemberModal={handleCloseAddCrewMemberModal}
        />
      }
    </>
  );

  const LOADER = () => (
    <div className="request-form-container">
      <div className="h-30 flex justify-center items-center">
        <div><Loader spinnerClassName='w-10 h-10 text-primary-blue' />
          <p className='text-primary-blue'> Loading data... </p>
        </div>
      </div>
    </div>
  );

  const TICKET_REQUEST_FORM = () => (
    <React.Fragment>
      {TICKET_REQUEST_DETAILS_SECTION()}
      {TICKET_CREW_MEMBERS_SECTION()}
      {TICKET_REQUEST_NOTICE_SECTION()}
      {ADD_CREWMEMBER_MODAL()}
    </React.Fragment>
  );

  return (
    <div id='TicketRequest-Form'>
      {isLoading
        ? LOADER()
        : TICKET_REQUEST_FORM()
      }
    </div>
  )
}

export default TicketRequestForm;