// Packages
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { Store } from 'react-notifications-component';

// APIs
import { CrewMemberListRequest, CrewMemberSearchRequest } from '../../../requests';

// Utils
import { constants, decodeString, authenticationErrorHandle } from '../../../utils';
import { auth } from '../../../atoms';
import { TableLite, Edit, Plus, Refresh, SearchBar } from '../../../components';

function CrewMembersList() {

  const [isLoading, _isLoading] = useState(false);
  const [authState, _authState] = useAtom(auth);
  const [searchString, _searchString] = useState('');
  const [crewMembers, _crewMembers] = useState([]);
  const [pagination, _pagination] = useState({
    paginationAvailable: 0,
    currentPage: 1,
    totalPages: 1
  });

  const Navigate = useNavigate();
  const CrewMembersheaders = ["id", "crewname", "crewtitle", "license", "passport", "nationality"];
  const sortBy = ["id", "crewname"];
  const CustomHeaders = {
    "id": "Member Id",
    "crewname": "Name",
    "crewtitle": "Title",
  };

  // Get crew members either from cache or from server
  useEffect(() => {
    if (authState) {
      getCrewMembers();
      // if (!crewMembers || !crewMembers.created || Date.now() - crewMembers.created >= 1200000) {
      // getCrewMembers();
      // }
    }
  }, [authState]);

  const getCrewMembers = (page = undefined) => {
    const token = decodeString(authState);
    _isLoading(true);
    CrewMemberListRequest(token, page).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data && data.results) {
        // Keep server data in local State
        _crewMembers({
          data: [...data.results],
          created: Date.now()
        });
        const newPagination = {
          currentPage: page,
          paginationAvailable: data?.count,
          totalPages: Math.ceil(data?.count / constants.PAGINATIONPERPAGE)
        };
        if (data?.count) _pagination(newPagination);
        _isLoading(false);
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch crew members' });
        }
      )
  };

  // *********** Handlers ***********

  const handleSearchString = (e) => {
    _searchString(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if(searchString.trim()?.length < 1) {
      getCrewMembers();
      return;
    }
    const token = decodeString(authState);
    _isLoading(true);
    CrewMemberSearchRequest(token, searchString).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {        
        // Keep server data in local State
        _crewMembers({
          data,
          created: Date.now()
        });
        const newPagination = {
          paginationAvailable: 0,
          currentPage: 1,
          totalPages: 1
        }
        _pagination(newPagination);
        _isLoading(false);
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch crew members' });
        }
      )
  };

  const handlePaginate = (pageNo) => {
    getCrewMembers(pageNo);
  };

  const routeToEditCrewMember = (id) => {
    Navigate(`/crew-members/edit/${id}`);
  };

  const routeToNewCrewMember = (id) => {
    Navigate(`/crew-members/new`);
  };

  // *********** Render Functions ***********

  const CUSTOM_EDIT_BTN = {
    "render":
      <button
        style={{ "color": "black" }}
        className={"custom-edit-fsr-btn small-left-margin"}
      >
        <span className="w3-tooltip">
          <span className='tooltip-text w3-text w3-tag w-12'>
            Edit
          </span>
          <Edit className='h-5 w-5' />
        </span>
      </button>
    ,
    "className": "custom-edit-fsr-btn"
  };


  const NEW_CREW_MEMBER_BUTTON = () => (
    <div className='flex w3-margin-bottom'>
      <div className=' ml-auto'>
        <div className='flex wrap'>
          <button className='flex items-center w3-btn bg-primary-blue w3-text-white small-right-margin' onClick={e => getCrewMembers()}>
            <Refresh className='w-4 h-4' /> <span className='px-1'> Refresh </span>
          </button>
          <button className='flex items-center w3-btn bg-primary-blue w3-text-white' onClick={routeToNewCrewMember}>
            <Plus className='w-5 h-5' /> <span className='px-1'> New Crew </span>
          </button>
        </div>
      </div>
    </div>
  );

  const CONTENT = () => (
    <div className='page-content w3-white h-full relative overflow-hidden'>
      <div className='py-2'>
        {NEW_CREW_MEMBER_BUTTON()}
        <div className='list-view-container overflow-auto'>
          <SearchBar
            placeholder='Search by Name or Passport ...'
            containerClass='w-full relative'
            className='w3-input w3-medium'
            value={searchString}
            onChange={handleSearchString}
            onSearch={handleSearch}
            buttonClass='cursor-pointer no-background w3-border-0 p-1 absolute right-0 small-right-margin'
          />
          <TableLite
            showActions={true}
            data={crewMembers && crewMembers?.data || []}
            headers={CrewMembersheaders}
            customHeaders={CustomHeaders}
            showPagination={pagination?.paginationAvailable}
            totalPages={pagination?.totalPages}
            currentPage={pagination?.currentPage}
            sortBy={sortBy}
            actionTypes={['edit']}
            renderEdit={CUSTOM_EDIT_BTN}
            onPaginate={(pageNo) => handlePaginate(pageNo)}
            onRowEdit={(event, row) => routeToEditCrewMember(row.id)}
            cellStyle={{ fontSize: '0.8em' }}
            noDataMessage={isLoading ? 'Loading data...' : 'No data found'}
          />
        </div>
      </div>
    </div>
  )
  return (
    <div>
      {CONTENT()}
    </div>
  )
}

export default CrewMembersList;