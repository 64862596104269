// Packages
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { Store } from 'react-notifications-component';
import dayjs from 'dayjs';

// APIs
import { ApprovedTicketRequestListRequest, TicketRequestCompleteRequest, TicketRequestEditRequest, TicketRequestListRequest, TicketRequestStatusChangeRequest } from '../../../requests';

// Utils
import { constants, decodeString, authenticationErrorHandle } from '../../../utils';
import { auth, loggedUserInfoCache } from '../../../atoms';
import { TableLite, Plus, Refresh, Modal, Warn } from '../../../components';
import { CustomTicketRequestTable } from './components';

function TicketRequestList() {
  const [isLoading, _isLoading] = useState(false);
  const [isSubmitting, _isSubmitting] = useState(false);
  const [authState, _authState] = useAtom(auth);
  const [selectedTicketRequest, _selectedTicketRequest] = useState(null);
  const [showRequestCancelModal, _showRequestCancelModal] = useState(false);
  const [showRequestApproveModal, _showRequestApproveModal] = useState(false);
  const [showRequestCompleteModal, _showRequestCompleteModal] = useState(false);
  const [ticketRequests, _ticketRequests] = useState([]);
  const [expandedTicketRequestListIds, _expandedTicketRequestListIds] = useState([]);
  const [approvedTicketRequestList, _approvedTicketRequestList] = useState([]);
  const [loadingApprovedTicketRequestList, _loadingApprovedTicketRequestList] = useState([]);
  const [loggedUserInfo, _loggedUserInfo] = useAtom(loggedUserInfoCache);
  const [pagination, _pagination] = useState({
    paginationAvailable: 0,
    currentPage: 1,
    totalPages: 1
  });
  const Navigate = useNavigate();

  const TicketRequestHeaders = ['id', 'departure_city', 'arrival_city', 'no_of_ticket', 'departure_datetime', 'status'];
  const CustomHeaders = {
    id: 'Request Id',
    departure_city: "From",
    arrival_city: 'To',
    no_of_ticket: "Tickets",
    departure_datetime: "Departure",
    // arrival_datetime: "Arrival",    
    status: "status"
  };

  // Get fleet & airbases either from cache or from server
  useEffect(() => {
    if (authState) {
      getTicketRequests();
    }
  }, [authState]);

  const getTicketRequests = (page = undefined) => {
    const token = decodeString(authState);
    _isLoading(true);
    TicketRequestListRequest(token, page).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    })
      .then(data => {
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if (data && data.results) {
          _isLoading(false);
          const newPagination = {
            currentPage: page,
            paginationAvailable: data?.count,
            totalPages: Math.ceil(data?.count / constants.PAGINATIONPERPAGE)
          };
          data.results = data.results?.map(D => (
            {
              ...D,
            }
          ));
          _ticketRequests([...data.results]);
          if (data?.count) _pagination(newPagination);
        } else {
          throw 'Request Failed';
        }
      }
      )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch ticket requests' });
        }
      )
  };

  const getApprovedTicketRequestList = (ticketRequestId) => {
    const token = decodeString(authState);
    if (loadingApprovedTicketRequestList?.find(id => id === ticketRequestId)) return;
    _loadingApprovedTicketRequestList(old => [...old, ticketRequestId]);
    ApprovedTicketRequestListRequest(token, ticketRequestId).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        _approvedTicketRequestList(old => [...old, ...data]);
        _loadingApprovedTicketRequestList(old => [...old?.filter(id => id !== ticketRequestId)]);
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch approved reservations history' });
        }
      );
  };

  // *********** Handlers ***********

  const handlePaginate = (pageNo) => {
    getTicketRequests(pageNo);
  };

  const routeToNewTicketRequest = () => {
    Navigate('/ticket-requests/new');
  };

  const routeToEditTicketRequest = (id) => {
    Navigate(`/ticket-requests/edit/${id}`);
  };

  const routeToViewTicketRequest = (id) => {
    Navigate(`/ticket-requests/view/${id}`);
  };

  const routeToViewApprovedTicketRequest = (id) => {
    Navigate(`/approved-ticket-request/view/${id}`);
  };

  const handleCancelRequest = (ticketRequest) => {
    _selectedTicketRequest(ticketRequest);
    _showRequestCancelModal(true);
  };

  const handleCloseRequestCancelModal = () => {
    _selectedTicketRequest(null);
    _showRequestCancelModal(false);
  };

  const handleApproveRequest = (ticketRequest) => {
    _selectedTicketRequest(ticketRequest);
    _showRequestApproveModal(true);
  };

  const handleCloseRequestApproveModal = () => {
    _selectedTicketRequest(null);
    _showRequestApproveModal(false);
  };

  const handleCompleteRequest = (ticketRequest) => {
    _selectedTicketRequest(ticketRequest);
    _showRequestCompleteModal(true);
  };

  const handleCloseRequestCompleteModal = () => {
    _selectedTicketRequest(null);
    _showRequestCompleteModal(false);
  };

  const handleConfirmTicketRequestApprove = () => {
    const token = decodeString(authState);
    _isSubmitting(true);
    const data = { ...selectedTicketRequest };
    data.status = "Approved";
    data.crew_members = data?.ticket_request_crew_member?.map(TC => TC?.crew_member);
    delete data.ticket_request_crew_member;
    delete data.approve_ticket_request;
    delete data.createdat;
    delete data.updatedat;
    TicketRequestEditRequest(token, JSON.stringify(data), selectedTicketRequest?.id)
      .then(res => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
          throw 'Request Failed';
        } else return (res.json())
      })
      .then(data => {
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
          throw 'Request Failed';
        } else {
          _isSubmitting(false);
          _expandedTicketRequestListIds([]);
          _approvedTicketRequestList([]);
          getTicketRequests();
          handleCloseRequestApproveModal();
          Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Ticket request approved' });
        }
      })
      .catch(err => {
        _isSubmitting(false);
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
      });
  };

  const handleConfirmTicketRequestCancel = () => {
    const token = decodeString(authState);
    _isSubmitting(true);
    const data = {};
    data.status = "Cancelled";
    TicketRequestStatusChangeRequest(token, JSON.stringify(data), selectedTicketRequest?.id)
      .then(res => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
          throw 'Request Failed';
        } else return (res.json())
      })
      .then(data => {
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
          throw 'Request Failed';
        } else {
          _isSubmitting(false);
          _expandedTicketRequestListIds([]);
          _approvedTicketRequestList([]);
          getTicketRequests();
          handleCloseRequestCancelModal();
          Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Ticket request cancelled' });
        }
      })
      .catch(err => {
        _isSubmitting(false);
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
      });
  };

  const handleConfirmTicketRequestComplete = () => {
    const token = decodeString(authState);
    _isSubmitting(true);
    const data = {};
    data.status = "Completed";
    TicketRequestCompleteRequest(token, JSON.stringify(data), selectedTicketRequest?.id)
      .then(res => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
          throw 'Request Failed';
        } else return (res.json())
      })
      .then(data => {
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
          throw 'Request Failed';
        } else {
          _isSubmitting(false);
          _expandedTicketRequestListIds([]);
          _approvedTicketRequestList([]);
          getTicketRequests();
          // getApprovedHotelRequestList(selectedHotelRequest?.hotel_request);
          handleCloseRequestCompleteModal();
          Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Ticket request completed' });
        }
      })
      .catch(err => {
        _isSubmitting(false);
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
      });
  };

  const toggleExpandTicketRequest = (ticketReq) => {
    if (expandedTicketRequestListIds?.find(id => id == ticketReq?.id)) {
      _expandedTicketRequestListIds(old => old?.filter(id => id != ticketReq?.id))
    } else _expandedTicketRequestListIds(old => [...old, ticketReq?.id]);
    if (approvedTicketRequestList?.find(approvedTicketRequest => approvedTicketRequest?.ticket_request == ticketReq?.id)) {
      _approvedTicketRequestList(old => old?.filter(approvedTicketRequest => approvedTicketRequest?.ticket_request != ticketReq?.id));
    } else
      getApprovedTicketRequestList(ticketReq?.id);
  };

  // *********** Render Functions ***********

  const NEW_TICKET_REQUEST_BUTTON = () => (
    <div className="flex w3-margin-bottom">
      <div className="ml-auto">
        <div className="flex wrap">
          <button className="flex items-center w3-btn bg-primary-blue w3-text-white small-right-margin" onClick={() => getTicketRequests()}>
            <Refresh className="w-4 h-4" /> <span className="px-1"> Refresh </span>
          </button>
          <button className="flex items-center w3-btn bg-primary-blue w3-text-white" onClick={routeToNewTicketRequest}>
            <Plus className="w-5 h-5" /> <span className="px-1"> New Ticket Request </span>
          </button>
        </div>
      </div>
    </div>
  );

  const REQUEST_CANCEL_MODAL = () => (
    <Modal isOpen={showRequestCancelModal} modalClass="w-fit w3-round-medium">
      <div className="w-full">
        <div className='w3-pale-red w3-text-red py-1 px-2 flex flex-wrap items-center'>
          <Warn className='w-6 h-6 small-right-margin' />
          <h3>
            Cancel Request
          </h3>
        </div>
        <div className='py-1 px-2'>
          <React.Fragment>
            Are you sure you want to cancel
            <span className='bold'>{` Ticket Request ID ${selectedTicketRequest?.id}`}</span>
            ?
            <p className='w3-text-red'> Cancelled requests can not be changed again </p>
          </React.Fragment>
          <div className="h-3"></div>
          <div className="flex justify-end">
            <button onClick={handleCloseRequestCancelModal} className='w3-btn w3-grey w3-text-white'> Close </button>
            <button onClick={handleConfirmTicketRequestCancel} className='w3-btn w3-red small-left-margin'>
              {isSubmitting ? 'Processing' : 'Confirm'}
            </button>
          </div>
          <div className="h-3"></div>
        </div>
      </div>
    </Modal>
  );

  const REQUEST_APPROVE_MODAL = () => (
    <Modal isOpen={showRequestApproveModal} modalClass="w-fit w3-round-medium">
      <div className="w-full">
        <div className='bg-primary-blue w3-text-white py-1 px-2 flex flex-wrap items-center'>
          <Warn className='w-6 h-6 small-right-margin' />
          <h3>
            Approve Request
          </h3>
        </div>
        <div className='py-1 px-2'>
          <React.Fragment>
            Are you sure you want to approve
            <span className='bold'>{` Ticket Request ID ${selectedTicketRequest?.id}`}</span>
            ?
          </React.Fragment>
          <div className="h-3"></div>
          <div className="flex justify-end">
            <button onClick={handleCloseRequestApproveModal} className='w3-btn w3-grey w3-text-white'> Close </button>
            <button onClick={handleConfirmTicketRequestApprove} disabled={isSubmitting} className='w3-btn w3-text-white bg-primary-blue small-left-margin'>
              {isSubmitting ? 'Processing' : 'Confirm'}
            </button>
          </div>
          <div className="h-3"></div>
        </div>
      </div>
    </Modal>
  );

  const REQUEST_COMPLETE_MODAL = () => (
    <Modal isOpen={showRequestCompleteModal} modalClass="w-fit w3-round-medium">
      <div className="w-full">
        <div className='w3-pale-green w3-text-green py-1 px-2 flex flex-wrap items-center'>
          <Warn className='w-6 h-6 small-right-margin' />
          <h3>
            Complete Request
          </h3>
        </div>
        <div className='py-1 px-2'>
          <React.Fragment>
            Are you sure you want to complete
            <span className='bold'>{` Ticket Request `}</span>
            ?
          </React.Fragment>
          <div className="h-3"></div>
          <div className="flex justify-end">
            <button onClick={handleCloseRequestCompleteModal} className='w3-btn w3-grey w3-text-white'> Close </button>
            <button onClick={handleConfirmTicketRequestComplete} className='w3-btn w3-green small-left-margin'>
              {isSubmitting ? 'Processing' : 'Confirm'}
            </button>
          </div>
          <div className="h-3"></div>
        </div>
      </div>
    </Modal>
  );

  const CONTENT = () => (
    <div className='page-content w3-white h-full relative overflow-hidden'>
      <div className='py-2'>
        {NEW_TICKET_REQUEST_BUTTON()}
        <div className='list-view-container overflow-auto'>
          <CustomTicketRequestTable
            ticketRequestList={ticketRequests}
            headers={Object.values(CustomHeaders)}
            expandedTicketRequestListIds={expandedTicketRequestListIds}
            approvedTicketRequestList={approvedTicketRequestList}
            routeToEditTicketRequest={routeToEditTicketRequest}
            routeToViewTicketRequest={routeToViewTicketRequest}
            routeToViewApprovedTicketRequest={routeToViewApprovedTicketRequest}
            handleCancelRequest={row => handleCancelRequest(row)}
            handleApproveRequest={row => handleApproveRequest(row)}
            handleCompleteRequest={row => handleCompleteRequest(row)}
            toggleExpandTicketRequest={row => toggleExpandTicketRequest(row)}
            isStaff={loggedUserInfo && loggedUserInfo?.data?.is_staff}
            loadingApprovedTicketRequestList={loadingApprovedTicketRequestList}
            isLoading={isLoading}
          />
          {/* Table lite code only used for pagination */}
          <TableLite
            data={[]}
            headers={TicketRequestHeaders}
            showPagination={pagination?.paginationAvailable}
            totalPages={pagination?.totalPages}
            currentPage={pagination?.currentPage}
            onPaginate={(pageNo) => handlePaginate(pageNo)}
            tableClass='hidden'
            rowStyle={{ display: 'hidden' }}
            headerStyle={{ display: 'hidden' }}
            cellStyle={{ fontSize: '0.8em' }}
            noDataMessage={isLoading ? 'Loading data...' : 'No data found'}
          />
        </div>
      </div>
      {REQUEST_CANCEL_MODAL()}
      {REQUEST_APPROVE_MODAL()}
      {REQUEST_COMPLETE_MODAL()}
    </div>
  );

  return (
    <div>
      {CONTENT()}
    </div>
  )
}

export default React.memo(TicketRequestList);