// Packages
import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Store } from 'react-notifications-component';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import dayjs from 'dayjs';

// APIs
import { FleetListRequest, CrewMemberCreateRequest, CrewMemberDetailsRequest, CrewMemberEditRequest, CrewMemberListRequest } from '../../../requests';

// Utils
import { auth, crewMembersCache, fleetCache, loggedUserInfoCache, } from '../../../atoms';
import { constants, decodeString, authenticationErrorHandle, verifyFileSizeLessThanOneMb, validateSubmissionData } from '../../../utils';
import { FileUpload, Loader, Refresh } from '../../../components';

function CrewMembersForm() {
  const { crewMemberId } = useParams();
  const Navigate = useNavigate();

  const INITIAL_FORM = {
    first_name: '',
    middle_name: '',
    last_name: '',
    crewname: '',
    crewtitle: '',
    saudia_id: '',
    passport: '',
    expiry_date: '',
    birth_date: '',
    license: '',
    nationality: '',
    fleet: '',
    company: '',
    passport_file: '',
    passport_file_name: '',
    gender: '',
    many_to_many_fleet: []
  };

  const VALIDATIONS = {
    first_name: { isRequired: true },
    last_name: { isRequired: true },
    passport: { isRequired: true },   
    many_to_many_fleet: { isRequired: true, isArray: true, label: "Multi fleet" }    
  };

  const [isSubmitting, _isSubmitting] = useState(false);
  const [isLoading, _isLoading] = useState(crewMemberId ? true : false);
  const [form, _form] = useState({ ...INITIAL_FORM });
  const [formErrors, _formErrors] = useState({});
  const [authState, _authState] = useAtom(auth);
  const [loggedUserInfo] = useAtom(loggedUserInfoCache);
  const [crewMembers, _crewMembers] = useAtom(crewMembersCache);
  const [fleet, _fleet] = useAtom(fleetCache);


  useEffect(() => {
    if (crewMemberId && authState) {
      getCrewMemberDetails();
    }
  }, [authState, crewMemberId]);

  // Get fleet either from cache or from server
  useEffect(() => {
    if (authState) {
      if (!fleet || !fleet.created || Date.now() - fleet.created >= 1200000) {
        getFleet();
      }
    }
  }, [authState, form?.id]);

  // Set company and fleet from logged user Info for create case
  useEffect(() => {
    if (!crewMemberId && loggedUserInfo?.data) {
      setCompanyAndFleet();
    }
  }, [loggedUserInfo?.data, form?.id]);

  // Set crewname on first_name, last_name & middle_name change
  useEffect(() => {
    const crewname = (form?.first_name || '') + ' ' +
      (form?.middle_name || '') + ' ' +
      (form?.last_name || '');
    _form(old => ({
      ...old,
      crewname
    }));
  }, [form?.first_name, form?.last_name, form?.middle_name]);

  const getCrewMemberDetails = () => {
    const token = decodeString(authState);
    _isLoading(true);
    CrewMemberDetailsRequest(token, crewMemberId).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        _isLoading(false);
        _form(data);
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch crew member details' });
        }
      )
  };

  const getCrewMembers = () => {
    const token = decodeString(authState);
    CrewMemberListRequest(token).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data && data.results) {
        // Keep server data in cache with current time
        _crewMembers({
          data: [...data.results],
          created: Date.now()
        });
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch crew members' });
        }
      )
  };

  const getFleet = () => {
    const token = decodeString(authState);
    FleetListRequest(token).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data && data.results) {
        // Keep server data in cache with current time
        _fleet({
          data: [...data.results],
          created: Date.now()
        });
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch fleet' });
        }
      )
  };

  const setCompanyAndFleet = () => {
    _form(old => ({
      ...old,
      company: loggedUserInfo?.data?.company,
      fleet: (!loggedUserInfo?.data?.is_staff && loggedUserInfo?.data?.fleet?.id) || old?.fleet,

      // set many_to_many_fleet from user's assigned fleet in case of new crew member create
      many_to_many_fleet: (!crewMemberId &&
        !loggedUserInfo?.data?.is_staff &&
        loggedUserInfo?.data?.fleet?.id &&
        [loggedUserInfo?.data?.fleet?.id]) ||
        [...old?.many_to_many_fleet]
    }));
  };

  // *********** Handlers ***********

  const handleCrewMemberFieldChange = (name, value) => {
    _form(old => ({
      ...old,
      [name]: value,
      fleet: old?.many_to_many_fleet?.[0] || ''
    }));
  };

  const handleCrewMemberPassportUpload = (e) => {
    if (verifyFileSizeLessThanOneMb(e.target.files[0].size))
      _form(old => ({
        ...old,
        passport_file: e.target.files[0],
        passport_file_name: e.target.files[0]?.name
      }));
    else Store.addNotification({ ...constants.ERRORTOAST, message: 'Passport size should be less than 1 MB' });
  };

  const handleCheckValidation = () => {
    const { allValid, errors } = validateSubmissionData(form, VALIDATIONS);
    if (!allValid) {
      _formErrors(errors);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Required fields have errors" });
    } else {
      _formErrors({});
    }
    return allValid;
  };

  const handleSubmit = () => {
    const token = decodeString(authState);
    const data = JSON.parse(JSON.stringify(form));
    const form_data = new FormData();
    const JSONStringData = JSON.stringify(data);
    form_data.append('data', JSON.stringify(data));
    form_data.append('passport_file', form?.passport_file);    
    const allValid = handleCheckValidation();
    if (!allValid) return;
    _isSubmitting(true);
    if (crewMemberId) {
      CrewMemberEditRequest(token, form_data, crewMemberId)
        .then(res => {
          if (res && res?.status === 401) {
            authenticationErrorHandle(() => _authState('0'));
            return (
              { errorCodes: constants.SESSIONTIMEOUT }
            );
          } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
            try {
              res.json().then(backEndErrorMessageObj => {
                Object.keys(backEndErrorMessageObj).forEach(key => {
                  Store.addNotification({ ...constants.ERRORTOAST, message: backEndErrorMessageObj?.[key]?.[0] });
                })
              })
            } catch (err) {
              console.err(err);
            }
            throw 'Request Failed';
          } else return (res.json())
        })
        .then(data => {
          if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
          if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
            throw 'Request Failed';
          } else {
            getCrewMembers();
            _isSubmitting(false);
            Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Crew member updated' });
            Navigate("/crew-members");
          }
        })
        .catch(err => {
          _isSubmitting(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
        });
    } else {
      CrewMemberCreateRequest(token, form_data)
        .then(res => {
          if (res && res?.status === 401) {
            authenticationErrorHandle(() => _authState('0'));
            return (
              { errorCodes: constants.SESSIONTIMEOUT }
            );
          } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
            try {
              res.json().then(backEndErrorMessageObj => {
                Object.keys(backEndErrorMessageObj).forEach(key => {
                  Store.addNotification({ ...constants.ERRORTOAST, message: backEndErrorMessageObj?.[key]?.[0] });
                })
              })
            } catch (err) {
              console.err(err);
            }
            throw 'Request Failed';
          } else return (res.json())
        })
        .then(data => {
          if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
          if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
            throw 'Request Failed';
          } else {
            getCrewMembers();
            _isSubmitting(false);
            Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Crew member created' });
            Navigate("/crew-members");
          }
        })
        .catch(err => {
          _isSubmitting(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
        });
    }
  };


  // *********** Render Functions ***********

  const CREW_MEMBER_DETAILS = () => (
    <React.Fragment>
      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <label> First Name </label>
          <input
            type='text'
            value={form?.first_name}
            onChange={e => handleCrewMemberFieldChange('first_name', e.target.value)}
            className={`w3-input w3-border w3-round small-top-margin small-bottom-margin ${formErrors?.first_name ? 'error-field' : ''}`}
          />
        </div>
        <div className='w3-col l6 m6 s12'>
          <label> Middle Name </label>
          <input
            type='text'
            value={form?.middle_name}
            onChange={e => handleCrewMemberFieldChange('middle_name', e.target.value)}
            className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
          />
        </div>
      </div>

      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <label> Last Name </label>
          <input
            type='text'
            value={form?.last_name}
            onChange={e => handleCrewMemberFieldChange('last_name', e.target.value)}
            className={`w3-input w3-border w3-round small-top-margin small-bottom-margin ${formErrors?.last_name ? 'error-field' : ''}`}
          />
        </div>
        <div className='w3-col l6 m6 s12'>
          <label> Full Name </label>
          <input
            type='text'
            value={form?.crewname}
            onChange={e => handleCrewMemberFieldChange('crewname', e.target.value)}
            className={`w3-input w3-border w3-round small-top-margin small-bottom-margin`}
          />
        </div>
      </div>

      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <label> Gender </label>
          <Select
            placeholder='Select Gender'
            options={[
              { value: 'M', label: 'Male' },
              { value: 'F', label: 'Female' }
            ]}
            value={form?.gender ? {
              value: form?.gender,
              label: constants.CREW_GENDER?.[form?.gender]
            } : null}
            onChange={G => handleCrewMemberFieldChange('gender', G.value)}
            className={`w-full small-top-margin small-bottom-margin`}
          />
        </div>
        <div className='w3-col l6 m6 s12'>
          {/* <label> Saudia ID </label> */}
          <label> Military no. </label>
          <input
            type='text'
            value={form?.saudia_id}
            onChange={e => handleCrewMemberFieldChange('saudia_id', e.target.value)}
            className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
          />
        </div>
      </div>

      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <label> Birth Date </label>
          <input
            max={dayjs().format('YYYY-MM-DD')}
            type='date'
            value={form?.birth_date}
            onChange={e => handleCrewMemberFieldChange('birth_date', e.target.value)}
            className={`w3-input w3-border w3-round small-top-margin small-bottom-margin ${formErrors?.birth_date ? 'error-field' : ''}`}
          />
        </div>
        <div className='w3-col l6 m6 s12'>
          <label> License </label>
          <input
            type='text'
            value={form?.license}
            onChange={e => handleCrewMemberFieldChange('license', e.target.value)}
            className={`w3-input w3-border w3-round small-top-margin small-bottom-margin ${formErrors?.license ? 'error-field' : ''}`}
          />
        </div>
      </div>

      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <label> Passport </label>
          <input
            type='text'
            value={form?.passport}
            onChange={e => handleCrewMemberFieldChange('passport', e.target.value)}
            className={`w3-input w3-border w3-round small-top-margin small-bottom-margin ${formErrors?.passport ? 'error-field' : ''}`}
          />
        </div>
        <div className='w3-col l6 m6 s12'>
          <label> Expiry Date </label>
          <input
            min={dayjs().format('YYYY-MM-DD')}
            type='date'
            value={form?.expiry_date}
            onChange={e => handleCrewMemberFieldChange('expiry_date', e.target.value)}
            className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
          />
        </div>
      </div>

      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <label> Title </label>
          <input
            type='text'
            value={form?.crewtitle}
            onChange={e => handleCrewMemberFieldChange('crewtitle', e.target.value)}
            className={`w3-input w3-border w3-round small-top-margin small-bottom-margin ${formErrors?.crewtitle ? 'error-field' : ''}`}
          />
        </div>
        <div className='w3-col l6 m6 s12'>
          <label> Nationality </label>
          <Select
            placeholder='Select Country'
            options={constants.COUNTRYARRAY?.map(C => (
              { value: C.country, label: C.country })
            )}
            value={form?.nationality ? {
              value: form?.nationality,
              label: form?.nationality
            } : null}
            onChange={A => handleCrewMemberFieldChange('nationality', A.value)}
            className={`w-full small-top-margin small-bottom-margin ${formErrors?.nationality ? 'error-field' : ''}`}
          />
        </div>
      </div>

      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <label> Fleets </label>
          <div className='flex items-center'>
            <Select               
              isMulti={true}
              isClearable={false}
              placeholder='Search Fleet'
              isDisabled={(loggedUserInfo && !loggedUserInfo?.data?.is_staff) || false}
              options={fleet && fleet?.data?.map(F => (
                { value: F.id, label: F.name })
              )}
              value={form?.many_to_many_fleet && form?.many_to_many_fleet?.map((fleetID => ({
                value: fleetID,
                label: fleet && fleet?.data?.find(F => F.id == fleetID)?.name
              }))) || []}
              onChange={F => handleCrewMemberFieldChange('many_to_many_fleet', F?.map(F => F?.value))}
              className={`w-full small-top-margin small-bottom-margin ${formErrors?.many_to_many_fleet ? 'error-field' : ''}`}
            />
            <span onClick={getFleet} className='px-2 cursor-pointer'> <Refresh className='h-5 w-5 w3-text-grey' /> </span>
          </div>
        </div>
        <div className='w3-col l6 m6 s12'>
          <FileUpload
            id='passport_file'
            name='passport_file'
            label='Upload Passport'
            accept={'application/pdf,image/*'}
            isDownloadable={true}
            file={form?.passport_file}
            fileName={form?.passport_file_name}
            onChange={handleCrewMemberPassportUpload}
            labelClass='w3-btn bg-primary-blue w3-text-white small-top-margin'
          />
        </div>
      </div>
    </React.Fragment>
  );

  const LOADER = () => (
    <div className="request-form-container">
      <div className="h-30 flex justify-center items-center">
        <div><Loader spinnerClassName='w-10 h-10 text-primary-blue' />
          <p className='text-primary-blue'> Loading data... </p>
        </div>
      </div>
    </div>
  );

  const FORM_FOOTER = () => (
    <React.Fragment>
      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <button
            disabled={isSubmitting}
            onClick={handleSubmit}
            className='w3-btn bg-primary-blue w3-text-white small-top-margin'
          >
            {isSubmitting ? 'Submitting' : 'Submit'}
          </button>
        </div>
      </div>
      <div className='h-2'></div>
    </React.Fragment>
  );

  const CREWMEMBER_FORM = () => (
    <div className='request-form-container w-full relative'>
      <span className='heading'> Crew Member </span>
      <div className='border-primary-blue w3-round p-2'>
        {CREW_MEMBER_DETAILS()}
        {FORM_FOOTER()}
      </div>
    </div>
  );

  return (
    <div id='Crewmember-Form'>
      {isLoading
        ? LOADER()
        : CREWMEMBER_FORM()
      }
    </div>
  )
}

export default CrewMembersForm;







// ************ OLD ************
// // Packages
// import React, { useEffect, useState } from 'react';
// import { useAtom } from 'jotai';
// import { Store } from 'react-notifications-component';
// import { useNavigate, useParams } from 'react-router-dom';
// import Select from 'react-select';

// // APIs
// import { FleetListRequest, CrewMemberCreateRequest, CrewMemberDetailsRequest, CrewMemberEditRequest, CrewMemberListRequest } from '../../../requests';

// // Utils
// import { auth, crewMembersCache, fleetCache, loggedUserInfoCache, } from '../../../atoms';
// import { constants, decodeString, authenticationErrorHandle } from '../../../utils';
// import { Refresh } from '../../../components';

// function CrewMemberForm() {
//   const { crewMemberId } = useParams();
//   const Navigate = useNavigate();

//   const INITIAL_FORM = {
//     company: '',
//     crewname: '',
//     crewtitle: '',
//     license: '',
//     passport: '',
//     nationality: '',
//     fleet: ''
//   };

//   const [isSubmitting, _isSubmitting] = useState(false);
//   const [isLoading, _isLoading] = useState(false);
//   const [form, _form] = useState({ ...INITIAL_FORM });
//   const [authState, _authState] = useAtom(auth);
//   const [loggedUserInfo] = useAtom(loggedUserInfoCache);
//   const [crewMembers, _crewMembers] = useAtom(crewMembersCache);
//   const [fleet, _fleet] = useAtom(fleetCache);


//   useEffect(() => {
//     if (crewMemberId && authState) {
//       getCrewMemberDetails();
//     }
//   }, [authState, crewMemberId]);

//   // Get fleet either from cache or from server
//   useEffect(() => {
//     if (authState) {
//       if (!fleet || !fleet.created || Date.now() - fleet.created >= 1200000) {
//         getFleet();
//       }
//     }
//   }, [authState, form?.id]);

//   // Set company from logged user Info for create case
//   useEffect(() => {
//     if (!crewMemberId && loggedUserInfo?.data) {
//       setCompany();
//     }
//   }, [loggedUserInfo?.data, form?.id]);

//   const getCrewMemberDetails = () => {
//     const token = decodeString(authState);
//     _isLoading(true);
//     CrewMemberDetailsRequest(token, crewMemberId).then(res => {
//       if (res && res?.status === 401) {
//         authenticationErrorHandle(() => _authState('0'));
//         return (
//           { errorCodes: constants.SESSIONTIMEOUT }
//         );
//       } else return (res.json())
//     }).then(data => {
//       if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
//       if (data) {
//         _isLoading(false);
//         _form(data);
//       } else {
//         throw 'Request Failed';
//       }
//     }
//     )
//       .catch(
//         err => {
//           _isLoading(false);
//           console.error(err);
//           Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch crew member details' });
//         }
//       )
//   };

//   const getCrewMembers = () => {
//     const token = decodeString(authState);
//     CrewMemberListRequest(token).then(res => {
//       if (res && res?.status === 401) {
//         authenticationErrorHandle(() => _authState('0'));
//         return (
//           { errorCodes: constants.SESSIONTIMEOUT }
//         );
//       } else return (res.json())
//     }).then(data => {
//       if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
//       if (data && data.results) {
//         // Keep server data in cache with current time
//         _crewMembers({
//           data: [...data.results],
//           created: Date.now()
//         });
//       } else {
//         throw 'Request Failed';
//       }
//     }
//     )
//       .catch(
//         err => {
//           console.error(err);
//           Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch crew members' });
//         }
//       )
//   };

//   const getFleet = () => {
//     const token = decodeString(authState);
//     FleetListRequest(token).then(res => {
//       if (res && res?.status === 401) {
//         authenticationErrorHandle(() => _authState('0'));
//         return (
//           { errorCodes: constants.SESSIONTIMEOUT }
//         );
//       } else return (res.json())
//     }).then(data => {
//       if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
//       if (data && data.results) {
//         // Keep server data in cache with current time
//         _fleet({
//           data: [...data.results],
//           created: Date.now()
//         });
//       } else {
//         throw 'Request Failed';
//       }
//     }
//     )
//       .catch(
//         err => {
//           console.error(err);
//           Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch fleet' });
//         }
//       )
//   };

//   const setCompany = () => {
//     _form(old => ({
//       ...old,
//       company: loggedUserInfo?.data?.company
//     }));
//   };

//   // *********** Handlers ***********

//   const handleCrewMemberFieldChange = (name, value) => {
//     _form(old => ({
//       ...old,
//       [name]: value
//     }));
//   };

//   const handleSubmit = () => {
//     const token = decodeString(authState);
//     const data = JSON.parse(JSON.stringify(form));
//     _isSubmitting(true);
//     if (crewMemberId) {
//       CrewMemberEditRequest(token, JSON.stringify(data), crewMemberId)
//         .then(res => {
//           if (res && res?.status === 401) {
//             authenticationErrorHandle(() => _authState('0'));
//             return (
//               { errorCodes: constants.SESSIONTIMEOUT }
//             );
//           } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
//             throw 'Request Failed';
//           } else return (res.json())
//         })
//         .then(data => {
//           if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
//           if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
//             throw 'Request Failed';
//           } else {
//             getCrewMembers();
//             _isSubmitting(false);
//             Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Crew member updated' });
//             Navigate("/crew-members");
//           }
//         })
//         .catch(err => {
//           _isSubmitting(false);
//           console.error(err);
//           Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
//         });
//     } else {
//       CrewMemberCreateRequest(token, JSON.stringify(data))
//         .then(res => {
//           if (res && res?.status === 401) {
//             authenticationErrorHandle(() => _authState('0'));
//             return (
//               { errorCodes: constants.SESSIONTIMEOUT }
//             );
//           } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
//             throw 'Request Failed';
//           } else return (res.json())
//         })
//         .then(data => {
//           if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
//           if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
//             throw 'Request Failed';
//           } else {
//             getCrewMembers();
//             _isSubmitting(false);
//             Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Crew member created' });
//             Navigate("/crew-members");
//           }
//         })
//         .catch(err => {
//           _isSubmitting(false);
//           console.error(err);
//           Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
//         });
//     }
//   };


//   // *********** Render Functions ***********

//   const CREW_MEMBER_DETAILS = () => (
//     <React.Fragment>
//       <div className='w3-row-padding'>
//         <div className='w3-col l6 m6 s12'>
//           <label> Name </label>
//           <input
//             type='text'
//             value={form?.crewname}
//             onChange={e => handleCrewMemberFieldChange('crewname', e.target.value)}
//             className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
//           />
//         </div>
//         <div className='w3-col l6 m6 s12'>
//           <label> Title </label>
//           <input
//             type='text'
//             value={form?.crewtitle}
//             onChange={e => handleCrewMemberFieldChange('crewtitle', e.target.value)}
//             className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
//           />
//         </div>
//       </div>

//       <div className='w3-row-padding'>
//         <div className='w3-col l6 m6 s12'>
//           <label> License </label>
//           <input
//             type='text'
//             value={form?.license}
//             onChange={e => handleCrewMemberFieldChange('license', e.target.value)}
//             className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
//           />
//         </div>
//         <div className='w3-col l6 m6 s12'>
//           <label> Passport </label>
//           <input
//             type='text'
//             value={form?.passport}
//             onChange={e => handleCrewMemberFieldChange('passport', e.target.value)}
//             className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
//           />
//         </div>
//       </div>

//       <div className='w3-row-padding'>
//         <div className='w3-col l6 m6 s12'>
//           <label> Fleet </label>
//           <div className='flex items-center'>
//             <Select
//               placeholder='Search Fleet'
//               options={fleet && fleet?.data?.map(F => (
//                 { value: F.id, label: F.name })
//               )}
//               value={form?.fleet ? {
//                 value: form?.fleet,
//                 label: fleet && fleet?.data?.find(F => F.id == form?.fleet)?.name
//               } : null}
//               onChange={F => handleCrewMemberFieldChange('fleet', F.value)}
//               className='w-full small-top-margin small-bottom-margin'
//             />
//             <span onClick={getFleet} className='px-2 cursor-pointer'> <Refresh className='h-5 w-5 w3-text-grey' /> </span>
//           </div>
//         </div>
//         <div className='w3-col l6 m6 s12'>
//           <label> Nationality </label>
//           <Select
//             placeholder='Select Country'
//             options={constants.COUNTRYARRAY?.map(C => (
//               { value: C.country, label: C.country })
//             )}
//             value={form?.nationality ? {
//               value: form?.nationality,
//               label: form?.nationality
//             } : null}
//             onChange={A => handleCrewMemberFieldChange('nationality', A.value)}
//             className='w-full small-top-margin small-bottom-margin'
//           />
//         </div>
//       </div>

//     </React.Fragment>
//   );

//   const FORM_FOOTER = () => (
//     <React.Fragment>
//       <div className='w3-row-padding'>
//         <div className='w3-col l6 m6 s12'>
//           <button
//             disabled={isSubmitting}
//             onClick={handleSubmit}
//             className='w3-btn bg-primary-blue w3-text-white small-top-margin'
//           >
//             {isSubmitting ? 'Submitting' : 'Submit'}
//           </button>
//         </div>
//       </div>
//       <div className='h-2'></div>
//     </React.Fragment>
//   );

//   const CREWMEMBER_FORM = () => (
//     <div className='request-form-container w-full relative'>
//       <span className='heading'> Crew Member </span>
//       <div className='border-primary-blue w3-round p-2'>
//         {CREW_MEMBER_DETAILS()}
//         {FORM_FOOTER()}
//       </div>
//     </div>
//   )
//   return (
//     <div id='Crewmember-Form'>
//       {CREWMEMBER_FORM()}
//     </div>
//   )
// }

// export default CrewMemberForm;