// Packages
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { Store } from 'react-notifications-component';
import dayjs from 'dayjs';

// APIs
import { AircraftListRequest } from '../../../requests';

// Utils
import { constants, decodeString, authenticationErrorHandle } from '../../../utils';
import { auth, aircraftsCache } from '../../../atoms';
import { TableLite, Edit, Plus, Refresh } from '../../../components';

function AircraftList() {

  const [isLoading, _isLoading] = useState(false);
  const [authState, _authState] = useAtom(auth);  
  const [aircrafts, _aircrafts] = useAtom(aircraftsCache);
  const [listData, _listData] = useState([]);
  const Navigate = useNavigate();

  const Aircraftheaders = ["id", "tailnumber", "fleet", "createdat"];
  const sortBy = ["id", "tailnumber", "fleet"];
  const CustomHeaders = {
    "id": "Aircraft Id",
    "tailnumber": "Tail Number",
    "fleet": "Fleet",
    "createdat": "Created Date",
  };

  // Get fleet either from cache or from server
  useEffect(() => {
    if (authState) {
      // *** by passing cache for aircrafts, fetching list on each time component loads ***
      getAircrafts();
      // if (!fleet || !fleet.created || Date.now() - fleet.created >= 1200000) {
      //   getFleet();
      // }
      // if (!aircrafts || !aircrafts.created || Date.now() - aircrafts.created >= 1200000) {
      //   getAircrafts();
      // }    
    }
  }, [authState]);

  useEffect(() => {
    if (aircrafts?.data) {
      const data = aircrafts?.data.map(A => ({
        ...A,
        fleet: A?.fleet_obj?.name,
        createdat: dayjs(A.createdat).format('YYYY-MM-DD')
      }))
      _listData(data);
    }
  }, [aircrafts?.data]);

  const getAircrafts = (page = undefined) => {
    const token = decodeString(authState);
    _isLoading(true);
    AircraftListRequest(token, page).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data && data.results) {        
        // Keep server data in cache with current time
        _aircrafts({
          data: [...data.results],
          created: Date.now(),
          pagination: {
            currentPage: page,
            totalPages: Math.ceil(data?.count / constants.PAGINATIONPERPAGE)
          }
        });
        _isLoading(false);
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch aircrafts' });
        }
      )
  };

  // *********** Handlers ***********

  const routeToEditAircraft = (id) => {
    Navigate(`/aircrafts/edit/${id}`);
  };

  const routeToNewAircraft = (id) => {
    Navigate(`/aircrafts/new`);
  };

  const handlePaginate = (pageNo) => {
    getAircrafts(pageNo);
  };

  // *********** Render Functions ***********
  const CUSTOM_EDIT_BTN = {
    "render":
      <button
        style={{ "color": "black" }}
        className={"custom-edit-fsr-btn small-left-margin"}
      >
        <span className="w3-tooltip">
          <span className='tooltip-text w3-text w3-tag w-12'>
            Edit
          </span>
          <Edit className='h-5 w-5' />
        </span>
      </button>
    ,
    "className": "custom-edit-fsr-btn"
  };

  const NEW_AIRCRAFT_BUTTON = () => (
    <div className='flex w3-margin-bottom'>
      <div className=' ml-auto'>
        <div className='flex wrap'>
          <button className='flex items-center w3-btn bg-primary-blue w3-text-white small-right-margin' onClick={e => getAircrafts()}>
            <Refresh className='w-4 h-4' /> <span className='px-1'> Refresh </span>
          </button>
          <button className='flex items-center w3-btn bg-primary-blue w3-text-white' onClick={routeToNewAircraft}>
            <Plus className='w-5 h-5' /> <span className='px-1'> New Aircraft </span>
          </button>
        </div>
      </div>
    </div>
  );

  const CONTENT = () => (
    <div className='page-content w3-white h-full relative overflow-hidden'>
      <div className='py-2'>
        {NEW_AIRCRAFT_BUTTON()}
        <div className='list-view-container overflow-auto'>
          <TableLite
            showActions={true}
            showPagination={true}
            totalPages={aircrafts?.pagination?.totalPages || 1}
            currentPage={aircrafts?.pagination?.currentPage || 1}
            data={listData}
            headers={Aircraftheaders}
            customHeaders={CustomHeaders}
            sortBy={sortBy}
            actionTypes={['edit']}
            renderEdit={CUSTOM_EDIT_BTN}
            onRowEdit={(event, row) => routeToEditAircraft(row.id)}
            onPaginate={(pageNo) => handlePaginate(pageNo)}
            cellStyle={{ fontSize: '0.8em' }}
            noDataMessage={isLoading ? 'Loading data...' : 'No data found'}
          />
        </div>
      </div>
    </div>
  )
  return (
    <div>
      {CONTENT()}
    </div>
  )
}

export default AircraftList;