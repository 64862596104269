// Packages
import React from 'react';

// Utils
import RequestHistoryCompareOldNew from './requestHistoryCompareOldNew';

const RequestComparisonChangesSection = ({
    historyObjectToBeCompared,
    historyObjectToCompare,
    changesFound,
    crewMembers,
    services
}) => {

    const typeOfChange = {
        'A': "added",
        'E': "changed",
        'D': "removed"
    };

    // *********** Render Functions ***********

    const FSR_CHANGES_SECTION = () => (
        <div className='fsr-changes'>
            <strong className='fsr-changes-title'> FSR Details </strong>
            {changesFound?.fsrDetails &&
                changesFound?.fsrDetails?.map((change, index) => (
                    <div className='py-1 w3-medium' key={index}>
                        <span className="fsr-changes-info">{change?.kind == 'E' && `${change?.path[0]} ${typeOfChange?.[change?.kind]}`}</span>
                        <RequestHistoryCompareOldNew>
                            <tr>
                                <td>{change?.lhs}</td>
                                <td>{change?.rhs}</td>
                            </tr>
                        </RequestHistoryCompareOldNew>
                    </div>
                ))
            }
        </div>
    );

    const LEGS_CHANGES_SECTION = () => (
        <div className='legs-changes changes-block'>
            <strong className='fsr-changes-title'> Legs </strong>
            {changesFound &&
                changesFound?.haveLegsChanged &&
                <div className='py-1 w3-border-bottom w3-medium'>
                    <span className='fsr-changes-info'> legs Changed </span>
                    <RequestHistoryCompareOldNew>
                        <tr>
                            <td>
                                <ol className="legs-listing">
                                    {changesFound?.oldLegs?.map((L, index) => (
                                        <li key={index}>
                                            {`${L?.departure_airport_icao}/${L?.departure_airport_iata} → ${L?.destination_airport_icao}/${L?.destination_airport_iata}`}
                                        </li>
                                    ))}
                                </ol>
                            </td>
                            <td>
                                <ol className="legs-listing">
                                    {changesFound?.newLegs?.map((L, index) => (
                                        <li key={index}>
                                            {`${L?.departure_airport_icao}/${L?.departure_airport_iata} → ${L?.destination_airport_icao}/${L?.destination_airport_iata}`}
                                        </li>
                                    ))}
                                </ol>
                            </td>
                        </tr>
                    </RequestHistoryCompareOldNew>
                </div>
            }
            {changesFound?.changesInLegs &&
                changesFound?.changesInLegs?.map((change, index) => (
                    <div className='py-1 w3-border-bottom w3-medium' key={index}>
                        {/* leg edit case */}
                        {change &&
                            change?.kind == 'E' &&
                            <>
                                <span className="fsr-changes-sub-heading">
                                    {`${change?.path[1]?.replaceAll("_", " ")} ${typeOfChange?.[change?.kind]}                                
                                    on
                                    `}
                                    <strong>
                                        {`${historyObjectToBeCompared?.approve_request_leg?.[change?.path[0]]?.departure_airport_icao
                                            }/${historyObjectToBeCompared?.approve_request_leg?.[change?.path[0]]?.departure_airport_iata
                                            }
                                        →
                                        ${historyObjectToBeCompared?.approve_request_leg?.[change?.path[0]]?.destination_airport_icao}
                                            /${historyObjectToBeCompared?.approve_request_leg?.[change?.path[0]]?.destination_airport_iata}
                                        `}
                                    </strong>
                                </span>
                                <RequestHistoryCompareOldNew>
                                    <tr>
                                        <td>{change?.lhs}</td>
                                        <td>{change?.rhs}</td>
                                    </tr>
                                </RequestHistoryCompareOldNew>
                            </>
                        }
                    </div>
                ))
            }
        </div>
    );

    const CREW_MEMBERS_CHANGES_SECTION = () => (
        <div className='crewmembers-changes changes-block'>
            <strong className='fsr-changes-title'> Crew Members </strong>
            {changesFound &&
                changesFound?.addedCrewMembers &&
                changesFound?.addedCrewMembers?.map((change, index) => (
                    change && change?.rhs &&
                    <div className='py-1 w3-border-bottom w3-medium' key={index}>
                        <span className="fsr-changes-sub-heading">
                            <span>Crew members added on </span>
                            <strong>{` ${change?.id}`}</strong>
                        </span>
                        <ol className="legs-listing">
                            {change?.rhs?.map((c, index) => (
                                <li key={index}>
                                    {crewMembers?.data?.find(Crew => Crew.id == c)?.crewname}
                                </li>
                            ))}
                        </ol>
                    </div>
                ))
            }
            {changesFound &&
                changesFound?.changedCrewMembers &&
                changesFound?.changedCrewMembers?.map((change, index) => (
                    change && change?.lhs &&
                    <div className='py-1 w3-border-bottom w3-medium' key={index}>
                        <span className="fsr-changes-sub-heading">
                            <span>Crew members changed on</span>
                            <strong>{` ${change?.id}`}</strong>
                        </span>
                        <RequestHistoryCompareOldNew>
                            <tr>
                                <td>
                                    <ol className="legs-listing">
                                        {change?.lhs?.length && change?.lhs?.map((c, index) => (
                                            <li key={index}>
                                                {crewMembers?.data?.find(Crew => Crew.id == c)?.crewname}
                                            </li>
                                        ))
                                            || <>NONE</>
                                        }
                                    </ol>
                                </td>
                                <td>
                                    <ol className="legs-listing">
                                        {change?.rhs?.length && change?.rhs?.map((c, index) => (
                                            <li key={index}>
                                                {crewMembers?.data?.find(Crew => Crew.id == c)?.crewname}
                                            </li>
                                        ))
                                            || <>NONE</>
                                        }
                                    </ol>
                                </td>
                            </tr>
                        </RequestHistoryCompareOldNew>
                    </div>
                ))
            }
        </div>
    );

    const SERVICES_CHANGES_SECTION = () => (
        <div className='services-changes changes-block'>
            <strong className='fsr-changes-title'> Services </strong>
            {changesFound &&
                changesFound?.addedServicesTo &&
                changesFound?.addedServicesTo?.map((change, index) => (
                    change && change?.rhs &&
                    <div className='py-1 w3-border-bottom w3-medium' key={index}>
                        <span className="fsr-changes-sub-heading">
                            <span>Services added at destination on </span>
                            <strong>{` ${change?.id}`}</strong>
                        </span>
                        <ol className="legs-listing">
                            {change?.rhs?.map((c, index) => (
                                <li key={index}>
                                    {services?.data?.find(Service => Service.id == c)?.name}
                                </li>
                            ))}
                        </ol>
                    </div>
                ))
            }
            {changesFound &&
                changesFound?.changedServicesTo &&
                changesFound?.changedServicesTo?.map((change, index) => (
                    change && change?.lhs &&
                    <div className='py-1 w3-border-bottom w3-medium' key={index}>
                        <span className="fsr-changes-sub-heading">
                            <span>Services changed at destination on </span>
                            <strong>{` ${change?.id}`}</strong>
                        </span>
                        <RequestHistoryCompareOldNew>
                            <tr>
                                <td>
                                    <ol className="legs-listing">
                                        {change?.lhs?.length && change?.lhs?.map((c, index) => (
                                            <li key={index}>
                                                {services?.data?.find(Service => Service.id == c)?.name}
                                            </li>
                                        ))
                                            || <> NONE </>
                                        }
                                    </ol>
                                </td>
                                <td>
                                    <ol className="legs-listing">
                                        {change?.rhs?.length && change?.rhs?.map((c, index) => (
                                            <li key={index}>
                                                {services?.data?.find(Service => Service.id == c)?.name}
                                            </li>
                                        ))
                                            || <> NONE </>
                                        }
                                    </ol>
                                </td>
                            </tr>
                        </RequestHistoryCompareOldNew>
                    </div>
                ))
            }
            {changesFound &&
                changesFound?.addedServicesFrom &&
                changesFound?.addedServicesFrom?.map((change, index) => (
                    change && change?.rhs &&
                    <div className='py-1 w3-border-bottom w3-medium' key={index}>

                        <span className="fsr-changes-sub-heading">
                            <span>Services added at departure on </span>
                            <strong>{` ${change?.id}`}</strong>
                        </span>
                        <ol className="legs-listing">
                            {change?.rhs?.map((c, index) => (
                                <li key={index}>
                                    {services?.data?.find(Service => Service.id == c)?.name}
                                </li>
                            ))}
                        </ol>
                    </div>
                ))
            }
            {changesFound &&
                changesFound?.changedServicesFrom &&
                changesFound?.changedServicesFrom?.map((change, index) => (
                    change && change?.lhs &&
                    <div className='py-1 w3-border-bottom w3-medium' key={index}>
                        <span className="fsr-changes-sub-heading">
                            <span>Services changed at departure on </span>
                            <strong>{` ${change?.id}`}</strong>
                        </span>
                        <RequestHistoryCompareOldNew>
                            <tr>
                                <td>
                                    <ol className="legs-listing">
                                        {change?.lhs?.length && change?.lhs?.map((c, index) => (
                                            <li key={index}>
                                                {services?.data?.find(Service => Service.id == c)?.name}
                                            </li>
                                        ))
                                            || <> NONE </>
                                        }
                                    </ol>
                                </td>
                                <td>
                                    <ol className="legs-listing">
                                        {change?.rhs?.length && change?.rhs?.map((c, index) => (
                                            <li key={index}>
                                                {services?.data?.find(Service => Service.id == c)?.name}
                                            </li>
                                        ))
                                            || <> NONE </>
                                        }
                                    </ol>
                                </td>
                            </tr>
                        </RequestHistoryCompareOldNew>
                    </div>
                ))
            }
        </div>
    );

    const HOTEL_CHANGES_SECTION = () => (
        <div className='services-changes changes-block'>
            <strong className='fsr-changes-title'> Hotel Booking </strong>
            {changesFound &&
                changesFound?.changedHotels &&
                changesFound?.changedHotels?.map((change, index) => (
                    change && (change?.rhs?.length || change?.lhs?.length)
                        ? <div className='py-1 w3-border-bottom w3-medium' key={index}>
                            <span className="fsr-changes-sub-heading">
                                <span>Hotel booking changed on </span>
                                <strong>{` ${change?.id}`}</strong>
                            </span>
                            <RequestHistoryCompareOldNew>
                                <tr>
                                    <td>
                                        <ol className="legs-listing">
                                            {change?.lhs?.length && change?.lhs?.map((c, index) => (
                                                <li key={index}>
                                                    {crewMembers?.data?.find(Crew => Crew.id == c)?.crewname}
                                                </li>
                                            ))
                                                || <> NONE </>
                                            }
                                        </ol>
                                    </td>
                                    <td>
                                        <ol className="legs-listing">
                                            {change?.rhs?.length && change?.rhs?.map((c, index) => (
                                                <li key={index}>
                                                    {crewMembers?.data?.find(Crew => Crew.id == c)?.crewname}
                                                </li>
                                            ))
                                                || <> NONE </>
                                            }
                                        </ol>
                                    </td>
                                </tr>
                            </RequestHistoryCompareOldNew>
                        </div>
                        : null
                ))
            }
            {changesFound &&
                changesFound?.changedHotels &&
                changesFound?.changedHotels?.map((change, index) => (
                    change && change?.hasHotelRemoved
                        ? <div className='py-1 w3-border-bottom w3-medium' key={index}>
                            <span className="fsr-changes-sub-heading">
                                <span>Hotel Booking removed on </span>
                                <strong>{` ${change?.id}`}</strong>
                            </span>
                        </div>
                        : null
                ))
            }
        </div>
    );

    const CATERING_CHANGES_SECTION = () => (
        <div className='catering-changes changes-block'>
            <strong className='fsr-changes-title'> Catering </strong>
            {changesFound &&
                changesFound?.changedCatering &&
                changesFound?.changedCatering?.map((change, index) => (
                    change && (change?.rhs?.length || change?.lhs?.length)
                        ? <div className='py-1 w3-border-bottom w3-medium' key={index}>
                            <span className="fsr-changes-sub-heading">
                                <span>Catering changed on </span>
                                <strong>{` ${change?.id}`}</strong>
                            </span>
                            <RequestHistoryCompareOldNew>
                                <tr>
                                    <td>
                                        <ol className="legs-listing">
                                            {change?.lhs?.length && change?.lhs?.map((c, index) => (
                                                <li key={index}>
                                                    {c}
                                                </li>
                                            ))
                                                || <> NONE </>
                                            }
                                        </ol>
                                    </td>
                                    <td>
                                        <ol className="legs-listing">
                                            {change?.rhs?.length && change?.rhs?.map((c, index) => (
                                                <li key={index}>
                                                    {c}
                                                </li>
                                            ))
                                                || <> NONE </>
                                            }
                                        </ol>
                                    </td>
                                </tr>
                            </RequestHistoryCompareOldNew>
                        </div>
                        : null
                ))
            }
            {changesFound &&
                changesFound?.changedCatering &&
                changesFound?.changedCatering?.map((change, index) => (
                    change && change?.hasCateringRemoved
                        ? <div className='py-1 w3-border-bottom w3-medium' key={index}>
                            <span className="fsr-changes-sub-heading">
                                <span>Catering removed on </span>
                                <strong>{` ${change?.id}`}</strong>
                            </span>
                        </div>
                        : null
                ))
            }
        </div>
    );

    const TRANSPORTATION_CHANGES_SECTION = () => (
        <div className='transportation-changes changes-block'>
            <strong className='fsr-changes-title'> Transportation </strong>
            {changesFound &&
                changesFound?.changedTransportations &&
                changesFound?.changedTransportations?.map((change, index) => (
                    change && (change?.rhs?.length || change?.lhs?.length)
                        ? <div className='py-1 w3-border-bottom w3-medium' key={index}>
                            <span className="fsr-changes-sub-heading">
                                <span>Transportation booking changed on </span>
                                <strong>{` ${change?.id}`}</strong>
                            </span>
                            <RequestHistoryCompareOldNew>
                                <tr>
                                    <td>
                                        <ol className="legs-listing">
                                            {change?.lhs?.length && change?.lhs?.map((c, index) => (
                                                <li key={index}>
                                                    {c}
                                                </li>
                                            ))
                                                || <> NONE </>
                                            }
                                        </ol>
                                    </td>
                                    <td>
                                        <ol className="legs-listing">
                                            {change?.rhs?.length && change?.rhs?.map((c, index) => (
                                                <li key={index}>
                                                    {c}
                                                </li>
                                            ))
                                                || <> NONE </>
                                            }
                                        </ol>
                                    </td>
                                </tr>
                            </RequestHistoryCompareOldNew>
                        </div>
                        : null
                ))
            }
            {changesFound &&
                changesFound?.changedTransportations &&
                changesFound?.changedTransportations?.map((change, index) => (
                    change && change?.hasTransportationRemoved
                        ? <div className='py-1 w3-border-bottom w3-medium' key={index}>
                            <span className="fsr-changes-sub-heading">
                                <span>Transportation booking removed on </span>
                                <strong>{` ${change?.id}`}</strong>
                            </span>
                        </div>
                        : null
                ))
            }
        </div>
    );

    return (
        <React.Fragment>
            {changesFound?.fsrDetails
                && FSR_CHANGES_SECTION()
            }
            {(changesFound?.haveLegsChanged || changesFound?.changesInLegs)
                && LEGS_CHANGES_SECTION()
            }
            {(changesFound?.addedCrewMembers?.find(c => c?.id)
                || changesFound?.changedCrewMembers.find(c => c?.id))
                && CREW_MEMBERS_CHANGES_SECTION()
            }
            {(changesFound?.changedServicesFrom?.find(s => s?.id)
                || changesFound?.changedServicesTo.find(s => s?.id)
                || changesFound?.addedServicesFrom.find(s => s?.id)
                || changesFound?.addedServicesTo.find(s => s?.id))
                && SERVICES_CHANGES_SECTION()
            }
            {changesFound?.changedHotels?.find(h => h?.id)
                && HOTEL_CHANGES_SECTION()
            }
            {changesFound?.changedCatering?.find(ct => ct?.id)
                && CATERING_CHANGES_SECTION()
            }
            {changesFound?.changedTransportations?.find(t => t?.id)
                && TRANSPORTATION_CHANGES_SECTION()
            }
        </React.Fragment>
    );
};

export default RequestComparisonChangesSection;