// Packages
import React from 'react';

// Utils
import { Cup } from '../../../../components'

const AddedCaterings = ({
  leg,
  airportType,
  handleSetCatering,
  handleCateringChange
}) => {
  const selectedCateringArray = airportType === 'from' ? leg?.requestlegcatering_requestLegCatering_from : leg?.requestlegcatering_requestLegCatering_to;
  return (
    <React.Fragment>
      <div className='w-full'>
        <h4 className='request-form-section-heading'> <Cup className='w-5 h-5 small-right-margin' /> <span>Catering</span>  </h4>
      </div>
      <div className='w-full'>
        <input
          type='checkbox'
          value={'catering'}
          id={`catering_leg_${leg?.id}`}
          checked={selectedCateringArray?.length}
          onChange={e => handleSetCatering(leg.id, e, airportType)}
        />
        <label htmlFor={`catering_leg_${leg?.id}`} className='px-1'> Book Catering </label>
      </div>
      {selectedCateringArray?.length
        ? <React.Fragment>
          <div>
            <div className='w3-col flex items-center wrap py-2'>
              <div className='w-full'>
                <label> Catering for Crew </label>
                <input
                  type='number'
                  min={1}
                  value={selectedCateringArray?.[0]?.crewcat}
                  onChange={e => handleCateringChange(leg.id, 'crewcat', e.target.value, airportType)}
                  className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
                />
              </div>
              <div className='w-full'>
                <div className='flex wrap items-center'>
                  <div>
                  <label> Catering for PAX </label>
                  <input
                    min={0}
                    type='number'
                    // disabled={true}
                    value={selectedCateringArray?.[0]?.paxcat}
                    onChange={e => handleCateringChange(leg.id, 'paxcat', e.target.value, airportType)}
                    className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
                  />
                  </div>
                  <div className='small-left-margin'>
                  <label> Catering Type </label>
                  <select
                    value={selectedCateringArray?.[0]?.type_of_catering}
                    className='w3-input w3-white w3-border w3-round small-top-margin small-bottom-margin'
                    onChange={e => handleCateringChange(leg.id, 'type_of_catering', e.target.value, airportType)}
                  >
                    <option value={'No Catering'}> No Catering </option>
                    <option value={'Regular'}> Regular </option>
                    <option value={'VIP'}> VIP </option>
                    <option value={'VVIP'}> VVIP </option>
                  </select>
                  </div>
                </div>
              </div>
              <div className='w-full'>
                <label> Comment </label>
                <textarea
                  value={selectedCateringArray?.[0]?.description || ''}
                  onChange={e => handleCateringChange(leg.id, 'description', e.target.value, airportType)}
                  className='w3-input w3-border w3-round small-top-margin small-bottom-margin resize-vertical-only'
                />
              </div>
            </div>
          </div>
        </React.Fragment>
        : null
      }
      <div className=''>
        <div className='w-full'>
          {/* <div className='h-2'></div> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddedCaterings;
