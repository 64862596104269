import React from 'react'

export default function HealthInsurance(props) {
	return (
		<svg
			{...props}
			viewBox="-5.3 0 53 53"
			xmlns="http://www.w3.org/2000/svg"
			fill="transparent"
		>
			<g id="SVGRepo_bgCarrier" strokeWidth={0} />
			<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
			<g id="SVGRepo_iconCarrier">
				{" "}
				<g
					id="Group_121"
					data-name="Group 121"
					transform="translate(-572.52 -201.007)"
				>
					{" "}
					<path
						id="Path_456"
						data-name="Path 456"
						d="M606.923,244.023a1.99,1.99,0,0,0-1.99,1.99v6.7l8.689-8.688Z"
						fill="transparent"
						stroke="currentcolor"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="3.657"
					/>{" "}
					<path
						id="Path_457"
						data-name="Path 457"
						d="M606.923,244.023h6.7v-39.73a1.99,1.99,0,0,0-1.99-1.99H575.807a1.99,1.99,0,0,0-1.991,1.99v46.428a1.99,1.99,0,0,0,1.991,1.99h29.126v-6.7A1.99,1.99,0,0,1,606.923,244.023Z"
						fill="transparent"
						stroke="currentcolor"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="3.657"
					/>{" "}
					<path
						id="Path_458"
						data-name="Path 458"
						d="M604.8,221.11h-7.324v-7.324H589.96v7.324h-7.324v7.519h7.324v7.325h7.519v-7.325H604.8Z"
						fill="transparent"
						stroke="currentcolor"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="3.657"
					/>{" "}
				</g>{" "}
			</g>
		</svg>
	)
}