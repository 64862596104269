import { constants } from "../utils";

const CostPlusInvoiceEditRequest = (token, data, id) => {
    var requestHeaders = new Headers();
    requestHeaders.append("Authorization", `Bearer ${token}`); 
    requestHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: 'PUT',
      headers: requestHeaders,
      body: data,
      redirect: 'follow'
    };

    return fetch(`${constants.BASEURL}/api/costplusinvoice/${id}/`, requestOptions);
};

export default CostPlusInvoiceEditRequest;