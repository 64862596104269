// Packages
import React from 'react';
import PropTypes from 'prop-types';

export default function FileUpload(props) {
  return (
    <div className={`file-upload-container ${props.containerClass}`}>
      {
        props?.isDownloadable && props?.file
          ? <div>
            <a
              target="_blank"
              className='text-primary-blue break-word'
              href={
                typeof (props?.file) !== 'string'
                  ? URL.createObjectURL(props?.file)
                  : props?.file
              }
            >
              {props?.fileName || 'No File'}
            </a>
          </div>
          : <div> {props?.fileName || 'No File'} </div>
      }
      <label className={`file-upload-text ${props.labelClass}`} htmlFor={props.id}>
        <span>{props.label}</span>
      </label>
      <input
        id={props.id}
        name={props.id}
        accept={props.accept}
        type='file'
        className='hidden'
        onChange={props.onChange}
      />
    </div>
  )
}

FileUpload.propTypes = {
  containerClass: PropTypes.string,
  labelClass: PropTypes.string,
  buttonClass: PropTypes.string
};

FileUpload.defaultProps = {
  containerClass: '',
  buttonClass: "",
  labelClass: ""
};
