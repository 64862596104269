// Packages
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { Store } from 'react-notifications-component';

// APIs
import { ServiceListRequest } from '../../../requests';

// Utils
import { constants, decodeString, authenticationErrorHandle } from '../../../utils';
import { auth, servicesCache } from '../../../atoms';
import { TableLite, Edit, Plus, Refresh } from '../../../components';

function ServicesList() {

  const [isLoading, _isLoading] = useState(false);
  const [authState, _authState] = useAtom(auth);
  const [services, _services] = useAtom(servicesCache);
  const Navigate = useNavigate();

  const Serviceheaders = ["id", "name", "serviceType", "serviceCategory", "price", "costPlusPrice", "profit"];
  const sortBy = ["id", "serviceType", "name"];

  const CustomHeaders = {
    "id": "Service Id",
    "serviceType": "Service Type",
    "serviceCategory": "Category",
    "costPlusPrice": "Cost Plus Price",
  };

  // Get services either from cache or from server
  useEffect(() => {
    if (authState) {
      if (!services || !services.created || Date.now() - services.created >= 1200000) {
        getServices();
      }
    }
  }, [authState]);

  const getServices = () => {
    _isLoading(true);
    const token = decodeString(authState);
    ServiceListRequest(token).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data && data.results) {
        // Keep server data in cache with current time
        _services({
          data: [...data.results],
          created: Date.now()
        });
        _isLoading(false);
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch services' });
        }
      )
  };

  // *********** Handlers ***********
  const routeToNewService = () => {
    Navigate('/services/new');
  };

  const routeToEditService = (id) => {
    Navigate(`/services/edit/${id}`);
  };

  // *********** Render Functions ***********

  const CUSTOM_EDIT_BTN = {
    "render":
      <button
        style={{ "color": "black" }}
        className={"custom-edit-fsr-btn small-left-margin"}
      >
        <span className="w3-tooltip">
          <span className='tooltip-text w3-text w3-tag w-12'>
            Edit
          </span>
          <Edit className='h-5 w-5' />
        </span>
      </button>
    ,
    "className": "custom-edit-fsr-btn"
  };

  const NEW_SERVICE_BUTTON = () => (
    <div className='flex w3-margin-bottom'>
      <div className=' ml-auto'>
        <div className='flex wrap'>
          <button className='flex items-center w3-btn bg-primary-blue w3-text-white small-right-margin' onClick={getServices}>
            <Refresh className='w-4 h-4' /> <span className='px-1'> Refresh </span>
          </button>
          <button className='flex items-center w3-btn bg-primary-blue w3-text-white' onClick={routeToNewService}>
            <Plus className='w-5 h-5' /> <span className='px-1'> New Service </span>
          </button>
        </div>
      </div>
    </div>
  );

  const CONTENT = () => (
    <div className='page-content w3-white h-full relative overflow-hidden'>
      <div className='py-2'>
        {NEW_SERVICE_BUTTON()}
        <div className='list-view-container overflow-auto'>
          <TableLite
            showActions={true}
            data={services && services?.data || []}
            headers={Serviceheaders}
            customHeaders={CustomHeaders}
            sortBy={sortBy}
            actionTypes={['edit']}
            renderEdit={CUSTOM_EDIT_BTN}
            onRowEdit={(event, row) => routeToEditService(row.id)}
            cellStyle={{ fontSize: '0.8em' }}
            noDataMessage={isLoading ? 'Loading data...' : 'No data found'}
          />
        </div>
      </div>
    </div>
  )
  return (
    <div>
      {CONTENT()}
    </div>
  )
}

export default ServicesList;