// Packages
import React from "react";

// Apis
import { ApprovedFsrLegDetailsRequest } from "../../../../requests";

// Utils
import { constants } from "../../../../utils";
import { Modal } from "../../../../components";


export default function CustomFsrFiltersModal({
    fsrFilters,
    showFilterModal,
    handleChangeFilters,
    handleApplyFilters,
    handleClearFilters
}) {

    return (
        <div className='fsr-filters-container'>
            <Modal isOpen={showFilterModal} modalClass="w-96 p-1 w3-round-medium">
                <div className="w-full p-2">
                    <h3> FSR Filters </h3>

                    <div className='w3-bar-item pt-1'>
                        <div className='bold'> FSR Legs </div>
                        <ul className='no-style-list w3-medium'>
                            {constants.AVAILABLE_FSR_FILTERS?.is_local?.options?.map((option, index) => (
                                <li
                                    key={index}
                                    className='capitalize flex items-center'
                                    onClick={e => handleChangeFilters('is_local', option)}
                                >
                                    {option ? 'Only Local Legs' : 'Local & International Leg'}
                                    <span className={`small-left-margin bold w3-text-green ${fsrFilters?.is_local?.selected === option ? '' : 'no-opacity'}`}> ✓ </span>                                    
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='w3-bar-item pt-1'>
                        <div className='bold'> Past Last Leg  </div>
                        <ul className='no-style-list w3-medium'>
                            {constants.AVAILABLE_FSR_FILTERS?.last_leg_past?.options?.map((option, index) => (
                                <li
                                    key={index}
                                    className='capitalize flex items-center'
                                    onClick={e => handleChangeFilters('last_leg_past', option)}
                                >
                                    Last leg with past date
                                    <span className={`small-left-margin bold w3-text-green ${fsrFilters?.last_leg_past?.selected === option ? '' : 'no-opacity'}`}> ✓ </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='w3-bar-item pt-1'>
                        <div className='bold'> Status </div>
                        <ul className='no-style-list w3-medium'>
                            {constants.AVAILABLE_FSR_FILTERS?.status?.options?.map((option, index) => (
                                <li
                                    key={index}
                                    className='capitalize flex items-center'
                                    onClick={e => handleChangeFilters('status', option)}
                                >
                                    {option}
                                    <span className={`small-left-margin bold w3-text-green ${fsrFilters?.status?.selected === option ? '' : 'no-opacity'}`}> ✓ </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='w3-bar-item'>
                        <div className="flex justify-end w-full small-top-margin">
                            <button onClick={handleClearFilters} className='w3-btn w3-small bg-primary-blue w3-text-white'> Clear Filters </button>
                            <button onClick={handleApplyFilters} className='small-left-margin w3-btn w3-small bg-primary-blue w3-text-white'> Apply </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
