// Packages
import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Store } from 'react-notifications-component';
import { useNavigate, useParams } from 'react-router-dom';

// APIs
import { ServiceListRequest, ServiceCreateRequest, ServiceDetailsRequest, ServiceEditRequest } from '../../../requests';

// Utils
import { auth, servicesCache, loggedUserInfoCache } from '../../../atoms';
import { constants, decodeString, authenticationErrorHandle, validateSubmissionData } from '../../../utils';
import { Loader } from '../../../components';

function ServicesForm() {
  const { serviceId } = useParams();
  const Navigate = useNavigate();

  const INITIAL_FORM = {
    name: '',
    serviceType: constants.GROUNDSERVICETYPE,
    serviceCategory: constants.HOTELSERVICETYPE,
    price: '0.0',
    costPlusPrice: '0.0',
    profit: '0.0',
    description: '',
    company: ''
  };

  const VALIDATIONS = {
    name: { isRequired: true },
    description: { isRequired: true },
  };

  const [isSubmitting, _isSubmitting] = useState(false);
  const [isLoading, _isLoading] = useState(serviceId ? true : false);
  const [form, _form] = useState({ ...INITIAL_FORM });
  const [formErrors, _formErrors] =  useState({});
  const [authState, _authState] = useAtom(auth);
  const [loggedUserInfo] = useAtom(loggedUserInfoCache);
  const [services, _services] = useAtom(servicesCache);


  useEffect(() => {
    if (serviceId && authState) {
      getServiceDetails();
    }
  }, [authState, serviceId]);

  // Set company from logged user Info for create case
  useEffect(() => {
    if (!serviceId && loggedUserInfo && loggedUserInfo?.data) {
      setCompany();
    }
  }, [loggedUserInfo?.data, form?.id]);

  const getServiceDetails = () => {
    const token = decodeString(authState);
    _isLoading(true);
    ServiceDetailsRequest(token, serviceId).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        _isLoading(false);
        _form(data);
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch service details' });
        }
      )
  };

  const getServices = () => {
    const token = decodeString(authState);
    ServiceListRequest(token).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data && data.results) {
        // Keep server data in cache with current time
        _services({
          data: [...data.results],
          created: Date.now()
        });
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch services' });
        }
      )
  };

  const setCompany = () => {
    _form(old => ({
      ...old,
      company: loggedUserInfo?.data?.company
    }))
  };

  // *********** Handlers ***********

  const handleServiceFieldChange = (name, value) => {
    _form(old => ({
      ...old,
      [name]: value
    }));
  };

  const handleCheckValidation = () => {
    const { allValid, errors } = validateSubmissionData(form, VALIDATIONS);
    if (!allValid) {
      _formErrors(errors);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Required fields have errors" });
    } else {
      _formErrors({});
    }
    return allValid;
  };

  const handleSubmit = () => {
    const token = decodeString(authState);
    const data = JSON.parse(JSON.stringify(form));
    const allValid = handleCheckValidation();
    if (!allValid) return;
    _isSubmitting(true);
    if (serviceId) {
      ServiceEditRequest(token, JSON.stringify(data), serviceId)
        .then(res => {
          if (res && res?.status === 401) {
            authenticationErrorHandle(() => _authState('0'));
            return (
              { errorCodes: constants.SESSIONTIMEOUT }
            );
          } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
            throw 'Request Failed';
          } else return (res.json())
        })
        .then(data => {
          if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
          if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
            throw 'Request Failed';
          } else {
            getServices();
            _isSubmitting(false);
            Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Service updated' });
            Navigate("/services");
          }
        })
        .catch(err => {
          _isSubmitting(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
        });
    } else {
      ServiceCreateRequest(token, JSON.stringify(data))
        .then(res => {
          if (res && res?.status === 401) {
            authenticationErrorHandle(() => _authState('0'));
            return (
              { errorCodes: constants.SESSIONTIMEOUT }
            );
          } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
            throw 'Request Failed';
          } else return (res.json())
        })
        .then(data => {
          if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
          if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
            throw 'Request Failed';
          } else {
            getServices();
            _isSubmitting(false);
            Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Service created' });
            Navigate("/services");
          }
        })
        .catch(err => {
          _isSubmitting(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
        });
    }
  };

  // *********** Render Functions ***********

  const SERVICE_DETAILS = () => (
    <React.Fragment>
      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <label> Name </label>
          <input
            type='text'
            value={form?.name}
            onChange={e => handleServiceFieldChange('name', e.target.value)}
            className={`w3-input w3-border w3-round small-top-margin small-bottom-margin ${formErrors?.name ? 'error-field' : ''}`}            
          />
        </div>
        <div className='w3-col l6 m6 s12'>
          <label> Service Type </label>
          <select
            value={form?.serviceType}
            onChange={e => handleServiceFieldChange('serviceType', e.target.value)}
            className='w3-white w3-input w3-border w3-round small-top-margin small-bottom-margin'
          >
            <option value={constants.GROUNDSERVICETYPE}> Ground Handling </option>
            <option value={constants.OPSSERVICETYPE}> Operations </option>
            <option value={constants.FLEETSERVICETYPE}>Fleet Service</option>
          </select>
        </div>
      </div>

      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <label> Service Category </label>
          <select
            value={form?.serviceCategory}
            onChange={e => handleServiceFieldChange('serviceCategory', e.target.value)}
            className='w3-white w3-input w3-border w3-round small-top-margin small-bottom-margin'
          >
            <option value={constants.HOTELSERVICETYPE}> Hotel </option>
            <option value={constants.CATERINGSERVICETYPE}> Catering </option>
            <option value={constants.CARRENTALSERVICETYPE}> Car Rental </option>
            <option value={constants.GROUNDSERVICETYPE}> Ground Handling </option>
            <option value={constants.OPSSERVICETYPE}> Operations </option>
            <option value={constants.FLEETSERVICETYPE}>Fleet Service</option>
          </select>
        </div>
        <div className='w3-col l6 m6 s12'>
          <label> Price </label>
          <input
            type='number'
            min={1.00}
            step={0.01}
            value={Number(form?.price)?.toFixed(2) || 0}
            onBlur={e => handleServiceFieldChange('price', parseFloat(e.target.value)?.toFixed(2))}
            onChange={e => handleServiceFieldChange('price', e.target.value)}
            className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
          />
        </div>
      </div>

      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <label> Cost Plus Price </label>
          <input
            type='number'
            min={1.00}
            step={0.01}
            value={Number(form?.costPlusPrice)?.toFixed(2) || 0}
            onBlur={e => handleServiceFieldChange('costPlusPrice', parseFloat(e.target.value)?.toFixed(2))}
            onChange={e => handleServiceFieldChange('costPlusPrice', e.target.value)}
            className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
          />
        </div>
        <div className='w3-col l6 m6 s12'>
          <label> Profit </label>
          <input
            type='number'
            min={1.00}
            step={0.01}
            value={Number(form?.profit)?.toFixed(2) || 0}
            onBlur={e => handleServiceFieldChange('profit', parseFloat(e.target.value)?.toFixed(2))}
            onChange={e => handleServiceFieldChange('profit', e.target.value)}
            className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
          />
        </div>
      </div>

      <div className='w3-row-padding'>
        <div className='w3-col l12 m12 s12'>
          <label> Description </label>
          <input
            type='text'
            value={form?.description}
            onChange={e => handleServiceFieldChange('description', e.target.value)}
            className={`w3-input w3-border w3-round small-top-margin small-bottom-margin ${formErrors?.description ? 'error-field' : ''}`}            
          />
        </div>
      </div>

    </React.Fragment>
  );

  const LOADER = () => (
    <div className="request-form-container">
      <div className="h-30 flex justify-center items-center">
        <div><Loader spinnerClassName='w-10 h-10 text-primary-blue' />
          <p className='text-primary-blue'> Loading data... </p>
        </div>
      </div>
    </div>
  );

  const FORM_FOOTER = () => (
    <React.Fragment>
      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <button
            disabled={isSubmitting}
            onClick={handleSubmit}
            className='w3-btn bg-primary-blue w3-text-white small-top-margin'
          >
            {isSubmitting ? 'Submitting' : 'Submit'}
          </button>
        </div>
      </div>
      <div className='h-2'></div>
    </React.Fragment>
  );

  const SERVICES_FORM = () => (
    <div className='request-form-container w-full relative'>
      <span className='heading'> Service </span>
      <div className='border-primary-blue w3-round p-2'>
        {SERVICE_DETAILS()}
        {FORM_FOOTER()}
      </div>
    </div>
  )
  return (
    <div id='Services-Form'>
      {isLoading
        ? LOADER()
        : SERVICES_FORM()
      }
    </div>
  )
}

export default ServicesForm;