import { constants } from "../utils";

const CrewMemberEditRequest = (token, data, id) => {
    var requestHeaders = new Headers();
    requestHeaders.append("Authorization", `Bearer ${token}`); 
    var requestOptions = {
      method: 'PUT',
      headers: requestHeaders,
      body: data,
      redirect: 'follow'
    };

    return fetch(`${constants.BASEURL}/api/crewmember/${id}/`, requestOptions);
};

export default CrewMemberEditRequest;
