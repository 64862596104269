import React from 'react';
import PropTypes from 'prop-types';

export default function User(props) {
  const image = props.image || '/assets/images/user.png';
  return (
    <div className={`${props.containerClass}`} onClick={props.onClick}>
         <img className={`${props.imageClass} w3-circle`} src={image} alt="user"/> 
    </div>
  )
}

User.propTypes = {
  image: PropTypes.string,
  containerClass: PropTypes.string,
  imageClass: PropTypes.string,
  onClick: PropTypes.func,
};

User.defaultProps = {
    onClick: () => null
}
