// Packages
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { Store } from 'react-notifications-component';

// APIs
import { AirbaseListRequest, FleetListRequest } from '../../../requests';

// Utils
import { constants, decodeString, authenticationErrorHandle } from '../../../utils';
import { airbasesCache, auth, fleetCache } from '../../../atoms';
import { TableLite, Edit, Plus, Refresh } from '../../../components';

function FleetList() {
  const [isLoading, _isLoading] = useState(false);
  const [authState, _authState] = useAtom(auth);
  const [fleet, _fleet] = useAtom(fleetCache);
  const [airbases, _airbases] = useAtom(airbasesCache);
  const [listData, _listData] = useState([]);
  const Navigate = useNavigate();

  const Fleetheaders = ['id', 'name', 'airbase'];
  const sortBy = ["id", "name", "airbase"];
  const CustomHeaders = {
    id: 'Fleet Id'
  };

  // Get fleet & airbases either from cache or from server
  useEffect(() => {
    if (authState) {
      if (!fleet || !fleet.created || Date.now() - fleet.created >= 1200000) {
        getFleet();
      }
      if (!airbases || !airbases.created || Date.now() - airbases.created >= 1200000) {
        getAirbases();
      }
    }
  }, [authState]);

  useEffect(() => {
    if (airbases?.data && fleet?.data) {
      const data = fleet?.data.map(F => ({
        ...F,
        airbase: airbases?.data?.find(Ab => Ab.id == F.airbase)?.name
      }))      
      _listData(data);
    }
  }, [airbases?.data, fleet?.data]);

  useEffect(() => {
    if (airbases?.data && fleet?.data) {
      const data = fleet?.data.map(F => ({
        ...F,
        airbase: airbases?.data?.find(Ab => Ab.id == F.airbase)?.name
      }))
      _listData(data);
    }
  }, []);

  const getFleet = () => {
    const token = decodeString(authState);
    _isLoading(true);
    FleetListRequest(token)
      .then((res) => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else return res.json();
      })
      .then((data) => {
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if (data && data.results) {
          // Keep server data in cache with current time
          _fleet({
            data: [...data.results],
            created: Date.now(),
          });
          _isLoading(false);
        } else {
          throw 'Request Failed';
        }
      })
      .catch((err) => {
        _isLoading(false);
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch fleet' });
      });
  };

  const getAirbases = () => {
    const token = decodeString(authState);
    AirbaseListRequest(token).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(nonPaginatedData => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in cache with current time
        _airbases({
          data: [...nonPaginatedData],
          created: Date.now()
        });
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch airbases' });
        }
      )
  };

  // *********** Handlers ***********
  const routeToNewFleet = () => {
    Navigate('/fleets/new');
  };

  const routeToEditFleet = (id) => {
    Navigate(`/fleets/edit/${id}`);
  };

  // *********** Render Functions ***********

  const CUSTOM_EDIT_BTN = {
    "render":
      <button
        style={{ "color": "black" }}
        className={"custom-edit-fsr-btn small-left-margin"}
      >
        <span className="w3-tooltip">
          <span className='tooltip-text w3-text w3-tag w-12'>
            Edit
          </span>
          <Edit className='h-5 w-5' />
        </span>
      </button>
    ,
    "className": "custom-edit-fsr-btn"
  };

  const NEW_FLEET_BUTTON = () => (
    <div className="flex w3-margin-bottom">
      <div className="ml-auto">
        <div className="flex wrap">
          <button className="flex items-center w3-btn bg-primary-blue w3-text-white small-right-margin" onClick={getFleet}>
            <Refresh className="w-4 h-4" /> <span className="px-1"> Refresh </span>
          </button>
          <button className="flex items-center w3-btn bg-primary-blue w3-text-white" onClick={routeToNewFleet}>
            <Plus className="w-5 h-5" /> <span className="px-1"> New Fleet </span>
          </button>
        </div>
      </div>
    </div>
  );

  const CONTENT = () => (
    <div className='page-content w3-white h-full relative overflow-hidden'>
      <div className='py-2'>
        {NEW_FLEET_BUTTON()}
        <div className='list-view-container overflow-auto'>
          <TableLite
            showActions={true}
            data={listData}
            headers={Fleetheaders}
            customHeaders={CustomHeaders}
            sortBy={sortBy}
            actionTypes={['edit']}
            renderEdit={CUSTOM_EDIT_BTN}
            onRowEdit={(event, row) => routeToEditFleet(row.id)}
            cellStyle={{ fontSize: '0.8em' }}
            noDataMessage={isLoading ? 'Loading data...' : 'No data found'}
          />
        </div>
      </div>
    </div>
  )
  return (
    <div>
      {CONTENT()}
    </div>
  )
}

export default FleetList;