import React from 'react';

export default function Clock(props) {
  return (
    <svg
      {...props}
      fill="currentColor"
      height={32}
      width={32}
      viewBox="0 0 512 512"
    >
      <g id="Icon_1_">
        <g>
          <g>
            <g>
              <g>
                <path
                  fillOpacity="0.9"
                  d="M256,43C137.789,43,43,138.851,43,256s94.789,213,213,213s213-95.851,213-213S373.149,43,256,43z
				 M256,426.4c-93.718,0-170.4-76.683-170.4-170.4S162.282,85.6,256,85.6S426.4,162.282,426.4,256S349.718,426.4,256,426.4z"
                >
                  <path
                    fillOpacity="0.9"
                    d="M256,43C137.789,43,43,138.851,43,256s94.789,213,213,213s213-95.851,213-213S373.149,43,256,43z
				 M256,426.4c-93.718,0-170.4-76.683-170.4-170.4S162.282,85.6,256,85.6S426.4,162.282,426.4,256S349.718,426.4,256,426.4z"
                  />
                </path>
              </g>
            </g>
            <polygon
              fillOpacity="0.9"
              points="266.65,149.5 234.7,149.5 234.7,277.3 346.525,344.393 362.5,317.768 266.65,261.324 		"
            >
              <polygon
                fillOpacity="0.9"
                points="266.65,149.5 234.7,149.5 234.7,277.3 346.525,344.393 362.5,317.768 266.65,261.324 		"
              />
            </polygon>
          </g>
        </g>
      </g>
    </svg>
  );
};	
