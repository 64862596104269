import { constants } from "../utils";

const GetFsrPdfRequest = (token, data, id) => {
    var requestHeaders = new Headers();
    requestHeaders.append("Authorization", `Bearer ${token}`);
    requestHeaders.append("Content-Type", "application/json");
    return (fetch(`${constants.BASEURL}/api/request/get_request_pdf/${id}/`, {
        method: "POST",
        body: data,
        headers: requestHeaders,
        redirect: "follow"
    }));
};

export default GetFsrPdfRequest;