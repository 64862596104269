// Packages
import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import dayjs from "dayjs";
import { Store } from "react-notifications-component";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';

// APIs 
import { FsrSearchRequest, CrewMemberByFleetListRequest, HotelRequestEditRequest, FleetByAirbaseListRequest, AircraftByFleetListRequest, AirbaseListRequest, HotelRequestCreateRequest, HotelRequestDetailsRequest, FsrDetailsRequest } from '../../../requests';

// Utils
import { auth, airbasesCache, loggedUserInfoCache } from '../../../atoms';
import { constants, decodeString, authenticationErrorHandle, validateSubmissionData } from '../../../utils';
import { Loader } from "../../../components";
import { CreateCrewMemberModal } from './components';


function HotelRequestForm() {

  const INPUT_FIELD_CLASS = `w3-input w3-white w3-border w3-round small-top-margin small-bottom-margin`;
  const HOTEL_PURPOSES = [
    { value: "O- Operation", label: "O- Operation" },
    { value: "F- FerryALS", label: "F- FerryALS" },
    { value: "ALS- Air Logistic Sortie", label: "ALS- Air Logistic Sortie" },
    { value: "T- Training", label: "T- Training" },
    { value: "TST - Mission Test", label: "TST - Mission Test" },
    { value: "Tac - Tactical", label: "Tac - Tactical" },
    { value: "ML- Mail", label: "ML- Mail" },
    { value: "STB- STBYSW", label: "STB- STBYSW" },
    { value: "SW- AIR SHOW", label: "SW- AIR SHOW" },
    { value: "SP - SPECIAL", label: "SP - SPECIAL" },
    { value: "SIM - Simulator", label: "SIM - Simulator" },
    { value: "EX- Exercise", label: "EX- Exercise" },
    { value: "EU - Mail Europe", label: "EU - Mail Europe" },
    { value: "US - Mail USA", label: "US - Mail USA" },
    { value: "MCF - Maintenance Flight Check", label: "MCF - Maintenance Flight Check" },
    { value: "FCF - Flight Check Function", label: "FCF - Flight Check Function" },
    { value: "OCF - Operation Check Flight", label: "OCF - Operation Check Flight" },
    { value: "PPS- Pilot Pro", label: "PPS- Pilot Pro" },
    { value: "PAX- Passenger Mobility", label: "PAX- Passenger Mobility" },    
  ]
  const INITIAL_FORM = {
    data: {
      airbase: '',
      fleet: '',
      aircraft: '',
      ACFT_REG: '',
      no_of_rooms: '',
      country: '',
      city: '',
      checkout_date: dayjs()?.format('YYYY-MM-DDTHH:mm'),
      checkin_date: dayjs()?.format('YYYY-MM-DDTHH:mm'),
      purpose: 'O- Operation',
      send_by: '',
      crew_members: [],
      temp_checkin_date: dayjs()?.format('YYYY-MM-DD'),
      temp_checkout_date: dayjs()?.format('YYYY-MM-DD'),
      temp_checkin_time: "00:00",
      temp_checkout_time: "00:00",
    },
    validations: {
      fleet: { isRequired: true, isNumber: true },
      aircraft: { isRequired: true, isNumber: true },
      airbase: { isRequired: true, isNumber: true },
      country: { isRequired: true },
      city: { isRequired: true },
      send_by: { isRequired: true },
      purpose: { isRequired: true },
      crew_members: { isRequired: true, isArray: true, min: 1 },
    },
    errors: {}
  };
  const { hotelRequestId } = useParams();
  const Navigate = useNavigate();

  const [form, _form] = useState({ ...INITIAL_FORM });
  const [isLoading, _isLoading] = useState(false);
  const [isSubmitting, _isSubmitting] = useState(false);
  const [showAddCrewMemberModal, _showAddCrewMemberModal] = useState(false);
  const [searchFSRString, _searchFSRString] = useState('');
  const [authState, _authState] = useAtom(auth);
  const [airbases, _airbases] = useAtom(airbasesCache);
  const [loggedUserInfo, _loggedUserInfo] = useAtom(loggedUserInfoCache);
  const [fleet, _fleet] = useState([]);
  const [aircrafts, _aircrafts] = useState([]);
  const [crewMembers, _crewMembers] = useState([]);
  const [fsrList, _fsrList] = useState([]);

  // Debounce fsr request seacrch
  useEffect(() => {
    const getFSRData = setTimeout(() => {
      if (searchFSRString && searchFSRString.trim() !== '') {
        getFsrList();
      }
    }, 800);
    return () => clearTimeout(getFSRData);
  }, [searchFSRString]);

  // Get airbases and menu items either from cache or from server
  useEffect(() => {
    if (authState) {
      if (!airbases || !airbases.created || Date.now() - airbases.created >= 1200000) {
        getAirbases();
      }
      if (hotelRequestId) {
        getHotelRequestDetails();
      }
    }
  }, [authState, hotelRequestId]);

  // Auto set fleet and airbase in edit case
  useEffect(() => {
    if (authState && loggedUserInfo && loggedUserInfo?.data) {
      setFleetAndAirbase();
    }
  }, [authState, airbases, loggedUserInfo?.data])

  // Get request details on request select 
  useEffect(() => {
    if (authState && form?.data?.request_id && !searchFSRString) {
      getFsrDetails();
    }
  }, [authState, form?.data?.request_id]);

  // Get fleet list On change of airbase 
  useEffect(() => {
    if (authState && form?.data?.airbase) {
      getFleetByAirbase();
    }
  }, [authState, form?.data?.airbase]);

  // Get crewmembers, and aircrafts list On change of fleet 
  useEffect(() => {
    if (authState && form?.data?.fleet) {
      getAircraftsByFleet();
      getCrewMembersByFleet();
    }
  }, [authState, form?.data?.fleet]);

  // Set Reg number of aircraft on aircraft change
  useEffect(() => {
    if (form?.data?.aircraft) {
      _form(old => ({
        ...old,
        data: {
          ...old?.data
        }
      }))
    }
  }, [authState, form?.data?.airbase]);

  const setRequestData = (type) => {
    const data = JSON.parse(JSON.stringify(form?.data));
    if (type === 'edit') {
      data.crew_members = data?.crew_members?.map(crewObj => crewObj?.crew_member);
      data.ACFT_REG = aircrafts?.data?.find(A => A?.id == form?.data?.aircraft)?.tailnumber;
      // data.crew_members = data?.crew_members?.map(crewObj => ({
      //   ...crewObj,
      //   hotel_request: hotelRequestId
      // }));
      delete data?.approve_hotel_request;
      delete data?.approve_hotel_requests;
      delete data?.hotel_request_crew_member;
      delete data?.updatedat;
      delete data?.createdat;
      return data;
    }
    else {
      data.crew_members = data?.crew_members?.map(crewObj => crewObj?.crew_member);
      data.ACFT_REG = aircrafts?.data?.find(A => A?.id == form?.data?.aircraft)?.tailnumber;
      delete data?.approve_hotel_request;
      delete data?.approve_hotel_requests;
      delete data?.hotel_request_crew_member;
      return data;
    }
  };

  const setFleetAndAirbase = () => {
    // Set pre-selected fleet and airbase according to loggedIn user's info
    if (loggedUserInfo?.data?.fleet && loggedUserInfo?.data?.fleet?.id) {
      _form(old => ({
        ...old,
        data: {
          ...old?.data,
          fleet: loggedUserInfo?.data?.fleet?.id || '',
          airbase: loggedUserInfo?.data?.fleet?.airbase || '',
          sendby: loggedUserInfo?.data?.username || '',
        }
      }));
    }
  };

  const getHotelRequestDetails = () => {
    _isLoading(true);
    const token = decodeString(authState);
    HotelRequestDetailsRequest(token, hotelRequestId).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        _isLoading(false);
        _form(old => (
          {
            ...old,
            data: {
              ...data,
              temp_checkin_date: constants.DATE_FORMAT_REGEX.exec(data?.checkin_date)?.[0] || '',
              temp_checkout_date: constants.DATE_FORMAT_REGEX.exec(data?.checkout_date)?.[0] || '',
              temp_checkin_time: constants.TIME_FORMAT_REGEX.exec(data?.checkin_date)?.[0]?.replace('T', '') || '',
              temp_checkout_time: constants.TIME_FORMAT_REGEX.exec(data?.checkout_date)?.[0]?.replace('T', '') || ''
            }
          }
        ));
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch hotel reservation details' });
        }
      )
  };

  const getFsrList = () => {
    const token = decodeString(authState);
    FsrSearchRequest(token, searchFSRString).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        _fsrList({
          data
        });
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch FSR list' });
        }
      )
  };

  const getAirbases = () => {
    const token = decodeString(authState);
    AirbaseListRequest(token).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((nonPaginatedData) => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in cache with current time
        _airbases({
          data: [...nonPaginatedData],
          created: Date.now(),
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch airbases" });
    });
  };

  const getFleetByAirbase = () => {
    const token = decodeString(authState);
    FleetByAirbaseListRequest(token, form?.data?.airbase).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((nonPaginatedData) => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in local state
        _fleet({
          data: [...nonPaginatedData],
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch fleet" });
    });
  };

  const getAircraftsByFleet = () => {
    const token = decodeString(authState);
    AircraftByFleetListRequest(token, form?.data?.fleet).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((nonPaginatedData) => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in local state
        _aircrafts({
          data: [...nonPaginatedData]
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch aircrafts" });
    });
  };

  const getCrewMembersByFleet = () => {
    const token = decodeString(authState);
    CrewMemberByFleetListRequest(token, form?.data?.fleet).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((nonPaginatedData) => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in local state
        _crewMembers({
          data: [...nonPaginatedData]
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch crew members" });
    });
  };

  const getFsrDetails = () => {
    const token = decodeString(authState);
    FsrDetailsRequest(token, form?.data?.request_id).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((data) => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        _fsrList({ data: [data] });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch request details" });
    });
  };

  // *********** Handlers ***********

  const handleAirbaseChange = (name, value) => {
    _form(old => ({
      ...old,
      data: {
        ...old?.data,
        [name]: value,
        crew_members: [],
        fleet: ''
      }
    }));
  };

  const handleFleetChange = (name, value) => {
    _form(old => ({
      ...old,
      data: {
        ...old?.data,
        [name]: value,
        aircraft: '',
        crew_members: []
      }
    }));
  };

  const handleFormatDateTime = (name, value, dataFor, type) => {
    const originalDateString = form?.data?.[dataFor];    
    const defaultDate = '2001-01-01';
    const defaultTime = '00:00';
    let parsedDateTimeString = "";    
    if (type === 'date') {
      try {
        const timepart = constants?.TIME_FORMAT_REGEX.exec(originalDateString)?.[0]?.replace('T','') || defaultTime;
        value = dayjs(value)?.format('YYYY-MM-DD') || defaultDate;
        parsedDateTimeString = `${value}T${timepart}:00Z`;
      } catch (err) {
        console.error(err);
        value = defaultDate;
        parsedDateTimeString = defaultDate + "T" + defaultTime + ":00Z";
      }
    } else if (type === 'time') {
      try {
        const datepart = constants?.DATE_FORMAT_REGEX.exec(originalDateString)?.[0] || defaultDate;
        value = dayjs(`${defaultDate}T${value}`)?.format('HH:mm') || defaultDate;
        parsedDateTimeString = `${datepart}T${value}:00Z`;
      } catch (err) {
        console.error(err);
        value = defaultTime;
        parsedDateTimeString = defaultDate + "T" + defaultTime + ":00Z";
      }
    }
    _form(old => ({
      ...old,
      data: {
        ...old?.data,
        [name]: value,
        [dataFor]: parsedDateTimeString
      }
    }));
  };

  const handleHotelRequestFieldChange = (name, value) => {
    _form(old => ({
      ...old,
      data: {
        ...old?.data,
        [name]: value
      }
    }));
  };


  const handleOpenAddCrewMemberModal = () => {
    _showAddCrewMemberModal(true);
  };

  const handleCloseAddCrewMemberModal = () => {
    _showAddCrewMemberModal(false);
  };

  const handleAddCrewMemberSave = (crewMemberObj) => {
    if (crewMemberObj) {
      _form(old => ({
        ...old,
        data: {
          ...old?.data,
          no_of_rooms: parseInt(old?.data?.no_of_rooms) + 1,
          crew_members: [...old?.data?.crew_members, { crew_member: crewMemberObj?.id }]
        }
      }))
    }
    handleCloseAddCrewMemberModal();
    getCrewMembersByFleet();
  };

  const handleChangeCrewMember = (crew) => {
    let crew_members = [];
    crew_members = crew?.map(C => ({ crew_member: C.value }));
    _form(old => ({
      ...old,
      data: {
        ...old?.data,
        crew_members,
        no_of_rooms: crew_members?.length || 0
      }
    }));
  };

  const handleSearchString = (search) => {
    _searchFSRString(search);
  };

  const handleSelectFsr = (fsr) => {
    _form(old => ({
      ...old,
      data: {
        ...old.data,
        request_id: fsr?.id,
        fleet: fsr?.fleet,
        airbase: fsr?.airbase,
        aircraft: fsr?.aircraft,
        crew_members: []
      }
    }));
  };

  const handleCheckValidation = () => {
    const { allValid, errors } = validateSubmissionData(form.data, form.validations);
    if (!allValid) {
      _form(old => ({
        ...old,
        errors
      }));
      Store.addNotification({ ...constants.ERRORTOAST, message: "Required fields have errors" });
    } else {
      _form(old => ({
        ...old,
        errors: {}
      }));
    }
    return allValid;
  };

  const handleSubmit = (e) => {
    const token = decodeString(authState);
    const allValid = handleCheckValidation();
    if (!allValid) return;
    _isSubmitting(true);
    if (hotelRequestId) {
      const data = setRequestData('edit');
      HotelRequestEditRequest(token, JSON.stringify(data), hotelRequestId)
        .then(res => {
          if (res && res?.status === 401) {
            authenticationErrorHandle(() => _authState('0'));
            return (
              { errorCodes: constants.SESSIONTIMEOUT }
            );
          } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
            throw 'Request Failed';
          } else return (res.json())
        })
        .then(data => {
          if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
          if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
            throw 'Request Failed';
          } else {
            _isSubmitting(false);
            Store.addNotification({ ...constants.SUCCESSTOAST, message: 'hotel reservation updated' });
            Navigate("/hotel-reservations");
          }
        })
        .catch(err => {
          _isSubmitting(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
        });
    }
    else {
      const data = setRequestData('create');
      HotelRequestCreateRequest(token, JSON.stringify(data))
        .then(res => {
          if (res && res?.status === 401) {
            authenticationErrorHandle(() => _authState('0'));
            return (
              { errorCodes: constants.SESSIONTIMEOUT }
            );
          } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
            throw 'Request Failed';
          } else return (res.json())
        })
        .then(data => {
          if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
          if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
            throw 'Request Failed';
          } else {
            _isSubmitting(false);
            Store.addNotification({ ...constants.SUCCESSTOAST, message: 'hotel reservation created' });
            Navigate("/hotel-reservations");
          }
        })
        .catch(err => {
          _isSubmitting(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
        });
    }
  };

  // *********** Render Functions ***********

  const HOTEL_RESERVATION_INFO = () => (
    <React.Fragment>
      <div className='w3-row-padding'>
        <div className="w3-col l6 m6 s12">
          <label> Request (Optional)</label>
          <Select            
            placeholder='Search request by id or flight no.'
            onInputChange={handleSearchString}
            onChange={obj => handleSelectFsr(obj.value)}
            value={form?.data?.request_id
              ? {
                label: fsrList?.data?.find(fsr => fsr?.id == form?.data?.request_id)?.flight_number,
                value: form?.data?.request_id
              }
              : null}
            options={fsrList && fsrList?.data?.map(Fsr => ({
              label: `${Fsr?.flight_number} (FSR-${Fsr?.id})`,
              value: { id: Fsr?.id, fleet: Fsr?.fleet, airbase: Fsr?.airbase, aircraft: Fsr?.aircraft }
            }))}
            className={`${form?.errors?.request_id ? 'error-field' : ''} w-full small-top-margin small-bottom-margin`}
          />
        </div>
      </div>

      <div className='w3-row-padding'>
        <div className="w3-col l6 m6 s12">
          <label> Airbase </label>
          <select
            value={form?.data?.airbase}
            disabled={!loggedUserInfo?.data?.is_staff && loggedUserInfo?.data?.fleet?.id}
            onChange={(e) => handleAirbaseChange("airbase", e.target.value)}
            className={`${INPUT_FIELD_CLASS} ${form?.errors?.airbase ? 'error-field' : ''}`}
          >
            <option value={""} disabled > Select Airbase </option>
            {airbases &&
              airbases?.data &&
              airbases?.data?.map((A, index) => (
                <option key={index} value={A.id}> {A.name} </option>
              ))}
          </select>
        </div>
        <div className="w3-col l6 m6 s12">
          <label> Fleet </label>
          <select
            value={form?.data?.fleet}
            disabled={!loggedUserInfo?.data?.is_staff && loggedUserInfo?.data?.fleet?.id}
            onChange={(e) => handleFleetChange("fleet", e.target.value)}
            className={`${INPUT_FIELD_CLASS} ${form?.errors?.fleet ? 'error-field' : ''}`}
          >
            <option value={""} disabled> Select Fleet </option>
            {fleet &&
              fleet?.data &&
              fleet?.data
                ?.filter((F) => F.airbase == form?.data?.airbase)
                ?.map((F, index) => (
                  <option value={F.id} key={index}> {F.name} </option>
                ))}
          </select>
        </div>
      </div>

      <div className='w3-row-padding'>
        <div className="w3-col l6 m6 s12">
          <label> Aircraft </label>
          <Select
            placeholder="Select aircraft"
            value={
              aircrafts &&
              aircrafts?.data
                ?.filter((A) => A.fleet == form?.data?.fleet && A.id == form?.data?.aircraft)
                .map((A) => ({ label: A.tailnumber, value: A.id }))
            }
            options={
              fleet &&
              aircrafts &&
              fleet?.data &&
              aircrafts?.data &&
              aircrafts?.data
                ?.filter((A) => A.fleet == form?.data?.fleet)
                .map((A) => ({ label: A.tailnumber, value: A.id }))
            }
            onChange={(option) => {
              handleHotelRequestFieldChange("aircraft", option.value)
            }}
            className={`${form?.errors?.aircraft ? 'error-field' : ''} w-full small-top-margin small-bottom-margin`}
          />
        </div>
        <div className='w3-col l6 m6 s12'>
          <label>No. of Rooms</label>
          <input
            type='text'
            disabled={true}
            onChange={e => null}
            value={form?.data?.no_of_rooms}
            // onChange={e => handleHotelRequestFieldChange("no_of_rooms", e.target.value)}
            className={`${INPUT_FIELD_CLASS} ${form?.errors?.no_of_rooms ? 'error-field' : 'cursor-disable'}`}
          />
        </div>
      </div>

      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <label> Country </label>
          <Select
            placeholder='Select Country'
            options={constants.COUNTRYARRAY?.map(C => (
              { value: C.country, label: C.country })
            )}
            value={form?.data?.country ? {
              value: form?.data?.country,
              label: form?.data?.country
            } : null}
            onChange={C => handleHotelRequestFieldChange("country", C.value)}
            className={`${form?.errors?.country ? 'error-field' : ''} w-full small-top-margin small-bottom-margin`}
          />
        </div>
        <div className='w3-col l6 m6 s12'>
          <label> City </label>
          <input
            type='text'
            value={form?.data?.city}
            onChange={e => handleHotelRequestFieldChange("city", e.target.value)}
            className={`${INPUT_FIELD_CLASS} ${form?.errors?.city ? 'error-field' : ''}`}
          />
        </div>
      </div>

      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <label> Checkin Date </label>
          <input
            type='date'
            value={form?.data?.temp_checkin_date}
            onChange={e => handleHotelRequestFieldChange("temp_checkin_date", e.target.value)}
            onBlur={e => handleFormatDateTime("temp_checkin_date", e.target.value, "checkin_date", 'date')}
            className={`${INPUT_FIELD_CLASS} ${form?.errors?.checkin_date ? 'error-field' : ''}`}
          />
        </div>

        <div className='w3-col l6 m6 s12'>
          <label> Checkin Time </label>
          <input
            placeholder="00:00"
            value={form?.data?.temp_checkin_time}
            onChange={e => handleHotelRequestFieldChange("temp_checkin_time", e.target.value)}
            onBlur={e => handleFormatDateTime("temp_checkin_time", e.target.value, "checkin_date", 'time')}
            className={`${INPUT_FIELD_CLASS} ${form?.errors?.checkin_date ? 'error-field' : ''}`}
          />
        </div>

        <div className='w3-col l6 m6 s12'>
          <label> Checkout Date </label>
          <input
            type='date'
            value={form?.data?.temp_checkout_date}
            min={dayjs(form?.data?.checkin_date).format('YYYY-MM-DD')}
            onChange={e => handleHotelRequestFieldChange("temp_checkout_date", e.target.value)}
            onBlur={e => handleFormatDateTime("temp_checkout_date", e.target.value, "checkout_date", 'date')}
            className={`${INPUT_FIELD_CLASS} ${form?.errors?.checkout_date ? 'error-field' : ''}`}
          />
        </div>

        <div className='w3-col l6 m6 s12'>
          <label> Checkout Time </label>
          <input
            placeholder="00:00"
            value={form?.data?.temp_checkout_time}
            onChange={e => handleHotelRequestFieldChange("temp_checkout_time", e.target.value)}
            onBlur={e => handleFormatDateTime("temp_checkout_time", e.target.value, "checkout_date", 'time')}
            className={`${INPUT_FIELD_CLASS} ${form?.errors?.checkout_date ? 'error-field' : ''}`}
          />
        </div>
      </div>

      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <label> Purpose </label>
          <Select
            options={[...HOTEL_PURPOSES]}
            value={{ label: form?.data?.purpose, value: form?.data?.purpose }}
            onChange={item => handleHotelRequestFieldChange("purpose", item?.value)}
            className={`small-top-margin small-bottom-margin ${form?.errors?.purpose ? 'error-field' : ''}`}
          />
        </div>
        <div className='w3-col l6 m6 s12'>
          <label> Send By  </label>
          <input
            type='text'
            value={form?.data?.send_by}
            onChange={e => handleHotelRequestFieldChange("send_by", e.target.value)}
            className={`${INPUT_FIELD_CLASS} ${form?.errors?.send_by ? 'error-field' : ''}`}
          />
        </div>
      </div>
    </React.Fragment>
  );

  const HOTEL_RESERVATION_SECTION = () => (
    <div className='request-form-container w-full relative'>
      <span className='heading'> Hotel Reservation </span>
      <div className='border-primary-blue w3-round p-2'>
        {HOTEL_RESERVATION_INFO()}
        {FORM_FOOTER()}
      </div>
    </div>
  );

  const RESERVATION_DEPARTMENT_SECTION = () => (
    <div className='request-form-container w-full relative'>
      <span className='heading'>Reservations department</span>
      <div className='border-primary-blue w3-round p-2'>
        <h3>Please confirm the following</h3>
        <div className="list-view-container w3-margin-bottom">
          <div className="react-table-lite-container">
            <table className="react-table-lite-table align-top">
              <thead className="react-table-lite-header">
                <tr>
                  {/* <th>Suite:</th> */}
                  <th>Crew Member</th>
                  <th>Stay</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                <tr className="react-table-lite-row">
                  {/* <td>
                    <ul className="list-unstyled list-details">
                      <li>FM / ALI AATI</li>
                    </ul>
                  </td> */}
                  <td>
                    <CreatableSelect
                      isMulti={true}
                      isDisabled={!form?.data?.fleet}
                      onCreateOption={handleOpenAddCrewMemberModal}
                      options={
                        crewMembers &&
                        crewMembers?.data &&
                        crewMembers?.data?.map((CM) => ({
                          value: CM?.id,
                          label: `${CM.crewname} (${CM?.passport || 'N/A'}) (${CM?.saudia_id || 'N/A'})`
                        }))
                      }
                      value={
                        crewMembers?.data &&
                        crewMembers?.data
                          ?.filter((CM) => form?.data?.crew_members
                            ?.map(obj => parseInt(obj?.crew_member))
                            ?.indexOf(CM?.id) > -1)
                          ?.map(CM => ({
                            value: CM?.id,
                            label: `${CM.crewname} (${CM?.passport || 'N/A'}) (${CM?.saudia_id || 'N/A'})`
                          }))
                      }
                      onChange={obj => handleChangeCrewMember(obj)}
                      className={`${form?.errors?.crew_members ? 'error-field' : ''} w-full small-top-margin small-bottom-margin`}
                    />
                  </td>
                  <td>
                    {`(${dayjs(form?.data?.checkout_date)?.diff(
                      dayjs(form?.data?.checkin_date),
                      'day', false)}) 
                      Nights`}
                  </td>
                  <td>{dayjs(form?.data?.checkin_date)?.format('DD-MM-YYYY')} : To {dayjs(form?.data?.checkout_date)?.format('DD-MM-YYYY')}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <button className="w3-btn w3-red block w-full">Note: Cancellation must be made before 24 hours.</button>
      </div>
    </div>
  );

  const HOTEL_RESERVATION_NOTICE_SECTION = () => (
    <div className='request-form-container w-full relative py-2'>
      <span className='heading'>Services Included</span>
      <div className='border-primary-blue w3-round p-2'>
        <div className="hotel-service-box">
          <ul className="list-services">
            <li>3 Daily meals or one room service meal (maximum) after restaurant working hours</li>
            <li>Laundry for 3 pieces per day (maximum)</li>
            <li>Internet service</li>
            <li>Airport transfer</li>
          </ul>
        </div>
        <div className="hotel-service-box">
          <strong className="hotel-service-title">On Saudia Private Aviation account.</strong>
          <span className="hotel-service-alert">*Please Note:</span>
          <ul className="list-services">
            <li>Saudia Private Aviation is not obligated to pay any other services that not included.</li>
            <li>The guest registration form must be signed by the guest and attached to the invoice in order to process the payment. otherwise, the hotel accommodation request will be void.</li>
            <li>The invoices must be sent within maximum 7 days from the guest checkout date to avoid any delay in the payment process to the email AP@spa.sa and to the below address by postal:</li>
          </ul>
        </div>
        <div className="hotel-service-box">
          <ul className="list-services no-bullets">
            <li>Saudia Private Aviation Co Ltd</li>
            <li>North Terminal</li>
            <li>Kind Abdul Aziz International</li>
            <li>Airport</li>
            <li>Saudi Arabia</li>
          </ul>
        </div>
      </div>
    </div>
  );

  const FORM_FOOTER = () => (
    <React.Fragment>
      {!form?.data?.status?.toLowerCase()?.includes('cancelled')
        // && !form?.data?.status?.toLowerCase()?.includes('approved')
        ? <div className='w3-row-padding'>
          <div className='w3-col l6 m6 s12'>
            <button
              disabled={isSubmitting}
              onClick={handleSubmit}
              className='w3-btn bg-primary-blue w3-text-white small-top-margin ml-auto'
            >
              {isSubmitting ? 'Saving' : 'Save'}
            </button>
          </div>
        </div>
        : null
      }
      <div className='h-2'></div>
    </React.Fragment>
  );

  const ADD_CREWMEMBER_MODAL = () => (
    <>
      {showAddCrewMemberModal &&
        <CreateCrewMemberModal
          fleet={fleet}
          isOpen={showAddCrewMemberModal}
          selectedFleet={form?.data?.fleet}
          onCrewMemberCreate={handleAddCrewMemberSave}
          handleCloseAddCrewMemberModal={handleCloseAddCrewMemberModal}
        />
      }
    </>
  );

  const LOADER = () => (
    <div className="request-form-container">
      <div className="h-30 flex justify-center items-center">
        <div><Loader spinnerClassName='w-10 h-10 text-primary-blue' />
          <p className='text-primary-blue'> Loading data... </p>
        </div>
      </div>
    </div>
  );

  const HOTEL_RESERVATION_FORM = () => (
    <React.Fragment>
      {HOTEL_RESERVATION_SECTION()}
      {RESERVATION_DEPARTMENT_SECTION()}
      {HOTEL_RESERVATION_NOTICE_SECTION()}
      {ADD_CREWMEMBER_MODAL()}
    </React.Fragment>
  );

  return (
    <div id='HotelRequest-Form'>
      {isLoading
        ? LOADER()
        : HOTEL_RESERVATION_FORM()
      }
    </div>
  )
}

export default HotelRequestForm;