// Packages
import React from 'react';

// Utils
import { Modal, Warn } from '../../../../components';

function AllowEditLegConfirmModal({
  selectedLegId,
  showAllowEditLegModal,
  handleAllowLegEdit,
  handleCloseAllowEditLegModal
}) {
  return (
    <Modal isOpen={showAllowEditLegModal} modalClass="w-fit w3-round-medium">
      <div className="w-full">
        <div className='w3-pale-red w3-text-red py-1 px-2 flex flex-wrap items-center'>
          <Warn className='w-6 h-6 small-right-margin' />
          <h3>
            Enable Edit Leg
          </h3>
        </div>
        <div className='py-1 px-2 w3-large'>
          <div>Are you sure you want to edit this leg?</div>
          <div className='w3-text-red w3-large py-1'> The past legs' changes will be fully charged </div>
          <div className='w3-text-red w3-large text-right'> سيتم احتساب تغييرات الرحلات السابقة بالكامل </div>
          <div className="h-4"></div>
          <div className="flex justify-end">
            <button onClick={handleCloseAllowEditLegModal} className='w3-btn w3-grey w3-text-white'> Close </button>
            <button
              onClick={e => handleAllowLegEdit(selectedLegId)}
              className='w3-btn w3-red small-left-margin'
            >
              Confirm
            </button>
          </div>
          <div className="h-3"></div>
        </div>
      </div>
    </Modal>
  )
};

export default AllowEditLegConfirmModal;