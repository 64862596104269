import { constants } from "../utils";

const HotelRequestStatusChangeRequest = (token, data, id) => {
    var requestHeaders = new Headers();
    requestHeaders.append("Authorization", `Bearer ${token}`);
    requestHeaders.append("Content-Type", "application/json");
    return (fetch(`${constants.BASEURL}/api/hotelrequest/${id}/update_status_hotel_request/`, {
        method: 'PUT',
        headers: requestHeaders,
        body: data,
        redirect: 'follow'
    }));
};

export default HotelRequestStatusChangeRequest;
