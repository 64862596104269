// Packages
import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import PropTypes from 'prop-types';

import { auth } from '../../atoms';

// Utils
import { AirportListRequest } from '../../requests';
import { decodeString } from '../../utils';

export default function AirportSelect(props) {
  const [authState, _authState] = useAtom(auth);
  const [airports, _airports] = useState([]);
  const [searchString, _searchString] = useState('');
  const [showDropDown, _showDropDown] = useState(false);

  useEffect(() => {
    if (props.defaultValue) {
      _searchString(props.defaultValue);
    }
  }, [props?.defaultValue])

  useEffect(() => {
    const getData = setTimeout(() => {
      const token = decodeString(authState);
      const onlyAlphanumericRegex = /[^0-9a-zA-Z ]/g;  // This is to ignore the search string if any special character is in it
      if (searchString.trim() !== '' && token && !onlyAlphanumericRegex.test(searchString)) {
        AirportListRequest(token, searchString).then(res => {
          if (res) {
            return (res.json())
          }
        }).then(
          data => {
            if (data) {
              _airports(data);
              _showDropDown(true);
            }
          }
        ).catch(
          err => console.error(err)
        )
      }
    }, 800);
    return () => clearTimeout(getData);
  }, [searchString]);

  const handleSearchStringChange = (e) => {
    _searchString(e.target.value);
  };

  const handleOptionSelect = (A) => {
    _showDropDown(false);
    _searchString(`${A.airport_code && A.airport_code[0]} / ${A.code && A.code[0]}`);
    props.handleOptionSelect(A.code, A.airport_code, A);
  };

  const handleGetFirstAirportFromList = (e) => {
    if (e?.key?.toLowerCase() == 'enter' && airports && airports?.length && props?.getFirstAirportOnEnterKey) {
      handleOptionSelect(airports?.[0])
    }
  };

  return (
    <React.Fragment>
      <div className={`w3-dropdown-click ${props.containerClass}`}>
        <input
          onChange={handleSearchStringChange}
          disabled={props.disabled}
          value={searchString}
          onKeyUp={props.onKeyUp}
          onKeyDown={e => handleGetFirstAirportFromList(e)}
          placeholder={props?.placeholder || 'Search...'}
          className={`w3-input ${props.inputClass} ${props?.disabled ? 'cursor-disable' : ''}`}
        // onKeyDown={e => {
        //   const children = e.target.parentElement?.lastChild?.children || [];
        //   const currentChildIndex = Array.from(children)?.findIndex(elem => (
        //     elem.classList.contains('w3-light-grey') > -1
        //   )) || 0;
        //   const currentChild = children[ currentChildIndex>children?.length - 1 ? 0 : currentChildIndex+1 ];
        //   if (currentChild) currentChild.classList.add('w3-light-grey');
        // }}
        />
        <div className={`h-40 overflow-auto w3-dropdown-content w3-bar-block w3-card-4 w-full ${showDropDown && airports && airports.length ? 'w3-show' : ''}`}>
          {airports && airports.map((A, index) =>
            <div key={index} className="w3-bar-item w3-button" onClick={e => handleOptionSelect(A)}>
              {`${A.airport_code && A.airport_code[0]} / ${A.code && A.code[0]} (${A.city && A.city[0]} / ${A.country && A.country[0]})`}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

AirportSelect.propTypes = {
  containerClass: PropTypes.string,
  inputClass: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  getFirstAirportOnEnterKey: PropTypes.bool,
  handleOptionSelect: PropTypes.func.isRequired
};

AirportSelect.defaultProps = {
  containerClass: '',
  inputClass: '',
  disabled: false,
  getFirstAirportOnEnterKey: false,
  onKeyUp: () => 0
}