import { constants } from "../utils";

const HotelReportDataRequest = (token, contractId, data) => {
  var requestHeaders = new Headers();
  requestHeaders.append("Authorization", `Bearer ${token}`);
  requestHeaders.append("Content-Type", "application/json");
  return fetch(`${constants.BASEURL}/api/hotelrequest/get_contract_hotel/${contractId}/`, {
    method: "POST",
    body: data,
    headers: requestHeaders,
    redirect: "follow",
  });
};

export default HotelReportDataRequest;
