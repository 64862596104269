import { constants } from "../utils";

const ApprovedFsrLegDetailsRequest = (token, requestId) => {
    var requestHeaders = new Headers();
    requestHeaders.append("Authorization", `Bearer ${token}`);
    requestHeaders.append("Content-Type", "application/json");
    return (fetch(`${constants.BASEURL}/api/approverequests/${requestId}/approved_request_legs_by_request`, { 
        method: 'GET',
        headers: requestHeaders,
        redirect: 'follow'
    }));
};

export default ApprovedFsrLegDetailsRequest;