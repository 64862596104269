import { constants } from "../utils";

const InsuranceRequestListRequest = (token, page=undefined) => {
    var requestHeaders = new Headers();
    requestHeaders.append("Authorization", `Bearer ${token}`);
    requestHeaders.append("Content-Type", "application/json");
    const paginateString = `${page ? '?page='+page : ''}`;
    return (fetch(`${constants.BASEURL}/api/medicalinsurance/${paginateString}`, { 
        method: 'GET',
        headers: requestHeaders,
        redirect: 'follow'
    }));
};

export default InsuranceRequestListRequest;