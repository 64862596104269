import { constants } from "../utils";

const LoginRequest = (data) => {
    var requestHeaders = new Headers();
    requestHeaders.append("Content-Type", "application/json");
    return (fetch(`${constants.BASEURL}/api/token/`, {
        method: 'POST',
        body: data,
        headers: requestHeaders,
        redirect: 'follow'
    }));
};

export default LoginRequest;