import React, { Fragment } from 'react';

export default function TypeHotelsInvoice({
    form,
    contracts,
    aircrafts,
    handleFieldChange,
    handleSetAircraft
}) {
    return (
        <Fragment>
            <div className='w3-col l6 m6 s12'>
                <label className='w3-medium'> Hotel Request Id </label>
                <input
                    min={0}
                    type='number'
                    name="request_id"
                    value={form?.request_id}
                    onChange={handleFieldChange}
                    placeholder="Enter hotel request id..."
                    className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
                />
            </div>
            <div className='w3-col l6 m6 s12'>
                <label className='w3-medium'> Contract </label>
                <select
                    name='contract_id'
                    value={form?.contract_id}
                    onChange={handleFieldChange}
                    className='w3-input w3-white w3-border w3-round small-top-margin small-bottom-margin'
                >
                    <option value={''} disabled> Select Contract </option>
                    {contracts?.data?.map(C => (
                        <option key={C?.id} value={C?.id}> {C?.name} </option>
                    ))}
                </select>
            </div>
            <div className='w3-col l6 m6 s12'>
                <label className='w3-medium'> Aircraft </label>
                <select
                    name='aircraft_id'
                    value={form?.aircraft_id}
                    onChange={handleSetAircraft}
                    className='w3-input w3-white w3-border w3-round small-top-margin small-bottom-margin'
                >
                    <option value={''} disabled> Select Aircraft </option>
                    {aircrafts?.data?.map(A => (
                        <option key={A?.id} value={A?.id}> {A?.tailnumber} </option>
                    ))}
                </select>
            </div>
        </Fragment>
    )
}
