// Packages
import React, { useState, useEffect } from 'react'
import Select from 'react-select';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { Store } from 'react-notifications-component';

// APIs
import { ContractCostPlusInvoicesListRequest, ContractsListRequest, FleetListRequest, AirbaseListRequest, AircraftByFleetListRequest } from '../../../requests';

// Utils
import { constants, decodeString, authenticationErrorHandle } from '../../../utils';
import { airbasesCache, auth, contractsCache, fleetCache } from '../../../atoms';
import { InvoiceReportTable, Loader } from '../../../components';

export default function FilteredInvoicesList(props) {

  const [authState, _authState] = useAtom(auth);
  const [contract, _contract] = useAtom(contractsCache);
  const [airbases, _airbases] = useAtom(airbasesCache);
  const [fleet, _fleet] = useAtom(fleetCache);
  const [aircrafts, _aircrafts] = useState([]);
  const [costPlusInvoicesItems, _costPlusInvoicesItems] = useState({});
  const [blockHoursInvoicesItems, _blockHoursInvoicesItems] = useState({});
  const [selectedContract, _selectedContract] = useState('');
  const [selectedAirbases, _selectedAirbases] = useState([]);
  const [selectedFleets, _selectedFleets] = useState([]);
  const [selectedAircrafts, _selectedAircrafts] = useState([]);
  const [selectedMonth, _selectedMonth] = useState(Number(dayjs().format('M')));
  const [selectedYear, _selectedYear] = useState(Number(dayjs().format('YYYY')));
  const [invoiceType, _invoiceType] = useState('cost-plus');
  const [isLoading, _isLoading] = useState(false);

  // Get contracts, airbases & fleets either from cache or from server
  useEffect(() => {
    if (authState) {
      if (!contract || !contract.created || Date.now() - contract.created >= 1200000)
        getContract();
      if (!airbases || !airbases.created || Date.now() - airbases.created >= 1200000)
        getAirbases()
      if (!fleet || !fleet.created || Date.now() - fleet.created >= 1200000)
        getFleet();
    }
  }, [authState])

  // Get aircrafts for each fleet from API
  useEffect(() => {
    if (authState && selectedFleets?.length)
      getAircraftsByFleet();
  }, [authState, selectedFleets]);

  // Fetch the invoice items based on filters with debounce  
  useEffect(() => {
    const getInvoiceData = setTimeout(() => {
      if (selectedContract && selectedMonth && selectedYear) {
        getContractInvoiceItems();
      }
    }, 800);
    return () => clearTimeout(getInvoiceData);
  }, [authState, selectedMonth, selectedYear, selectedContract, selectedFleets, selectedAirbases, selectedAircrafts]);

  const getContract = () => {
    const token = decodeString(authState);
    ContractsListRequest(token).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data && data.results) {
        // Keep server data in cache with current time
        _contract({
          data: [...data.results],
          created: Date.now()
        });
      } else {
        throw 'Request Failed';
      }
    })
      .catch(
        err => {
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch contracts' });
        }
      )
  };

  const getContractInvoiceItems = () => {
    _isLoading(true);
    const token = decodeString(authState);
    const contractId = parseInt(selectedContract);
    const data = JSON.stringify({
      month: parseInt(selectedMonth),
      year: parseInt(selectedYear),
      airbase_ids: selectedAirbases,
      fleet_ids: selectedFleets,
      aircraft_ids: selectedAircrafts
    });
    ContractCostPlusInvoicesListRequest(token, contractId, data)
      .then((res) => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else return res.json();
      })
      .then((nonPaginatedData) => {
        if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
        if (nonPaginatedData?.invoice_service_data) {
          _isLoading(false);
          _costPlusInvoicesItems(old => ({
            invoice_items: nonPaginatedData?.invoice_service_data?.service_data &&
              nonPaginatedData?.invoice_service_data?.service_data.map((item => ({
                ...item,
                month: dayjs(`${selectedYear}-${selectedMonth}-01`)?.format('MMM-YYYY')
              }))),
            total: nonPaginatedData?.invoice_service_data?.total_stats?.net_total_cost || 0
          }));
          _blockHoursInvoicesItems(old => ({
            invoice_items: nonPaginatedData?.block_hour_usage?.block_hour_data &&
              nonPaginatedData?.block_hour_usage?.block_hour_data.map((item => ({
                ...item,
                month: dayjs(`${selectedYear}-${selectedMonth}-01`)?.format('MMM-YYYY')
              }))),
            total: nonPaginatedData?.block_hour_usage?.total_stats?.total_blockhours || 0
          }));
        } else {
          _costPlusInvoicesItems({});
          _blockHoursInvoicesItems({});
          throw "Request Failed";
        }
      })
      .catch(err => {
        _isLoading(false);
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch invoice items" });
      })
  };

  const getAirbases = () => {
    _isLoading(true);
    const token = decodeString(authState);
    AirbaseListRequest(token)
      .then((res) => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else return res.json();
      })
      .then((nonPaginatedData) => {
        if (constants?.LOGOUTERRORTYPES?.includes(nonPaginatedData?.errorCodes)) return;
        if (nonPaginatedData) {
          // Keep server data in cache with current time
          _airbases({
            data: [...nonPaginatedData],
            created: Date.now(),
          });
          _isLoading(false);
        } else {
          throw "Request Failed";
        }
      })
      .catch((err) => {
        console.error(err);
        _isLoading(false);
        Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch airbases" });
      });
  };

  const getFleet = () => {
    const token = decodeString(authState);
    _isLoading(true);
    FleetListRequest(token)
      .then((res) => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else return res.json();
      })
      .then((data) => {
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if (data && data.results) {
          // Keep server data in cache with current time
          _fleet({
            data: [...data.results],
            created: Date.now(),
          });
          _isLoading(false);
        } else {
          throw 'Request Failed';
        }
      })
      .catch((err) => {
        _isLoading(false);
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch fleet' });
      });
  };

  async function getAircraftsByFleet() {
    const token = decodeString(authState);
    const promises = selectedFleets?.map(fleet => AircraftByFleetListRequest(token, fleet));
    const data = await Promise.all(promises);
    let aircraftByFleetList = [];
    try {
      data.forEach(res => res?.json()
        .then(nonPaginatedData => {
          aircraftByFleetList = [...aircraftByFleetList, ...nonPaginatedData];
          _aircrafts({
            data: aircraftByFleetList
          });
        })
        .catch(err => console.error(err)
        ));
    } catch (err) {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch aircrafts" });
    }
  };

  // *********** Handlers ***********

  const handleContractChange = (contract) => {
    _selectedContract(contract?.value);
    _selectedAircrafts([]);
    _selectedAirbases([]);
    _aircrafts([]);
  };

  const handleAirbaseChange = (airbases) => {
    _selectedAirbases(airbases?.map(A => A?.value));
    _selectedFleets([]);
    _selectedAircrafts([]);
  };

  const handleFleetChange = (fleets) => {
    _selectedFleets(fleets?.map(F => F?.value));
    _selectedAircrafts([]);
  };

  const handleAircraftChange = (aircrafts) => {
    _selectedAircrafts(aircrafts?.map(A => A?.value));
  };

  const handleMonthChange = (month) => {
    _selectedMonth(month?.value);
  };

  const handleYearChange = (year) => {
    _selectedYear(year?.value);
  };

  const handleInvoiceTypeChange = (value) => {
    _invoiceType(value);
  };

  // *********** Render Functions ***********

  const LOADER = () => (
    <div className="request-form-container">
      <div className="h-30 flex justify-center items-center">
        <div><Loader spinnerClassName='w-10 h-10 text-primary-blue' />
          <p className='text-primary-blue'> Loading data... </p>
        </div>
      </div>
    </div>
  );

  const REPORT_FILTER_FIELDS = () => (
    <div id="invoice-report-filter-container">
      <div className='w3-row-padding w3-stretch'>
        <div className="w3-col s12 m4">
          <label className='w3-medium' htmlFor='month'> Month </label>
          <CustomFilterSelectInput
            id="month"
            valueKey='value'
            labelKey='month'
            isClearable={false}
            isMulti={false}
            placeholder='Select Month'
            value={selectedMonth}
            onChange={handleMonthChange}
            optionsArray={constants?.MONTHS?.map((month, index) => ({ month, value: index + 1 }))}
          />
        </div>

        <div className="w3-col s12 m4">
          <label className='w3-medium' htmlFor='year'> Year </label>
          <CustomFilterSelectInput
            id="year"
            valueKey='year'
            labelKey='year'
            isClearable={false}
            isMulti={false}
            placeholder='Select Year'
            value={selectedYear}
            onChange={handleYearChange}
            optionsArray={Array.from({ length: parseInt(dayjs().year()) - 2024 + 1 }, (_, i) => i + 2024).map((item) => ({ year: item }))}
          />
        </div>

        <div className="w3-col s12 m4">
          <label className='w3-medium' htmlFor='contract'> Contract </label>
          <CustomFilterSelectInput
            id="contract"
            valueKey='id'
            labelKey='name'
            isClearable={false}
            isMulti={false}
            placeholder='Select Contract'
            value={selectedContract}
            optionsArray={contract && contract?.data}
            onChange={handleContractChange}
          />
        </div>

        <div className="w3-col s12 m4">
          <label className='w3-medium' htmlFor='airbases'> Airbase </label>
          <CustomFilterSelectInput
            id="airbases"
            valueKey='id'
            labelKey='name'
            isClearable={true}
            isMulti={true}
            placeholder='All Airbases'
            value={selectedAirbases}
            isDisabled={!selectedContract}
            onChange={handleAirbaseChange}
            optionsArray={airbases && airbases?.data?.filter(A => A.contract == selectedContract)}
          />
        </div>

        <div className="w3-col s12 m4">
          <label className='w3-medium' htmlFor='fleets'> Fleet </label>
          <CustomFilterSelectInput
            id="fleets"
            valueKey='id'
            labelKey='name'
            isClearable={true}
            isMulti={true}
            placeholder='All Fleets'
            value={selectedFleets}
            isDisabled={selectedAirbases?.length < 1}
            onChange={handleFleetChange}
            optionsArray={fleet && fleet?.data?.filter(F => selectedAirbases?.includes(F?.airbase_obj?.id))}
          />
        </div>

        <div className="w3-col s12 m4">
          <label className='w3-medium' htmlFor='aircrafts'> Aircraft </label>
          <CustomFilterSelectInput
            id="aircrafts"
            valueKey='id'
            labelKey='tailnumber'
            isClearable={true}
            isMulti={true}
            placeholder='All Aircrafts'
            value={selectedAircrafts}
            isDisabled={selectedFleets?.length < 1}
            onChange={handleAircraftChange}
            optionsArray={aircrafts && aircrafts?.data?.filter(A => selectedFleets?.includes(A?.fleet_obj?.id))}
          />
        </div>

        <div className="w3-col m12 w3-bar" >
          <div
            onClick={e => handleInvoiceTypeChange('cost-plus')}
            className={`w3-col w3-half w3-button ${invoiceType === "cost-plus" ? 'bg-primary-blue w3-text-white' : 'w3-light-grey'}`}
          >
            Cost Plus
          </div>
          <div
            onClick={e => handleInvoiceTypeChange('block-hours')}
            className={`w3-col w3-half w3-button ${invoiceType === "block-hours" ? 'bg-primary-blue w3-text-white' : 'w3-light-grey'}`}
          >
            Block Hours
          </div>
        </div>
      </div>
    </div>
  );

  const SELECT_CONTRACT_MESSAGE = () => (
    <div className='w-full w3-margin-top text-center w3-text-gray'> Select contract & month to see invoice items </div>
  );

  const INVOICE_ITEMS_LIST_TABLE = () => (
    <InvoiceReportTable
      downloadable={true}
      costPlusInvoiceItems={costPlusInvoicesItems}
      blockHoursInvoiceItems={blockHoursInvoicesItems}
      isLoading={isLoading}
      invoiceType={invoiceType}
    />
  );

  return (
    <div className='page-content w3-white h-full relative overflow-hidden'>
      <div className='py-2'>
        {REPORT_FILTER_FIELDS()}
        {selectedMonth && selectedContract
          ? isLoading ? LOADER() : INVOICE_ITEMS_LIST_TABLE()
          : SELECT_CONTRACT_MESSAGE()
        }
      </div>
    </div>
  )
};

// Filters Custom React Select Field
const CustomFilterSelectInput = ({
  id = '',
  isMulti = false,
  isClearable = true,
  optionsArray = [],
  onChange = e => 0,
  placeholder = '',
  labelKey = '',
  valueKey = '',
  value = null,
  isDisabled = false
}) => {
  const SelectableOptions = optionsArray?.map(item => ({ label: item[labelKey], value: item[valueKey] }));
  const SelectedValue = isMulti ? SelectableOptions?.filter(item => value.indexOf(item?.value) > -1) : SelectableOptions?.find(item => item?.value === value);
  return (
    <Select
      id={id}
      isMulti={isMulti}
      placeholder={placeholder}
      options={SelectableOptions}
      value={SelectedValue}
      onChange={onChange}
      isDisabled={isDisabled}
      isClearable={isClearable}
      menuPortalTarget={document.body}
      className='w3-round small-top-margin small-bottom-margin'
    />
  );
}