import { constants } from "../utils";

const FleetDetailsRequest = (token, fleetId) => {
    var requestHeaders = new Headers();
    requestHeaders.append("Authorization", `Bearer ${token}`);
    requestHeaders.append("Content-Type", "application/json");
    return (fetch(`${constants.BASEURL}/api/fleet/${fleetId}`, { 
        method: 'GET',
        headers: requestHeaders,
        redirect: 'follow'
    })); 
};

export default FleetDetailsRequest;
