// Packages
import React from "react";
import { useNavigate } from "react-router-dom";

function BillingSelectionView() {

  const Navigate = useNavigate();

  // *********** Handlers ***********

  const routeToBillingScreen = (route) => {
    Navigate(`/billing${route}`);
  };

  // *********** Render Functions ***********

  const CONTENT = () => (
    <div className='border-primary-blue w3-round p-2'>
      <ul id="invoice-report-selection-list">
        <li>
          <div className="cursor-pointer" onClick={e => routeToBillingScreen('/hotels')}> Hotels </div>
        </li>
        <li>
          <div className="cursor-pointer" onClick={e => routeToBillingScreen('/insurance')}> Insurance </div>
        </li>        
      </ul>
    </div>
  );

  return (
    <div className='request-form-container w-full relative'>
      <span className='heading'> Billing </span>
      {CONTENT()}
    </div>
  );
}

export default BillingSelectionView;
