// Packages
import React, { useState, useEffect } from 'react';
import { Store } from 'react-notifications-component';
import dayjs from 'dayjs';

import { Clock } from '../svgs';
import { constants, decodeString } from '../../utils';

const SessionExpiryDetail = () => {

	let expiry = localStorage?.getItem('is_valid') || '';
	expiry = decodeString(expiry, constants.ENCODER_LOGIN_TIME);
	const [showSessionWarnToast, _showSessionWarnToast] = useState(false);

	// Keep checking expiry after every 1 minutes
	useEffect(() => {
		const checkExpiry = setInterval(() => {
			if (!showSessionWarnToast && dayjs(expiry) - dayjs() <= 1800000) {
				_showSessionWarnToast(true);
				Store.addNotification({ ...constants.NOTIFYTOAST, message: 'Session is about to expire, please save your work & re-login' });
			}
		}, 60000)
		return () => clearInterval(checkExpiry);
	}, [showSessionWarnToast]);

	// *********** Handlers ***********

	const handleRefreshSession = () => {
	};

	// *********** Render Functions ***********

	const EXPIRY_TIME = () => (
		<div className='flex items-center'>
			<div>
				<Clock className='w-8 h-8 w3-text-white small-right-margin' />
			</div>
			<div className='w3-text-white w3-medium'>
				<div> Session Expires At </div>
				<div> {dayjs(expiry)?.format('DD/MM/YY HH:mm')} </div>
			</div>
		</div>
	);

	const REFRESH_SESSION_BUTTON = () => (
		<button
			onClick={handleRefreshSession}
			className=''
		>
			Refresh Session
		</button>
	);

	return (
		<div id='Session-Remaining' className='px-2'>
			{
				EXPIRY_TIME()
			}
		</div>
	);
};

export default SessionExpiryDetail;

