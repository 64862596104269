import { constants } from "../utils";

const ApprovedFsrMarkCompleteLegRequest = (token, data) => {
    var requestHeaders = new Headers();
    requestHeaders.append("Authorization", `Bearer ${token}`);
    requestHeaders.append("Content-Type", "application/json");
    return fetch(`${constants.BASEURL}/api/approverequests/mark_approve_leg_complete/`, {
      method: "POST",
      body: data,
      headers: requestHeaders,
      redirect: "follow",
    });
};

export default ApprovedFsrMarkCompleteLegRequest;