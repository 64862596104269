import { constants } from "../utils";

const AircraftBlockHoursInvoicesListRequest = (token, aircraftId, data) => {
  var requestHeaders = new Headers();
  requestHeaders.append("Authorization", `Bearer ${token}`);
  requestHeaders.append("Content-Type", "application/json");
  return fetch(`${constants.BASEURL}/api/aircraft/get_aircraft_blockhours_data/${aircraftId}/`, {
    method: "POST",
    body: data,
    headers: requestHeaders,
    redirect: "follow",
  });
};

export default AircraftBlockHoursInvoicesListRequest;
