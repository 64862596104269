// Packages
import React, { useRef } from 'react';

// Utils
import { Enter, Search } from '../../components';

export default function SearchBar(props) {
	const internalRef = useRef(0);
	const formRef = props?.SearchRef || internalRef;
	return (
		<div className={`search-bar-container ${props?.containerClass}`}>
			<form ref={formRef} id={props?.id} onSubmit={props?.onSearch} className='flex items-center'>
				<input
					className={props?.className}
					onBlur={props?.onBlur}
					onChange={props?.onChange}
					value={props?.value}
					placeholder={props?.placeholder}
				/>
				<button className={props?.buttonClass} type='submit'>
					{props?.value?.trim() !== ''
						? <Enter className='w-5 h-5' />
						: <Search className='w-5 h-5' />
					}
				</button>
			</form>
		</div>
	)
}
