// Packages
import React, { Fragment } from 'react';

// Utils
import TableLite from '../table-lite/TableLite';
import { formatPrice } from '../../utils';

export default function InvoiceReportTable({
  invoiceType,
  isLoading,
  downloadable,
  costPlusInvoiceItems,
  blockHoursInvoiceItems
}) {
  const CostInvoiceItemsHeaders = ["service_type", "month", "aircraft_tail_number", "airbase", "service", "count", "total_quantity", "unit_cost", "total_cost"];
  const BlockHoursInvoiceItemsHeaders = ["aircraft_tail_number", "airbase", "month", "block_hours", "unit_cost", "vat_percentage", "vat_amount", "total_cost"];
  const CostPlusInvoiceItemsCustomHeaders = {
    "service_type": "Service Type",
    "month": "Month",
    "aircraft_tail_number": "Aircraft",
    "service": "Service",
    "count": "Count",
    "total_quantity": "Total Quantity",
    "unit_cost": "Unit Cost",
    "total_cost": "Total Cost",
  };
  const BlockHoursInvoiceItemsCustomHeaders = {
    "month": "Month",
    "aircraft_tail_number": "Aircraft",
    "unit_cost": "Unit Cost",
    "total_cost": "Total Cost",
    "block_hours": "Block Hours",
    "vat_percentage": "Vat Percentage",
    "vat_amount": "Vat Amount",
  };

  const COST_PLUS_CSV_KEYS = ["service", "service_type", "aircraft_tail_number", "month", "unit_cost", "count", "total_quantity", "total_cost"];
  const BLOCK_HRS_CSV_KEYS = ["aircraft_tail_number", "month", "block_hours", "unit_cost", "total_cost", "vat_percentage", "vat_amount"];

  // *********** Render Functions ***********

  const CUSTOM_RENDER_TABLE_CELL = {
    airbase: (row) => <Fragment> {row?.airbase?.name || row?.aircraft?.fleet_obj?.airbase_obj?.name || '-'} </Fragment>,
    unit_cost: (row) => <Fragment> {formatPrice(Number(row?.unit_cost || 0))} </Fragment>,
    total_cost: (row) => <Fragment> {formatPrice(Number(row?.total_cost || 0))} </Fragment>,
    vat_percentage: (row) => <Fragment> {formatPrice(Number(row?.vat_percentage || 0))} </Fragment>,
    vat_amount: (row) => <Fragment> {formatPrice(Number(row?.vat_amount || 0))} </Fragment>,
    block_hours: (row) => <Fragment> {formatPrice(Number(row?.block_hours || 0))} </Fragment>
  };

  const COST_PLUS_INVOICE_ITEMS_LIST = () => (
    <Fragment>
      {invoiceType === 'cost-plus' &&
        <TableLite
          showPagination={false}
          showMultiSelect={false}
          onRowEdit={() => 0}
          totalPages={1}
          currentPage={1}
          csvKeys={COST_PLUS_CSV_KEYS}
          fileName={"cost-plus-data"}
          headerClass='w3-small'
          headerStyle={{ fontSize: '0.86em' }}
          cellStyle={{ fontSize: '0.8em' }}
          data={costPlusInvoiceItems?.invoice_items || []}
          headers={CostInvoiceItemsHeaders}
          customHeaders={CostPlusInvoiceItemsCustomHeaders}
          customRenderCell={CUSTOM_RENDER_TABLE_CELL}
          downloadable={downloadable && costPlusInvoiceItems?.invoice_items}
          noDataMessage={isLoading ? 'Loading data...' : 'No data found'}
          downloadCsvButtonClass="w3-btn bg-primary-blue w3-text-white small-top-margin ml-auto"
        />
      }
      {costPlusInvoiceItems
        && costPlusInvoiceItems?.total
        ? <div className='w3-row'>
          <div className='w3-col m6'> <div className='p-2 bold'> Total Amount </div></div>
          <div className='w3-col m6'><div className='p-2 w-full text-right bold'>{formatPrice(Number(costPlusInvoiceItems?.total))}</div></div>
        </div>
        : null
      }
    </Fragment>
  );

  const BLOCK_HOUR_INVOICE_ITEMS_LIST = () => (
    <Fragment>
      {invoiceType === 'block-hours' &&
        <TableLite
          showPagination={false}
          showMultiSelect={false}
          onRowEdit={() => 0}
          totalPages={1}
          currentPage={1}
          csvKeys={BLOCK_HRS_CSV_KEYS}
          fileName={"block-hours-data"}
          headerClass='w3-small'
          headerStyle={{ fontSize: '0.86em' }}
          cellStyle={{ fontSize: '0.8em' }}
          data={blockHoursInvoiceItems?.invoice_items || []}
          headers={BlockHoursInvoiceItemsHeaders}
          customHeaders={BlockHoursInvoiceItemsCustomHeaders}
          customRenderCell={CUSTOM_RENDER_TABLE_CELL}
          downloadable={downloadable && blockHoursInvoiceItems?.invoice_items}
          noDataMessage={isLoading ? 'Loading data...' : 'No data found'}
          downloadCsvButtonClass="w3-btn bg-primary-blue w3-text-white small-top-margin ml-auto"
        />
      }
      {blockHoursInvoiceItems
        && blockHoursInvoiceItems?.total
        ? <div className='w3-row'>
          <div className='w3-col m6'> <div className='p-2 bold'> Total Block Hrs </div></div>
          <div className='w3-col m6'><div className='p-2 w-full text-right bold'>{blockHoursInvoiceItems?.total}</div></div>
        </div>
        : null
      }
    </Fragment>
  );

  const INVOICE_ITEMS_LIST_TABLE = () => (
    <div className='list-view-container overflow-auto'>
      {invoiceType === 'cost-plus'
        ? COST_PLUS_INVOICE_ITEMS_LIST()
        : BLOCK_HOUR_INVOICE_ITEMS_LIST()
      }
    </div>
  );

  return INVOICE_ITEMS_LIST_TABLE();
}