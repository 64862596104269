import { constants } from "../utils";

const LogoutRequest = (token) => {
    var requestHeaders = new Headers();
    requestHeaders.append("Authorization", `Bearer ${token}`);
    return (fetch(`${constants.BASEURL}/api/logout`, { 
        method: 'GET',
        headers: requestHeaders,
        redirect: 'follow'
    }));
};

export default LogoutRequest;