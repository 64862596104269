import { constants } from "../utils";

const CrewMemberCreateRequest = (token, data, id) => {
    var requestHeaders = new Headers();
    requestHeaders.append("Authorization", `Bearer ${token}`);
    var requestOptions = {
        method: 'POST',
        headers: requestHeaders,
        body: data,
        redirect: 'follow'
    };

    return fetch(`${constants.BASEURL}/api/crewmember/`, requestOptions);
};

export default CrewMemberCreateRequest;