import { constants } from "../utils";

const CheckUserRequest = (data) => {
    var requestHeaders = new Headers();    
    requestHeaders.append("Content-Type", "application/json");
    return (fetch(`${constants.BASEURL}/api/passwordreset/check_user/`, { 
        method: 'POST',
        body: data,
        headers: requestHeaders,
        redirect: 'follow'
    }));
};

export default CheckUserRequest;
