// Packages
import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { Store } from "react-notifications-component";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";

// APIs
import {
  AirbaseListRequest,
  AirbaseCreateRequest,
  AirbaseDetailsRequest,
  AirbaseEditRequest,
  ContractsListRequest,
} from "../../../requests";

// Utils
import { auth, airbasesCache, contractsCache, loggedUserInfoCache } from "../../../atoms";
import { constants, decodeString, authenticationErrorHandle, validateSubmissionData } from "../../../utils";
import { Loader } from "../../../components";

function AirbaseForm() {
  const { airbaseId } = useParams();
  const Navigate = useNavigate();

  const INITIAL_FORM = {
    phone: "",
    name: "",
    state: "",
    zip: "",
    country: "",
    city: "",
    address: "",
    iata: "",
    oca: "",
    email: "",
    contract: "",
    company: "",
  };

  const VALIDATIONS = {
    name: { isRequired: true },
    city: { isRequired: true },
    country: { isRequired: true },
    iata: { isRequired: true },
    oca: { isRequired: true },
    contract: { isRequired: true, isNumber: true },
  };

  const [isSubmitting, _isSubmitting] = useState(false);
  const [isLoading, _isLoading] = useState(airbaseId ? true : false);
  const [formErrors, _formErrors] = useState({});
  const [form, _form] = useState({ ...INITIAL_FORM });
  const [authState, _authState] = useAtom(auth);
  const [loggedUserInfo] = useAtom(loggedUserInfoCache);
  const [airbases, _airbases] = useAtom(airbasesCache);
  const [contracts, _contracts] = useAtom(contractsCache);

  useEffect(() => {
    if (airbaseId && authState) {
      getAirbaseDetails();
    }
  }, [authState, airbaseId]);

  // Get airbases & contracts either from cache or from server
  useEffect(() => {
    if (authState) {
      if (
        !airbases || !airbases.created || Date.now() - airbases.created >= 1200000) {
        getAirbases();
      }
      if (!contracts || !contracts.created || Date.now() - contracts.created >= 1200000) {
        getContracts()
      }
    }
  }, [authState, form?.id]);

  // Set company from logged user Info for create case
  useEffect(() => {
    if (!airbaseId && loggedUserInfo?.data) {
      setCompany();
    }
  }, [loggedUserInfo?.data, form?.id]);

  const getAirbases = () => {
    const token = decodeString(authState);
    AirbaseListRequest(token)
      .then((res) => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else return res.json();
      })
      .then((nonPaginatedData) => {
        if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
        if (nonPaginatedData) {
          // Keep server data in cache with current time
          _airbases({
            data: [...nonPaginatedData],
            created: Date.now(),
          });
        } else {
          throw "Request Failed";
        }
      })
      .catch((err) => {
        console.error(err);
        Store.addNotification({
          ...constants.ERRORTOAST,
          message: "Failed to fetch airbases",
        });
      });
  };

  const getContracts = () => {
    const token = decodeString(authState);
    ContractsListRequest(token)
      .then((res) => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else return res.json();
      })
      .then((data) => {
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if (data && data.results) {
          // Keep server data in cache with current time
          _contracts({
            data: [...data.results],
            created: Date.now(),
          });
        } else {
          throw "Request Failed";
        }
      })
      .catch((err) => {
        console.error(err);
        Store.addNotification({
          ...constants.ERRORTOAST,
          message: "Failed to fetch contracts",
        });
      });
  };

  const getAirbaseDetails = () => {
    const token = decodeString(authState);
    _isLoading(true);
    AirbaseDetailsRequest(token, airbaseId)
      .then((res) => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else return res.json();
      })
      .then((data) => {
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if (data) {
          _isLoading(false);
          _form(data);
        } else {
          throw "Request Failed";
        }
      })
      .catch((err) => {
        _isLoading(false);
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch airbase details" });
      });
  };

  const setCompany = () => {
    _form(old => ({
      ...old,
      company: loggedUserInfo?.data?.company
    }))
  };

  // *********** Handlers ***********

  const handleAirbaseFileChange = (name, value) => {
    _form((old) => ({
      ...old,
      [name]: value,
    }));
  };

  const handleUserFieldChange = (name, value) => {
    _form(old => ({
      ...old,
      [name]: value
    }));
  };

  const handleCheckValidation = () => {
    const { allValid, errors } = validateSubmissionData(form, VALIDATIONS);
    if (!allValid) {
      _formErrors(errors);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Required fields have errors" });
    } else {
      _formErrors({});
    }
    return allValid;
  };

  const handleSubmit = () => {
    const token = decodeString(authState);
    const data = JSON.parse(JSON.stringify(form));
    const allValid = handleCheckValidation();
    if (!allValid) return;
    _isSubmitting(true);
    if (airbaseId) {
      AirbaseEditRequest(token, JSON.stringify(data), airbaseId)
        .then((res) => {
          if (res && res?.status === 401) {
            authenticationErrorHandle(() => _authState('0'));
            return (
              { errorCodes: constants.SESSIONTIMEOUT }
            );
          } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
            throw 'Request Failed';
          } else return res.json();
        })
        .then((data) => {
          if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
          if ((typeof data === 'string' && data.indexOf('Error') > -1)) {
            throw "Request Failed";
          } else {
            getAirbases();
            _isSubmitting(false);
            Store.addNotification({ ...constants.SUCCESSTOAST, message: "Airbase updated" });
            Navigate("/airbases");
          }
        })
        .catch((err) => {
          _isSubmitting(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: "Request failed" });
        });
    } else {
      AirbaseCreateRequest(token, JSON.stringify(data))
        .then((res) => {
          if (res && res?.status === 401) {
            authenticationErrorHandle(() => _authState('0'));
            return (
              { errorCodes: constants.SESSIONTIMEOUT }
            );
          } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
            throw 'Request Failed';
          } else return res.json();
        })
        .then((data) => {
          if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
          if ((typeof data === 'string' && data.indexOf('Error') > -1)) {
            throw "Request Failed";
          } else {
            getAirbases();
            _isSubmitting(false);
            Store.addNotification({ ...constants.SUCCESSTOAST, message: "Airbase Registered" });
            Navigate("/airbases");
          }
        })
        .catch((err) => {
          _isSubmitting(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: "Request failed" });
        });
    }
  };

  // *********** Render Functions ***********

  const AIRBASE_DETAILS = () => (
    <React.Fragment>
      <div className="w3-row-padding">
        <div className="w3-col l6 m6 s12">
          <label> Name </label>
          <input
            type="text"
            value={form?.name}
            onChange={(e) => handleAirbaseFileChange("name", e.target.value)}
            className={`w3-input w3-border w3-round small-top-margin small-bottom-margin ${formErrors?.name ? 'error-field' : ''}`}
          />
        </div>
        <div className="w3-col l6 m6 s12">
          <label> Country </label>
          <Select
            placeholder='Select Country'
            options={constants.COUNTRYARRAY?.map(C => (
              { value: C.country, label: C.country })
            )}
            value={form?.country ? {
              value: form?.country,
              label: form?.country
            } : null}
            onChange={A => handleUserFieldChange('country', A.value)}
            className={`w-full small-top-margin small-bottom-margin ${formErrors?.country ? 'error-field' : ''}`}
          />
        </div>
      </div>

      <div className="w3-row-padding">
        <div className="w3-col l6 m6 s12">
          <label> Address </label>
          <input
            type="text"
            value={form?.address}
            onChange={(e) => handleAirbaseFileChange("address", e.target.value)}
            className="w3-input w3-border w3-round small-top-margin small-bottom-margin"
          />
        </div>
        <div className="w3-col l6 m6 s12">
          <label> Phone </label>
          <input
            value={form?.phone}
            onChange={(e) => handleAirbaseFileChange("phone", e.target.value)}
            className="w3-input w3-border w3-round small-top-margin small-bottom-margin"
          />
        </div>
      </div>

      <div className="w3-row-padding">
        <div className="w3-col l6 m6 s12">
          <label> IATA </label>
          <input
            type="text"
            value={form?.iata}
            onChange={(e) => handleAirbaseFileChange("iata", e.target.value)}
            className={`w3-input w3-border w3-round small-top-margin small-bottom-margin ${formErrors?.iata ? 'error-field' : ''}`}
          />
        </div>
        <div className="w3-col l6 m6 s12">
          <label> OCA </label>
          <input
            value={form?.oca}
            onChange={(e) => handleAirbaseFileChange("oca", e.target.value)}
            className={`w3-input w3-border w3-round small-top-margin small-bottom-margin ${formErrors?.oca ? 'error-field' : ''}`}
          />
        </div>
      </div>

      <div className="w3-row-padding">
        <div className="w3-col l6 m6 s12">
          <label> City </label>
          <input
            type="text"
            value={form?.city}
            onChange={(e) => handleAirbaseFileChange("city", e.target.value)}
            className={`w3-input w3-border w3-round small-top-margin small-bottom-margin ${formErrors?.city ? 'error-field' : ''}`}
          />
        </div>
        <div className="w3-col l6 m6 s12">
          <label> State </label>
          <input
            value={form?.state}
            onChange={(e) => handleAirbaseFileChange("state", e.target.value)}
            className="w3-input w3-border w3-round small-top-margin small-bottom-margin"
          />
        </div>
        <div className="w3-col l6 m6 s12">
          <label> Zip </label>
          <input
            value={form?.zip}
            onChange={(e) => handleAirbaseFileChange("zip", e.target.value)}
            className="w3-input w3-border w3-round small-top-margin small-bottom-margin"
          />
        </div>
        <div className="w3-col l6 m6 s12">
          <label> Email </label>
          <input
            value={form?.email}
            onChange={(e) => handleAirbaseFileChange("email", e.target.value)}
            className="w3-input w3-border w3-round small-top-margin small-bottom-margin"
          />
        </div>
        <div className='w3-col l6 m6 s12'>
          <label> Contract </label>
          <Select
            placeholder='Select Contract'
            options={contracts && contracts?.data?.map(C => (
              { value: C.id, label: C.name })
            )}
            value={form?.contract ? {
              value: form?.contract,
              label: contracts && contracts?.data?.find(C => C.id == form?.contract)?.name
            } : null}
            onChange={C => handleAirbaseFileChange("contract", C.value)}
            className={`w-full small-top-margin small-bottom-margin ${formErrors?.contract ? 'error-field' : ''}`}            
          />
        </div>
      </div>
    </React.Fragment>
  );

  const LOADER = () => (
    <div className="request-form-container">
      <div className="h-30 flex justify-center items-center">
        <div><Loader spinnerClassName='w-10 h-10 text-primary-blue' />
          <p className='text-primary-blue'> Loading data... </p>
        </div>
      </div>
    </div>
  );

  const FORM_FOOTER = () => (
    <React.Fragment>
      <div className="w3-row-padding">
        <div className="w3-col l6 m6 s12">
          <button
            disabled={isSubmitting}
            onClick={handleSubmit}
            className="w3-btn bg-primary-blue w3-text-white small-top-margin"
          >
            {isSubmitting ? "Submitting" : "Submit"}
          </button>
        </div>
      </div>
      <div className="h-2"></div>
    </React.Fragment>
  );

  const AIRBASE_FORM = () => (
    <div className="request-form-container w-full relative">
      <span className="heading"> Airbase </span>
      <div className="border-primary-blue w3-round p-2">
        {AIRBASE_DETAILS()}
        {FORM_FOOTER()}
      </div>
    </div>
  );
  return (
    <div id='Airbase-Form'>
      {isLoading
        ? LOADER()
        : AIRBASE_FORM()
      }
    </div>
  );
}

export default AirbaseForm;
