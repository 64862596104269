// Packages
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { Store } from 'react-notifications-component';
import dayjs from 'dayjs';

// APIs
import {  FilteringListRequest, AircraftListRequest, AirbaseListRequest } from '../../../requests';

// Utils
import { constants, decodeString, authenticationErrorHandle } from '../../../utils';
import { airbasesCache, aircraftsCache, auth, loggedUserInfoCache } from '../../../atoms';
import { TableLite, ChevronRight } from '../../../components';
import { CustomFilters } from './components';

const InitialFilters = {
  to: "",
  from: "",
  to_range_one: "",
  to_range_two: "",
  from_range_one: "",
  from_range_two: "",
  activeTab: "requests",
  tabInView: "requests",
  airportType: "AND",
  dateType: "AND",
  aircraft_id: "",
  fsr_status: "",
  is_local: "",
  airbase: "",
};

function FilteredList() {
  const [isLoading, _isLoading] = useState(false);
  const [isSubmitting, _isSubmitting] = useState(false);
  const [authState, _authState] = useAtom(auth);
  const [showFilters, _showFilters] = useState(false);
  const [loggedUserInfo, _loggedUserInfo] = useAtom(loggedUserInfoCache);
  const [filters, _filters] = useState({ ...InitialFilters });
  const [filterErrors, _filterErrors] = useState({});
  const [aircrafts, _aircrafts] = useAtom(aircraftsCache);
  const [airbases, _airbases] = useAtom(airbasesCache);
  const [data, _data] = useState([]);
  const [pagination, _pagination] = useState({
    paginationAvailable: 0,
    currentPage: 1,
    totalPages: 1
  });
  const Navigate = useNavigate();

  const CustomFSRHeaders = {
    "id": "Request Id",
    "flight_number": "Flight No",
    "purpose_of_flight": "Request Type",
    "createdat": "Date of Request",
    "flight_log": "Flight Log",
  };

  const CustomBlockHourHeaders = {
    "id": "Invoice Id",
    "request_id": "Request Id",
    "createdat": "Created Date"
  };

  const CustomCostInvoiceHeaders = {
    "id": "Invoice Id",
    "request_id": "Request Id",
    "createdat": "Created Date"
  };
  
  // Get aircrafts either from cache or from server
  useEffect(() => {
    if (authState) {
      if (!aircrafts || !aircrafts.created || Date.now() - aircrafts.created >= 1200000) {
        getAircrafts();
      }
      if (
        !airbases || !airbases.created || Date.now() - airbases.created >= 1200000) {
        getAirbases();
      }
    }
  }, [authState]);

  // Clear fsr status & international leg filters when cost plus Or block hour invoice filter is applied
  useEffect(() => {
    if (filters?.tabInView !== 'requests') {
      _filters(old => ({
        ...old,
        fsr_status: "",
        is_local: ""
      }));
    }
  }, [filters?.tabInView]);

  const checkValidFilters = () => {
    let isAllValid = true;
    let to_range_error = false;
    let from_range_error = false;
    const filtersToCheck = JSON.parse(JSON.stringify(filters));
    delete filtersToCheck.activeTab;
    delete filtersToCheck.tabInView;
    delete filtersToCheck.dateType;
    delete filtersToCheck.airportType;
    filtersToCheck.is_local = String(filtersToCheck.is_local) || '';   
    if (Object.values(filtersToCheck).every(filter => !Boolean(parseInt(filter)) && filter?.trim() === '')) {
      isAllValid = false;
      Store.addNotification({ ...constants.NOTIFYTOAST, message: 'Select atleast one filter to apply' });
    }
    if (filtersToCheck?.from_range_one || filtersToCheck?.from_range_two) {
      if (dayjs(filtersToCheck?.from_range_two) < dayjs(filtersToCheck?.from_range_one) ||
        Boolean(filtersToCheck?.from_range_two) != Boolean(filtersToCheck?.from_range_one)
      ) {
        isAllValid = false;
        from_range_error = true;
      }
    }
    if (filtersToCheck?.to_range_one || filtersToCheck?.to_range_two) {
      if (dayjs(filtersToCheck?.to_range_two) < dayjs(filtersToCheck?.to_range_one) ||
        Boolean(filtersToCheck?.to_range_two) != Boolean(filtersToCheck?.to_range_one)
      ) {
        isAllValid = false;
        to_range_error = true;
      }
    }
    _filterErrors({
      from_range_error,
      to_range_error
    });
    return isAllValid
  };

  const getFilteredList = () => {
    const token = decodeString(authState);
    const filtersToSubmit = JSON.parse(JSON.stringify(filters));
    filtersToSubmit.activeTab = filtersToSubmit.tabInView;
    _isLoading(true);
    FilteringListRequest(token, JSON.stringify(filtersToSubmit)).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {        
        const modifiedData = data.map(item => ({
          ...item,
          createdat: dayjs(item?.createdat).format('YYYY-MM-DD HH:mm'),
          flight_log: item?.status || 'pending'
        }));
        _data(modifiedData);
        _filters({ ...filtersToSubmit });
        _isLoading(false);
      } else {
        throw 'Request Failed';
      }
    }
    ).catch(
      err => {
        _isLoading(false);
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch filtered list' });
      }
    );
  }

  const getAircrafts = () => {
    const token = decodeString(authState);
    _isLoading(true);
    AircraftListRequest(token).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data && data.results) {
        // Keep server data in cache with current time
        _aircrafts({
          data: [...data.results],
          created: Date.now()
        });
        _isLoading(false);
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch aircrafts' });
        }
      )
  };

  const getAirbases = () => {
    const token = decodeString(authState);
    AirbaseListRequest(token)
      .then((res) => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else return res.json();
      })
      .then((nonPaginatedData) => {
        if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
        if (nonPaginatedData) {
          // Keep server data in cache with current time
          _airbases({
            data: [...nonPaginatedData],
            created: Date.now(),
          });
        } else {
          throw "Request Failed";
        }
      })
      .catch((err) => {
        console.error(err);
        Store.addNotification({
          ...constants.ERRORTOAST,
          message: "Failed to fetch airbases",
        });
      });
  };

  // *********** Handlers ***********

  const handleResetFitlers = () => {
    _data([]);
    _filters({ ...InitialFilters });
  };

  const handleToggleFilterShow = () => {
    _showFilters(old => !old);
  };

  const handleChangeFilters = (name, value) => {    
    _filters(old => ({
      ...old,
      [name]: value
    }));
  };

  const handleRemoveAirport = (name, value) => {
    if(value?.trim() === ''){
      _filters(old => ({
        ...old,
        [name]: value
      }));
    }
  };

  const handleFormatDateFilter = (name, value) => {
    const isValidDate = dayjs(value)?.format('YYYY-MM-DD');
    const formattedDate = isValidDate !== 'Invalid Date' ? value : '';
    // const formattedDate = isValidDate !== 'Invalid Date' ? dayjs(`${value} 00:00`) : '';
    // const formattedDate = isValidDate !== 'Invalid Date' ? dayjs(`${value} 00:00`) : '';
    _filters(old => ({
      ...old, 
      [name]: formattedDate
    }));
  };

  const handleApplyFilters = () => {
    if (checkValidFilters())
      getFilteredList();
  }

  const handleRouteToViewScreen = (e, item) => {
    if (filters?.activeTab === 'requests')
      window.open(`/requests/view/${item?.id}`, '_blank');
    else if (filters?.activeTab === 'block-hour-invoice')
      window.open(`/block-hour-invoices/view/${item?.id}`, '_blank');
    else
      window.open(`/costplus-invoices/view/${item?.id}`, '_blank');
  }

  // *********** Render Functions ***********

  const LIST_FILTERS = () => (
    <div className="w3-margin-bottom w3-filtered-area">
      <div
        onClick={handleToggleFilterShow}
        className={`${showFilters ? "active" : "not-acitve"} flex items-center p-2 cursor-pointer w3-light w3-large justify-between`}
      >
        <div className='no-select'> Filters </div>
        <div className='chevron-holder'>
          <ChevronRight />
        </div>
      </div>
      <CustomFilters
        filters={filters}
        showFilters={showFilters}
        filterErrors={filterErrors}
        aircrafts={aircrafts?.data || []}
        airbases={airbases?.data || []}
        handleApplyFilters={handleApplyFilters}
        handleResetFitlers={handleResetFitlers}
        handleRemoveAirport={handleRemoveAirport}
        handleChangeFilters={handleChangeFilters}
        handleFormatDateFilter={handleFormatDateFilter}
      />
    </div>
  );

  const CONTENT = () => {
    let headers = filters?.activeTab === 'requests'
      ? Object.keys(CustomFSRHeaders)
      : filters?.activeTab === 'block-hour-invoice'
        ? Object.keys(CustomBlockHourHeaders)
        : Object.keys(CustomCostInvoiceHeaders);

    let customHeaders = filters?.activeTab === 'requests'
      ? CustomFSRHeaders
      : filters?.activeTab === 'block-hour-invoice'
        ? CustomBlockHourHeaders
        : CustomCostInvoiceHeaders;

    const customRenderCell = filters?.activeTab !== 'requests'
      ? { createdat: (row) => dayjs(row?.createdat)?.format('YYYY-MM-DD') }
      : {};

    return (
      <div className='page-content w3-white h-full relative overflow-hidden'>
        <div className='py-2'>
          {LIST_FILTERS()}
          <div className='list-view-container overflow-auto'>
            <TableLite
              data={data}
              csvKeys = {Object.keys(customHeaders)} 
              headers={headers}
              customHeaders={customHeaders}
              downloadable={false}
              showActions={true}
              actionTypes={['view']}
              onRowView={handleRouteToViewScreen}
              customRenderCell={customRenderCell}
              cellStyle={{ fontSize: '0.8em' }}
              noDataMessage={isLoading ? 'Loading data...' : 'No data found'}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {CONTENT()}
    </div>
  )
}

export default FilteredList;