// Packages
import React from 'react';


// Utils
import { HotelsReportTable } from './components';

function HotelsReportView() {

  // *********** Render Functions ***********

  const HOTELS_REPORT_TABLE = () => (
    <div className="w-full">
      <HotelsReportTable />
    </div>
  );

  const CONTENT = () => (
    <div className='page-content w3-white h-full relative overflow-hidden'>
      <div className='py-2'>
        {HOTELS_REPORT_TABLE()}
      </div>
    </div>
  );

  return (
    <div>
      {CONTENT()}
    </div>
  )
}

export default HotelsReportView;