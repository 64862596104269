// Packages
import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Store } from 'react-notifications-component';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';

// APIs
import { AirbaseListRequest, FleetCreateRequest, FleetDetailsRequest, FleetEditRequest, FleetListRequest } from '../../../requests';

// Utils
import { auth, airbasesCache, fleetCache, loggedUserInfoCache } from '../../../atoms';
import { constants, decodeString, authenticationErrorHandle, validateSubmissionData } from '../../../utils';
import { Loader } from '../../../components';

function FleetForm() {
  const { fleetId } = useParams();
  const Navigate = useNavigate();

  const INITIAL_FORM = {
    name: '',
    airbase: '',
    description: '',
    company: '',
    block_hours_rate: ''
  };

  const VALIDATIONS = {
    name: { isRequired: true },
    airbase: { isRequired: true, isNumber: true },
    description: { isRequired: true }
  };

  const [isSubmitting, _isSubmitting] = useState(false);
  const [isLoading, _isLoading] = useState(fleetId ? true : false);
  const [form, _form] = useState({ ...INITIAL_FORM });
  const [formErrors, _formErrors] = useState({});
  const [authState, _authState] = useAtom(auth);
  const [loggedUserInfo] = useAtom(loggedUserInfoCache);
  const [fleet, _fleet] = useAtom(fleetCache);
  const [airbases, _airbases] = useAtom(airbasesCache);

  useEffect(() => {
    if (fleetId && authState && airbases?.data) {
      getFleetDetails();
    }
  }, [authState, fleetId, airbases]);

  // Get airbases either from cache or from server
  useEffect(() => {
    if (authState) {
      if (!airbases || !airbases.created || Date.now() - airbases.created >= 1200000) {
        getAirbases();
      }
    }
  }, [authState, form?.id]);

  // Set company from logged user Info for create case
  useEffect(() => {
    if (!fleetId && loggedUserInfo && loggedUserInfo?.data) {
      setCompany();
    }
  }, [loggedUserInfo?.data, form?.id]);

  const getAirbases = () => {
    const token = decodeString(authState);
    AirbaseListRequest(token).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(nonPaginatedData => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in cache with current time
        _airbases({
          data: [...nonPaginatedData],
          created: Date.now()
        });
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch airbases' });
        }
      )
  };

  const getFleet = () => {
    const token = decodeString(authState);
    FleetListRequest(token)
      .then((res) => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else return res.json();
      })
      .then((data) => {
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if (data && data.results) {
          // Keep server data in cache with current time
          _fleet({
            data: [...data.results],
            created: Date.now(),
          });
        } else {
          throw 'Request Failed';
        }
      })
      .catch((err) => {
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch fleet' });
      });
  };

  const getFleetDetails = () => {
    _isLoading(true);
    const token = decodeString(authState);
    FleetDetailsRequest(token, fleetId).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        _isLoading(false);
        _form({
          ...data,          
        });
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch fleet details' });
        }
      )
  };

  const setCompany = () => {
    _form(old => ({
      ...old,
      company: loggedUserInfo?.data?.company
    }))
  };

  // *********** Handlers ***********

  const handleChangeAirbase = (name, value) => {
    // If airbase is changed set company value according to airbase company
    let company = 1;
    if (airbases && airbases.data) {
      const selectedAirbase = airbases?.data.find(A => A.id == value);
      company = selectedAirbase?.company;
    }
    _form(old => ({
      ...old,
      [name]: value,
      company,
    }));
  };

  const handleFleetFieldChange = (name, value) => {
    _form(old => ({
      ...old,
      [name]: value
    }));
  };

  const handleCheckValidation = () => {
    const { allValid, errors } = validateSubmissionData(form, VALIDATIONS);
    if (!allValid) {
      _formErrors(errors);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Required fields have errors" });
    } else {
      _formErrors({});
    }
    return allValid;
  };

  const handleSubmit = () => {
    const token = decodeString(authState);
    const data = JSON.parse(JSON.stringify(form));
    const allValid = handleCheckValidation();
    if (!allValid) return;
    _isSubmitting(true);
    if (fleetId) {
      FleetEditRequest(token, JSON.stringify(data), fleetId)
        .then(res => {
          if (res && res?.status === 401) {
            authenticationErrorHandle(() => _authState('0'));
            return (
              { errorCodes: constants.SESSIONTIMEOUT }
            );
          } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
            throw 'Request Failed';
          } else return (res.json())
        })
        .then(data => {
          if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
          if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
            throw 'Request Failed';
          } else {
            getFleet();
            _isSubmitting(false);
            Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Fleet updated' });
            Navigate("/fleets");
          }
        })
        .catch(err => {
          _isSubmitting(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
        });
    } else {
      FleetCreateRequest(token, JSON.stringify(data))
        .then(res => {
          if (res && res?.status === 401) {
            authenticationErrorHandle(() => _authState('0'));
            return (
              { errorCodes: constants.SESSIONTIMEOUT }
            );
          } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
            throw 'Request Failed';
          } else return (res.json())
        })
        .then(data => {
          if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
          if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
            throw 'Request Failed';
          } else {
            getFleet();
            _isSubmitting(false);
            Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Fleet Registered' });
            Navigate("/fleets");
          }
        })
        .catch(err => {
          _isSubmitting(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
        });
    }
  };


  // *********** Render Functions ***********

  const FLEET_DETAILS = () => (
    <React.Fragment>
      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <label> Name </label>
          <input
            type='text'
            value={form?.name}
            onChange={e => handleFleetFieldChange('name', e.target.value)}
            className={`w3-input w3-border w3-round small-top-margin small-bottom-margin ${formErrors?.name ? 'error-field' : ''}`}
          />
        </div>
        <div className='w3-col l6 m6 s12'>
          <label> Airbase </label>
          <Select
            placeholder='Search Airbase'
            options={airbases && airbases?.data?.map(A => (
              { value: A.id, label: A.name })
            )}
            value={form?.airbase ? {
              value: form?.airbase,
              label: airbases && airbases?.data?.find(A => A.id == form?.airbase)?.name
            } : null}
            onChange={A => handleChangeAirbase('airbase', A.value)}
            className={`w-full small-top-margin small-bottom-margin ${formErrors?.airbase ? 'error-field' : ''}`}
          />
        </div>
      </div>
      <div className='w3-row-padding'>
        <div className='w3-col l12 m12 s12'>
          <label> Block Hours Rate </label>
          <input
            type='number'
            min={0}
            value={Number(form?.block_hours_rate)?.toFixed(2) || 0}
            onChange={e => handleFleetFieldChange('block_hours_rate', e.target.value)}
            className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
          />
        </div>
      </div>
      <div className='w3-row-padding'>
        <div className='w3-col l12 m12 s12'>
          <label> Description </label>
          <input
            type='text'
            value={form?.description}
            onChange={e => handleFleetFieldChange('description', e.target.value)}
            className={`w3-input w3-border w3-round small-top-margin small-bottom-margin ${formErrors?.description ? 'error-field' : ''}`}
          />
        </div>
      </div>

    </React.Fragment>
  );

  const LOADER = () => (
    <div className="request-form-container">
      <div className="h-30 flex justify-center items-center">
        <div><Loader spinnerClassName='w-10 h-10 text-primary-blue' />
          <p className='text-primary-blue'> Loading data... </p>
        </div>
      </div>
    </div>
  );

  const FORM_FOOTER = () => (
    <React.Fragment>
      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <button
            disabled={isSubmitting}
            onClick={handleSubmit}
            className='w3-btn bg-primary-blue w3-text-white small-top-margin'
          >
            {isSubmitting ? 'Submitting' : 'Submit'}
          </button>
        </div>
      </div>
      <div className='h-2'></div>
    </React.Fragment>
  );

  const FLEET_FORM = () => (
    <div className='request-form-container w-full relative'>
      <span className='heading'> Fleet </span>
      <div className='border-primary-blue w3-round p-2'>
        {FLEET_DETAILS()}
        {FORM_FOOTER()}
      </div>
    </div>
  )
  return (
    <div id='Fleet-Form'>
      {isLoading
        ? LOADER()
        : FLEET_FORM()
      }
    </div>
  )
}

export default FleetForm;