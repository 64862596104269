import { constants } from "../utils";

const AirbaseEditRequest = (token, data, id) => {
  var requestHeaders = new Headers();
  requestHeaders.append("Authorization", `Bearer ${token}`);
  requestHeaders.append("Content-Type", "application/json");
  return fetch(`${constants.BASEURL}/api/airbase/${id}/`, {
    method: "PUT",
    body: data,
    headers: requestHeaders,
    redirect: "follow",
  });
};

export default AirbaseEditRequest;
