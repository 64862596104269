
// Packages
import React from 'react';
import { useRoutes } from 'react-router-dom';

// Utils
import Login from './login';
import VerifyAndReset from './verify-and-reset';

function UnAuthenticatedRoutes() {
    const Routes = [
        { path: '/verify', element: <VerifyAndReset /> },        
        { path: '*', element: <Login /> }
    ];

    return (useRoutes(Routes))
}

export default UnAuthenticatedRoutes;