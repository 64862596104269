import { constants } from "../utils";

const InsuranceRequestCompleteRequest = (token, data, id) => {
    var requestHeaders = new Headers();
    requestHeaders.append("Authorization", `Bearer ${token}`);
    requestHeaders.append("Content-Type", "application/json");
    return (fetch(`${constants.BASEURL}/api/medicalinsurance/approve_medical_insurance_request_update_status/${id}/`, {
        method: 'POST',
        body: data,
        headers: requestHeaders,
        redirect: 'follow'
    }));
};

export default InsuranceRequestCompleteRequest;
