import { constants } from "../utils";

const FsrCompleteRequest = (token, data, id) => {
    var requestHeaders = new Headers();
    requestHeaders.append("Authorization", `Bearer ${token}`);
    requestHeaders.append("Content-Type", "application/json");
    return (fetch(`${constants.BASEURL}/api/blockhourinvoice/${id}/generate_blockhour_on_complete/`, {
        method: 'POST',
        body: data,
        headers: requestHeaders,
        redirect: 'follow'
    }));
};

export default FsrCompleteRequest;
