import React from 'react'

export default function Plane(props) {
    return (
        <svg {...props} fill="currentcolor" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 600.972 600.973" xmlSpace="preserve">
        <g>
            <g>
                <path d="M580.156,20.803c-7.381-7.381-16.337-12.859-26.622-16.282c-8.008-2.666-16.706-4.017-25.854-4.019
        c-13.819-0.001-28.627,3.096-42.822,8.955c-13.961,5.762-26.268,13.736-35.591,23.06l-75.941,75.941l-246.31-70.646
        c-5.24-1.502-10.658-2.264-16.106-2.264c-7.657,0-15.131,1.476-22.214,4.387c-7.183,2.952-13.61,7.234-19.102,12.727L30.06,92.196
        c-6.533,6.533-11.479,14.615-14.301,23.371c-2.679,8.313-3.461,17.291-2.259,25.962c1.201,8.671,4.395,17.098,9.233,24.369
        c5.098,7.659,12.054,14.091,20.118,18.602l163.155,91.266l-47.055,47.055l-77.893-14.873c-3.608-0.689-7.293-1.037-10.958-1.037
        c-7.674,0-15.164,1.482-22.261,4.406c-7.164,2.951-13.574,7.227-19.053,12.707l-11.18,11.18
        c-6.531,6.531-11.476,14.611-14.299,23.367c-2.679,8.312-3.461,17.287-2.261,25.959c1.201,8.67,4.392,17.096,9.229,24.367
        c5.095,7.658,12.049,14.092,20.11,18.604l91.731,51.338l51.345,91.738c10.319,18.439,29.864,29.893,51.007,29.893
        c15.589,0,30.252-6.076,41.289-17.111l11.182-11.182c13.698-13.699,19.708-33.238,16.077-52.268l-14.869-77.889l47.055-47.057
        l91.267,163.156c10.317,18.445,29.864,29.904,51.014,29.904c15.589,0,30.253-6.078,41.289-17.113l39.534-39.535
        c15.012-15.012,20.701-37.016,14.848-57.422l-70.645-246.309l75.941-75.942c13.888-13.887,24.944-34.971,29.577-56.396
        c2.654-12.271,3.147-24.088,1.466-35.123C597.139,44.716,590.452,31.098,580.156,20.803z M538.161,121.412l-87.295,87.294
        c-4.006,4.006-5.522,9.871-3.961,15.317l75.072,261.744c1.562,5.445,0.046,11.311-3.961,15.316l-39.534,39.535
        c-3.087,3.088-7.058,4.566-10.997,4.566c-5.386,0-10.711-2.768-13.625-7.979L345.006,342.612
        c-2.915-5.211-8.238-7.979-13.625-7.979c-3.938,0-7.911,1.48-10.997,4.566l-82.733,82.732c-3.65,3.65-5.256,8.871-4.288,13.941
        l17.574,92.066c0.968,5.07-0.638,10.293-4.288,13.943l-11.181,11.18c-3.087,3.088-7.058,4.566-10.997,4.566
        c-5.385,0-10.709-2.766-13.624-7.975l-55.104-98.451c-1.405-2.512-3.478-4.584-5.988-5.99l-98.446-55.096
        c-9.018-5.047-10.716-17.314-3.409-24.621l11.18-11.182c2.95-2.949,6.926-4.564,11.021-4.564c0.972,0,1.95,0.092,2.923,0.277
        l92.07,17.58c0.973,0.186,1.951,0.275,2.923,0.275c4.093,0,8.071-1.615,11.021-4.564l82.733-82.733
        c7.308-7.308,5.608-19.576-3.412-24.622L63.764,147.111c-9.02-5.045-10.72-17.314-3.412-24.622l39.535-39.535
        c2.964-2.964,6.945-4.565,11.022-4.565c1.433,0,2.879,0.198,4.295,0.604l261.743,75.072c1.417,0.406,2.862,0.604,4.296,0.604
        c4.076,0,8.058-1.601,11.021-4.565l87.294-87.295c10.717-10.717,30.961-19.468,48.118-19.467
        c8.761,0.001,16.72,2.284,22.188,7.752C566.058,67.29,554.355,105.217,538.161,121.412z" />
                <path d="M224.471,600.97c-21.324,0-41.036-11.552-51.443-30.148l-51.276-91.615l-91.608-51.269
        C22.013,423.387,15,416.898,9.86,409.175c-4.878-7.333-8.097-15.831-9.308-24.576c-1.211-8.743-0.422-17.797,2.28-26.181
        c2.847-8.83,7.833-16.979,14.421-23.567l11.18-11.18c5.526-5.527,11.991-9.84,19.216-12.815
        c7.157-2.949,14.711-4.444,22.451-4.444c3.702,0,7.42,0.352,11.052,1.046l77.633,14.823l46.4-46.4L42.607,184.937
        c-8.133-4.549-15.149-11.037-20.29-18.762c-4.88-7.334-8.101-15.833-9.312-24.577c-1.212-8.745-0.425-17.799,2.278-26.185
        c2.847-8.831,7.834-16.982,14.423-23.571L69.239,52.31c5.539-5.54,12.021-9.858,19.265-12.836
        c7.144-2.936,14.682-4.425,22.404-4.425c5.495,0,10.96,0.769,16.244,2.284l246.026,70.564l75.733-75.732
        c9.369-9.37,21.732-17.381,35.754-23.168c14.253-5.883,29.124-8.993,43.006-8.993c9.212,0.001,17.964,1.362,26.02,4.044
        c10.358,3.448,19.381,8.966,26.817,16.403c10.371,10.371,17.107,24.084,19.48,39.659c1.691,11.095,1.196,22.973-1.472,35.304
        c-4.652,21.517-15.76,42.693-29.712,56.645l-75.733,75.734l70.563,246.025c5.904,20.581,0.166,42.772-14.975,57.913
        l-39.534,39.535c-11.131,11.13-25.92,17.26-41.643,17.26c-21.33,0-41.044-11.557-51.45-30.16l-90.943-162.579l-46.401,46.401
        l14.819,77.629c3.663,19.191-2.398,38.898-16.214,52.715l-11.182,11.182C254.983,594.841,240.194,600.97,224.471,600.97z
         M70.101,307.411c-7.609,0-15.035,1.47-22.07,4.368c-7.102,2.926-13.457,7.165-18.89,12.599l-11.18,11.18
        c-6.476,6.477-11.378,14.487-14.176,23.167c-2.657,8.242-3.432,17.142-2.242,25.737c1.191,8.597,4.355,16.95,9.151,24.158
        c5.051,7.593,11.946,13.971,19.938,18.444l91.854,51.407l0.069,0.123l51.345,91.738c10.23,18.28,29.608,29.637,50.57,29.637
        c15.457,0,29.994-6.024,40.935-16.965l11.182-11.182c13.581-13.582,19.54-32.954,15.939-51.82l-14.918-78.148l0.187-0.188
        l47.523-47.524l0.322,0.577l91.267,163.156c10.229,18.288,29.609,29.648,50.577,29.648c15.456,0,29.993-6.025,40.936-16.967
        l39.534-39.535c14.884-14.884,20.524-36.698,14.721-56.931L491.95,227.5l76.149-76.15c13.822-13.822,24.828-34.812,29.442-56.148
        c2.641-12.212,3.132-23.968,1.46-34.942c-2.341-15.362-8.979-28.884-19.199-39.103c-7.325-7.326-16.217-12.763-26.427-16.161
        c-7.954-2.648-16.6-3.991-25.696-3.993c-13.759,0-28.498,3.083-42.632,8.917c-13.9,5.737-26.15,13.674-35.428,22.951
        l-76.149,76.149l-0.283-0.082l-246.31-70.646c-5.195-1.489-10.567-2.245-15.969-2.245c-7.591,0-15.001,1.463-22.024,4.35
        c-7.122,2.927-13.493,7.172-18.938,12.618L30.414,92.549c-6.478,6.478-11.38,14.49-14.178,23.171
        c-2.657,8.243-3.431,17.144-2.24,25.74c1.191,8.596,4.356,16.951,9.154,24.161c5.054,7.593,11.951,13.97,19.946,18.442
        l163.732,91.589l-47.709,47.709l-78.153-14.923C77.395,307.757,73.739,307.411,70.101,307.411z M224.47,558.13
        c-5.832,0-11.219-3.153-14.06-8.23l-55.104-98.451c-1.36-2.431-3.364-4.436-5.796-5.798l-98.446-55.096
        c-4.437-2.483-7.38-6.795-8.078-11.831s0.965-9.985,4.56-13.58l11.18-11.182c3.039-3.038,7.078-4.711,11.374-4.711
        c1.006,0,2.021,0.097,3.017,0.286l92.07,17.58c0.928,0.177,1.879,0.267,2.829,0.267c3.971,0,7.859-1.61,10.667-4.418
        l82.733-82.733c3.371-3.371,4.93-8.013,4.275-12.736s-3.417-8.767-7.578-11.095L63.52,147.547
        c-4.438-2.482-7.382-6.794-8.08-11.831c-0.698-5.036,0.964-9.986,4.559-13.581l39.535-39.535
        c3.039-3.039,7.078-4.712,11.375-4.712c1.5,0,2.991,0.21,4.433,0.624l261.743,75.072c1.354,0.388,2.752,0.584,4.158,0.584
        c4.03,0,7.818-1.569,10.668-4.418l87.294-87.295c11.364-11.365,31.749-19.613,48.469-19.613
        c9.584,0.001,17.379,2.733,22.544,7.898c16.372,16.373,4.631,54.69-11.703,71.024l-87.295,87.294
        c-3.876,3.876-5.345,9.557-3.834,14.826l75.072,261.744c1.612,5.618,0.046,11.675-4.088,15.808l-39.534,39.535
        c-3.038,3.039-7.069,4.713-11.351,4.713c-5.833,0-11.221-3.155-14.062-8.234L344.57,342.856
        c-2.665-4.764-7.718-7.723-13.188-7.723c-4.014,0-7.793,1.569-10.644,4.42l-82.733,82.732c-3.537,3.537-5.089,8.582-4.15,13.494
        l17.574,92.066c1,5.239-0.654,10.619-4.426,14.391l-11.181,11.18C232.783,556.456,228.751,558.13,224.47,558.13z M70.101,350.251
        c-4.029,0-7.817,1.569-10.667,4.418l-11.18,11.182c-3.371,3.371-4.93,8.013-4.276,12.735c0.654,4.724,3.415,8.768,7.575,11.096
        l98.446,55.096c2.593,1.453,4.73,3.591,6.181,6.183l55.104,98.451c2.664,4.761,7.717,7.719,13.187,7.719
        c4.014,0,7.794-1.569,10.644-4.42l11.181-11.18c3.537-3.537,5.089-8.583,4.15-13.496l-17.574-92.066
        c-1-5.238,0.654-10.617,4.426-14.389l82.733-82.732c3.039-3.039,7.07-4.713,11.351-4.713c5.832,0,11.22,3.155,14.062,8.234
        l108.854,194.594c2.664,4.764,7.717,7.723,13.188,7.723c4.015,0,7.794-1.569,10.644-4.42l39.534-39.535
        c3.877-3.876,5.346-9.557,3.834-14.825l-75.072-261.744c-1.611-5.618-0.045-11.675,4.088-15.808l87.295-87.294
        c16.055-16.055,27.685-53.627,11.703-69.61c-4.974-4.974-12.523-7.604-21.834-7.605c-16.217,0-36.75,8.306-47.765,19.32
        l-87.294,87.295c-3.038,3.038-7.078,4.711-11.375,4.711c-1.499,0-2.991-0.21-4.434-0.624L115.066,79.473
        c-1.353-0.388-2.751-0.584-4.158-0.584c-4.03,0-7.819,1.569-10.668,4.419l-39.535,39.535c-3.372,3.371-4.93,8.014-4.276,12.737
        c0.655,4.724,3.417,8.768,7.578,11.095l194.594,108.854c4.438,2.482,7.382,6.795,8.08,11.831s-0.964,9.986-4.559,13.581
        l-82.733,82.733c-2.994,2.994-7.14,4.711-11.374,4.711c-1.012,0-2.027-0.096-3.017-0.284l-92.07-17.58
        C71.996,350.341,71.044,350.251,70.101,350.251z" />
            </g>
        </g>
    </svg>
    )
}
