import { constants } from "../utils";

const AirbaseDetailsRequest = (token, aircraftId) => {
  var requestHeaders = new Headers();
  requestHeaders.append("Authorization", `Bearer ${token}`);
  requestHeaders.append("Content-Type", "application/json");
  return fetch(`${constants.BASEURL}/api/airbase/${aircraftId}`, {
    method: "GET",
    headers: requestHeaders,
    redirect: "follow",
  });
};

export default AirbaseDetailsRequest;
