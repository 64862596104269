// Packages
import React, { Fragment, useEffect, useState } from 'react';
import { Store } from 'react-notifications-component';
import Select from 'react-select';
import { useAtom } from 'jotai';

// APIs
import { FsrSearchRequest, ServiceListRequest } from '../../../../requests';

// Utils
import { authenticationErrorHandle, constants, decodeString } from '../../../../utils';
import { auth } from '../../../../atoms';
import { Plus } from '../../../../components';
import CostListItemModal from './CostListItemModal';

export default function TypeFsrInvoice({
    form,
    contracts,
    aircrafts,
    parentRequest,
    handleSelectFsr,
    handleFieldChange,
    handleSetAircraft,
    handleSaveCostListItem,
    handleCostListItemFieldChange
}) {

    const [fsrList, _fsrList] = useState([]);
    const [services, _services] = useState([]);
    const [authState, _authState] = useAtom(auth);
    const [searchFSRString, _searchFSRString] = useState('');
    const [showCostListItemModal, _showCostListItemModal] = useState(false);

    // Debounce fsr request seacrch
    useEffect(() => {
        const getFSRData = setTimeout(() => {
            if (searchFSRString && searchFSRString.trim() !== '') {
                getFsrList();
            }
        }, 800);
        return () => clearTimeout(getFSRData);
    }, [searchFSRString]);

    // Fetch services
    useEffect(() => {
        if (authState) {
            getServices()
        }
    }, [authState]);

    const getFsrList = () => {
        const token = decodeString(authState);
        FsrSearchRequest(token, searchFSRString).then(res => {
            if (res && res?.status === 401) {
                authenticationErrorHandle(() => _authState('0'));
                return (
                    { errorCodes: constants.SESSIONTIMEOUT }
                );
            } else return (res.json())
        }).then(data => {
            if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
            if (data) {
                _fsrList({
                    data
                });
            } else {
                throw 'Request Failed';
            }
        }
        )
            .catch(
                err => {
                    console.error(err);
                    Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch FSR list' });
                }
            )
    };

    const getServices = () => {
        const token = decodeString(authState);
        ServiceListRequest(token).then(res => {
            if (res && res?.status === 401) {
                authenticationErrorHandle(() => _authState('0'));
                return (
                    { errorCodes: constants.SESSIONTIMEOUT }
                );
            } else return (res.json())
        }).then(data => {
            if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
            if (data && data.results) {
                // Keep server data in cache with current time
                _services({
                    data: [...data.results],
                    created: Date.now()
                });
            } else {
                throw 'Request Failed';
            }
        }
        )
            .catch(
                err => {
                    console.error(err);
                    Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch services' });
                }
            )
    };

    /******* handlers *******/

    const handleSearchString = (search) => {
        _searchFSRString(search);
    };

    const handleOpenCostListItemModal = () => {
        _showCostListItemModal(true);
    };

    const handleCloseCostListItemModal = () => {
        _showCostListItemModal(false);
    };

    /******* Render Functions *******/

    const ADD_COST_LIST_ITEM_BUTTON = () => (
        <div className='w3-row-padding small-top-margin'>
            <div className='w3-col s12 m12 l12'>
                <label className='no-opacity w3-medium'> Aircraft </label>
                <button
                    className="flex items-center w3-btn bg-primary-blue w3-text-white"
                    onClick={handleOpenCostListItemModal}
                >
                    <Plus className="w-5 h-5" />{" "}
                    <span className="px-1"> Add Cost List Item </span>
                </button>
            </div>
        </div>
    );

    const COST_LIST_ITEM_MODAL = () => (
        <CostListItemModal
            form={form}
            services={services}
            allowedRoles={form?.allowed_roles || []}
            legs={parentRequest?.requestleg_requestLeg}
            showCostListItemModal={showCostListItemModal}
            handleSaveCostListItem={handleSaveCostListItem}
            handleCloseCostListItemModal={handleCloseCostListItemModal}
        />
    );

    return (
        <Fragment>
            <div className="w3-col l6 m6 s12">
                <label> Request Id </label>
                <Select
                    placeholder='Search request by id or flight no.'
                    onInputChange={handleSearchString}
                    onChange={obj => handleSelectFsr(obj.value)}
                    value={form?.request_id
                        ? {
                            label: fsrList?.data?.find(fsr => fsr?.id == form?.request_id)?.flight_number,
                            value: form?.request_id
                        }
                        : null}
                    options={fsrList && fsrList?.data?.map(Fsr => ({
                        label: `${Fsr?.flight_number} (FSR-${Fsr?.id})`,
                        value: {
                            request_id: Fsr?.id,
                            fleet: Fsr?.fleet,
                            aircraft_id: Fsr?.aircraft,
                            contract_id: Fsr?.contract,
                            aircraft_tailnumber: Fsr?.aircraft_tailnumber
                        }
                    }))}
                    className={`${form?.errors?.request_id ? 'error-field' : ''} w-full small-top-margin small-bottom-margin`}
                />
            </div>
            <div className='w3-col l6 m6 s12'>
                <label className='w3-medium'> Contract </label>
                <select
                    name='contract_id'
                    value={form?.contract_id}
                    onChange={handleFieldChange}
                    className='w3-input w3-white w3-border w3-round small-top-margin small-bottom-margin'
                >
                    <option value={''} disabled> Select Contract </option>
                    {contracts?.data?.map(C => (
                        <option key={C?.id} value={C?.id}> {C?.name} </option>
                    ))}
                </select>
            </div>
            <div className='w3-col l6 m6 s12'>
                <label className='w3-medium'> Aircraft </label>
                <select
                    name='aircraft_id'
                    value={form?.aircraft_id}
                    onChange={handleSetAircraft}
                    className='w3-input w3-white w3-border w3-round small-top-margin small-bottom-margin'
                >
                    <option value={''} disabled> Select Aircraft </option>
                    {aircrafts?.data?.map(A => (
                        <option key={A?.id} value={A?.id}> {A?.tailnumber} </option>
                    ))}
                </select>
            </div>
            {ADD_COST_LIST_ITEM_BUTTON()}
            {showCostListItemModal && COST_LIST_ITEM_MODAL()}
            <div className='w3-col s12'>
                {parentRequest?.requestleg_requestLeg?.map((L, index) => (
                    <div key={index}>
                        <div className='table-heading'> {`${L?.departure_airport_icao}/${L?.departure_airport_iata} → ${L?.destination_airport_icao}/${L?.destination_airport_iata}`} </div>
                        <table>
                            <thead>
                                <tr>
                                    <th><div style={{ minWidth: '400px' }}> Service </div></th>
                                    <th><div style={{ minWidth: '180px' }}> Quantity </div></th>
                                    <th><div style={{ minWidth: '180px' }}> Unit Cost </div></th>
                                    <th><div style={{ minWidth: '180px' }}> Total Cost </div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {form?.cost_plus_invoice_item
                                    ?.find(C => C?.request_leg === L?.id)?.services
                                    ?.map((S, index) => (
                                        <tr key={index}>
                                            <td>
                                                <div style={{ minWidth: '400px' }}>{S?.service_name}</div>
                                            </td>
                                            <td>
                                                <input
                                                    name='quantity'
                                                    value={S?.quantity}
                                                    style={{ width: '180px' }}
                                                    className='w3-input w3-border w3-round w-fit'
                                                    onChange={e => handleCostListItemFieldChange(e, S)}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    name='unit_cost'
                                                    value={S?.unit_cost}
                                                    style={{ width: '180px' }}
                                                    className='w3-input w3-border w3-round w-fit'
                                                    onChange={e => handleCostListItemFieldChange(e, S)}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    name='total_cost'
                                                    value={S?.total_cost}
                                                    style={{ width: '180px' }}
                                                    className='w3-input w3-border w3-round w-fit'
                                                    onChange={e => handleCostListItemFieldChange(e, S)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                ))}
            </div>
        </Fragment>
    )
}
