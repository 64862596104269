// Packages
import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Store } from 'react-notifications-component';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';


// Utils
import { auth, airbasesCache, fleetCache, loggedUserInfoCache } from '../../../atoms';
import { constants, decodeString, authenticationErrorHandle, validateSubmissionData } from '../../../utils';
import { Loader, Refresh } from '../../../components';
import { NewAirportDetailsRequest } from "../../../requests";
import { NewAirportEditRequest, NewAirportCreateRequest } from "../../../requests";


function AirportsForm() {
  const { airportId } = useParams();
  const Navigate = useNavigate();

  const INITIAL_FORM = {
    airport_name: '',
    airport_code_iata: '',
    airport_code_icao: '',
    country: '',
    city: ''
  };

  const VALIDATIONS = {
    airport_name: { isRequired: true },
    airport_code_iata: { isRequired: true },
    airport_code_icao: { isRequired: true },
    country: { isRequired: true },
    city: { isRequired: true }, 
  };

  const [isSubmitting, _isSubmitting] = useState(false);
  const [isLoading, _isLoading] = useState(airportId ? true : false);
  const [formErrors, _formErrors] =  useState({});
  const [form, _form] = useState({ ...INITIAL_FORM });
  const [authState, _authState] = useAtom(auth);
  const [loggedUserInfo] = useAtom(loggedUserInfoCache);
  const [fleet, _fleet] = useAtom(fleetCache);
  const [airbases, _airbases] = useAtom(airbasesCache);
  const [airport, _airport] = useState({});

  // Set company from logged user Info for create case
  useEffect(() => {
    if (authState && airportId) {
      getAirport();
    }
  }, [authState, airportId]);

  const getAirport = () => {
    const token = decodeString(authState);
    _isLoading(true);
    NewAirportDetailsRequest(token, airportId).then(res => {
      // console.log(res)
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(
      data => {
        // console.log('this is data', data)
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if (data) {
          // Keep server data in local state with current time
          _form({
            ...data
          });
          _isLoading(false);
        } else {
          throw 'Request Failed';
        }
      }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch cost plus invoices' });
        }
      )
  };

  // *********** Handlers ***********

  // const handleChangeFleet = (name, value) => {
  //   // If fleet is changed set contract value according to airbase contract in feet
  //   let contract = '';
  //   if (fleet && fleet.data) {
  //     const selectedFleet = fleet?.data.find(F => F.id == value);
  //     const selectedAirbase = airbases?.data.find(A => A.id == selectedFleet?.airbase);
  //     contract = selectedAirbase ? selectedAirbase.contract : null;
  //   }
  //   _form(old => ({
  //     ...old,
  //     [name]: value,
  //     contract,
  //   }));
  // };

  const handleCountryChange = (name, value) => {
    _form(old => ({
      ...old,
      [name]: value
    }));
  };

  const handleFieldChange = (name, e) => {
    _form(old => ({
      ...old,
      [name]: e.target.value
    }));
  };

  const handleCheckValidation = () => {
    const { allValid, errors } = validateSubmissionData(form, VALIDATIONS);
    if (!allValid) {
      _formErrors(errors);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Required fields have errors" });
    } else {
      _formErrors({});
    }
    return allValid;
  };

  const handleSubmit = () => {
    const token = decodeString(authState);
    const data = JSON.parse(JSON.stringify(form));
    const allValid = handleCheckValidation();
    if (!allValid) return;
    _isSubmitting(true);
    if (airportId) {
      NewAirportEditRequest(token, JSON.stringify(data), airportId)
        .then(res => {
          if (res && res?.status === 401) {
            authenticationErrorHandle(() => _authState('0'));
            return (
              { errorCodes: constants.SESSIONTIMEOUT }
            );
          } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
            throw 'Request Failed';
          } else return (res.json())
        })
        .then(data => {
          if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
          if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
            throw 'Request Failed';
          } else {
            _isSubmitting(false);
            Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Aircraft updated' });
            Navigate("/airports");
          }
        })
        .catch(err => {
          _isSubmitting(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
        });
    } else {
      NewAirportCreateRequest(token, JSON.stringify(data))
        .then(res => {
          if (res && res?.status === 401) {
            authenticationErrorHandle(() => _authState('0'));
            return (
              { errorCodes: constants.SESSIONTIMEOUT }
            );
          } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
            throw 'Request Failed';
          } else return (res.json())
        })
        .then(data => {
          if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
          if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
            throw 'Request Failed';
          } else {
            _isSubmitting(false);
            Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Aircraft Registered' });
            Navigate("/airports");
          }
        })
        .catch(err => {
          _isSubmitting(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
        });
    }
  };

  // *********** Render Functions ***********

  const AIRPORT_DETAILS = () => (
    <React.Fragment>
      <div className="w3-row-padding">
        <div className="w3-col l6 m6 s12">
          <label> Name </label>
          <input
            onChange={A => handleFieldChange("airport_name", A)}
            type="text"
            value={form?.airport_name}
            className={`w3-input w3-border w3-round small-top-margin small-bottom-margin ${formErrors?.airport_name ? 'error-field' : ''}`}
          />
        </div>
        <div className="w3-col l6 m6 s12">
          <label> City </label>
          <input
            onChange={A => handleFieldChange("city", A)}
            type="text"
            value={form?.city}
            className={`w3-input w3-border w3-round small-top-margin small-bottom-margin ${formErrors?.city ? 'error-field' : ''}`}
          />
        </div>
      </div>

      <div className="w3-row-padding">
        <div className="w3-col l6 m6 s12">
          <label> Country </label>
          <Select
            onChange={A => handleCountryChange("country", A.value)}
            // onChange ={e => handleCountryChange(e)}
            placeholder='Select Country'
            options={constants.COUNTRYARRAY?.map(C => (
              { value: C.country, label: C.country })
            )}
            value={form?.country ? {
              value: form?.country,
              label: form?.country
            } : null}
            className={`w-full small-top-margin small-bottom-margin ${formErrors?.country ? 'error-field' : ''}`}
          />
        </div>
        <div className="w3-col l6 m6 s12">
          <label> Timezone  </label>
          <input
            onChange={A => handleFieldChange("timezone", A)}
            value={form?.timezone}
            className="w3-input w3-border w3-round small-top-margin small-bottom-margin"
          />
        </div>
      </div>

      <div className="w3-row-padding">
        <div className="w3-col l6 m6 s12">
          <label> IATA </label>
          <input
            onChange={A => handleFieldChange("airport_code_iata", A)}
            type="text"
            value={form?.airport_code_iata}
            className={`w3-input w3-border w3-round small-top-margin small-bottom-margin ${formErrors?.airport_code_iata ? 'error-field' : ''}`}
          />
        </div>
        <div className="w3-col l6 m6 s12">
          <label> ICAO </label>
          <input
            onChange={A => handleFieldChange("airport_code_icao", A)}
            value={form?.airport_code_icao}
            className={`w3-input w3-border w3-round small-top-margin small-bottom-margin ${formErrors?.airport_code_icao ? 'error-field' : ''}`}
          />
        </div>
      </div>

      <div className="w3-row-padding">
        <div className="w3-col l6 m6 s12">
          <label> Latitude </label>
          <input
            onChange={A => handleFieldChange("latitude", A)}
            type="text"
            value={form?.latitude}
            className="w3-input w3-border w3-round small-top-margin small-bottom-margin"
          />
        </div>
        <div className="w3-col l6 m6 s12">
          <label> Longitute </label>
          <input
            onChange={A => handleFieldChange("longitute", A)}
            value={form?.longitute}
            className="w3-input w3-border w3-round small-top-margin small-bottom-margin"
          />
        </div>
      </div>

      <div className="w3-row-padding">
        <div className="w3-col l6 m6 s12">
          <label> Region </label>
          <input
            onChange={A => handleFieldChange("region", A)}
            value={form?.region}
            className="w3-input w3-border w3-round small-top-margin small-bottom-margin"
          />
        </div>
        <div className="w3-col l6 m6 s12">
          <label> Type </label>
          <input
            onChange={A => handleFieldChange("type", A)}
            value={form?.type}
            className="w3-input w3-border w3-round small-top-margin small-bottom-margin"
          />
        </div>
      </div>

      <div className="w3-row-padding">

        {/* <div className="w3-col l6 m6 s12">
          <label> utc  </label>
          <input
            value={form?.utc }
            className="w3-input w3-border w3-round small-top-margin small-bottom-margin"
          />
        </div> */}
      </div>
    </React.Fragment>
  );

  const LOADER = () => (
    <div className="request-form-container">
      <div className="h-30 flex justify-center items-center">
        <div><Loader spinnerClassName='w-10 h-10 text-primary-blue' />
          <p className='text-primary-blue'> Loading data... </p>
        </div>
      </div>
    </div>
  );

  const FORM_FOOTER = () => (
    <React.Fragment>
      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <button
            disabled={isSubmitting}
            onClick={handleSubmit}
            className='w3-btn bg-primary-blue w3-text-white small-top-margin'
          >
            {isSubmitting ? 'Submitting' : 'Submit'}
          </button>
        </div>
      </div>
      <div className='h-2'></div>
    </React.Fragment>
  );

  const AIRPORT_FORM = () => (
    <div className='request-form-container w-full relative'>
      <span className='heading'> Airport </span>
      <div className='border-primary-blue w3-round p-2'>
        {AIRPORT_DETAILS()}
        {FORM_FOOTER()}
      </div>
    </div>
  );
  return (
    <div id='Airport-Form'>
      {isLoading
        ? LOADER()
        : AIRPORT_FORM()
      }
    </div>
  )
}

export default AirportsForm;