// Packages
import React from 'react';

// Utils
import { ChevronRight, User, SessionExpiryDetail, ZuluTime } from '../../components';

export default function Header(props) {
	return (
		<div className='header sticky top-0 left-0 h-14 z-10 bg-primary-blue'>
			<div className='header-wrapper w-full items-center flex'>
				{props?.isStaff
					? <button
						onClick={props?.isStaff ? props?.onCompanyBtnClick : () => 0}
						id='company-change-button'
						className={`flex items-center ${props?.isStaff ? 'w3-button' : 'bg-primary-blue w3-border-0 px-2'} w3-text-white float-left`}
					>
						<span>
							{window.innerWidth <= 600
								? 'Company'
								: props?.company?.length > 15
									? props?.company?.slice(0, 15) + '..'
									: props?.company
							}
							<ChevronRight className='h-3 w-3 small-left-margin rotate-clock-wise-90' />
						</span>
					</button>
					: <button
						onClick={props?.onFleetBtnClick}
						id='fleet-change-button'
						className={`flex items-center ${!props?.isStaff ? 'w3-button' : 'bg-primary-blue w3-border-0 px-2'} w3-text-white float-left`}
					>
						<span>
							{window.innerWidth <= 600
								? 'Fleet'
								: props?.fleet?.name?.length > 15
									? props?.fleet?.name?.slice(0, 15) + '..'
									: props?.fleet?.name
							}
							<ChevronRight className='h-3 w-3 small-left-margin rotate-clock-wise-90' />
						</span>
					</button>
				}
				<div className='zulu-time'>
					<ZuluTime />
				</div>
				<div className='flex items-center float-right px-1 py-1 ml-auto'>
					<SessionExpiryDetail />
					<User
						onClick={props.onUserClick}
						imageClass='w-12 h-12 w3-white'
						containerClass='w3-circle cursor-pointer'
					/>
				</div>
			</div>
		</div>
	)
}
