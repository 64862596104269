// Packages
import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import dayjs from "dayjs";
import jsPDF from 'jspdf';
import { Store } from "react-notifications-component";
import { useParams } from "react-router-dom";

// APIs 
import { CrewMemberByFleetListRequest, FleetByAirbaseListRequest, AircraftByFleetListRequest, AirbaseListRequest, HotelRequestDetailsRequest, GetHotelPdfRequest } from '../../../requests';

// Utils
import { auth, airbasesCache, loggedUserInfoCache } from '../../../atoms';
import { constants, decodeString, authenticationErrorHandle } from '../../../utils';
import { Save } from "../../../components";


function HotelRequestView() {

  const INITIAL_FORM = {
    data: {
      airbase: '',
      fleet: '',
      aircraft: '',
      ACFT_REG: '',
      no_of_rooms: '',
      country: '',
      city: '',
      checkout_date: dayjs()?.format('YYYY-MM-DD'),
      checkin_date: dayjs()?.format('YYYY-MM-DD'),
      purpose: '',
      send_by: '',
      crew_members: []
    },
    errors: {}
  };
  const { hotelRequestId } = useParams();

  const [form, _form] = useState({ ...INITIAL_FORM });
  const [isLoading, _isLoading] = useState(false);
  const [isLoadingCrew, _isLoadingCrew] = useState(false);
  const [authState, _authState] = useAtom(auth);
  const [airbases, _airbases] = useAtom(airbasesCache);
  const [loggedUserInfo, _loggedUserInfo] = useAtom(loggedUserInfoCache);
  const [fleet, _fleet] = useState([]);
  const [aircrafts, _aircrafts] = useState([]);
  const [crewMembers, _crewMembers] = useState([]);

  // Get airbases and menu items either from cache or from server
  useEffect(() => {
    if (authState) {
      if (!airbases || !airbases.created || Date.now() - airbases.created >= 1200000) {
        getAirbases();
      }
      if (hotelRequestId) {
        getHotelRequestDetails();
      }
    }
  }, [authState, hotelRequestId]);

  // Auto set fleet and airbase in edit case
  useEffect(() => {
    if (authState && loggedUserInfo && loggedUserInfo?.data) {
      setFleetAndAirbase();
    }
  }, [authState, airbases, loggedUserInfo?.data])

  // Get fleet list On change of airbase 
  useEffect(() => {
    if (authState && form?.data?.airbase) {
      getFleetByAirbase();
    }
  }, [authState, form?.data?.airbase]);

  // Get crewmembers, and aircrafts list On change of fleet 
  useEffect(() => {
    if (authState && form?.data?.fleet) {
      getAircraftsByFleet();
      getCrewMembersByFleet();
    }
  }, [authState, form?.data?.fleet]);

  const setFleetAndAirbase = () => {
    // Set pre-selected fleet and airbase according to loggedIn user's info
    if (loggedUserInfo?.data?.fleet && loggedUserInfo?.data?.fleet?.id) {
      _form(old => ({
        ...old,
        data: {
          ...old?.data,
          fleet: loggedUserInfo?.data?.fleet?.id || '',
          airbase: loggedUserInfo?.data?.fleet?.airbase || '',
          sendby: loggedUserInfo?.data?.username || '',
        }
      }));
    }
  };

  const getHotelRequestDetails = () => {
    _isLoading(true);
    const token = decodeString(authState);
    HotelRequestDetailsRequest(token, hotelRequestId).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        _isLoading(false);
        _form(old => (
          {
            ...old,
            data
          }
        ));
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch hotel reservation details' });
        }
      )
  };

  const getAirbases = () => {
    const token = decodeString(authState);
    AirbaseListRequest(token).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((nonPaginatedData) => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in cache with current time
        _airbases({
          data: [...nonPaginatedData],
          created: Date.now(),
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch airbases" });
    });
  };

  const getFleetByAirbase = () => {
    const token = decodeString(authState);
    FleetByAirbaseListRequest(token, form?.data?.airbase).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((nonPaginatedData) => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in local state
        _fleet({
          data: [...nonPaginatedData],
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch fleet" });
    });
  };

  const getAircraftsByFleet = () => {
    const token = decodeString(authState);
    AircraftByFleetListRequest(token, form?.data?.fleet).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((nonPaginatedData) => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in local state
        _aircrafts({
          data: [...nonPaginatedData]
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch aircrafts" });
    });
  };

  const getCrewMembersByFleet = () => {
    const token = decodeString(authState);
    _isLoadingCrew(true);
    CrewMemberByFleetListRequest(token, form?.data?.fleet).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((nonPaginatedData) => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in local state
        _crewMembers({
          data: [...nonPaginatedData]
        });
        _isLoadingCrew(false);
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      _isLoadingCrew(false);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch crew members" });
    });
  };

  // *********** Handlers ***********

  const handleDownloadPdf = () => {
    const fileName = `hotel_request_${hotelRequestId}.pdf`;
    // create a wrapper div for content and set it's width
    const wrapper = document.createElement('div');
    wrapper.style.width = '1020px';
    wrapper.style.margin = '0px';
    // create a duplicate of the content div which is to be printed
    let sourceDiv = document.querySelector('.request-block');
    sourceDiv = sourceDiv.cloneNode(true);
    sourceDiv.style.padding = '0px';
    wrapper.appendChild(sourceDiv);
    let doc = new jsPDF('p', 'px', [650, 1500]);
    // create water mark
    for (let i = 1; i <= 2; i++) {
      doc.setPage(i)
      doc.saveGraphicsState();
      doc.setGState(new doc.GState({ opacity: 0.2, strokeOpacity: 1 }));
      doc.setTextColor('#1D1D1D40');
      // doc.text('DRAFT', 38, 15, { align: 'center', baseline: 'middle' })
      doc.restoreGraphicsState();
    }
    doc.html(wrapper, {
      callback: function (doc) {
        doc.save(fileName);
      },
      autoPaging: 'text',
      html2canvas: { scale: 0.6 },
      margin: [25, 0, 25, 0],
      x: 18,
      y: 0
    });
  };

  const handleGetPdfFromServer = () => {
    const token = decodeString(authState);
    GetHotelPdfRequest(token, hotelRequestId)
      .then(res => {
        if (!res.ok) {
          throw "Request Failed";
          // throw new Error('Network response was not ok');
        }
        return res.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `Hotel-${hotelRequestId}.pdf`;
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch(err => {
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch pdf file" });
      })
  };

  // *********** Render Functions ***********

  const HOTEL_DETAILS_SECTION = () => {
    return (
      <React.Fragment>
        <div className="table-heading w3-margin-top">
          <span className="float-left"> {`Id ${hotelRequestId}`}</span>
          <span> HOTEL RESERVATION </span>
        </div>
        <table className="w3-table w3-border">
          <tbody>
            <tr>
              <td className='w3-border bold'>Status</td>
              <td className='w3-border'>
                <div>{form?.data?.status}</div>
              </td>
              <td className='w3-border bold'>FSR Id</td>
              <td colSpan={4} className='w3-border'>
                <div>{form?.data?.request_id || '-'}</div>
              </td>
            </tr>
            <tr>
              <td className='w3-border bold'>Airbase</td>
              <td className='w3-border'>
                <div>{airbases?.data && airbases?.data?.find(A => A.id == form?.data?.airbase)?.name}</div>
              </td>
              <td className='w3-border bold'>Fleet</td>
              <td className='w3-border'>
                <div>{fleet?.data && fleet?.data?.find(F => F.id == form?.data?.fleet)?.name}</div>
              </td>
              <td className='w3-border bold'>Aircraft</td>
              <td className='w3-border'>
                <div>{aircrafts?.data && aircrafts?.data?.find(AC => AC.id == form?.data?.aircraft)?.tailnumber}</div>
              </td>
            </tr>
            <tr>
              <td className="w3-border bold"> Country </td>
              <td className='w3-border'>
                <div>{form?.data?.country}</div>
              </td>
              <td className="w3-border bold"> City </td>
              <td className='w3-border'>
                <div>{form?.data?.city}</div>
              </td>
              <td className="w3-border bold"> Rooms </td>
              <td className="w3-border">
                <div> {form?.data?.no_of_rooms} </div>
              </td>
            </tr>
            <tr>
              <td className="w3-border bold"> Check-in</td>
              <td className='w3-border'>
                <div>{form?.data?.checkin_date.replace(/[A-Z]/gm, ' ')}</div>
              </td>
              <td className="w3-border bold"> Check-out</td>
              <td className='w3-border'>
                <div>{form?.data?.checkout_date.replace(/[A-Z]/gm, ' ')}</div>
              </td>
              <td className="w3-border bold"> Total Nights </td>
              <td className='w3-border'>
                <div>{dayjs(form?.data?.checkout_date)?.diff(
                  dayjs(form?.data?.checkin_date),
                  'day', false)}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    )
  };

  const HOTEL_CREW_SECTION = () => {
    const hotel_crew = [...form?.data?.crew_members?.map(c => parseInt(c.crew_member))];
    return (
      <React.Fragment>
        <div className="table-heading w3-margin-top">CREW MEMBERS</div>
        <table className="w3-table">
          <tbody>
            <tr className='bold'>
              <td className="title w3-border">Duty</td>
              <td className="title w3-border">Name</td>
              <td className="title w3-border">Saudia ID</td>
              <td className="title w3-border">Nationality</td>
              <td className="title w3-border">ID/Passport#</td>
              <td className="title w3-border">License#</td>
            </tr>
            {
              !crewMembers?.data?.length && isLoadingCrew
                ? <tr>
                  <td className='w3-border' colSpan={6}> Loading... </td>
                </tr>
                : hotel_crew?.map((crew, index) => (
                  <tr key={index}>
                    <td className='w3-border'>{crewMembers?.data?.find(C => crew == C?.id)?.crewtitle}</td>
                    <td className='w3-border'>{crewMembers?.data?.find(C => crew == C?.id)?.crewname}</td>
                    <td className='w3-border'>{crewMembers?.data?.find(C => crew == C?.id)?.nationality?.toLowerCase()?.indexOf("saudi") > -1 ? "Yes" : "No"}</td>
                    <td className='w3-border'>{crewMembers?.data?.find(C => crew == C?.id)?.nationality}</td>
                    <td className='w3-border'>{crewMembers?.data?.find(C => crew == C?.id)?.passport}</td>
                    <td className='w3-border'>{crewMembers?.data?.find(C => crew == C?.id)?.license}</td>
                  </tr>
                ))
            }
          </tbody>
        </table>
      </React.Fragment>
    );
  };

  const HOTEL_SEND_BY_SECTION = () => {
    return (
      <React.Fragment>
        <table className="w3-table w3-margin-top">
          <tbody>
            <tr>
              <td className='w3-border bold w-32'> Purpose </td>
              <td className='w3-border'>{form?.data?.purpose}</td>
            </tr>
            <tr>
              <td className='w3-border bold w-32'> Send By </td>
              <td className='w3-border'>{form?.data?.send_by}</td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  };

  const SERVICE_INCLUDED_SECION = () => (
    <React.Fragment>
      <div className="table-heading w3-margin-top">SERVICES INCLUDED</div>
      <div className="small-bottom-margin p-2 w3-red block w-full">Note: Cancellation must be made before 24 hours.</div>
      <div className="px-1">
        <div className="hotel-service-box">
          <ul className="list-services">
            <li>3 Daily meals or one room service meal (maximum) after restaurant working hours</li>
            <li>Laundry for 3 pieces per day (maximum)</li>
            <li>Internet service</li>
            <li>Airport transfer</li>
          </ul>
        </div>
        <div className="hotel-service-box">
          <strong className="hotel-service-title">On Saudia Private Aviation account.</strong>
          <span className="hotel-service-alert">*Please Note:</span>
          <ul className="list-services">
            <li>Saudia Private Aviation is not obligated to pay any other services that not included.</li>
            <li>The guest registration form must be signed by the guest and attached to the invoice in order to process the payment. otherwise, the hotel accommodation request will be void.</li>
            <li>The invoices must be sent within maximum 7 days from the guest checkout date to avoid any delay in the payment process to the email AP@spa.sa and to the below address by postal:</li>
          </ul>
        </div>
        <div className="hotel-service-box">
          <ul className="list-services no-bullets">
            <li>Saudia Private Aviation Co Ltd</li>
            <li>North Terminal</li>
            <li>Kind Abdul Aziz International</li>
            <li>Airport</li>
            <li>Saudi Arabia</li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );

  const DOWNLOAD_PDF_BUTTON = () => (
    <button
      // onClick={handleDownloadPdf}
      onClick={handleGetPdfFromServer}
      className={`pdf-download-button fixed bottom-0 right-0 w3-btn bg-primary-blue w3-text-white shadow-lg w3-circle`}
    >
      <Save className='h-10 w-10' />
    </button>
  );

  const HOTEL_REQUEST_VIEW = () => (
    <div className='request-block'>
      {HOTEL_DETAILS_SECTION()}
      {HOTEL_CREW_SECTION()}
      {HOTEL_SEND_BY_SECTION()}
      {SERVICE_INCLUDED_SECION()}
      {DOWNLOAD_PDF_BUTTON()}
    </div>
  );

  return (
    <div id='HotelRequest-Form'>
      {HOTEL_REQUEST_VIEW()}
    </div>
  )
}

export default HotelRequestView;