import React from 'react'

export default function Bills(props) {
    return (
        <svg
            {...props}
            fill="currentColor"
            style={{ display: "inline-block", verticalAlign: "middle" }}
            height={32}
            width={32}
            viewBox="0 0 2048 1792"
        >
            <path d="M1024 512h-384v384h384v-384zM1152 1152v128h-640v-128h640zM1152 384v640h-640v-640h640zM1792 1152v128h-512v-128h512zM1792 896v128h-512v-128h512zM1792 640v128h-512v-128h512zM1792 384v128h-512v-128h512zM256 1344v-960h-128v960q0 26 19 45t45 19 45-19 19-45zM1920 1344v-1088h-1536v1088q0 33-11 64h1483q26 0 45-19t19-45zM2048 128v1216q0 80-56 136t-136 56h-1664q-80 0-136-56t-56-136v-1088h256v-128h1792z" />
        </svg>
    );
};
