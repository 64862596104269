// Packages
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Store } from 'react-notifications-component';

// APIs
import { CheckUserRequest, ResetPasswordRequest, VerifyOtpCodeRequest } from '../../../requests';

// Utils
import { ChevronRight, UserSolid, MailSolid, PadlockSolid } from '../../../components';
import { encodeString, validateSubmissionData, constants } from '../../../utils';

export default function VerifyAndReset() {
    const Navigate = useNavigate();
    const Location = useLocation();
    const [form, _form] = useState({
        data: {
            username: '',
            email: '',
            otp: '',
            token: '',
            newpassword: '',
            confirm_password: ''
        },
        validationStepOne: {
            username: { isRequired: true, label: "Name" },
            email: { isRequired: true }
        },
        validationStepTwo: {
            otp: { isRequired: true }
        },
        validationStepThree: {
            newpassword: { isRequired: true },
            confirm_password: { isRequired: true }
        },
        errors: {},
        currentStep: 0,
        isSubmitting: false
    });

    // *********** Handlers ***********
    const handleFormChange = (e) => {
        _form(old => (
            {
                ...old,
                data: {
                    ...old.data,
                    [e.target.name]: e.target.value
                }
            }
        ));
    };


    const handlePreSubmitCheckStepOne = (e) => {
        e.preventDefault();
        const { allValid, errors } = validateSubmissionData(form.data, form.validationStepOne);
        if (!allValid) {
            _form(old => ({
                ...old,
                errors
            }));
            return;
        } else {
            handleSubmitStepOne();
        }
    };

    const handlePreSubmitCheckStepTwo = (e) => {
        e.preventDefault();
        const { allValid, errors } = validateSubmissionData(form.data, form.validationStepTwo);
        if (!allValid) {
            _form(old => ({
                ...old,
                errors
            }));
            return;
        } else {
            handleSubmitStepTwo();
        }
    };

    const handlePreSubmitCheckStepThree = (e) => {
        e.preventDefault();
        let { allValid, errors } = validateSubmissionData(form.data, form.validationStepThree);
        if(form?.data?.confirm_password !==  form?.data?.newpassword) {
            allValid = false;
            errors = { ...errors, newpassword: "Password not matched"}
            Store.addNotification({ ...constants.ERRORTOAST, message: 'Password not matched' });
        }
        if (!allValid) {
            _form(old => ({
                ...old,
                errors
            }));
            return;
        } else {
            handleSubmitStepThree();
        }
    };

    const handleSubmitStepOne = () => {
        _form(old => ({
            ...old,
            isSubmitting: true,
            errors: {}
        }));
        let submitData = {
            username: form.data.username,
            email: form.data.email
        };
        submitData = JSON.stringify(submitData);
        CheckUserRequest(submitData)
            .then((res) => res.json())
            .then((data) => {
                if (data && data?.error) {
                    Store.addNotification({ ...constants.ERRORTOAST, message: data?.error });
                } else {
                    _form(old => ({
                        ...old,
                        currentStep: 1
                    }));
                }
            }
            ).catch(
                (err) => {
                    console.error(err);
                    Store.addNotification({ ...constants.ERRORTOAST, message: 'Request Failed' });
                }
            ).finally(
                () => {
                    _form(old => ({
                        ...old,
                        isSubmitting: false,
                    }));
                }
            )
    };

    const handleSubmitStepTwo = () => {
        _form(old => ({
            ...old,
            isSubmitting: true,
            errors: {}
        }));
        let submitData = {
            username: form.data.username,
            email: form.data.email,
            otp: form.data.otp
        };
        submitData = JSON.stringify(submitData);
        VerifyOtpCodeRequest(submitData)
            .then((res) => res.json())
            .then((data) => {
                if (data && data?.error) {
                    Store.addNotification({ ...constants.ERRORTOAST, message: data?.error });
                } else {                    
                    _form(old => ({
                        ...old,
                        currentStep: 2,
                        data: {
                            ...old.data,
                            token: data?.token,                            
                        }
                    }))
                }
            }
            ).catch(
                (err) => {
                    console.error(err);
                    Store.addNotification({ ...constants.ERRORTOAST, message: 'Request Failed' });
                }
            ).finally(() => {
                    _form(old => ({
                        ...old,
                        isSubmitting: false,
                    }));
                }
            )
    };

    const handleSubmitStepThree = () => {
        _form(old => ({
            ...old,
            isSubmitting: true,
            errors: {}
        }));
        let submitData = {
           ...form.data
        };
        submitData = JSON.stringify(submitData);
        ResetPasswordRequest(submitData)           
            .then(() => {                
                Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Password changed succesfully' });
                Navigate('/');                                
            })
            .catch((err) => {
                console.error(err);
                Store.addNotification({ ...constants.ERRORTOAST, message: 'Request Failed' });
            })
            .finally(() => {
                _form(old => ({
                    ...old,
                    isSubmitting: false,
                }));
            });
    };
    
    const handleRouteToLoginScreen = () => {
        Navigate('/');
    };

    // *********** Render Functions ***********
    const BACKGROUND = () => (
        <div className="screen__background">
            <span className="screen__background__shape screen__background__shape4" />
            <span className="screen__background__shape screen__background__shape3" />
            <span className="screen__background__shape screen__background__shape2" />
            <span className="screen__background__shape screen__background__shape1" />
        </div>
    );

    const VERIFY_EMAIL_FORM = () => (
        <form className="login_form" onSubmit={handlePreSubmitCheckStepOne}>
            <div className='logo'><img className={`img-logo`} src="/assets/images/spa_logo.png" alt="user" /> </div>
            <div className="login__field flex items-center">
                <UserSolid className='absolute' />
                <input
                    required={true}
                    name='username'
                    type="text"
                    className="login__input"
                    placeholder="User name"
                    onChange={handleFormChange}
                />
            </div>
            <div className="login__field flex items-center">
                <MailSolid className='absolute' />
                <input
                    required={true}
                    name='email'
                    type="text"
                    className="login__input"
                    placeholder="Email"
                    onChange={handleFormChange}
                />
            </div>
            <button className="button login__submit" type='submit'>
                <span className="button__text">Next</span>
                <ChevronRight className='ml-auto' />
            </button>
            <span className="button login__submit" onClick={handleRouteToLoginScreen}>
                <ChevronRight className='mr-auto rotate-180' />
                <span className="button__text">Back</span>
            </span>
        </form>
    );

    const OTP_FORM = () => (
        <form className="login_form" onSubmit={handlePreSubmitCheckStepTwo}>
            <div className='logo'><img className={`img-logo`} src="/assets/images/spa_logo.png" alt="user" /> </div>
            <div className='w3-medium w3-margin-top text-primary-blue'> A code has been sent to your email address. Please enter the code to continue. </div>
            <div className="login__field flex items-center">
                <PadlockSolid className='absolute' />
                <input
                    required={true}
                    name='otp'
                    type="text"
                    className="login__input"
                    placeholder="Code"
                    onChange={handleFormChange}
                />
            </div>
            <button className="button login__submit" type='submit'>
                <span className="button__text">Next</span>
                <ChevronRight className='ml-auto' />
            </button>
        </form>
    );

    const PASSWORD_FORM = () => (
        <form className="login_form" onSubmit={handlePreSubmitCheckStepThree}>
            <div className='logo'><img className={`img-logo`} src="/assets/images/spa_logo.png" alt="user" /> </div>
            <div className="login__field flex items-center">
                <PadlockSolid className='absolute' />
                <input
                    required={true}
                    name='newpassword'
                    type="password"
                    className={`login__input ${form?.errors?.newpassword ? 'error-field': ''}`}
                    placeholder="New Password"
                    onChange={handleFormChange}
                />
            </div>
            <div className="login__field flex items-center">
                <PadlockSolid className='absolute' />
                <input
                    required={true}
                    name='confirm_password'
                    type="password"
                    className={`login__input ${form?.errors?.confirm_password ? 'error-field': ''}`}
                    placeholder="Confirm Password"
                    onChange={handleFormChange}
                />
            </div>
            <button className="button login__submit" type='submit'>
                <span className="button__text">Next</span>
                <ChevronRight className='ml-auto' />
            </button>
        </form>
    );

    return (
        <div id='Login'>
            <div className="container">
                <div className="screen">
                    {BACKGROUND()}
                    <div className="screen__content">
                        {form?.currentStep === 0 ? VERIFY_EMAIL_FORM() : null}
                        {form?.currentStep === 1 ? OTP_FORM() : null}
                        {form?.currentStep === 2 ? PASSWORD_FORM() : null}
                    </div>
                </div>
            </div>
        </div>
    )
}
