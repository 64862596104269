
const ONLY_NUMBERS_REGEX = /^[\d]+[.]{0,1}[\d]*$/;

// NOTE: Used only in sign up screen
export const validateField = (fieldValue, fieldName = 'Field', options = {}) => {
    /*
        validates single field with options
        options: {min, max, regex, isRequired, isArray, isNumber}
    */
    let isValid = true;
    let error = ''
    fieldName = options.label || (fieldName[0].toUpperCase() + fieldName.slice(1)).replaceAll('_', ' ');

    // required
    if (options.isRequired) {
        if (options.isArray && (!fieldValue || fieldValue.length === 0)) {
            // if Array
            isValid = false;
            error = `${fieldName} is required`;
            return ({ isValid, error });
        } else if (!fieldValue || (typeof (fieldValue) === 'string' && fieldValue.trim().length === 0)) {
            // if String
            isValid = false;
            error = `${fieldName} is required`;
            return ({ isValid, error });
        }
    }

    // invalid type check
    if (fieldValue && options.isNumber && !ONLY_NUMBERS_REGEX.test(fieldValue)) {
        // if (fieldValue && options.isNumber && typeof (fieldValue) !== 'number') {
        // if number
        isValid = false;
        error = `${fieldName} should be a number`;
        return ({ isValid, error });
    } else if (fieldValue && !options.isNumber && !options.isArray && typeof (fieldValue) !== 'string') {
        // if string
        isValid = false;
        error = `${fieldName} should be a string`;
        return ({ isValid, error });
    } else if (fieldValue && options.isArray && !Array.isArray(fieldValue)) {
        // if array
        isValid = false;
        error = `${fieldName} should be an array`;
        return ({ isValid, error });
    }

    // min length
    if (options.min) {
        if (options.isArray && fieldValue && fieldValue.length < options.min) {
            // if Array
            isValid = false;
            error = `Minimum ${options.min} item${options.min > 1 ? 's are' : ' is'} required`;
            return ({ isValid, error });
        } else if (options.isNumber && fieldValue && fieldValue < options.min) {
            // if Number
            isValid = false;
            error = `Minimum value required is ${options.min}`;
            return ({ isValid, error });

        } else if (!options.isArray && fieldValue && fieldValue.trim().length < options.min) {
            // if String
            isValid = false;
            error = `Minimum ${options.min} character${options.min > 1 ? 's are' : ' is'} required`;
            return ({ isValid, error });
        }
    }

    // max length
    if (options.max) {
        if (options.isArray && fieldValue && fieldValue.length > options.max) {
            // if Array
            isValid = false;
            error = `Maximum ${options.max} item${options.max > 1 ? 's are' : ' is'} allowed`;
            return ({ isValid, error });
        } else if (options.isNumber && fieldValue && fieldValue > options.max) {
            // if Number
            isValid = false;
            error = `Maximum value allowed is ${options.max}`;
            return ({ isValid, error });

        } else if (!options.isArray && fieldValue && fieldValue.trim().length > options.max) {
            // if String
            isValid = false;
            error = `Maximum ${options.max} character${options.max > 1 ? 's are' : ' is'} allowed`;
            return ({ isValid, error });
        }
    }

    // regex
    if (options.regex && fieldValue && !options.regex.test(fieldValue)) {
        isValid = false;
        error = `${fieldName} is not valid`;
        return ({ isValid, error });
    }

    return ({ isValid, error });
}

// NOTE: Used only in sign up screen
export const validateSubmissionData = (data, options) => {
    /*
        validates entire data object using keys matching options object keys
        options: {min, max, regex, isRequired, isArray, isNumber}
    */
    let allValid = true;
    const errors = {};
    Object.keys(data).forEach(key => {
        if (options[key]) {
            const { isValid, error } = validateField(data[key], key, options[key]);
            if (!isValid) {
                errors[key] = error;
                allValid = false;
            }
        }
    });
    return ({ allValid, errors });
}

