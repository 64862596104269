import { constants } from "../utils";

const ApprovedHotelRequestDetailsRequest = (token, hotelRequestId) => {
    var requestHeaders = new Headers();
    requestHeaders.append("Authorization", `Bearer ${token}`);
    requestHeaders.append("Content-Type", "application/json");
    return (fetch(`${constants.BASEURL}/api/hotelrequest/get_approve_request/${hotelRequestId}/`, {
        method: 'GET',
        headers: requestHeaders,
        redirect: 'follow'
    }));
};

export default ApprovedHotelRequestDetailsRequest;
