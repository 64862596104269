import React from 'react'

export default function Services(props) {
    return (
        <svg fill="currentColor" {...props} viewBox="0 0 512 512"><g><path d="M161,160.5h192c-1.688-20-9.729-35.45-27.921-40.356c-0.446-0.119-1.12-0.424-1.567-0.541
		c-12.004-3.424-21.012-7.653-21.012-20.781V78.227C302.5,52.691,281.976,32,256.49,32c-25.466,0-45.99,20.691-45.99,46.227v20.595
		c0,13.128-8.592,17.169-20.597,20.593c-0.447,0.117-0.8,0.61-1.266,0.729C170.446,125.05,162.927,140.5,161,160.5z M256.99,64.395
		c7.615,0,13.791,6.195,13.791,13.832c0,7.654-6.176,13.85-13.791,13.85c-7.614,0-13.772-6.195-13.772-13.85
		C243.218,70.59,249.376,64.395,256.99,64.395z"><path d="M161,160.5h192c-1.688-20-9.729-35.45-27.921-40.356c-0.446-0.119-1.12-0.424-1.567-0.541
		c-12.004-3.424-21.012-7.653-21.012-20.781V78.227C302.5,52.691,281.976,32,256.49,32c-25.466,0-45.99,20.691-45.99,46.227v20.595
		c0,13.128-8.592,17.169-20.597,20.593c-0.447,0.117-0.8,0.61-1.266,0.729C170.446,125.05,162.927,140.5,161,160.5z M256.99,64.395
		c7.615,0,13.791,6.195,13.791,13.832c0,7.654-6.176,13.85-13.791,13.85c-7.614,0-13.772-6.195-13.772-13.85
		C243.218,70.59,249.376,64.395,256.99,64.395z" /></path><path d="M405.611,63.5H331.5v13.988c0,10.583,9.193,19.012,19.507,19.012h37.212c6.667,0,12.099,5.435,12.44,12.195l0.085,327.1
		c-0.322,6.432-5.303,11.546-11.514,12.017l-264.418,0.031c-6.211-0.471-11.149-5.695-11.472-12.126l-0.085-327.014
		c0.322-6.761,5.858-12.203,12.506-12.203h37.231c10.313,0,18.507-8.429,18.507-19.012V63.5h-73.131
		C93.25,63.5,80.5,76.058,80.5,91.575v360.38c0,15.502,12.75,28.545,27.869,28.545H256.99h148.621
		c15.138,0,26.889-13.043,26.889-28.545V91.575C432.5,76.058,420.749,63.5,405.611,63.5z"><path d="M405.611,63.5H331.5v13.988c0,10.583,9.193,19.012,19.507,19.012h37.212c6.667,0,12.099,5.435,12.44,12.195l0.085,327.1
		c-0.322,6.432-5.303,11.546-11.514,12.017l-264.418,0.031c-6.211-0.471-11.149-5.695-11.472-12.126l-0.085-327.014
		c0.322-6.761,5.858-12.203,12.506-12.203h37.231c10.313,0,18.507-8.429,18.507-19.012V63.5h-73.131
		C93.25,63.5,80.5,76.058,80.5,91.575v360.38c0,15.502,12.75,28.545,27.869,28.545H256.99h148.621
		c15.138,0,26.889-13.043,26.889-28.545V91.575C432.5,76.058,420.749,63.5,405.611,63.5z" /></path><rect x="144.5" y="192.5" width={112} height={32}><rect x="144.5" y="192.5" width={112} height={32} /></rect><rect x="144.5" y="384.5" width={160} height={32}><rect x="144.5" y="384.5" width={160} height={32} /></rect><rect x="144.5" y="320.5" width={129} height={32}><rect x="144.5" y="320.5" width={129} height={32} /></rect><rect x="144.5" y="256.5" width={208} height={32}><rect x="144.5" y="256.5" width={208} height={32} /></rect></g></svg>
    )
}
