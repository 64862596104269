// Packages
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import jsPDF from 'jspdf';
import { useAtom } from "jotai";
import { Store } from "react-notifications-component";
import { useParams } from "react-router-dom";

// APIs 
import { CrewMemberByFleetListRequest, TicketRequestDetailsRequest } from '../../../requests';

// Utils
import { auth } from '../../../atoms';
import { constants, decodeString, authenticationErrorHandle, ignoreTimeZone } from '../../../utils';
import { Save } from "../../../components";

function TicketRequestView() {
  const INITIAL_FORM = {
    data: {
      departure_country: '',
      departure_city: '',
      arrival_country: '',
      arrival_city: '',
      departure_datetime: dayjs()?.format('YYYY-MM-DD HH:mm'),
      arrival_datetime: dayjs()?.format('YYYY-MM-DD HH:mm'),
      departure_date: dayjs()?.format('YYYY-MM-DD'),
      departure_time: dayjs(ignoreTimeZone(dayjs().toISOString()))?.format('HH:mm'),
      arrival_date: dayjs()?.format('YYYY-MM-DD'),
      arrival_time: dayjs(ignoreTimeZone(dayjs().toISOString()))?.format('HH:mm'),
      crew_members: [],
      status: "New",
      no_of_ticket: "",
      comments: "",
      fleet: '',
    }
  };

  const { ticketRequestId } = useParams();

  const [form, _form] = useState({ ...INITIAL_FORM });
  const [isLoading, _isLoading] = useState(false);
  const [crewMembers, _crewMembers] = useState([]);
  const [authState, _authState] = useAtom(auth);

  // Get ticket request details
  useEffect(() => {
    if (authState) {
      if (ticketRequestId) {
        getTicketRequestDetails();
      }
    }
  }, [authState, ticketRequestId]);

  // Get crew members on fleet change
  useEffect(() => {
    if (authState && form?.data?.fleet) {
      getCrewMembersByFleet();
    }
  }, [authState, form?.data?.fleet]);

  const getTicketRequestDetails = () => {
    _isLoading(true);
    const token = decodeString(authState);
    TicketRequestDetailsRequest(token, ticketRequestId).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        _isLoading(false);
        _form(old => (
          {
            ...old,
            data: {
              ...data,
              departure_date: dayjs(data?.departure_datetime)?.format('YYYY-MM-DD') || '',
              arrival_date: dayjs(data?.arrival_datetime)?.format('YYYY-MM-DD') || '',
              departure_time: dayjs(ignoreTimeZone(data?.departure_datetime))?.format('HH:mm') || '',
              arrival_time: dayjs(ignoreTimeZone(data?.arrival_datetime))?.format('HH:mm') || ''
            }
          }
        ));
      } else {
        throw 'Request Failed';
      }
    }
    ).catch(
      err => {
        _isLoading(false);
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch ticket request details' });
      }
    )
  };

  const getCrewMembersByFleet = () => {
    const token = decodeString(authState);
    CrewMemberByFleetListRequest(token, form?.data?.fleet).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((nonPaginatedData) => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in local state
        _crewMembers({
          data: [...nonPaginatedData]
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch crew members" });
    });
  };

  const CrewMembersForTicketRequest = crewMembers?.data
    ? crewMembers?.data?.filter(C => form?.data?.crew_members
      ?.map(ticket_crew => ticket_crew.crew_member)
      ?.includes(C.id))
    : [];

  // *********** Handlers ***********

  const handleDownloadPdf = () => {
    const fileName = `ticket_request_${ticketRequestId}.pdf`;
    // create a wrapper div for content and set it's width
    const wrapper = document.createElement('div');
    wrapper.style.width = '1020px';
    wrapper.style.margin = '0px';
    // create a duplicate of the content div which is to be printed
    let sourceDiv = document.querySelector('.request-block');
    sourceDiv = sourceDiv.cloneNode(true);
    sourceDiv.style.padding = '0px';
    wrapper.appendChild(sourceDiv);
    let doc = new jsPDF('p', 'px', [650, 1500]);
    // create water mark
    for (let i = 1; i <= 2; i++) {
      doc.setPage(i)
      doc.saveGraphicsState();
      doc.setGState(new doc.GState({ opacity: 0.2, strokeOpacity: 1 }));
      doc.setTextColor('#1D1D1D40');
      // doc.text('DRAFT', 38, 15, { align: 'center', baseline: 'middle' })
      doc.restoreGraphicsState();
    }
    doc.html(wrapper, {
      callback: function (doc) {
        doc.save(fileName);
      },
      autoPaging: 'text',
      html2canvas: { scale: 0.6 },
      margin: [25, 0, 25, 0],
      x: 18,
      y: 0
    });
  };

  // *********** Render Functions ***********

  const TICKET_REQUEST_DETAILS_SECION = () => (
    <React.Fragment>
      <div className="table-heading w3-margin-top">
        <span className="float-left"> {`Id ${ticketRequestId}`}</span>
        <span> TICKET REQUEST </span>
      </div>
      <table className="w3-table w3-border">
        <tbody>
          <tr>
            <td className='w3-border bold'>Status</td>
            <td className='w3-border'>
              <div>{form?.data?.status}</div>
            </td>
            <td className='w3-border bold'>No of Tickets</td>
            <td className='w3-border'>
              <div>{form?.data?.no_of_ticket}</div>
            </td>
          </tr>
          <tr>
            <td className="w3-border bold"> Departure Country </td>
            <td className='w3-border'>
              <div>{form?.data?.departure_country}</div>
            </td>
            <td className="w3-border bold">Departure City </td>
            <td className='w3-border'>
              <div>{form?.data?.departure_city}</div>
            </td>
          </tr>
          <tr>
            <td className="w3-border bold">Arrival Country</td>
            <td className='w3-border'>
              <div> {form?.data?.arrival_country} </div>
            </td>
            <td className="w3-border bold">Arrival City</td>
            <td className='w3-border'>
              <div> {form?.data?.arrival_city} </div>
            </td>
          </tr>
          <tr>
            <td className="w3-border bold">Departure Date</td>
            <td className='w3-border'>
              <div> {form?.data?.departure_date} </div>
            </td>
            <td className="w3-border bold">Departure Time</td> 
            <td className='w3-border'>
              <div> {form?.data?.departure_time} </div>
            </td>
          </tr>
          <tr>
            <td className="w3-border bold">Comments</td>
            <td className='w3-border' colSpan="5">
              <div> {form?.data?.comments} </div>
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );

  const TICKET_REQUEST_CREW_SECTION = () => (
    <React.Fragment>
      <div className="table-heading w3-margin-top">CREW MEMBERS</div>
      <table className="w3-table">
        <tbody>
          <tr className='bold'>
            <td className="title w3-border">Duty</td>
            <td className="title w3-border">Name</td>
            <td className="title w3-border">Nationality</td>
            <td className="title w3-border">ID/Passport#</td>
            <td className="title w3-border">License#</td>
          </tr>
          {CrewMembersForTicketRequest && CrewMembersForTicketRequest?.map((C, index) => (
            <tr key={index}>
              <td className='w3-border'>{C.crewtitle}</td>
              <td className='w3-border'>{C.crewname}</td>
              <td className='w3-border'>{C.nationality}</td>
              <td className='w3-border'>{C.passport}</td>
              <td className='w3-border'>{C.license}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </React.Fragment>
  );

  const DOWNLOAD_PDF_BUTTON = () => (
    <button
      onClick={handleDownloadPdf}
      className={`pdf-download-button fixed bottom-0 right-0 w3-btn bg-primary-blue w3-text-white shadow-lg w3-circle`}
    >
      <Save className='h-10 w-10' />
    </button>
  );

  const TICKET_REQUEST_VIEW = () => (
    <div className='request-block'>
      {TICKET_REQUEST_DETAILS_SECION()}
      {TICKET_REQUEST_CREW_SECTION()}
      {DOWNLOAD_PDF_BUTTON()}
    </div>
  );

  return (
    <div id='Ticket-Request-View' className="w3-margin-bottom">
      {TICKET_REQUEST_VIEW()}
    </div>
  );
}

export default TicketRequestView;