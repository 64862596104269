import { constants } from "../utils";

const AirportDistanceRequest = (token, data) => {
    var requestHeaders = new Headers();
    requestHeaders.append("Authorization", `Bearer ${token}`);
    requestHeaders.append("Content-Type", "application/json");
    requestHeaders.append("X-RapidAPI-Key", constants.XRAPIDAPIKEY);
    requestHeaders.append("X-RapidAPI-Host", constants.XRAPIDAPIHOST);
    return (fetch(`${constants.AIRPORTDISTANCEURL}${data}`, { 
        method: 'GET',
        headers: requestHeaders,
        redirect: 'follow'
    }));
};

export default AirportDistanceRequest;