// Packages
import React from 'react';

const RequestHistoryCompareOldNew = (props) => (
    <table className='request-history-changes-compare-table'>
        <thead>
            <tr>
                <th> <span className='w3-tag w3-red'>Old</span> </th>
                <th> <span className='w3-tag w3-blue'>New</span> </th>
            </tr>
        </thead>
        <tbody>
            {props.children}
        </tbody>
    </table>
);

export default RequestHistoryCompareOldNew;