import { constants } from "../utils";

const InsuranceRequestStatusChangeRequest = (token, data, id) => {
    var requestHeaders = new Headers();
    requestHeaders.append("Authorization", `Bearer ${token}`);
    requestHeaders.append("Content-Type", "application/json");
    return (fetch(`${constants.BASEURL}/api/medicalinsurance/${id}/update_status_medical_insurance_request/`, {
        method: 'PUT',
        headers: requestHeaders,
        body: data,
        redirect: 'follow'
    }));
};

export default InsuranceRequestStatusChangeRequest;
