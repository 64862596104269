// Packages
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component'
import { useAtom } from 'jotai';
import 'react-notifications-component/dist/theme.css';

// Apis
import { CustomerDetailRequest } from '../requests';

// Utils
import UnAuthenticatedRoutes from './unauthenticated_routes';
import AuthenticatedRoutes from './authenticated_routes';
import { decodeToken, decodeString } from '../utils';
import { auth } from '../atoms';

const Screens = () => {
  const localStorageSession = localStorage.getItem('session');
  const [authState, _authState] = useAtom(auth);
  const [isLoggedIn, _isLoggedIn] = useState(localStorageSession ? true : false);

  // Check if already authenticated user
  useEffect(() => {
    if (authState) {
      if (authState === 0) {
        _isLoggedIn(false);
        localStorage.clear();
      } else {
        try {
          const token = decodeString(authState);
          const data = decodeToken(token);
        } catch (err) {
          console.error('Authentication Failed');
          _isLoggedIn(false);
          localStorage.clear();
        }
      }
    }
  }, [authState]);

  useEffect(() => {
    if (localStorageSession) {
      try {
        const token = decodeString(localStorageSession);
        const data = decodeToken(token);
        _authState(localStorageSession);
      } catch (err) {
        console.error('Authentication Failed');
        _isLoggedIn(false);
        localStorage.removeItem('session');
      }
    }
  }, []);

  return (
    <Router>
      <div id="Screens">
        <ReactNotifications />
        {/* <UnAuthenticatedRoutes /> */}
        {/* <AuthenticatedRoutes /> */}
        {isLoggedIn
          ? <AuthenticatedRoutes />
          : <UnAuthenticatedRoutes />
        }
      </div>
    </Router>
  )
}

export default Screens;