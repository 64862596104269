// Packages
import React from 'react';

// Utils
import { Modal, Warn } from '../../../../components';

function LegDeleteConfirmationModal({
  legs,
  isSubmitting,
  handleRemoveLeg,
  selectedDeletingLegId,
  showConfirmRemoveLegModal,
  handleCloseConfirmRemoveLegModal
}) {
  return (
    <Modal isOpen={showConfirmRemoveLegModal} modalClass="w-96 w3-round-medium">
      <div className="w-full">
        <div className='w3-pale-red w3-text-red py-1 px-2 flex flex-wrap items-center'>
          <Warn className='w-6 h-6 small-right-margin' />
          <h3>
            Remove Leg
          </h3>
        </div>
        <div className='py-1 px-2'>
          Are you sure you want to remove leg?
          <p className='bold'>
            {legs && legs?.filter(L => L?.id == selectedDeletingLegId)?.map(L =>
              <React.Fragment key={L?.id}>
                {
                  `${L?.departure_airport_icao || L?.departure?.airport_code?.[0]}/${L?.departure_airport_iata} → 
              ${L?.destination_airport_icao || L?.destination?.airport_code?.[0]}/${L?.destination_airport_iata}`
                }
              </React.Fragment>
            )}
          </p>
          <span className='w3-text-red'> This leg will be deleted permanently and has to be recreated if it is needed again </span>
          <div className="h-3"></div>
          <div className="flex justify-end">
            <button onClick={handleCloseConfirmRemoveLegModal} className='w3-btn w3-grey w3-text-white'> Close </button>
            <button
              onClick={e => handleRemoveLeg(selectedDeletingLegId)}
              disabled={false}
              className='w3-btn w3-red small-left-margin'
            >
              {isSubmitting ? 'Processing' : 'Confirm'}
            </button>
          </div>
          <div className="h-3"></div>
        </div>
      </div>
    </Modal>
  )
};

export default LegDeleteConfirmationModal;