import { constants } from "../utils";

const FsrEditRequest = (token, data, id) => {
    var requestHeaders = new Headers();
    requestHeaders.append("Authorization", `Bearer ${token}`);
    requestHeaders.append("Content-Type", "application/json");
    return (fetch(`${constants.BASEURL}/api/request/${id}/`, {
        method: 'PUT',
        headers: requestHeaders,
        body: data,
        redirect: 'follow'
    }));
};

export default FsrEditRequest;
