// Packages
import React from 'react';
import dayjs from 'dayjs';

// Utils
import { Transport, X } from '../../../../components'

const AddedTransports = ({
  leg,
  handleSetTransport,
  handleChangeTransportField
}) => {

  // *********** Handlers ***********
  const handleFormatTime = (T, e) => {
    let TimeValue = dayjs(`2001-01-01 ${e.target.value}`);
    if (dayjs(TimeValue).isValid) {
      TimeValue = dayjs(TimeValue).format('HH:mm');
    } else {
      TimeValue = '00:00';
    }
    handleChangeTransportField(T?.index_seq, {
      target: {
        name: 'transport_time',
        value: TimeValue
      }
    })
  };

  return (
    <React.Fragment>
      <div className='w-full'>
        <div className='w3-col'>
          <h4 className='request-form-section-heading'> <Transport className='w-5 h-5 small-right-margin' /> <span>Transport</span> </h4>
        </div>
      </div>
      {
        leg?.reqlegtransport_requestLegTransport &&
        leg?.reqlegtransport_requestLegTransport?.map((T, index) => (
          <div key={index} className='w-full flex items-center small-top-margin small-bottom-margin'>
            <div>
              <label> Type </label>
              <div className='h-1'></div>
              <div className='flex items-center'>
                <select
                  name='transport_type'
                  value={T?.transport_type}
                  onChange={e => handleChangeTransportField(T?.index_seq, e)}
                  className='w3-input w3-white w3-border'
                >
                  <option value='Airport to Hotel'> Airport To Hotel </option>
                  <option value='Hotel To Airport'> Hotel To Airport </option>
                </select>
                {/* <X
                  onClick={e => handleSetTransport(T?.index_seq, 'remove')}
                  className='w-6 h-6 w3-text-red cursor-pointer small-left-margin'
                /> */}
              </div>
            </div>
            {/* Time field hidden after changes request from FSR finance team */}
            <div className={`px-2 ${T?.transport_type !=='Hotel To Airport' ? 'hidden' : ''}`}>
              <label> Pickup Time </label>
              <div className='h-1'></div>
              <input
                type='text'
                name='transport_time'
                placeholder='00:00'
                value={T?.transport_time}
                className={`w3-input w3-white w3-border`}
                onChange={e => handleChangeTransportField(T?.index_seq, e)}
                onBlur={e => handleFormatTime(T, e)}
              />
            </div>
            <div className='flex items-center px-2'>
              <X
                onClick={e => handleSetTransport(T?.index_seq, 'remove')}
                className='w-5 h-5 w3-text-red cursor-pointer'
              />
            </div>
          </div>
        ))
      }
      <button
        onClick={e => handleSetTransport(null, 'add')}
        className='w3-btn bg-primary-blue w3-text-white small-top-margin'
      >
        + Add
      </button>
    </React.Fragment>
  );
};

export default AddedTransports;
