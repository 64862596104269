// Packages
import React, { Fragment, useState } from 'react';
import Sidebar from 'react-sidebar';
import { NavLink } from "react-router-dom";

// Utils
import { constants } from '../../utils';
import { Bars } from '../../components';

function SideNav(props) {
	const [sidebarOpen, _sidebarOpen] = useState(true);
	const [sidebarType, _sidebarType] = useState(window.innerWidth <= 600 ? constants.SIDEBARMINI : constants.SIDEBARFULL);

	const toggleSidebar = () => {
		if (sidebarType.width === '70px') {
			_sidebarType(constants.SIDEBARFULL);
		} else {
			_sidebarType(constants.SIDEBARMINI);
		}
	};

	// *********** Render Functions ***********

	const PROJECT_LOGO = () => (
		<div className='project-logo flex items-center px-2 py-2'>
			<div className='flex'>
			<img className={`img-logo`} src="/assets/images/spa_logo.png" alt="user"/> 
				{/* <span className='text-primary-blue'>
					{`S${sidebarType === constants.SIDEBARFULL ? 'PA' : ''}`}
				</span>
				{sidebarType === constants.SIDEBARFULL &&
					<span className='w3-text-gray'>ero</span>
				} */}
			</div>
		</div>
	);

	const SideTabMenu = () => (
		<div id='SideTabMenu'>
			{PROJECT_LOGO()}			
			{props?.links?.map((Link, index) => (
				<Fragment key={`menu-${index}`}>
					{/* {Link?.hasSubheader && <span className='px-2 w3-small'> {Link?.hasSubheader} </span>} */}
					<NavLink
						key={index}
						to={Link.path}
						className={({ isActive }) =>
							isActive ? "active-nav" : ""
						}
					>
						<div className='px-2 py-2 overflow-hidden'>
							<div className='flex items-center no-wrap sidenav-item'>
								<div className='nav-icon'>
									{Link.icon}
								</div>
								<div className='nav-text'> {Link.label} </div>
							</div>
						</div>
					</NavLink>
				</Fragment>
			))}
		</div>
	);

	return (
		<Sidebar
			open={window.innerWidth <= 600 ? false : sidebarOpen}
			onSetOpen={() => _sidebarOpen(true)}
			docked={true}
			shadow={true}
			pullRight={false}
			sidebar={<SideTabMenu />}
			sidebarClassName='sidenav'
			styles={{
				sidebar: { ...sidebarType },
				root: {},
				content: {},
				dragHandle: {},
				overlay: {}
			}}
		>
			<React.Fragment>
				<button className='fixed h-14 z-20 w3-button sidenav-btn w3-hide-small no-background w3-text-white' onClick={() => toggleSidebar()}>
					<Bars className='w-6 h-6' />
				</button>
				{props.children}
			</React.Fragment>
		</Sidebar>
	);
}

export default SideNav;