// Packages
import React, { useState } from 'react';
import Select from 'react-select';

// Utils
import { Modal } from '../../../../components';

function DuplicateCrewMemberModal({
  legs,
  selectedDuplicateLegId,
  showDuplicateCrewMemberModal,
  handleDuplicateCrewMembers,
  handleCloseDuplicateCrewMemberModal
}) {
  const L = legs?.find(L => L?.id == selectedDuplicateLegId);
  const [toLegs, _toLegs] = useState([]);

  // *********** Handlers ***********

  const handleSelectLegs = (legs) => {
    _toLegs(legs?.map(L => L?.value));
  };

  return (
    <Modal isOpen={showDuplicateCrewMemberModal} modalClass="w3-round-medium">
      <div className="w-full">
        <div className='py-1 px-2 flex flex-wrap items-center'>
          <h3>
            Duplicate Crew Members
          </h3>
        </div>
        <div className='py-1 px-2'>
          <span>From</span>
          <br />
          <div className='small-top-margin'>
            {
              `${L?.departure_airport_icao || L?.departure?.airport_code?.[0]}/${L?.departure_airport_iata} → 
          ${L?.destination_airport_icao || L?.destination?.airport_code?.[0]}/${L?.destination_airport_iata}`
            }
          </div>
          <div className='h-2'> </div>
          <span>To</span>
          <br />
          <Select
            isMulti={true}
            className='small-top-margin small-bottom-margin'
            onChange={handleSelectLegs}
            options={legs?.filter(L => L?.id != selectedDuplicateLegId)?.map(L => (
              {
                value: L?.id,
                label:
                  `Leg-${legs?.findIndex(X => L?.id === X?.id)+1} 
                  ${L?.departure_airport_icao || L?.departure?.airport_code?.[0]}/${L?.departure_airport_iata} → 
              ${L?.destination_airport_icao || L?.destination?.airport_code?.[0]}/${L?.destination_airport_iata}`

              }
            ))}
          />
          <div className="h-3"></div>
          <div className="flex justify-end">
            <button onClick={handleCloseDuplicateCrewMemberModal} className='w3-btn w3-grey w3-text-white'> Close </button>
            <button
              disabled={!Boolean(toLegs.length)}
              onClick={e => handleDuplicateCrewMembers(selectedDuplicateLegId, toLegs)}
              className='w3-btn bg-primary-blue w3-text-white small-left-margin'
            >
              Confirm
            </button>
          </div>
          <div className="h-3"></div>
        </div>
      </div>
    </Modal>
  )
};

export default DuplicateCrewMemberModal;