// Packages
import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useNavigate, useLocation } from 'react-router-dom';
import { Store } from 'react-notifications-component';

// APIs
import { LoginRequest, VerifyLoginOtpCodeRequest } from '../../../requests';

// Utils
import { ChevronRight, UserSolid, PadlockSolid } from '../../../components';
import { encodeString, validateSubmissionData, constants } from '../../../utils';

export default function Login() {
    const Navigate = useNavigate();
    const Location = useLocation();
    const [loginAttempt, _loginAttempt] = useState(false);
    const [form, _form] = useState({
        data: {
            user: '',
            password: '',
            otp: ''
        },
        validation: {
            user: { isRequired: true, label: "ID" },
            password: { isRequired: true }
        },
        errors: {},
        isSubmitting: false
    });

    // Check if logged out due to token expiry 401 error
    useEffect(() => {
        if (Location?.hash === '#Timeout') {
            Store.removeNotification();
            Store.addNotification({ ...constants.ERRORTOAST, message: 'Session has expired' });
        }
    }, []);

    // *********** Handlers ***********
    const handleFormChange = (e) => {
        _form(old => (
            {
                ...old,
                data: {
                    ...old.data,
                    [e.target.name]: e.target.value
                }
            }
        ));
    };

    const handlePreSubmitCheck = (e) => {
        e.preventDefault();
        const { allValid, errors } = validateSubmissionData(form.data, form.validation);
        if (!allValid) {
            _form(old => ({
                ...old,
                errors
            }));
            return;
        } else {
            handleSubmitLogin();
        }
    };

    const handleSubmitLogin = () => {
        _form(old => ({
            ...old,
            isSubmitting: true,
            errors: {}
        }));
        let submitData = {
            username: form.data.user,
            password: form.data.password
        };
        submitData = JSON.stringify(submitData);
        LoginRequest(submitData).then((res) => res.json()).then(
            (data) => {
                // console.log('****DATA****', data);

                // To bypass verify login step comment out this if block and un-comment the next if block
                if (data && data?.status === "success") {
                    handleMoveToOTPForm();
                }
                else if(data && data?.error){
                    Store.addNotification({ ...constants.ERRORTOAST, message: data?.error });                    
                }
                else throw new Error('Login Failed');
                // if (data && data.access) {
                //     const token = encodeString(data.access);
                //     let LoginTime = dayjs().add(8, 'hour').toISOString();
                //     LoginTime = encodeString(LoginTime, constants.ENCODER_LOGIN_TIME);
                //     localStorage.setItem('session', token);
                //     localStorage.setItem('is_valid', LoginTime);
                //     window.history.replaceState(null, null, ' ');
                //     Navigate(0);
                // } else {
                //     throw "Server Error";
                // }
            }
        ).catch(
            (err) => {
                console.error(err);
                Store.addNotification({ ...constants.ERRORTOAST, message: 'Login failed' });
            }
        ).finally(
            () => {
                _form(old => ({
                    ...old,
                    isSubmitting: false,
                }));
            }
        )
    };

    const handleSubmitVerifyLogin = (e) => {
        e.preventDefault();
        _form(old => ({
            ...old,
            isSubmitting: true,
            errors: {}
        }));
        let submitData = {
            username: form.data.user,
            otp: form.data.otp
        };
        submitData = JSON.stringify(submitData);
        VerifyLoginOtpCodeRequest(submitData).then((res) => res.json()).then(
            (data) => {
                // console.log('****DATA****', data);             
                if (data && data.access) {
                    const token = encodeString(data.access);
                    let LoginTime = dayjs().add(8, 'hour').toISOString();
                    LoginTime = encodeString(LoginTime, constants.ENCODER_LOGIN_TIME);
                    localStorage.setItem('session', token);
                    localStorage.setItem('is_valid', LoginTime);
                    window.history.replaceState(null, null, ' ');
                    Navigate(0);
                } else {
                    throw "Server Error";
                }
            }
        ).catch(
            (err) => {
                console.error(err);
                Store.addNotification({ ...constants.ERRORTOAST, message: 'Login failed' });
            }
        ).finally(
            () => {
                _form(old => ({
                    ...old,
                    isSubmitting: false,
                }));
            }
        )
    };

    const handleRouteToVerifyScreen = () => {
        Navigate('/verify');
    };

    const handleBackToLoginForm = () => _loginAttempt(false);

    const handleMoveToOTPForm = () => _loginAttempt(true);

    // *********** Render Functions ***********
    const BACKGROUND = () => (
        <div className="screen__background">
            <span className="screen__background__shape screen__background__shape4" />
            <span className="screen__background__shape screen__background__shape3" />
            <span className="screen__background__shape screen__background__shape2" />
            <span className="screen__background__shape screen__background__shape1" />
        </div>
    );

    const LOGINFORM = () => (
        <form className="login_form" onSubmit={handlePreSubmitCheck}>
            <div className='logo'><img className={`img-logo`} src="/assets/images/spa_logo.png" alt="user" /> </div>
            <div className="login__field flex items-center">
                <UserSolid className='absolute' />
                <input
                    required={true}
                    name='user'
                    type="text"
                    className="login__input"
                    placeholder="User name / Email"
                    onChange={handleFormChange}
                />
            </div>
            <div className="login__field flex items-center">
                <PadlockSolid className='absolute' />
                <input
                    required={true}
                    name='password'
                    type="password"
                    className="login__input"
                    placeholder="Password"
                    onChange={handleFormChange}
                />
            </div>
            <button className="button login__submit" type='submit'>
                <span className="button__text">Log In Now</span>
                <ChevronRight className='ml-auto' />
            </button>
            <span className="button login__submit" onClick={handleRouteToVerifyScreen}>
                <span className="button__text">Forgot Password</span>
                <ChevronRight className='ml-auto' />
            </span>
        </form>
    );

    const OTP_FORM = () => (
        <form className="login_form" onSubmit={handleSubmitVerifyLogin}>
            <div className='logo'><img className={`img-logo`} src="/assets/images/spa_logo.png" alt="user" /> </div>
            <div className='w3-medium w3-margin-top text-primary-blue'> A code has been sent to your email address. Please enter the code to continue. </div>
            <div className="login__field flex items-center">
                <PadlockSolid className='absolute' />
                <input
                    required={true}
                    name='otp'
                    type="text"
                    className="login__input"
                    placeholder="Code"
                    onChange={handleFormChange}
                />
            </div>
            <button className="button login__submit" type='submit'>
                <span className="button__text">Next</span>
                <ChevronRight className='ml-auto' />
            </button>
            <span className="button login__submit" onClick={handleBackToLoginForm}>
                <ChevronRight className='mr-auto rotate-180' />
                <span className="button__text">Back</span>
            </span>
        </form>
    );

    return (
        <div id='Login'>
            <div className="container">
                <div className="screen">
                    {BACKGROUND()}
                    <div className="screen__content">
                        {loginAttempt && OTP_FORM()}
                        {!loginAttempt && LOGINFORM()}
                    </div>
                </div>
            </div>
        </div>
    )
}
