import { constants } from "../utils";

const UserFleetEditRequest = (token, fleetId) => {
    var requestHeaders = new Headers();
    requestHeaders.append("Authorization", `Bearer ${token}`);
    requestHeaders.append("Content-Type", "application/json");
    return (fetch(`${constants.BASEURL}/api/userfleets/change_user_fleet/${fleetId}/`, { 
        method: 'PUT',        
        headers: requestHeaders,
        redirect: 'follow'
    }));
};

export default UserFleetEditRequest;
