// Packages
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { Store } from 'react-notifications-component';
import dayjs from 'dayjs';

// APIs
import { FsrListRequest, FsrSearchRequest, ApprovedFsrListRequest, FsrCompleteRequest, FsrStatusChangeRequest, FsrLockRequest, ApprovedFsrMarkCompleteLegRequest } from '../../../requests';

// Utils
import { constants, decodeString, authenticationErrorHandle } from '../../../utils';
import { auth, loggedUserInfoCache } from '../../../atoms';
import { TableLite, SearchBar, Modal, Plus, Warn } from '../../../components';
import { CompleteFsrForm, CustomFsrTable, CustomFsrFiltersModal } from './components';


function RequestList() {

  const [fsrList, _fsrList] = useState([]);
  const [approvedFsrList, _approvedFsrList] = useState([]);
  const [expandedFsrListIds, _expandedFsrListIds] = useState([]);
  const [fsrFilters, _fsrFilters] = useState({ ...constants.AVAILABLE_FSR_FILTERS });
  const [filterQueryString, _filterQueryString] = useState('');
  const [isLoading, _isLoading] = useState(false);
  const [loadingApprovedFsrList, _loadingApprovedFsrList] = useState([]);
  const [isSubmitting, _isSubmitting] = useState(false);
  const [showRequestCancelModal, _showRequestCancelModal] = useState(false);
  const [showRequestLockModal, _showRequestLockModal] = useState(false);
  const [showRequestCompleteModal, _showRequestCompleteModal] = useState(false)
  const [showFilterModal, _showFilterModal] = useState(false);
  const [searchString, _searchString] = useState('');
  const [selectedFSR, _selectedFSR] = useState(null);
  const [authState, _authState] = useAtom(auth);
  const [loggedUserInfo, _loggedUserInfo] = useAtom(loggedUserInfoCache);
  const [pagination, _pagination] = useState({
    paginationAvailable: 0,
    currentPage: 1,
    totalPages: 1
  });

  // Get requests either from cache or from server
  useEffect(() => {
    if (authState && !isLoading) {
      getFsrList();
    }
  }, [authState]);

  const getFsrList = (page = undefined, filters = undefined) => {
    const token = decodeString(authState);
    _isLoading(true);

    // If filters are not available in func parameters, clear the filter state
    if (!Boolean(filters)) {
      _filterQueryString('');
      _fsrFilters({ ...constants.AVAILABLE_FSR_FILTERS });
    }

    FsrListRequest(token, page, filters).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data && data.results) {
        _isLoading(false);
        const fsr = data.results.map(fsr => ({
          ...fsr,
          // id: `Request-ID ${fsr.id}`,
          id: `${fsr.id}`,
          createdat: dayjs(fsr?.createdat).format('YYYY-MM-DD HH:mm'),
          flight_log: fsr?.status || 'pending'
        }));
        const newPagination = {
          currentPage: page,
          paginationAvailable: Boolean(filters) ? 0 : data?.count,  // disable pagination if there are filters pres
          totalPages: Math.ceil(data?.count / constants.PAGINATIONPERPAGE)
        };
        _fsrList(fsr);
        if (data?.count) _pagination(newPagination);
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch FSR list' });
        }
      )
  };

  const getApprovedFsrList = (requestId) => {
    const token = decodeString(authState);
    if (loadingApprovedFsrList?.find(id => id === requestId)) return;
    _loadingApprovedFsrList(old => [...old, requestId]);
    ApprovedFsrListRequest(token, requestId).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        _approvedFsrList(old => [...old, ...data]);
        _loadingApprovedFsrList(old => [...old?.filter(id => id !== requestId)]);
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          console.error(err);
          _loadingApprovedFsrList(old => [...old?.filter(id => id !== requestId)]);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch approved request history' });
        }
      );
  };

  const Navigate = useNavigate();

  const FSRheaders = ["id", "flight_number", "aircraft_tailnumber", "purpose_of_flight", "createdat", "flight_log"];

  const CustomHeaders = {
    "id": "Request Id",
    "flight_number": "Flight No",
    "aircraft_tailnumber": "Aircraft",
    "purpose_of_flight": "Request Type",
    "createdat": "Date of Request",
    "flight_log": "Flight Log",
  };

  const sortBy = ["id", "flight_number", "purpose_of_flight", "createdat"];

  // *********** Handlers ***********

  const handleSearchString = (e) => {
    _searchString(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchString.trim()?.length < 1) {
      getFsrList();
      return;
    }
    const token = decodeString(authState);
    _isLoading(true);
    // Clear filters on search
    _filterQueryString('');
    _fsrFilters({ ...constants.AVAILABLE_FSR_FILTERS });
    FsrSearchRequest(token, searchString).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        const fsr = data.map(fsr => ({
          ...fsr,
          // id: `Request-ID ${fsr.id}`,
          id: `${fsr.id}`,
          createdat: dayjs(fsr?.createdat).format('YYYY-MM-DD HH:mm'),
          flight_log: fsr?.status || 'pending'
        }));
        _fsrList(fsr);
        const newPagination = {
          paginationAvailable: 0,
          currentPage: 1,
          totalPages: 1
        }
        _pagination(newPagination);
        _isLoading(false);
      } else {
        throw 'Request Failed';
      }
    }
    ).catch(
      err => {
        _isLoading(false);
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch FSR list' });
      }
    )
  };

  const handlePaginate = (pageNo) => {
    getFsrList(pageNo);
  };

  const handleChangeFilters = (filterName, value) => {
    _fsrFilters(old => ({
      ...old,
      [filterName]: {
        ...old?.[filterName],
        selected: String(old?.[filterName]?.selected) === String(value) ? '' : value
      }
    }))
  };

  const handleClearFilters = () => {
    _searchString('');
    handleCloseFilterModal();
    getFsrList();
  };

  const handleApplyFilters = () => {
    let queryString = '';
    let appliedFilters = Object.keys(fsrFilters);
    appliedFilters = appliedFilters.filter(F => fsrFilters[F]?.selected !== '');
    appliedFilters = appliedFilters.map(F => `${F}=${fsrFilters[F]?.selected}`);
    queryString = appliedFilters.join('&');
    _searchString('');
    _filterQueryString(queryString);
    getFsrList(undefined, queryString);
    handleCloseFilterModal();
  };

  const handleCancelRequest = (fsr) => {
    _selectedFSR(fsr);
    _showRequestCancelModal(true);
  };

  const handleCompleteRequest = (approvedFsr) => {
    _selectedFSR(approvedFsr);
    _showRequestCompleteModal(true);
  };

  const handleLockRequest = (approvedFsr) => {
    _selectedFSR(approvedFsr);
    _showRequestLockModal(true);
  };

  const handleShowFilterModal = () => {
    _showFilterModal(true);
  };

  const handleCloseFilterModal = () => {
    _showFilterModal(false);
  };

  const handleCloseRequestCancelModal = () => {
    _selectedFSR(null);
    _showRequestCancelModal(false);
  };

  const handleCloseRequestLockModal = () => {
    _selectedFSR(null);
    _showRequestLockModal(false);
  };

  const handleCloseRequestCompleteModal = () => {
    _selectedFSR(null);
    _showRequestCompleteModal(false);
  };

  const handleToggleExpandFsr = (fsr) => {
    if (expandedFsrListIds?.find(id => id == fsr?.id)) {
      _expandedFsrListIds(old => old?.filter(id => id != fsr?.id))
    } else _expandedFsrListIds(old => [...old, fsr?.id]);
    if (approvedFsrList?.find(approvedFsr => approvedFsr?.request == fsr?.id)) {
      _approvedFsrList(old => old?.filter(approvedFsr => approvedFsr?.request != fsr?.id));
    } else
      getApprovedFsrList(fsr?.id);
  };

  const handleConfirmFsrCancel = () => {
    const token = decodeString(authState);
    _isSubmitting(true);
    const data = { ...fsrList?.find(fsr => fsr?.id == selectedFSR?.id) };
    data.status = 'Cancelled';
    data.id = data.id.replace(/[A-Za-z/\s-]/g, '');
    const statusChanged = { status: data.status };
    const form_data = new FormData();
    form_data.append('data', JSON.stringify(data));
    const JSONStringData = JSON.stringify(statusChanged);
    FsrStatusChangeRequest(token, JSONStringData, data?.id)
      .then((res) => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
          throw 'Request Failed';
        } else return res.json();
      })
      .then((data) => {
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if ((typeof data === 'string' && data.indexOf('Error') > -1)) {
          throw "Request Failed";
        } else {
          getFsrList();
          _isSubmitting(false);
          handleCloseRequestCancelModal();
          Store.addNotification({ ...constants.SUCCESSTOAST, message: "Request cancelled" });
        }
      })
      .catch((err) => {
        _isSubmitting(false);
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: "Request failed" });
      });
  };

  const handleConfirmFsrLock = () => {
    const token = decodeString(authState);
    _isSubmitting(true);
    const data = { ...fsrList?.find(fsr => fsr?.id == selectedFSR?.request) };
    data.status = 'Locked';
    data.id = data.id.replace(/[A-Za-z/\s-]/g, '');
    const statusChanged = { status: data.status };
    const form_data = new FormData();
    form_data.append('data', JSON.stringify(data));
    const JSONStringData = JSON.stringify(statusChanged);
    FsrLockRequest(token, JSONStringData, data?.id)
      .then((res) => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
          throw 'Request Failed';
        } else return res.json();
      })
      .then((data) => {
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if ((typeof data === 'string' && data.indexOf('Error') > -1)) {
          throw "Request Failed";
        } else {
          getFsrList();
          _isSubmitting(false);
          handleCloseRequestLockModal();
          Store.addNotification({ ...constants.SUCCESSTOAST, message: "Request locked" });
        }
      })
      .catch((err) => {
        _isSubmitting(false);
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: "Request failed" });
      });
  };

  const handleCompleteFsr = (e, approvedFsrDetils) => {
    const token = decodeString(authState);
    const submitData = JSON.stringify({ ...approvedFsrDetils });
    _isSubmitting(true);
    FsrCompleteRequest(token, submitData, approvedFsrDetils?.request).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if ((typeof data === 'string' && data.indexOf('Error') > -1)) {
        throw "Request Failed";
      } else {
        Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Request completed successfully' });
        _isSubmitting(false);
        handleCloseRequestCompleteModal();
        getFsrList();
      }
    }).catch(
      err => {
        console.error(err);
        _isSubmitting(false);
        Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to complete request' });
      }
    );
  };

  const handleCompleteLeg = (approvedFsrLeg) => {
    const data = {
      id: approvedFsrLeg?.id,
      arrival_actual_time: approvedFsrLeg?.arrival_actual_time,
      departure_actual_time: approvedFsrLeg?.departure_actual_time
    };
    const token = decodeString(authState);
    const submitData = JSON.stringify({ ...data });
    _isSubmitting(true);
    ApprovedFsrMarkCompleteLegRequest(token, submitData).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if ((typeof data === 'string' && data.indexOf('Error') > -1)) {
        throw "Request Failed";
      } else {
        Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Leg completed successfully' });
        _isSubmitting(false);
        handleCloseRequestCompleteModal();
        getFsrList();
      }
    }).catch(
      err => {
        console.error(err);
        _isSubmitting(false);
        Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to complete leg' });
      }
    );
  }

  const routeToNewRequest = () => {
    Navigate('/requests/new');
  };

  const routeToViewRequest = (id) => {
    const requestId = id.replace(/[\sA-Za-z-]/g, '');
    // if (filterQueryString) {
    //   window.open(`/requests/view/${requestId}`, '_blank');
    // } else
    // Navigate(`/requests/view/${requestId}`);
    window.open(`/requests/view/${requestId}`, '_blank');
  };

  const routeToViewRequestHistory = (requestId) => {
    // Navigate(`/requests/history/view/${requestId}`);
    window.open(`/requests/history/view/${requestId}`, '_blank');
  };

  const routeToEditRequest = (id, fsr) => {
    if (fsr && (fsr?.status?.toLowerCase() === 'cancelled' || fsr?.status?.toLowerCase() === 'completed')) {
      _selectedFSR(fsr);
      _showRequestCancelModal(true);
      return;
    }
    const requestId = id.replace(/[\sA-Za-z-]/g, '');
    // if (filterQueryString) {
    //   window.open(`/requests/edit/${requestId}`, '_blank');
    // } else
    Navigate(`/requests/edit/${requestId}`);
  };

  // *********** Render Functions ***********

  const REQUEST_CANCEL_MODAL = () => (
    <Modal isOpen={showRequestCancelModal} modalClass="w-fit w3-round-medium">
      <div className="w-full">
        <div className='w3-pale-red w3-text-red py-1 px-2 flex flex-wrap items-center'>
          <Warn className='w-6 h-6 small-right-margin' />
          <h3>
            Cancel Request
          </h3>
        </div>
        <div className='py-1 px-2'>
          <React.Fragment>
            Are you sure you want to cancel
            <span className='bold'>{` Request ID ${selectedFSR?.id}`}</span>
            ?
            <p className='w3-text-red'> Cancelled requests can not be changed again </p>
          </React.Fragment>
          <div className="h-3"></div>
          <div className="flex justify-end">
            <button onClick={handleCloseRequestCancelModal} className='w3-btn w3-grey w3-text-white'> Close </button>
            <button onClick={handleConfirmFsrCancel} disabled={isSubmitting} className='w3-btn w3-red small-left-margin'>
              {isSubmitting ? 'Processing' : 'Confirm'}
            </button>
          </div>
          <div className="h-3"></div>
        </div>
      </div>
    </Modal>
  );

  const REQUEST_LOCK_MODAL = () => (
    <Modal isOpen={showRequestLockModal} modalClass="w-fit w3-round-medium">
      <div className="w-full">
        <div className='w3-text-pink w3-pale-red py-1 px-2 flex flex-wrap items-center'>
          <Warn className='w-6 h-6 small-right-margin' />
          <h3>
            Lock Request
          </h3>
        </div>
        <div className='py-1 px-2'>
          <React.Fragment>
            Are you sure you want to lock
            <span className='bold'>{` Request ID ${selectedFSR?.request}`}</span>
            ?
            <p className='w3-text-pink'> Locked requests can not be changed again </p>
          </React.Fragment>
          <div className="h-3"></div>
          <div className="flex justify-end">
            <button onClick={handleCloseRequestLockModal} className='w3-btn w3-grey w3-text-white'> Close </button>
            <button onClick={handleConfirmFsrLock} disabled={isSubmitting} className='w3-btn w3-pink w3-text-white small-left-margin'>
              {isSubmitting ? 'Processing' : 'Confirm'}
            </button>
          </div>
          <div className="h-3"></div>
        </div>
      </div>
    </Modal>
  );


  const REQUEST_COMPLETE_MODAL = () => (
    <Modal isOpen={showRequestCompleteModal} modalClass="w3-round-medium">
      <div className="w-full">
        <div className='w3-pale-green text-success py-1 px-2 flex flex-wrap items-center'>
          <Warn className='w-6 h-6 small-right-margin' />
          <h3>
            Complete Legs
          </h3>
        </div>
        <div className='py-1 px-2'>
          <React.Fragment>
            Mark completed legs in
            <span className='bold'>{` Request ID ${selectedFSR?.request}`} </span>
            <p className='text-success'> Completed legs can not be changed again </p>
          </React.Fragment>
          {
            selectedFSR
              ? <CompleteFsrForm
                approvedFsr={selectedFSR}
                isSubmitting={isSubmitting}
                handleCompleteFsr={handleCompleteFsr}
                handleCompleteLeg={handleCompleteLeg}
                handleCloseRequestCompleteModal={handleCloseRequestCompleteModal}
              />
              : null
          }
          <div className="h-3"></div>
        </div>
      </div>
    </Modal>
  );

  const NEW_REQUEST_BUTTON = () => (
    <div className="flex w3-margin-bottom">
      <div className="ml-auto">
        <div className="flex wrap">
          <button className="flex items-center w3-btn bg-primary-blue w3-text-white small-right-margin" onClick={handleShowFilterModal}>
            <span className="px-1">
              {`Filters (${Object.keys(fsrFilters)?.reduce((acc, filter) => (fsrFilters?.[filter]?.selected !== "" ? acc + 1 : acc), 0)
                })`}
            </span>
          </button>
          <button className='flex items-center w3-btn bg-primary-blue w3-text-white ml-auto' onClick={routeToNewRequest}>
            <Plus className='w-5 h-5' /> <span className='px-1'> Create FSR </span>
          </button>
        </div>
      </div>
    </div>
  );

  const FSR_FILTERS = () => (
    <CustomFsrFiltersModal
      fsrFilters={fsrFilters}
      showFilterModal={showFilterModal}
      handleChangeFilters={handleChangeFilters}
      handleApplyFilters={handleApplyFilters}
      handleClearFilters={handleClearFilters}
    />
  );

  const CONTENT = () => (
    <div className='page-content w3-white h-full relative overflow-hidden'>
      <div className='py-2'>
        {NEW_REQUEST_BUTTON()}
        {FSR_FILTERS()}
        <div className='list-view-container overflow-auto'>
          <SearchBar
            placeholder='Search by Flight No, Airbase or Aircraft ...'
            containerClass='w-full relative'
            className='w3-input w3-medium'
            value={searchString}
            onChange={handleSearchString}
            onSearch={handleSearch}
            buttonClass='cursor-pointer no-background w3-border-0 p-1 absolute right-0 small-right-margin'
          />
          <CustomFsrTable
            fsrList={fsrList}
            isLoading={isLoading}
            headers={Object.values(CustomHeaders)}
            approvedFsrList={approvedFsrList}
            expandedFsrListIds={expandedFsrListIds}
            loadingApprovedFsrList={loadingApprovedFsrList}
            toggleExpandFsr={row => handleToggleExpandFsr(row)}
            handleLockRequest={row => handleLockRequest(row)}
            handleCancelRequest={row => handleCancelRequest(row)}
            handleCompleteRequest={row => handleCompleteRequest(row)}
            routeToViewRequestHistory={row => routeToViewRequestHistory(row.id)}
            routeToViewRequest={row => routeToViewRequest(row.id)}
            routeToEditRequest={row => routeToEditRequest(row.id, row)}
            isStaff={loggedUserInfo && loggedUserInfo?.data?.is_staff}
          />
          {/* Old table code, currently only used for pagination */}
          <TableLite
            data={[]}
            // data={fsrList}
            headers={FSRheaders}
            showActions={false}
            customHeaders={CustomHeaders}
            showPagination={pagination?.paginationAvailable}
            totalPages={pagination?.totalPages}
            currentPage={pagination?.currentPage}
            onPaginate={(pageNo) => handlePaginate(pageNo)}
            cellStyle={{ display: 'none' }}
            rowStyle={{ display: 'none' }}
            headerStyle={{ display: 'none' }}
            noDataMessage={isLoading ? 'Loading data...' : 'No data found'}
          />
        </div>
      </div>
      {REQUEST_LOCK_MODAL()}
      {REQUEST_CANCEL_MODAL()}
      {REQUEST_COMPLETE_MODAL()}
    </div>
  )
  return (
    <div>
      {CONTENT()}
    </div>
  )
}

export default RequestList;