// Packages
import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import CreatableSelect from 'react-select/creatable';
import { useAtom } from 'jotai';

// Utils
import { Modal, Plane, X } from '../../../../components'
import { constants, generateUID, ignoreTimeZone } from '../../../../utils';
import { AddedHotels, AddedCaterings, AddedTransports, AddedRentalCars, CreateCrewMemberModal } from '.';
import { loggedUserInfoCache } from '../../../../atoms';

const LegSetupModal = (
  {
    leg,
    legs,
    services,
    crewMembers,
    handleSaveUpdatedLeg,
    getCrewMembersByFleet,
    selectedFleet,
    fleet,
    isOpen,
    closeModal,
    modalClass
  }) => {
  const [loggedUserInfo, _loggedUserInfo] = useAtom(loggedUserInfoCache);
  const [showAddCrewMemberModal, _showAddCrewMemberModal] = useState(false);
  const [groundHandlingSearch, _groundHandlingSearch] = useState({ to: '', from: '' });
  const [fleetSearch, _fleetSearch] = useState({ to: '', from: '' });

  // create a local copy of leg
  const [localCopyLeg, _localCopyLeg] = useState({ ...leg });
  useEffect(() => {
    if (leg) {
      _localCopyLeg({ ...leg });
    }
  }, [leg]);

  // *********** Handlers ***********

  const handleSetHotel = (legId, e) => {
    const Leg = { ...localCopyLeg };
    if (e && e.target.checked) {
      // let checkInDateTime = dayjs(leg?.departure_date)?.format('YYYY-MM-DD');
      // let checkOutDateTime = dayjs(leg?.arrival_date)?.format('YYYY-MM-DD');
      let checkInDateTime = dayjs(leg?.departure_date +'T'+ leg?.departure_time+":00Z")?.toISOString();
      let checkOutDateTime = dayjs(leg?.arrival_date +'T'+ leg?.arrival_time+":00Z")?.toISOString();
      const nextLeg = legs[legs?.findIndex(L => L.id == legId) + 1];
      if (nextLeg) {
        // checkInDateTime = dayjs(leg?.arrival_date)?.format('YYYY-MM-DD');
        // checkOutDateTime = dayjs(nextLeg?.departure_date)?.format('YYYY-MM-DD');
        checkInDateTime = dayjs(leg?.arrival_date +'T'+ leg?.arrival_time+":00Z")?.toISOString();
        checkOutDateTime = dayjs(nextLeg?.departure_date +'T'+ nextLeg?.departure_time+":00Z")?.toISOString();
      }            
      Leg.requestleghotel_requestLegHotel = [{
        id: generateUID('hotel_'),
        hotelName: 'hotel',
        hotelType: 'hotel',
        paxcat: 0,
        description: "",
        crewcat: Leg?.requestlegcrewmember_crewMembers?.length,
        requestleghotelpax_requestLegHotelPAX: [
          ...Leg?.requestlegcrewmember_crewMembers?.map(C => ({ crewMembers: C?.crewmember }))
        ],
        checkInDateTime,
        checkOutDateTime
      }];
    } else {
      Leg.requestleghotel_requestLegHotel = [];
    }
    _localCopyLeg({ ...Leg });
  };

  const handleSethotelField = (legId, e, type) => {
    const Leg = { ...localCopyLeg };
    if (Leg?.requestleghotel_requestLegHotel?.length > 0) {
      Leg?.requestleghotel_requestLegHotel?.forEach(L => {
        if (e.target.name.includes('check') && type === 'date'){
          const datepart =  e.target.value;
          const timepart = constants.TIME_FORMAT_REGEX.exec(L[e.target.name])?.[1];          
          L[e.target.name]  = datepart+'T'+timepart+":00.000";
        }
        else if (e.target.name.includes('check') && type === 'time') {
          const datepart = dayjs(L[e.target.name])?.format('YYYY-MM-DD')
          const timepart = dayjs('2001-01-01 '+e.target.value)?.format('HH:mm');
          L[e.target.name] = datepart+'T'+timepart+":00.000";   
          // console.log(L[e.target.name])     
        }
        else L[e.target.name] = e.target.value;
      })
      _localCopyLeg({ ...Leg });
    }
  };

  const handleSethotelPAX = (e) => {
    const Leg = { ...localCopyLeg };
    Leg.requestleghotel_requestLegHotel.forEach(h => {
      if (h?.requestleghotelpax_requestLegHotelPAX?.length == 0) {
        h.requestleghotelpax_requestLegHotelPAX = [{ crewMembers: e.target.value }];
      } else {
        const isCrewAlreadyInPAX = h?.requestleghotelpax_requestLegHotelPAX?.find(pax => Number(pax?.crewMembers) === Number(e.target.value));
        if (isCrewAlreadyInPAX) {
          h.requestleghotelpax_requestLegHotelPAX = h?.requestleghotelpax_requestLegHotelPAX?.filter(pax => Number(pax?.crewMembers) !== Number(e.target.value));
        }
        else
          h.requestleghotelpax_requestLegHotelPAX = [...h?.requestleghotelpax_requestLegHotelPAX, { crewMembers: e.target.value }];
      }
    });
    _localCopyLeg({ ...Leg });
  };

  const handleSetCatering = (legId, e, airportType) => {
    const Leg = { ...localCopyLeg };
    let selectedCatering = [];
    if (e && e.target.checked) {
      selectedCatering = [{
        id: generateUID('catering_'),
        crewcat: (Leg?.departure_airport_type === 'Military' && Leg?.is_local) ? 0 : Leg?.requestlegcrewmember_crewMembers?.length || 1,
        paxcat: parseInt(Leg?.pax_count) || 0,
        description: '',
        service: services?.data?.find(S => S.serviceCategory.toLowerCase() === constants.CATERINGSERVICETYPE)?.id,
        requestLegCateringItem: [],
        type_of_catering: (Leg?.departure_airport_type === 'Military' && Leg?.is_local) ? 'No Catering' : 'Regular',
      }];
    } else {
      selectedCatering = [];
    }
    if (airportType === 'from')
      Leg.requestlegcatering_requestLegCatering_from = [...selectedCatering];
    else
      Leg.requestlegcatering_requestLegCatering_to = [...selectedCatering];
    _localCopyLeg({ ...Leg });
  };

  const handleCateringChange = (legId, name, value, airportType) => {
    const Leg = { ...localCopyLeg };
    if (airportType === 'from')
      Leg.requestlegcatering_requestLegCatering_from[0][name] = value;
    else
      Leg.requestlegcatering_requestLegCatering_to[0][name] = value;
    _localCopyLeg({ ...Leg });
  };

  const handleSetTransport = (index_seq, operation = 'add') => {
    const Leg = { ...localCopyLeg };
    if (!index_seq && operation === 'add') {
      Leg.reqlegtransport_requestLegTransport = [
        ...Leg.reqlegtransport_requestLegTransport,
        {
          type: 'destination',
          transport_type: 'Airport to Hotel',
          transport_from: 'Airport',
          transport_to: 'Hotel',
          transport_time: Leg?.departure_time,
          index_seq: parseInt(Leg.reqlegtransport_requestLegTransport?.length + 1)
        }
      ]
    } else {
      Leg.reqlegtransport_requestLegTransport = Leg?.reqlegtransport_requestLegTransport?.filter(T => T?.index_seq != index_seq)
    }
    _localCopyLeg({ ...Leg });
  };

  const handleChangeTransportField = (index_seq, e) => {
    const Leg = { ...localCopyLeg };
    if (index_seq) {
      Leg.reqlegtransport_requestLegTransport?.forEach(L => {
        if (L?.index_seq === index_seq) {
          L[e.target.name] = e.target.value;
        }
        if (e.target.name === 'transport_type' && e.target.value === 'Airport to Hotel') {
          L.transport_from = 'Airport';
          L.transport_to = 'Hotel';
          L.type = 'destination'
        } else if (e.target.name === 'transport_type' && e.target.value === 'Hotel to Airport') {
          L.transport_from = 'Hotel';
          L.transport_to = 'Airport';
          L.type = 'departure';
        }
      })
    }
    _localCopyLeg({ ...Leg });
  };

  // const handleSetTransport = (legId, e, type, value) => {
  //   const Leg = { ...localCopyLeg };
  //   if (e && e.target.checked) {
  //     Leg.reqlegtransport_requestLegTransport = [
  //       ...Leg.reqlegtransport_requestLegTransport,
  //       {
  //         type,
  //         index_seq: parseInt(Leg.reqlegtransport_requestLegTransport?.length + 1),
  //         transport_type: value,
  //         transport_time: type === 'destination' ? Leg?.arrival_time : Leg?.departure_time
  //       }
  //     ]
  //   } else {
  //     Leg.reqlegtransport_requestLegTransport = Leg?.reqlegtransport_requestLegTransport?.filter(T => !(T.type === type && T.transport_type == value))
  //   }
  //   _localCopyLeg({ ...Leg });
  // };

  const handleChangeQuantity = (airportType, serviceId, e) => {
    const Leg = { ...localCopyLeg };
    if (airportType === 'from') {
      Leg.requestlegservice_services_from = Leg?.requestlegservice_services_from?.map(legService => {
        if (legService.service === serviceId) {
          legService.quantity = parseInt(e.target.value.replace(/[A-Za-z ]/g, ''));
        }
        return legService;
      });
    } else {
      Leg.requestlegservice_services_to = Leg?.requestlegservice_services_to?.map(legService => {
        if (legService.service === serviceId) {
          legService.quantity = parseInt(e.target.value.replace(/[A-Za-z ]/g, ''));
        }
        return legService;
      });
    }
    _localCopyLeg({ ...Leg });
  };

  const handleChangeLegPax = (e) => {
    const Leg = { ...localCopyLeg };
    Leg.pax_count = e.target.value;
    _localCopyLeg({ ...Leg });
  };

  const handleChangeLegFuelUplift = (e) => {
    const Leg = { ...localCopyLeg };
    Leg.fuel_uplift = e.target.value;
    _localCopyLeg({ ...Leg });
  };

  const handleChangeLegPermitDescription = (e) => {
    const Leg = { ...localCopyLeg };
    Leg.permit_description = e.target.value;
    _localCopyLeg({ ...Leg });
  };

  const handleChangeLegType = (e) => {
    const Leg = { ...localCopyLeg };
    Leg.leg_type = e.target.value;
    _localCopyLeg({ ...Leg });
  };

  const handleLegServiceChange = (airportType, serviceId, type) => {
    const Leg = { ...localCopyLeg };
    let selectedServicesArray = airportType === 'from' ? Leg?.requestlegservice_services_from : Leg?.requestlegservice_services_to
    if (selectedServicesArray?.find(legService => legService.service === serviceId)) {
      selectedServicesArray = selectedServicesArray?.filter(legService => legService.service !== serviceId);
    } else {
      const newService = {
        service: serviceId,
        company: loggedUserInfo?.data?.company,
        quantity: type === constants.FLEETSERVICETYPE && Leg?.pax_count || 1
      };
      selectedServicesArray = [...selectedServicesArray, newService];
    }
    if (airportType === 'from') {
      Leg.requestlegservice_services_from = selectedServicesArray?.map(S => ({ ...S, is_destination: false }));
    } else {
      Leg.requestlegservice_services_to = selectedServicesArray?.map(S => ({ ...S, is_destination: true }));
    }
    _localCopyLeg({ ...Leg });
  };

  // Seperate handler for OPS services like FLT-Dispatch Envelope
  const handleLegOPSServiceChange = (airportType, serviceId) => {
    const Leg = { ...localCopyLeg };
    const newService = {
      service: serviceId,
      company: loggedUserInfo?.data?.company,
      quantity: 1
    };
    let from_services = [...Leg?.requestlegservice_services_from];
    let to_services = [...Leg?.requestlegservice_services_to];
    if (from_services?.find(legService => legService.service === serviceId)) {
      from_services = from_services.filter(legService => legService.service !== serviceId)
    } else {
      from_services.push(newService)
    }
    if (to_services?.find(legService => legService.service === serviceId)) {
      to_services = to_services.filter(legService => legService.service !== serviceId)
    } else {
      to_services.push(newService)
    }
    Leg.requestlegservice_services_from = from_services?.map(S => ({ ...S, is_destination: false }));
    Leg.requestlegservice_services_to = to_services?.map(S => ({ ...S, is_destination: true }));
    _localCopyLeg({ ...Leg });
  };

  const handleLegCrewChange = (crew) => {
    // When crewmembers array changes, use many-to-one ids of the existing items in the crewmembers array.
    const newCrewMembers = crew?.map(c => ({
      crewmember: c.value,
      ...(leg?.requestlegcrewmember_crewMembers?.find(crew => crew?.crewmember == c.value) &&
        { id: leg?.requestlegcrewmember_crewMembers?.find(crew => crew?.crewmember == c.value)?.id }),
    }));
    const Leg = { ...localCopyLeg };
    Leg.requestlegcrewmember_crewMembers = newCrewMembers;
    // Change the selected hotel pax when crew in leg is changed
    Leg?.requestleghotel_requestLegHotel?.forEach(h => {
      h.requestleghotelpax_requestLegHotelPAX =
        h.requestleghotelpax_requestLegHotelPAX?.length
          ? Leg?.requestlegcrewmember_crewMembers?.map(C => ({ crewMembers: C?.crewmember }))
          : [];
    });
    _localCopyLeg({ ...Leg });
  };

  const handleServiceSearch = (serviceType, e, airportType) => {
    if (serviceType === constants.GROUNDSERVICETYPE) {
      _groundHandlingSearch(old => ({
        ...old,
        [airportType] : e.target.value
      }));
    } else if (serviceType === constants.FLEETSERVICETYPE) {
      _fleetSearch(old => ({
        ...old,
        [airportType] : e.target.value
      }));
    }
  };

  const handleRemoveAllServices = (airportType, type, e) => {
    const Leg = { ...localCopyLeg };
    const removableServices = services
      ?.filter(s => s.serviceCategory.toLowerCase() === type)
      ?.map(s => s.id) || [];
    if (airportType === 'from') {
      Leg.requestlegservice_services_from = Leg?.requestlegservice_services_from?.filter(legService => !removableServices.includes(legService.service));
    } else {
      Leg.requestlegservice_services_to = Leg?.requestlegservice_services_to?.filter(legService => !removableServices.includes(legService.service));
    }
    _localCopyLeg({ ...Leg });
  };

  const handleSetCarRental = (legId, e) => {
    const Leg = { ...localCopyLeg };
    if (e && e.target.checked) {
      let pick_up_date = dayjs(leg?.departure_date)?.format('YYYY-MM-DD');
      let drop_off_date = dayjs(leg?.arrival_date)?.format('YYYY-MM-DD');
      const nextLeg = legs[legs?.findIndex(L => L.id == legId) + 1];
      if (nextLeg) {
        pick_up_date = dayjs(leg?.arrival_date)?.format('YYYY-MM-DD');
        drop_off_date = dayjs(nextLeg?.departure_date)?.format('YYYY-MM-DD');
      }
      Leg.reqlegcarrental_requestLegCarRental = [{
        id: generateUID('car_rental_'),
        car_rental_type: '',
        crewmembers: [],
        rental_days: dayjs(drop_off_date).diff(dayjs(pick_up_date), 'day') + 1,
        pick_up_date,
        drop_off_date
      }];
    } else {
      Leg.reqlegcarrental_requestLegCarRental = [];
    }
    _localCopyLeg({ ...Leg });
  };

  const handleCarRentalChange = (legId, carRentalId, name, value) => {
    const Leg = { ...localCopyLeg };
    const carRentalIndex = Leg.reqlegcarrental_requestLegCarRental.findIndex(T => T.id === carRentalId);
    Leg.reqlegcarrental_requestLegCarRental[carRentalIndex][name] = value;
    Leg.reqlegcarrental_requestLegCarRental[carRentalIndex].rental_days = dayjs(Leg.reqlegcarrental_requestLegCarRental[carRentalIndex]?.drop_off_date)
      .diff(dayjs(Leg.reqlegcarrental_requestLegCarRental[carRentalIndex]?.pick_up_date), 'day') + 1;
    _localCopyLeg({ ...Leg });
  };

  const handleAddCrewMemberSave = (crewMemberObj, legId) => {
    if (crewMemberObj && legId) {
      const Leg = { ...localCopyLeg };
      const newCrewMembers = [...Leg?.requestlegcrewmember_crewMembers, { crewmember: crewMemberObj?.id }];
      Leg.requestlegcrewmember_crewMembers = newCrewMembers;
      // Unset the selected hotel pax when crew in leg is changed
      Leg?.requestleghotel_requestLegHotel?.forEach(h => {
        h.requestleghotelpax_requestLegHotelPAX = []
      });
      _localCopyLeg({ ...Leg });
    }
    handleCloseAddCrewMemberModal();
    getCrewMembersByFleet();
  };

  const handleOpenAddCrewMemberModal = () => {
    _showAddCrewMemberModal(true);
  };

  const handleCloseAddCrewMemberModal = () => {
    _showAddCrewMemberModal(false);
  };

  // *********** Render Functions ***********

  const AIRPORT_COLUMN = (airportType, airportLabel) => {
    const selectedServicesArray = airportType === 'from' ? localCopyLeg?.requestlegservice_services_from : localCopyLeg?.requestlegservice_services_to;
    return (
      <div className='w3-col w3-custom-half w3-categories'>
        <div className='w3-row-padding'>
          <div className='w3-col w3-border-bottom w3-border-dark-gray text-center'>
            <h4 className='text-primary-blue'><Plane className="img-svg-plane" />{airportLabel}</h4>
          </div>
        </div>
        <div
          className='w3-row-padding'
          style={airportType !== 'from' ? { opacity: 0 } : {}}
        >
          <div className='w-full'>
            <h4 className='flex'> <span>Ops</span> </h4>
          </div>

          <div className='services-option-container w3-round overflow-y-auto thin-scroll thin-scroll-chromium thin-scroll-firefox w3-border'>
            {
              services
                ?.sort((a, b) => a.name.localeCompare(b.name))
                ?.filter(s => s.serviceCategory.toLowerCase() === constants.OPSSERVICETYPE)
                ?.map(s => ({ label: s.name, value: s.id }))
                ?.map((service, index) => (
                  <div key={index} className='service-option flex items-center justify-between'>
                    <div className='flex w3-medium'>
                      <input
                        checked={selectedServicesArray?.find(s => s.service === service.value) || false}
                        className='w3-input w-4 small-right-margin'
                        onChange={e => handleLegOPSServiceChange(airportType, service?.value, constants.OPSSERVICETYPE)}
                        id={`${airportType}-service-${service.value}`}
                        disabled={airportType !== 'from'}
                        value={service.value}
                        type='checkbox'
                      />
                      <label htmlFor={`${airportType}-service-${service.value}`}> {service.label}</label>
                    </div>
                  </div>
                ))
            }
          </div>
        </div>

        <div className='w3-row'>
          <div className='w3-col w3-custom-half'>
            <div className='w3-row-padding'>
              <div className='w-full'>
                <h4 className='flex'> <span>Ground Handling</span> </h4>
                <div className='flex w3-medium small-bottom-margin'>
                  <button
                    type='button'
                    className='bg-primary-blue w3-text-white w3-small w3-btn'
                    onClick={e => handleRemoveAllServices(airportType, constants.GROUNDSERVICETYPE, e)}
                  >
                    No Services
                  </button>
                </div>
              </div>
              <div className='services-option-container relative w3-round overflow-y-auto thin-scroll thin-scroll-chromium thin-scroll-firefox w3-border pt-0'>
                <div className='sticky top-0 w3-white'>
                  <input className='w3-input' placeholder='Search...' onChange={e => handleServiceSearch(constants.GROUNDSERVICETYPE, e, airportType)} />
                </div>
                {
                  services
                    ?.sort((a, b) => a.name.localeCompare(b.name))
                    ?.filter(s => s.serviceCategory.toLowerCase() === constants.GROUNDSERVICETYPE)
                    ?.map(s => ({ label: s.name, value: s.id }))
                    ?.map((service, index) => (
                      <div
                        key={index}
                        className={`
                service-option flex items-center justify-between
               ${groundHandlingSearch[airportType] &&
                            !service?.label?.toLowerCase()?.includes(groundHandlingSearch[airportType]?.toLowerCase())
                            ? 'hidden'
                            : ''
                          }`
                        }
                      >
                        <div className='flex w3-medium'>
                          <input
                            checked={selectedServicesArray?.find(s => s.service === service.value) || false}
                            className='w3-input w-4 small-right-margin'
                            onChange={e => handleLegServiceChange(airportType, service?.value, constants.GROUNDSERVICETYPE)}
                            id={`${airportType}-service-${service.value}`}
                            value={service.value}
                            type='checkbox'
                          />
                          <label htmlFor={`${airportType}-service-${service.value}`}> {service.label}</label>
                        </div>
                      </div>
                    ))
                }
              </div>
            </div>
          </div>
          <div className='w3-col w3-custom-half'>
            <div className='w3-row-padding'>
              <div className='w-full'>
                <h4 className='flex'> <span>Fleet Service</span> </h4>
                <div className='flex w3-medium small-bottom-margin'>
                  <button
                    type='button'
                    className='bg-primary-blue w3-text-white w3-small w3-btn'
                    onClick={e => handleRemoveAllServices(airportType, constants.FLEETSERVICETYPE, e)}
                  >
                    No Services
                  </button>
                </div>
              </div>
              <div className='services-option-container w3-round overflow-y-auto thin-scroll thin-scroll-chromium thin-scroll-firefox w3-border pt-0'>
                <div className='sticky top-0 w3-white'>
                  <input className='w3-input' placeholder='Search...' onChange={e => handleServiceSearch(constants.FLEETSERVICETYPE, e, airportType)} />
                </div>
                {
                  services
                    ?.sort((a, b) => a.name.localeCompare(b.name))
                    ?.filter(s => s.serviceCategory.toLowerCase() === constants.FLEETSERVICETYPE)
                    ?.map(s => ({ label: s.name, value: s.id }))
                    ?.map((service, index) => (
                      <div
                        key={index}
                        className={`
                  service-option flex items-center justify-between
                 ${fleetSearch[airportType] &&
                            !service?.label?.toLowerCase()?.includes(fleetSearch[airportType]?.toLowerCase())
                            ? 'hidden'
                            : ''
                          }`
                        }
                      >
                        <div className='flex w3-medium'>
                          <input
                            checked={selectedServicesArray?.find(s => s.service === service.value) || false}
                            className='w3-input w-4 small-right-margin'
                            onChange={e => handleLegServiceChange(airportType, service?.value, constants.FLEETSERVICETYPE)}
                            id={`${airportType}-service-${service.value}`}
                            value={service.value}
                            type='checkbox'
                          />
                          <label htmlFor={`${airportType}-service-${service.value}`}> {service.label}</label>
                        </div>
                        {
                          <input
                            type='number'
                            placeholder='Quantity'
                            min={1}
                            onChange={e => handleChangeQuantity(airportType, service.value, e)}
                            value={selectedServicesArray?.find(s => s.service === service.value)?.quantity || ''}
                            className={
                              `small-left-margin w3-round w-28 w3-input
                        ${!selectedServicesArray?.find(s => s.service === service.value) ? 'w3-disabled' : ''}`
                            }
                            disabled={!selectedServicesArray?.find(s => s.service === service.value)}
                          />
                        }
                      </div>
                    ))
                }
              </div>
            </div>
          </div>
        </div>
        {airportType === 'from'
          && <div className='w3-row-padding'>
            <AddedCaterings
              leg={localCopyLeg}
              airportType={airportType}
              handleSetCatering={handleSetCatering}
              handleCateringChange={handleCateringChange}
            />
          </div>
        }
      </div>
    )
  };

  const ADD_CREWMEMBER_MODAL = () => (
    <>
      {showAddCrewMemberModal &&
        <CreateCrewMemberModal
          fleet={fleet}
          isOpen={showAddCrewMemberModal}
          selectedLeg={localCopyLeg}
          selectedFleet={selectedFleet}
          onCrewMemberCreate={handleAddCrewMemberSave}
          handleCloseAddCrewMemberModal={handleCloseAddCrewMemberModal}
        />
      }
    </>
  );

  return (
    <Modal isOpen={isOpen} modalClass={modalClass} modalContainerClass='z-50'>
      <div className='flex w-full justify-between items-center  p-2'>
        <h3>   {
          `${localCopyLeg?.departure_airport_icao || localCopyLeg?.departure?.airport_code?.[0]}/${localCopyLeg?.departure_airport_iata} → 
          ${localCopyLeg?.destination_airport_icao || localCopyLeg?.destination?.airport_code?.[0]}/${localCopyLeg?.destination_airport_iata}`
        } </h3>
        <X className='w-6 h-6 cursor-pointer' onClick={closeModal} />
      </div>
      <div className='request-modal-body px-2'>
        <div className='w3-row-padding'>
          <div className='w-full'>
            <h4 className='flex'> <span>Crew Members</span> </h4>
          </div>
          <CreatableSelect
            className='w-full'
            isMulti={true}
            onChange={c => handleLegCrewChange(c)}
            onCreateOption={handleOpenAddCrewMemberModal}
            options={
              crewMembers?.map(c => ({ value: c.id, label: `${c.crewname} (${c?.passport || 'N/A'}) (${c?.saudia_id || 'N/A'})` }))
            }
            value={
              crewMembers?.map(c => ({ value: c.id, label: `${c.crewname} (${c?.passport || 'N/A'}) (${c?.saudia_id || 'N/A'})` }))
                ?.filter(crew => localCopyLeg?.requestlegcrewmember_crewMembers
                  ?.map(localCrew => localCrew.crewmember).indexOf(crew?.value) > -1
                )
            }
          />
        </div>

        <div className='w3-row'>
          <div className='w3-col w3-custom-half'>
            <div className='w3-row-padding'>
              <div className='w-full'>
                <h4 className='flex'> <span>Leg Type</span> </h4>
              </div>
              <select
                value={localCopyLeg?.leg_type || ''}
                className={`w-full w3-input w3-white w3-border w3-round`}
                onChange={handleChangeLegType}
              >
                <option value={""} disabled > Select type </option>
                <option value='Normal'> Normal </option>
                <option value='VIP'> VIP </option>
                <option value='Cargo'> Cargo </option>
                <option value='Ferry'> Ferry </option>
                <option value='Medical'> Medical </option>
                <option value='Mail'> Mail </option>
                <option value='Overfly'> Overfly </option>
                <option value='Other'> Other </option>
              </select>
            </div>
          </div>
          <div className='w3-col w3-custom-half'>
            <div className='w3-row-padding'>
              <div className='w-full'>
                <h4 className='flex'> <span>Fuel Uplift (lb)</span> </h4>
              </div>
              <input
                type='number'
                min={0}
                value={localCopyLeg?.fuel_uplift || 0}
                className={`w-full w3-input w3-white w3-border w3-round`}
                onChange={handleChangeLegFuelUplift}
              />
            </div>
          </div>
        </div>

        <div className='w3-row-padding'>
          <div className='w-4'>
            <h4 className='flex'> <span>Pax</span> </h4>
          </div>
          <input
            type='number'
            min={0}
            value={localCopyLeg.pax_count || 0}
            className={`w-full w3-input w3-white w3-border w3-round`}
            onChange={e => handleChangeLegPax(e)}
          />
        </div>

        <div className='w3-row'>
          {AIRPORT_COLUMN('from', `${localCopyLeg?.departure_airport_icao || localCopyLeg?.departure?.airport_code?.[0]}/${localCopyLeg?.departure_airport_iata}`)}
          {AIRPORT_COLUMN('to', `${localCopyLeg?.destination_airport_icao || localCopyLeg?.destination?.airport_code?.[0]}/${localCopyLeg?.destination_airport_iata}`)}
        </div>

        <div className='w3-row-padding'>
          <AddedHotels
            leg={localCopyLeg}
            legs={legs}
            crewMembers={crewMembers}
            handleSetHotel={handleSetHotel}
            handleSethotelField={handleSethotelField}
            handleSethotelPAX={handleSethotelPAX}
          />
        </div>
        <div className='w3-row-padding'>
          <AddedTransports
            leg={localCopyLeg}
            handleSetTransport={handleSetTransport}
            handleChangeTransportField={handleChangeTransportField}
          />
        </div>
        <div className='w3-row-padding' style={{ display : 'none' }}>
          <AddedRentalCars
            leg={localCopyLeg}
            legs={legs}
            crewMembers={crewMembers}
            handleCarRentalChange={handleCarRentalChange}
            handleSetCarRental={handleSetCarRental}
          />
        </div>
        <div className='w3-row-padding'>
          <div className='w-full'>
            <h4 className='flex'> <span>Permit Description</span> </h4>
          </div>
          <textarea
            placeholder='Enter description...'
            value={localCopyLeg?.permit_description}
            className={`w-full w3-input w3-white w3-border w3-round resize-vertical-only`}
            onChange={handleChangeLegPermitDescription}
          />
        </div>
      </div>
      <div className="flex justify-end p-2">
        <button onClick={closeModal} className='w3-btn w3-grey w3-text-white'> Close </button>
        <button onClick={e => handleSaveUpdatedLeg(localCopyLeg)} className='w3-btn bg-primary-blue w3-text-white small-left-margin'> Save </button>
      </div>
      {ADD_CREWMEMBER_MODAL()}
    </Modal>
  );
}

export default LegSetupModal;