import { constants } from "../utils";

const GetInsurancePdfRequest = (token, id) => {
    var requestHeaders = new Headers();
    requestHeaders.append("Authorization", `Bearer ${token}`);
    requestHeaders.append("Content-Type", "application/json");
    return (fetch(`${constants.BASEURL}/api/medicalinsurance/get_medical_insurance_pdf/${id}/`, {
        method: "GET",        
        headers: requestHeaders,
        redirect: "follow"
    }));
};

export default GetInsurancePdfRequest;