import { constants } from "../utils";

const GetHotelPdfRequest = (token, id) => {
    var requestHeaders = new Headers();
    requestHeaders.append("Authorization", `Bearer ${token}`);
    requestHeaders.append("Content-Type", "application/json");
    return (fetch(`${constants.BASEURL}/api/hotelrequest/get_hotel_reservation/${id}/`, {
        method: "GET",        
        headers: requestHeaders,
        redirect: "follow"
    }));
};

export default GetHotelPdfRequest;