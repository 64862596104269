import React from 'react'

export default function FilterList(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeWidth="1.5"
                d="M19 4v6m0 0a2 2 0 100 4m0-4a2 2 0 110 4m0 0v6M12 4v12m0 0a2 2 0 100 4 2 2 0 000-4zM5 8v12M5 8a2 2 0 100-4 2 2 0 000 4z"
            ></path>
        </svg>
    )
}
